import { storage } from '../../firebase-config';
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  deleteObject,
} from 'firebase/storage';
import {
  PROGRESS,
  TEXT_URL,
  LEAD_FILE_UPLOAD_STATUS,
  LEAD_FILE_URL,
  CLIENT_LEAD_FILE_URL,
} from '../types';
import randomstring from 'randomstring';

export const uploadFile = (selected) => async (dispatch) => {
  const name = randomstring.generate(8);
  const uploadRef = ref(storage, `/texts/${name}`);
  const uploadTask = uploadBytesResumable(uploadRef, selected);

  uploadTask.on(
    'state_changed',
    (snapshot) => {
      const prog = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      dispatch({ type: PROGRESS, payload: prog });
    },
    (error) => console.log(error),
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        dispatch({ type: TEXT_URL, payload: downloadURL });
      });
    }
  );
};

//Upload tech file//
export const uploadLeadFile =
  ({ type, file }) =>
  async (dispatch) => {
    try {
      const name = randomstring.generate(8);
      const uploadRef = ref(storage, `/${type}/${name}`);
      const uploadTask = uploadBytesResumable(uploadRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          dispatch({ type: LEAD_FILE_UPLOAD_STATUS, payload: prog });
        },
        (error) => console.error(error.message),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const data = {
              url: downloadURL,
              type: type,
            };
            dispatch({ type: LEAD_FILE_URL, payload: data });
          });
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

//Upload tech file//
export const uploadClientLeadFile =
  ({ type, file }) =>
  async (dispatch) => {
    try {
      const name = randomstring.generate(8);
      const uploadRef = ref(storage, `/${type}/${name}`);
      const uploadTask = uploadBytesResumable(uploadRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          dispatch({ type: LEAD_FILE_UPLOAD_STATUS, payload: prog });
        },
        (error) => console.error(error.message),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const data = {
              url: downloadURL,
              type: type,
            };
            dispatch({ type: CLIENT_LEAD_FILE_URL, payload: data });
          });
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

//Delete file//
export const deleteFile =
  ({ type, name }) =>
  async (dispatch) => {
    const deleteRef = ref(storage, `${type}/${name}`);
    try {
      await deleteObject(deleteRef);
      return 'success';
    } catch (error) {
      console.log('Error deleting file; ', error.message);
      return 'error';
    }
  };
