import { useState, useEffect } from 'react';
import { Button, Modal, Box, Typography, Grid } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EngineeringIcon from '@mui/icons-material/Engineering';
import '../TechLogin.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ClaimTicket({
  claimTicket,
  techId,
  techName,
  ticketId,
  assignedTo,
  email,
  createdBy,
  deviceToken,
  username,
}) {
  const [open, setOpen] = useState(false);
  const [assignedId, setAssignedId] = useState(null);

  useEffect(() => {
    if (assignedTo) {
      setAssignedId(assignedTo.id);
    }
  }, [assignedTo]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const forMe = () => {
    claimTicket({
      ticketId,
      techId,
      techName,
      email,
      createdBy,
      deviceToken,
      username,
    });
  };

  return (
    <div>
      {assignedId ? (
        assignedId === techId ? (
          //Ticket belongs to currently signed in tech//
          <Button color="info">
            <EngineeringIcon />
          </Button>
        ) : (
          //ticket Belongs to another tech
          <Button color="success">
            <CheckCircleIcon />
          </Button>
        )
      ) : (
        <Button onClick={handleOpen} color="error">
          <CancelIcon />
        </Button>
      )}

      <Modal open={open} onClose={handleClose} id="modal-options">
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Are you sure you want to claim this ticket?
          </Typography>
          <Grid container>
            <Grid item xs={6}>
              <Button variant="outlined" onClick={handleClose}>
                No
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant="outlined" color="success" onClick={forMe}>
                Yes
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
