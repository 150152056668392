import { useState } from 'react';
import {
  List,
  ListItem,
  MenuItem,
  Menu,
  Typography,
  Grid,
} from '@mui/material';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

export default function SelectTech({ techs, setAssigned, assigned }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index, tech) => {
    const { id, name } = tech;
    let assign = { id, name };
    setAssigned(assign);
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List
        component="nav"
        aria-label="Device settings"
        sx={{ bgcolor: 'background.paper' }}
      >
        <ListItem
          button
          aria-haspopup="listbox"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
        >
          {assigned === null ? (
            <Grid container>
              <Grid item xs={8}>
                <Typography>Select Tech</Typography>
              </Grid>
              <Grid item xs={2}>
                <KeyboardDoubleArrowDownIcon />
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={8}>
                <Typography>{assigned.name}</Typography>
              </Grid>
              <Grid item xs={2}>
                <KeyboardDoubleArrowDownIcon />
              </Grid>
            </Grid>
          )}
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {techs.map((tech, index) => (
          <MenuItem
            key={tech.id}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index, tech)}
          >
            {tech.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
