import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  CircularProgress,
  Typography,
  Divider,
  useTheme,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getDepartment,
  addToDepartment,
  removeFromDepartment,
} from '../../../actions/clients/clients';
import { PersonOutline, ArrowBackIos } from '@mui/icons-material';

import ListOfUsers from './ListOfUsers';
import { tokens } from '../../../theme';

const Department = ({
  client: { client_users, loading, department_info },
  getDepartment,
  addToDepartment,
  removeFromDepartment,
  alert,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { departmentId } = useParams();
  const [addToArray, setAddToArray] = useState([]);
  const [removeFromArr, setRemoveFromArr] = useState([]);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (alert.length > 0) {
      setUpdating(false);
    }
  }, [alert]);

  useEffect(() => {
    getDepartment(departmentId);
  }, [departmentId, getDepartment]);
  const navigate = useNavigate();

  if (loading || client_users === null || department_info === null)
    return <CircularProgress />;

  const addThisUser = ({ userId, userName, userSurname }) => {
    const myArr = addToArray;
    let newUser = {
      name: userName,
      userId: userId,
      surname: userSurname,
    };
    myArr.push(newUser);
    setAddToArray(myArr);
  };

  const removeThisUser = (userId) => {
    const newArr = addToArray.filter((usr) => {
      return usr.userId !== userId;
    });

    setAddToArray(newArr);
  };

  const removeArr = (userId) => {
    let myArr = removeFromArr;
    myArr.push(userId);
    setRemoveFromArr(myArr);
  };
  const dontRemove = (userId) => {
    const myArr = removeFromArr.filter((id) => {
      return userId !== id;
    });

    setRemoveFromArr(myArr);
  };

  const updateUsers = () => {
    if (addToArray.length > 0) {
      let newUsers = addToArray;
      addToDepartment({ departmentId, newUsers });
    }
    if (removeFromArr.length > 0) {
      removeFromDepartment({ departmentId, removeFromArr });
    }

    setUpdating(true);

    setAddToArray([]);
    setRemoveFromArr([]);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Grid spacing={2} container>
        <Grid xs={1} item>
          <ArrowBackIos onClick={() => goBack()} />
        </Grid>
        <Grid xs={9} item>
          <Typography
            variant="h5"
            sx={{ color: colors.blueAccent[100], fontWeight: 'bold' }}
          >
            Department: {department_info && department_info.name}
          </Typography>
        </Grid>
        <Grid xs={2} item>
          {department_info && (
            <ListOfUsers
              client_users={client_users}
              info={department_info}
              addThisUser={addThisUser}
              removeThisUser={removeThisUser}
              updateUsers={updateUsers}
              removeArr={removeArr}
              dontRemove={dontRemove}
              alert={alert}
              updating={updating}
            />
          )}
        </Grid>
      </Grid>

      <Divider variant="middle" sx={{ mt: '10px', mb: '10px' }} />

      <Grid container>
        <Grid xs={6} item>
          <Typography
            sx={{
              color: colors.grey[500],
              fontWeight: 'bold',
              ml: '10px',
            }}
          >
            Department Users
          </Typography>
          <Divider variant="middle" sx={{ mt: '10px', mb: '10px' }} />
          {department_info.users.length === 0
            ? 'No Users'
            : department_info.users.map((user) => (
                <Container key={user.userId}>
                  <Grid container>
                    <Grid item>
                      <PersonOutline />
                    </Grid>
                    <Grid item>
                      <Typography>
                        {user.name} {user.surname}
                      </Typography>
                    </Grid>
                  </Grid>
                </Container>
              ))}
        </Grid>
        <Grid xs={6} item>
          <Typography
            sx={{
              color: colors.grey[500],
              fontWeight: 'bold',
              ml: '10px',
            }}
            severity="info"
          >
            Department Activity
          </Typography>
          <Divider variant="middle" sx={{ mt: '10px', mb: '10px' }} />
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: state.client,
  alert: state.alert,
});

export default connect(mapStateToProps, {
  getDepartment,
  addToDepartment,
  removeFromDepartment,
})(Department);
