import {
  CircularProgress,
  Container,
  Divider,
  Box,
  Typography,
} from '@mui/material';
import React from 'react';

const Initialize = () => {
  return (
    <Container>
      <Box m={1} display="flex" justifyContent="center">
        <CircularProgress size={300} sx={{ marginBottom: '30px' }} />
        <Divider />
      </Box>
      <Typography display="flex" justifyContent="center" variant="h4">
        Loading Support App...
      </Typography>
    </Container>
  );
};

export default Initialize;
