import {
  MY_PROJECTS,
  MY_PROJECT,
  CLEAR_MY_PROJECTS,
  MY_PROJECT_MILE,
  MY_PROJECT_NEXT,
  MY_PROJECT_FEATURE,
} from '../../actions/types';

const initState = {
  projects: null,
  project: null,
  next: null,
  milestones: null,
  feature: null,
  loading: true,
};

function projectReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case MY_PROJECTS:
      return {
        ...state,
        projects: payload,
        loading: false,
      };
    case MY_PROJECT:
      return {
        ...state,
        project: payload,
        loading: false,
      };
    case MY_PROJECT_MILE:
      return {
        ...state,
        milestones: payload,
        loading: false,
      };
    case MY_PROJECT_NEXT:
      return {
        ...state,
        next: payload,
        loading: false,
      };
    case MY_PROJECT_FEATURE:
      return {
        ...state,
        feature: payload,
        loading: false,
      };
    case CLEAR_MY_PROJECTS:
      return {
        ...state,
        projects: null,
        project: null,
        loading: true,
      };
    default:
      return state;
  }
}

export default projectReducer;
