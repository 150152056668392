import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ArrowBackIos,
  Cancel,
  Email,
  Phone,
  PushPinOutlined,
  Remove,
} from '@mui/icons-material/';
import {
  updateClientToLead,
  updateNoteToNewLead,
  updateFollowToNewNote,
  updateCollab,
  removeFromCollab,
  createALead,
} from '../../../actions/users/sales';
import {
  getBranches,
  getDepartments,
  getClientUsers,
  currentUserClient,
} from '../../../actions/users/admin';
import { v4 as uuidv4 } from 'uuid';
import { tokens } from '../../../theme';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import AddLeadNote from './AddLeadNote';
import AddLeadfollowUp from './AddLeadFollow';
import AssignToUser from '../../layout/AssignToUser';
import AssignToBranch from '../../layout/AssignToBranch';
import AssignToDepartment from '../../layout/AssignToDepartment';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmLeadCreation from './ConfirmLeadCreation';
import { setAlert } from '../../../actions/alert';

const AddClientLead = ({
  user_admin: { loading, branches, departments, users },
  updateClientToLead,
  sales: { client, lead_notes, lead_followUps, collabSales },
  user,
  updateNoteToNewLead,
  updateFollowToNewNote,
  getBranches,
  getDepartments,
  getClientUsers,
  updateCollab,
  removeFromCollab,
  currentUserClient,
  setAlert,
  createALead,
}) => {
  const { clientId } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [type, setType] = useState(null);
  const [desc, setDesc] = useState('');
  const [value, setValue] = useState(0);
  let navigate = useNavigate();

  useEffect(() => {
    getBranches(clientId);
    getDepartments(clientId);
    getClientUsers(clientId);
    currentUserClient(clientId);
  }, [
    clientId,
    getBranches,
    getDepartments,
    getClientUsers,
    currentUserClient,
  ]);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    address: '',
  });

  const { name, email, number, address } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    updateClientToLead(formData);
  };

  const removeClient = () => {
    updateClientToLead(null);
  };

  const addToNoteArr = ({ createdAt, createdBy, note }) => {
    const _id = uuidv4();
    const newNote = {
      _id,
      createdAt,
      createdBy,
      note,
    };
    let arr = lead_notes.concat(newNote);
    updateNoteToNewLead(arr);
  };

  const removeNote = (id) => {
    const arr = lead_notes.filter((note) => {
      return note._id !== id;
    });
    updateNoteToNewLead(arr);
  };

  const addToFollowUpArr = ({ createdAt, createdBy, date, note }) => {
    const _id = uuidv4();
    const newFollowU = {
      _id,
      createdAt,
      createdBy,
      date,
      note,
    };
    let arr = lead_followUps.concat(newFollowU);
    updateFollowToNewNote(arr);
  };

  const removeFollowup = (id) => {
    const arr = lead_followUps.filter((followUp) => {
      return followUp._id !== id;
    });
    updateFollowToNewNote(arr);
  };

  const assignThisDude = ({ responsible }) => {
    updateCollab({ responsible });
  };
  const removeThisDude = () => {
    removeFromCollab();
  };
  const navToNewLead = (res) => {
    removeThisDude();
    removeClient();
    updateFollowToNewNote([]);
    updateNoteToNewLead([]);
    navigate(`/user/sales/client-lead/${res}`);
  };

  const goBack = () => {
    navigate(-1);
  };

  if (loading || users === null) {
    return <CircularProgress />;
  }
  return (
    <Container>
      <Box>
        <Grid container>
          <Grid item xs={1}>
            <Button onClick={goBack}>
              <ArrowBackIos />
            </Button>
          </Grid>

          <Grid xs={8} item>
            <Typography variant="h5" sx={{ color: colors.blueAccent[100] }}>
              Add a new lead
            </Typography>
          </Grid>

          <Grid xs={2} item>
            <ConfirmLeadCreation
              type={type}
              client={client}
              clientId={clientId}
              user={user}
              desc={desc}
              collabSales={collabSales}
              notes={lead_notes}
              setAlert={setAlert}
              followUps={lead_followUps}
              createALead={createALead}
              navToNewLead={navToNewLead}
            />
          </Grid>
        </Grid>
        <Divider variant="middle" sx={{ mb: '10px', mt: '10px' }} />
        <Grid container>
          <Grid item xs={4}>
            <Typography sx={{ color: colors.grey[300], fontWeight: 'bold' }}>
              Lead details
            </Typography>
            <Divider variant="middle" sx={{ mb: '10px', mt: '10px' }} />
            <Grid sx={{ mb: '10px' }} item>
              <Typography
                sx={{
                  textAlign: 'center',
                  mb: '5px',
                  color: colors.grey[500],
                  fontWeight: 'bold',
                }}
              >
                Lead type
              </Typography>
              <Grid container>
                <Grid xs={4} item>
                  <Button
                    variant={type === 'Sale' ? 'contained' : 'outlined'}
                    sx={{
                      color: type !== 'Sale' ? colors.grey[500] : '',
                      borderColor:
                        type === 'Sale'
                          ? colors.blueAccent[100]
                          : colors.grey[600],
                      backgroundColor:
                        type === 'Sale' ? colors.blueAccent[100] : '',
                    }}
                    fullWidth
                    onClick={() => setType('Sale')}
                  >
                    Sale
                  </Button>
                </Grid>
                <Grid xs={4} item>
                  <Button
                    variant={type === 'Project' ? 'contained' : 'outlined'}
                    sx={{
                      color: type !== 'Project' ? colors.grey[500] : '',
                      borderColor:
                        type === 'Project'
                          ? colors.blueAccent[100]
                          : colors.grey[600],
                      backgroundColor:
                        type === 'Project' ? colors.blueAccent[100] : '',
                    }}
                    fullWidth
                    onClick={() => setType('Project')}
                  >
                    Project
                  </Button>
                </Grid>
                <Grid xs={4} item>
                  <Button
                    variant={type === 'Client' ? 'contained' : 'outlined'}
                    sx={{
                      color: type !== 'Client' ? colors.grey[500] : '',
                      borderColor:
                        type === 'Client'
                          ? colors.blueAccent[100]
                          : colors.grey[600],
                      backgroundColor:
                        type === 'Client' ? colors.blueAccent[100] : '',
                    }}
                    fullWidth
                    onClick={() => setType('Client')}
                  >
                    Client
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <TextField
                label="Lead Description"
                multiline
                maxRows={4}
                name="desc"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                autoComplete="false"
                fullWidth
                required
              />
            </Grid>
            <Grid item>
              <TextField
                label="Value"
                type="number"
                name="value"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                autoComplete="false"
                fullWidth
                required
              />
            </Grid>

            {client ? (
              <>
                <Grid container>
                  <Grid xs={10} item>
                    <Typography
                      sx={{
                        mt: '8px',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color: colors.grey[500],
                      }}
                    >
                      You have added; {client.name}
                    </Typography>
                  </Grid>
                  <Grid xs={2} item>
                    <Button onClick={() => removeClient()}>
                      <Cancel sx={{ color: colors.redAccent[400] }} />
                    </Button>
                  </Grid>
                </Grid>
                <Divider variant="middle" sx={{ mt: '5px' }} />
                <List>
                  <ListItem>
                    <ListItemButton>
                      <Email />
                    </ListItemButton>
                    <ListItemText primary={client.email} />
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <Phone />
                    </ListItemButton>
                    <ListItemText primary={client.number} />
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <PushPinOutlined />
                    </ListItemButton>
                    <ListItemText primary={client.address} />
                  </ListItem>
                </List>
              </>
            ) : (
              <form onSubmit={onSubmit}>
                <Grid item>
                  <TextField
                    label="Company Name"
                    type="text"
                    name="name"
                    value={name}
                    onChange={onChange}
                    autoComplete="false"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item>
                  <TextField
                    label="Company Email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={onChange}
                    autoComplete="false"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Company Number"
                    type="number"
                    name="number"
                    value={number}
                    onChange={onChange}
                    autoComplete="false"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Company Address"
                    type="text"
                    name="address"
                    value={address}
                    onChange={onChange}
                    autoComplete="false"
                    fullWidth
                    required
                  />
                </Grid>

                <Button
                  sx={{ backgroundColor: 'green', mt: '15px' }}
                  fullWidth
                  variant="contained"
                  type="submit"
                >
                  Confirm client details
                </Button>
              </form>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ color: colors.grey[300], fontWeight: 'bold' }}>
              Notes and follow ups
            </Typography>
            <Divider sx={{ mb: '10px', mt: '10px' }} variant="middle" />
            <Grid container>
              <Grid item xs={6}>
                <AddLeadNote addToNoteArr={addToNoteArr} user={user} />
              </Grid>
              <Grid item xs={6}>
                <AddLeadfollowUp
                  addToFollowUpArr={addToFollowUpArr}
                  user={user}
                />
              </Grid>
            </Grid>
            <Typography
              sx={{
                textAlign: 'center',
                color: colors.greenAccent[500],
                borderColor: colors.greenAccent[500],
              }}
            >
              Lead notes
            </Typography>
            <Divider variant="middle" />
            {lead_notes.length === 0 ? (
              <Typography
                sx={{ textAlign: 'center', mt: '5px', color: colors.grey[400] }}
              >
                Add a note
              </Typography>
            ) : (
              lead_notes.map((note) => (
                <ListItem
                  key={note._id}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      color="warning"
                      onClick={() => removeNote(note._id)}
                    >
                      <Remove />
                    </IconButton>
                  }
                >
                  <ListItemText primary={`${note.note}`} />
                </ListItem>
              ))
            )}

            <Typography
              sx={{
                textAlign: 'center',
                mt: '10px',
                color: colors.greenAccent[500],
                borderColor: colors.greenAccent[500],
              }}
            >
              Lead follow ups
            </Typography>
            <Divider variant="middle" />
            {lead_followUps.length === 0 ? (
              <Typography
                sx={{ textAlign: 'center', mt: '5px', color: colors.grey[400] }}
              >
                Add a follow up
              </Typography>
            ) : (
              lead_followUps.map((followUp) => (
                <ListItem
                  key={followUp._id}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      color="warning"
                      onClick={() => removeFollowup(followUp._id)}
                    >
                      <Remove />
                    </IconButton>
                  }
                >
                  <ListItemText primary={`${followUp.note}`} />
                </ListItem>
              ))
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ color: colors.grey[300], fontWeight: 'bold' }}>
              Collaboration
            </Typography>
            <Grid container>
              <Grid item xs={4}>
                <AssignToBranch
                  branches={branches}
                  assignedToDo={collabSales.responsible}
                  assignThisDude={assignThisDude}
                />
              </Grid>
              <Grid item xs={4}>
                <AssignToDepartment
                  departments={departments}
                  assignedToDo={collabSales.responsible}
                  assignThisDude={assignThisDude}
                />
              </Grid>
              <Grid item xs={4}>
                <AssignToUser
                  users={users}
                  assignedToDo={collabSales.responsible}
                  assignThisDude={assignThisDude}
                />
              </Grid>
            </Grid>

            <Divider
              variant="middle"
              sx={{ marginBottom: '5px', marginTop: '5px' }}
            />
            {collabSales.responsible && (
              <ListItem
                secondaryAction={
                  <IconButton onClick={removeThisDude} color="warning">
                    <Remove />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={`Assigned to: ${collabSales.responsible.name}`}
                />
              </ListItem>
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  sales: state.client_sales,
  user: state.user.user_profile,
  user_admin: state.user_admin,
});

export default connect(mapStateToProps, {
  updateClientToLead,
  updateNoteToNewLead,
  updateFollowToNewNote,
  getBranches,
  getDepartments,
  getClientUsers,
  updateCollab,
  removeFromCollab,
  currentUserClient,
  setAlert,
  createALead,
})(AddClientLead);
