import { useState, useEffect } from 'react';
import {
  List,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import InfoIcon from '@mui/icons-material/Info';

const Tickets = ({ ticket }) => {
  const [triage, setTriage] = useState(null);
  useEffect(() => {
    if (ticket) {
      if (ticket.howbad === 'Not so bad') setTriage('grey');
      if (ticket.howbad === 'Really bad') setTriage('orange');
      if (ticket.howbad === 'Its a disaster') setTriage('red');
    }
  }, [setTriage, ticket]);
  let navigate = useNavigate();
  const { header } = ticket;
  const fh = header.substring(0, 33);
  return (
    <>
      <List sx={{ bgcolor: `${triage}` }}>
        <ListItemButton
          onClick={() => navigate(`/tech/tech-ticket/${ticket.id}`)}
        >
          <ListItem
            secondaryAction={
              <IconButton edge="end">
                <ForumOutlinedIcon />
              </IconButton>
            }
          >
            <ListItemIcon>
              <InfoIcon sx={{ mr: 2 }} />
              <ListItemText primary={`${fh}...`} />
            </ListItemIcon>
          </ListItem>
        </ListItemButton>
      </List>
      <Divider />
    </>
  );
};

export default Tickets;
