import { useState } from 'react';
import { connect } from 'react-redux';
import { updateUser } from '../../../actions/clients/clients';
import {
  Container,
  Grid,
  Alert,
  AlertTitle,
  TextField,
  Modal,
  Button,
  Box,
} from '@mui/material';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import EditIcon from '@mui/icons-material/Edit';

const UpdateUser = ({ user, updateUser, userId }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(user.name);
  const [surname, setSurname] = useState(user.surname);
  const [cellphone, setCellphone] = useState(user.cellphone);
  const [position, setPosition] = useState(user.position);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setName(user.name);
    setSurname(user.surname);
    setCellphone(user.cellphone);
    setPosition(user.position);
    setOpen(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    updateUser({ name, surname, cellphone, position, userId });
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  return (
    <Container>
      <Button severity="primary" onClick={handleOpen} size="small">
        <EditIcon />
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style }}>
          <Grid item>
            <Alert icon={false} variant="filled" severity="info">
              <AlertTitle>Update A User:</AlertTitle>
            </Alert>
            <form onSubmit={onSubmit}>
              <Grid mt={2} item>
                <TextField
                  label="User Name"
                  variant="outlined"
                  type="text"
                  placeholder={name}
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoComplete="false"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <TextField
                  label="User Surname"
                  variant="outlined"
                  type="text"
                  name="surname"
                  placeholder={surname}
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <TextField
                  label="User Position"
                  variant="outlined"
                  type="text"
                  placeholder={position}
                  name="position"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <TextField
                  label="User cellphone"
                  variant="outlined"
                  type="text"
                  placeholder={cellphone}
                  name="cellphone"
                  value={cellphone}
                  onChange={(e) => setCellphone(e.target.value)}
                  fullWidth
                />
              </Grid>

              <Button type="submit" variant="contained" color="info" fullWidth>
                Update User <SkipNextIcon />
              </Button>
            </form>
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
};

export default connect(null, { updateUser })(UpdateUser);
