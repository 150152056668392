import {
  CLIENT_UPDATE_TASKLIST,
  CLIENT_ASSIGN_TODO,
  CLIENT_TODO_DEADLINE,
  CLIENT_MY_TODOS,
  CLIENT_MY_TODO,
  CLIENT_CLEAR_TODOS,
} from '../types';
import { firestore } from '../../firebase-config';
import {
  addDoc,
  collection,
  getDocs,
  orderBy,
  query,
  where,
  doc,
  getDoc,
} from 'firebase/firestore';
import { setAlert } from '../alert';
import moment from 'moment';

//Update TaskList//
export const updateTaskList = (taskList) => async (dispatch) => {
  try {
    dispatch({ type: CLIENT_UPDATE_TASKLIST, payload: taskList });
  } catch (error) {
    console.error(error.message);
  }
};

//Assign to todo//
export const assignToDo =
  ({ responsible }) =>
  async (dispatch) => {
    try {
      dispatch({ type: CLIENT_ASSIGN_TODO, payload: responsible });
    } catch (error) {
      console.error(error.message);
    }
  };

//Remove assignment//
export const unAssignTodo = () => async (dispatch) => {
  try {
    dispatch({ type: CLIENT_ASSIGN_TODO, payload: null });
  } catch (error) {
    console.error(error.message);
  }
};

//Set todo deadline//
export const setTodoDeadline =
  ({ todoDeadline }) =>
  async (dispatch) => {
    try {
      dispatch({ type: CLIENT_TODO_DEADLINE, payload: todoDeadline });
    } catch (error) {
      console.error(error.message);
    }
  };

//Remove todo deadline//
export const removeTodoDeadline = () => (dispatch) => {
  try {
    dispatch({ type: CLIENT_TODO_DEADLINE, payload: null });
  } catch (error) {
    console.error(error.message);
  }
};

//Create ToDo//
export const createMyToDo =
  ({
    todoName,
    todoDescription,
    taskList,
    assignedTo,
    deadline,
    createdBy,
    clientId,
  }) =>
  async (dispatch) => {
    const todoRef = collection(firestore, 'client_todo');
    const createdAt = moment().format();
    try {
      const todo = await addDoc(todoRef, {
        todoName,
        todoDescription,
        taskList,
        assignedTo,
        deadline,
        createdBy,
        createdAt,
        clientId,
      });
      dispatch(setAlert('ToDo created successfully', 'success'));
      return todo.id;
    } catch (error) {
      console.error(error.message);
    }
  };

export const fetchTodos = (client) => async (dispatch) => {
  try {
    const q = query(
      collection(firestore, 'client_todo'),
      where('clientId', '==', client),
      orderBy('createdAt')
    );
    const jam = await getDocs(q);

    const todos = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    dispatch({ type: CLIENT_MY_TODOS, payload: todos });
  } catch (error) {
    console.error(error.message);
  }
};

export const myTodos = () => async (dispatch) => {
  try {
    ///
  } catch (error) {
    console.error(error.message);
  }
};

export const getTODO = (todoId) => async (dispatch) => {
  dispatch({ type: CLIENT_CLEAR_TODOS });
  const todoRef = doc(firestore, 'client_todo', todoId);

  try {
    const todoDoc = await getDoc(todoRef);
    const todo = todoDoc.data();
    dispatch({ type: CLIENT_MY_TODO, payload: todo });
  } catch (error) {
    console.error(error.message);
  }
};
