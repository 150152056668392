import {
  PENDING_TICKETS,
  ADMIN_TICKET,
  ADMIN_TEXTS,
  CLEAR_ADMIN_TICKET,
  CLIENT_TICKETS,
  USER_TICKETS,
  CUSTOM_TICKET_RANGE,
  TIME_ON_TICKETS,
  TIME_ON_CUSTOM_TICKETS,
  STILL_ACTIVE,
  CUSTOM_STILL_ACTIVE,
  TICKETS_THAT_NEED_REVIEW,
} from '../../actions/types';

const initState = {
  pending_tickets: null,
  client_tickets: null,
  custom_tickets: null,
  user_tickets: null,
  tickets_need_review: null,
  ticket: null,
  texts: null,
  loading: true,
  time_on_tickets: null,
  time_on_custom_tickets: null,
  stillActive: 0,
  customStillActive: 0,
};

function ticketReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case TICKETS_THAT_NEED_REVIEW:
      return {
        ...state,
        tickets_need_review: payload,
      };
    case PENDING_TICKETS:
      return {
        ...state,
        pending_tickets: payload,
        loading: false,
      };
    case CLIENT_TICKETS:
      return {
        ...state,
        client_tickets: payload,
        loading: false,
      };
    case CUSTOM_TICKET_RANGE:
      return {
        ...state,
        custom_tickets: payload,
      };
    case USER_TICKETS:
      return {
        ...state,
        user_tickets: payload,
      };
    case ADMIN_TICKET:
      return {
        ...state,
        ticket: payload,
        loading: false,
      };
    case ADMIN_TEXTS:
      return {
        ...state,
        texts: payload,
        loading: false,
      };
    case TIME_ON_TICKETS:
      return {
        ...state,
        time_on_tickets: payload,
      };
    case STILL_ACTIVE:
      return {
        ...state,
        stillActive: payload,
      };
    case TIME_ON_CUSTOM_TICKETS:
      return {
        ...state,
        time_on_custom_tickets: payload,
      };
    case CUSTOM_STILL_ACTIVE:
      return {
        ...state,
        customStillActive: payload,
      };
    case CLEAR_ADMIN_TICKET:
      return {
        ...state,
        ticket: null,
        custom_tickets: null,
        texts: null,
      };
    default:
      return state;
  }
}

export default ticketReducer;
