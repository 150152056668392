import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  useTheme,
  Container,
  Grid,
  Button,
  Typography,
  Divider,
  Card,
  CardContent,
  TextField,
  ListItem,
  IconButton,
  ListItemText,
} from '@mui/material';
import {
  updateCollab,
  removeFromCollab,
  updateTaskList,
  removeTodoDeadline,
} from '../../../actions/techs/todo';
import BackSpaceIcon from '@mui/icons-material/ArrowBackIos';
import RemoveIcon from '@mui/icons-material/Remove';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { tokens } from '../../../theme';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ListOfTechs from '../ListOfTechs';
import AddTask from './AddTask';
import OnceOffDeadline from './OnceOffDeadline';
import DailyDeadline from './DailyDeadline';
import WeeklyDeadline from './WeeklyDeadline';
import MonthlyDeadline from './MonthlyDeadline';
import moment from 'moment';
import ConfirmTodo from './ConfirmTodo';

const CreateTodo = ({
  updateTaskList,
  updateCollab,
  removeFromCollab,
  tech_profile,
  todo: { taskList, todoDeadline, collabCacheAdd },
  removeTodoDeadline,
}) => {
  const [deadlineType, setDeadlineType] = useState(null);
  const [dday, setDday] = useState(null);
  const [todoName, setToDoName] = useState('');
  const [todoDescription, setToDoDescription] = useState('');
  useEffect(() => {
    if (todoDeadline) {
      setDeadlineType(todoDeadline.type);
      setDday(todoDeadline.deadline);
    } else {
      setDeadlineType(null);
      setDday(null);
    }
  }, [todoDeadline]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const buttonStyles = {
    marginBottom: '8px',
    color: colors.grey[500],
  };

  const addToTaskArr = (title) => {
    const myArr = taskList;
    const id = uuidv4();

    const newTask = {
      done: false,
      id,
      complete: false,
      title: title,
    };

    myArr.push(newTask);
    updateTaskList(myArr);
  };

  const removeFromTaskArr = (taskId) => {
    const newArr = taskList.filter((task) => {
      return task.id !== taskId;
    });

    updateTaskList(newArr);
  };

  const removeDeadline = () => {
    removeTodoDeadline();
  };

  const addTech = (tech) => {
    //Check to see if tech is part of orignal team//
    const loot = collabCacheAdd.filter((collab) => {
      let id = collab.id;
      let techId = tech.id;
      return id === techId;
    });
    if (loot.length === 0) {
      //If tech is not part of the original team add them to add cache//
      let arr = collabCacheAdd;
      arr.push(tech);
      updateCollab(arr);
    }
  };

  const removeTech = (techId) => {
    //Check to see if tech is part of orignal team//
    const loot = collabCacheAdd.filter((collab) => {
      let id = collab.id;
      return id !== techId;
    });
    removeFromCollab(loot);
    //just remove from add cache//
    const arr = collabCacheAdd.filter((tech) => {
      let id = tech.id;
      return id !== techId;
    });
    updateCollab(arr);
  };

  const upadateMyCollab = (tech) => {
    console.log('this doesnt do anything');
  };

  const goBack = () => {
    updateTaskList([]);
    removeTech();
    removeDeadline();
    navigate(-1);
  };

  const navigateToCreatedToDo = (todoId) => {
    updateTaskList([]);
    removeTech();
    removeDeadline();
    navigate(`/tech/tech-todo/${todoId}`);
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={1}>
          <Button size="small" sx={{ ...buttonStyles }} onClick={goBack}>
            <BackSpaceIcon />
          </Button>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h5" color="green">
            Add Todo
          </Typography>
        </Grid>

        <Grid item xs={1}>
          <ConfirmTodo
            todoName={todoName}
            todoDescription={todoDescription}
            taskList={taskList}
            assignedToDo={collabCacheAdd}
            todoDeadline={todoDeadline}
            navigateToCreatedToDo={navigateToCreatedToDo}
            tech_profile={tech_profile}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={2}>
        <Grid xs={4} item>
          <Typography variant="h6" color="grey" gutterBottom>
            Todo details:
          </Typography>

          <Card sx={{ marginBottom: '10px' }}>
            <CardContent>
              <Grid item>
                <TextField
                  label="Todo Name"
                  type="text"
                  placeholder="Todo Name"
                  variant="outlined"
                  value={todoName}
                  onChange={(e) => setToDoName(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Todo description"
                  placeholder="Todo description"
                  rows={4}
                  value={todoDescription}
                  onChange={(e) => setToDoDescription(e.target.value)}
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item>
                <AddTask addToTaskArr={addToTaskArr} />
                {taskList.length === 0 ? (
                  <Typography textAlign="center" mt="5px">
                    Add your first task
                  </Typography>
                ) : (
                  taskList.map((task) => (
                    <ListItem
                      key={task.id}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          color="warning"
                          onClick={() => removeFromTaskArr(task.id)}
                        >
                          <RemoveIcon />
                        </IconButton>
                      }
                    >
                      <IconButton>
                        <RadioButtonUncheckedIcon />
                      </IconButton>

                      <ListItemText primary={`${task.title}`} />
                    </ListItem>
                  ))
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={4} item>
          <Typography variant="h6" color="grey" gutterBottom sx={{ m: '3px' }}>
            Assign Todo:
          </Typography>
          <Grid item xs={2}>
            <ListOfTechs
              addTech={addTech}
              removeTech={removeTech}
              upadateMyCollab={upadateMyCollab}
              collab={collabCacheAdd}
            />

            <Divider
              variant="middle"
              sx={{ marginBottom: '5px', marginTop: '5px' }}
            />
          </Grid>
          {collabCacheAdd.length === 0 ? (
            <Typography
              sx={{
                color: colors.grey[500],
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              Would you like to collaborate?
            </Typography>
          ) : (
            collabCacheAdd.map((collab) => (
              <Container key={collab.id}>
                <ListItem
                  secondaryAction={
                    <IconButton
                      edge="end"
                      color="warning"
                      onClick={() => removeTech(collab.id)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={`${collab.name}`} />
                </ListItem>
              </Container>
            ))
          )}
        </Grid>
        <Grid xs={4} item>
          <Typography variant="h6" color="grey" gutterBottom>
            Todo deadlines:
          </Typography>
          <OnceOffDeadline deadlineType={deadlineType} />
          <Divider
            variant="middle"
            sx={{ marginBottom: '5px', marginTop: '5px' }}
          />
          <DailyDeadline deadlineType={deadlineType} />
          <Divider
            variant="middle"
            sx={{ marginBottom: '5px', marginTop: '5px' }}
          />
          <WeeklyDeadline deadlineType={deadlineType} />
          <Divider
            variant="middle"
            sx={{ marginBottom: '5px', marginTop: '5px' }}
          />
          <MonthlyDeadline deadlineType={deadlineType} />

          <Divider
            variant="middle"
            sx={{ marginBottom: '15px', marginTop: '5px' }}
          />
          {deadlineType && deadlineType === 'Once' ? (
            <ListItem
              secondaryAction={
                <IconButton onClick={removeDeadline} color="warning">
                  <RemoveIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={` Deadline day: ${moment(dday).format(
                  'MMMM Do YYYY'
                )}`}
              />
            </ListItem>
          ) : deadlineType === 'Daily' ? (
            <ListItem
              secondaryAction={
                <IconButton onClick={removeDeadline} color="warning">
                  <RemoveIcon />
                </IconButton>
              }
            >
              <ListItemText primary={`Deadline day: Daily @ ${dday}`} />
            </ListItem>
          ) : deadlineType === 'Weekly' ? (
            <ListItem
              secondaryAction={
                <IconButton onClick={removeDeadline} color="warning">
                  <RemoveIcon />
                </IconButton>
              }
            >
              <ListItemText primary={`Deadline day: Weekly on ${dday}`} />
            </ListItem>
          ) : deadlineType === 'Monthly' ? (
            <ListItem
              secondaryAction={
                <IconButton onClick={removeDeadline} color="warning">
                  <RemoveIcon />
                </IconButton>
              }
            >
              <ListItemText primary={`Deadline day: Monthly on the ${dday}`} />
            </ListItem>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  tech_profile: state.tech.tech_profile,
  todo: state.todo,
});

export default connect(mapStateToProps, {
  updateCollab,
  removeFromCollab,
  updateTaskList,
  removeTodoDeadline,
})(CreateTodo);
