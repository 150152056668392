import React from 'react';
import { Alert } from '@mui/material';

const TechItem = ({ tech }) => {
  return (
    <Alert variant="outlined" style={{ marginTop: '10px' }}>
      {tech.name} {tech.surname}
    </Alert>
  );
};

export default TechItem;
