import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';

const MyTickets = ({ ticket }) => {
  let navigate = useNavigate();

  return (
    <Box
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      id="tickets-box"
    >
      <List>
        <ListItemButton
          onClick={() => navigate(`/user/my-ticket/${ticket.id}`)}
        >
          <ListItem
            secondaryAction={
              <IconButton edge="end">
                <ForumOutlinedIcon />
              </IconButton>
            }
          >
            <ListItemIcon>
              <InfoIcon sx={{ mr: 2 }} />
              <ListItemText primary={`${ticket.header}`} />
            </ListItemIcon>
          </ListItem>
        </ListItemButton>
        <Divider />
      </List>
    </Box>
  );
};

export default MyTickets;
