import React, { useEffect, useState } from 'react';
import { Typography, Card, CardContent, CardMedia } from '@mui/material';
import ReactAudioPlayer from 'react-audio-player';

import moment from 'moment';

const Text = ({ text, techId }) => {
  const [author, setAuthor] = useState();
  const [img, setImg] = useState(null);
  const [voice, setVoice] = useState(null);
  useEffect(() => {
    if (text) {
      if (text.authorId === techId) {
        setAuthor('You');
      } else {
        setAuthor(text.authorName);
      }
      const audioChecker = text.text.split('%');
      const check = audioChecker[0].split('/');
      const loot = check[check.length - 1];
      if (loot === 'voiceNote') {
        return setVoice(text.text);
      }

      if (text.text.split('.').shift() === 'https://firebasestorage')
        setImg(text.text);
    }
  }, [text, techId]);

  const time = moment(text.createdAt).calendar();

  return (
    <Card id={text.authorId === techId ? 'my-text' : 'sender'}>
      <CardContent>
        {voice ? (
          <ReactAudioPlayer src={voice} controls />
        ) : img ? (
          <CardMedia component="img" image={img} alt="Image failed to load" />
        ) : (
          <Typography>{text.text}</Typography>
        )}
      </CardContent>
      <Typography variant="caption" sx={{ flexDirection: 'column' }}>
        {author}: {time}
      </Typography>
    </Card>
  );
};

export default Text;
