import { useEffect, useState } from 'react';
import { Button, Modal, Box, Typography, Grid, Divider } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function MarkComplete({
  closeTicket,
  ticketId,
  closeBtn,
  alert,
  userName,
  email,
}) {
  const [open, setOpen] = useState(false);
  const [closing, setClosing] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    if (alert.length > 0) {
      handleClose();
    }
  }, [alert]);

  const done = () => {
    let user = userName;
    closeTicket({ ticketId, user, email });
    setClosing(true);
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="success"
        size="small"
        onClick={handleOpen}
      >
        Close Ticket
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Are you sure you would like to close the ticket?
          </Typography>
          <Divider />
          <Grid container>
            <Grid item xs={6}>
              <Button variant="outlined" onClick={handleClose}>
                No
              </Button>
            </Grid>
            <Grid item xs={6}>
              {closing ? (
                <LoadingButton loading>Yes</LoadingButton>
              ) : (
                <Button
                  variant="outlined"
                  color="success"
                  ref={closeBtn}
                  onClick={done}
                >
                  Yes
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
