import { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  CircularProgress,
  Container,
  Typography,
  Grid,
  Divider,
  Alert,
} from '@mui/material';
import Tickets from './Tickets';
import {
  fetchTickets,
  getTicketsThatNeedReview,
} from '../../../actions/techs/ticket';

const Helpdesk = ({
  tech_tickets: { pending_tickets, tickets_need_review },
  fetchTickets,
  getTicketsThatNeedReview,
}) => {
  useEffect(() => {
    fetchTickets();
    getTicketsThatNeedReview();
  }, [fetchTickets, getTicketsThatNeedReview]);

  if (pending_tickets === null || tickets_need_review === null)
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Welcome to the helpdesk
      </Typography>
      <Divider
        variant="middle"
        sx={{ marginBottom: '5px', marginTop: '5px' }}
      />
      <Grid container>
        <Grid xs={6} item>
          <Typography variant="h5" mb="10px">
            Active tickets
          </Typography>
          {pending_tickets.length === 0 ? (
            <Alert>There are no pending tickets</Alert>
          ) : (
            pending_tickets.map((ticket) => (
              <Tickets key={ticket.id} ticket={ticket} />
            ))
          )}
        </Grid>
        <Grid xs={6} item>
          <Typography variant="h5" mb="10px">
            Ticket review
          </Typography>
          {tickets_need_review.length === 0 ? (
            <Alert>There are no pending tickets</Alert>
          ) : (
            tickets_need_review.map((ticket) => (
              <Tickets key={ticket.id} ticket={ticket} />
            ))
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  tech_tickets: state.tech_tickets,
});

export default connect(mapStateToProps, {
  fetchTickets,
  getTicketsThatNeedReview,
})(Helpdesk);
