import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Grid,
  Alert,
  AlertTitle,
  TextField,
  Modal,
  Button,
  Box,
} from '@mui/material';
import { supportAgent } from '../../actions/business';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { setAlert } from '../../actions/alert';
import moment from 'moment';

const AddSupportAgent = ({ supportAgent, setAlert, business_profile }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    password: '',
    password2: '',
  });

  const { name, surname, email, password, password2 } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (password !== password2) {
      setAlert('Passwords do not match', 'error');
    } else {
      let createdAt = moment().format();
      let businessName = business_profile.name;
      let businessId = business_profile.id;
      let isAgent = true;
      supportAgent({
        name,
        surname,
        email,
        password,
        businessId,
        createdAt,
        businessName,
        isAgent,
      });
      setFormData({
        name: '',
        surname: '',
        email: '',
        password: '',
        password2: '',
      });
      setOpen(false);
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <Container>
      <Button
        variant="outlined"
        severity="primary"
        onClick={handleOpen}
        size="small"
      >
        Add support agent
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style }}>
          <Grid item>
            <Alert icon={false} variant="filled" severity="info">
              <AlertTitle>Add support agent:</AlertTitle>
            </Alert>
            <form onSubmit={onSubmit}>
              <Grid mt={2} item>
                <TextField
                  label="Agent Name"
                  variant="outlined"
                  type="text"
                  name="name"
                  value={name}
                  onChange={onChange}
                  autoComplete="false"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Agent Surname"
                  variant="outlined"
                  type="text"
                  name="surname"
                  value={surname}
                  onChange={onChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item>
                <TextField
                  label="Agent Email"
                  variant="outlined"
                  type="email"
                  name="email"
                  value={email}
                  onChange={onChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Password"
                  variant="outlined"
                  type="password"
                  name="password"
                  value={password}
                  onChange={onChange}
                  autoComplete="off"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Please verify password"
                  variant="outlined"
                  type="password"
                  name="password2"
                  value={password2}
                  onChange={onChange}
                  fullWidth
                  required
                />
              </Grid>
              <Button
                type="submit"
                variant="contained"
                color="info"
                sx={{ marginTop: '20px' }}
                fullWidth
              >
                Add User <SkipNextIcon />
              </Button>
            </form>
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
};

export default connect(null, { supportAgent, setAlert })(AddSupportAgent);
