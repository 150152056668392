import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUser } from '../../actions/business';
import { CircularProgress } from '@mui/material';

const BusinessUser = ({ getUser, user }) => {
  const { userId } = useParams();
  useEffect(() => {
    getUser(userId);
  }, [userId, getUser]);

  if (user === null) return <CircularProgress />;
  return <div>BusinessUser</div>;
};

const mapStateToProps = (state) => ({
  user: state.business.user,
});

export default connect(mapStateToProps, { getUser })(BusinessUser);
