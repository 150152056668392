import {
  PIPELINE_CLIENT_LEADS,
  CLOSED_CLIENT_LEADS,
  CLIENT_LEAD,
  SUBMIT_CLIENT_TO_LEAD,
  ADD_NOTE_TO_LEAD,
  ADD_FOLLOWUP_TO_LEAD,
  UPDATE_LEAD_COLLAB,
  CLIENT_LEAD_FILE_URL,
  CLIENT_LEAD_NOTES,
  CLIENT_LEAD_FOLLOWUPS,
  CLIENT_LEAD_TEXTS,
} from '../types';
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  doc,
  getDoc,
  where,
  getDocs,
  updateDoc,
  deleteField,
} from 'firebase/firestore';
import { firestore } from '../../firebase-config';
import { setAlert } from '../alert';
import moment from 'moment';

//Add note to new lead//
export const updateNoteToNewLead = (arr) => async (dispatch) => {
  try {
    dispatch({ type: ADD_NOTE_TO_LEAD, payload: arr });
  } catch (error) {
    console.error(error.message);
  }
};

//Add Followup to new note//
export const updateFollowToNewNote = (followup) => async (dispatch) => {
  try {
    dispatch({ type: ADD_FOLLOWUP_TO_LEAD, payload: followup });
  } catch (error) {
    console.error(error.message);
  }
};

//Add client to lead//
export const updateClientToLead = (client) => (dispatch) => {
  try {
    dispatch({ type: SUBMIT_CLIENT_TO_LEAD, payload: client });
  } catch (error) {
    console.error(error.message);
  }
};

//Create client lead//
export const createALead =
  ({
    client,
    createdBy,
    createdAt,
    desc,
    collab,
    lead_notes,
    leadFollowUps,
    clientId,
    type,
  }) =>
  async (dispatch) => {
    const lead = collection(firestore, 'client_leads');
    const { name, email } = client;
    let stage = 'Recon';

    try {
      const result = await addDoc(lead, {
        type,
        name,
        email,
        client,
        createdBy,
        createdAt,
        desc,
        collab,
        stage,
        clientId,
      });

      let leadId = result.id;
      if (lead_notes.length > 0) {
        ////Create the notes//
        lead_notes.forEach((lnote) => {
          const { createdAt, createdBy, note } = lnote;

          dispatch(createNoteForLead({ leadId, note, createdAt, createdBy }));
        });
      }

      if (leadFollowUps.length > 0) {
        ///create Followups
        leadFollowUps.forEach((followUp) => {
          const { createdAt, createdBy, date, note } = followUp;
          dispatch(
            createFollowUpForLead({ leadId, note, createdAt, date, createdBy })
          );
        });
      }

      dispatch(setAlert('Lead Created Successfully', 'success'));

      return leadId;
    } catch (error) {
      console.error(error.messagge);
    }
  };

//Get client leads//
export const getLeads = (clientId) => async (dispatch) => {
  // dispatch({ type: CLEAR_LEADS });

  const leadsRef = collection(firestore, 'client_leads');
  try {
    const q = query(
      leadsRef,
      where('clientId', '==', clientId),
      orderBy('createdAt')
    );
    onSnapshot(q, (snapshot) => {
      let leads = [];
      snapshot.docs.forEach((doc) => {
        leads.push({ ...doc.data(), id: doc.id });
      });

      const pipeline = leads.filter((lead) => {
        let stage = lead.stage;
        return stage !== 'Close';
      });

      const closed = leads.filter((lead) => {
        let stage = lead.stage;
        return stage === 'Close';
      });

      dispatch({ type: PIPELINE_CLIENT_LEADS, payload: pipeline });
      dispatch({ type: CLOSED_CLIENT_LEADS, payload: closed });
    });
  } catch (error) {
    console.error(error.message);
  }
};

//Get Lead//
export const getLead = (leadId) => async (dispatch) => {
  // dispatch({ type: CLEAR_LEADS });
  const leadRef = doc(firestore, 'client_leads', leadId);
  try {
    const lead = await getDoc(leadRef);
    const theLead = lead.data();
    dispatch({ type: CLIENT_LEAD, payload: theLead });
  } catch (error) {
    console.error(error.message);
  }
};

//Get Lead Notes//
export const getLeadNotes = (leadId) => async (dispatch) => {
  const noteRef = collection(firestore, 'client_lead_notes');
  const q = query(noteRef, where('leadId', '==', leadId), orderBy('createdAt'));
  try {
    const jam = await getDocs(q);
    const notes = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({ type: CLIENT_LEAD_NOTES, payload: notes });
  } catch (error) {
    console.error(error.message);
  }
};

//Get incomplete Lead Follow ups//
export const getFollowUps = (leadId) => async (dispatch) => {
  const followUpRef = collection(firestore, 'client_lead_follow_ups');
  try {
    const q = query(
      followUpRef,
      where('leadId', '==', leadId),
      where('complete', '==', false),
      orderBy('createdAt')
    );
    const jam = await getDocs(q);
    const follows = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({ type: CLIENT_LEAD_FOLLOWUPS, payload: follows });
  } catch (error) {
    console.error(error.message);
  }
};

//Complete a follow up//
export const followUpDone =
  ({ leadId, fid }) =>
  async (dispatch) => {
    const followUpRef = doc(firestore, 'client_lead_follow_ups', fid);
    try {
      const complete = true;
      const completedAt = moment().format();
      const newFields = { complete, completedAt };
      await updateDoc(followUpRef, newFields);
      dispatch(setAlert('Well done on following up!', 'success'));
      dispatch(getFollowUps(leadId));
      dispatch(getDoneFollowUps(leadId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Get completed follow ups//
export const getDoneFollowUps = (leadId) => async (dispatch) => {
  const followUpRef = collection(firestore, 'followUps');
  try {
    const q = query(
      followUpRef,
      where('leadId', '==', leadId),
      where('complete', '==', true),
      orderBy('createdAt')
    );
    const jam = await getDocs(q);
    const done = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    console.log(done);
    // dispatch({ type: DONE_FOLLOW_UPS, payload: done });
  } catch (error) {
    console.error(error.message);
  }
};

//Collab on lead//
export const updateCollab = (collab) => (dispatch) => {
  try {
    dispatch({ type: UPDATE_LEAD_COLLAB, payload: collab });
  } catch (error) {
    console.error(error.message);
  }
};

//Remove from collab//
export const removeFromCollab = () => (dispatch) => {
  try {
    dispatch({ type: UPDATE_LEAD_COLLAB, payload: {} });
  } catch (error) {
    console.error(error.message);
  }
};

//Update lead collaboration real time//
export const realTimeLeadCollabUpdate =
  ({ responsible, leadId }) =>
  async (dispatch) => {
    try {
      const leadRef = doc(firestore, 'client_lead', leadId);
      const newFields = { collab: responsible };
      await updateDoc(leadRef, newFields);
      dispatch(setAlert('Collab updated successfully', 'success'));
      dispatch(getLead(leadId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Create note for lead//
export const createNoteForLead =
  ({ leadId, note, createdAt, createdBy }) =>
  async (dispatch) => {
    const noteRef = collection(firestore, 'client_lead_notes');
    try {
      //
      await addDoc(noteRef, {
        note,
        leadId,
        createdAt,
        createdBy,
      });
      dispatch(setAlert('Note added successfully', 'success'));
      dispatch(getLeadNotes(leadId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Create followup for lead//
export const createFollowUpForLead =
  ({ leadId, note, createdAt, date, createdBy }) =>
  async (dispatch) => {
    const followUpRef = collection(firestore, 'client_lead_follow_ups');
    let complete = false;
    try {
      await addDoc(followUpRef, {
        leadId,
        note,
        createdAt,
        date,
        createdBy,
        complete,
      });
      dispatch(setAlert('Follow successfully created', 'success'));
      dispatch(getFollowUps(leadId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Fetch Lead text//
export const fetchLeadText = (leadId) => async (dispatch) => {
  const textsRef = collection(firestore, 'client_lead_texts');

  const q = query(
    textsRef,
    where('leadId', '==', leadId),
    orderBy('createdAt')
  );

  const unsub = onSnapshot(q, (snapshot) => {
    let texts = [];
    snapshot.docs.forEach((doc) => {
      texts.push({ ...doc.data(), id: doc.id });
    });
    dispatch({ type: CLIENT_LEAD_TEXTS, payload: texts });
  });
  return unsub;
};

//Send lead text//
export const sendLeadText =
  ({ authorId, authorName, createdAt, text, leadId }) =>
  async (dispatch) => {
    const textRef = collection(firestore, 'client_lead_texts');
    try {
      await addDoc(textRef, { authorId, authorName, createdAt, text, leadId });
    } catch (error) {
      console.error(error.message);
    }
  };

//update lead collaboration//
export const updateLeadCollab =
  ({ arr, leadId }) =>
  async (dispatch) => {
    dispatch(updateCollab([]));
    dispatch(removeFromCollab([]));
    const leadRef = doc(firestore, 'client_leads', leadId);
    const newFields = { collab: arr };
    await updateDoc(leadRef, newFields);
    dispatch(setAlert('Lead collab updated...', 'success'));
    dispatch(getLead(leadId));
  };

//change lead stage//
export const changeLeadStage =
  ({ newStage, leadId }) =>
  async (dispatch) => {
    const leadRef = doc(firestore, 'client_leads', leadId);
    try {
      const newFields = { stage: newStage };
      await updateDoc(leadRef, newFields);
      dispatch(setAlert('Lead stage updated...', 'success'));
      dispatch(getLead(leadId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Update the lead file//
export const updateLeadFile =
  ({ type, url, leadId }) =>
  async (dispatch) => {
    try {
      const leadRef = doc(firestore, 'client_leads', leadId);

      if (type === 'lead_quote') {
        //quote
        const newFields = { quote: url };
        await updateDoc(leadRef, newFields);
        dispatch(setAlert('Quote successfully updated...', 'success'));
        dispatch(getLead(leadId));
      } else if (type === 'lead_pitch') {
        //pitch
        const newFields = { pitch: url };
        await updateDoc(leadRef, newFields);
        dispatch(setAlert('Quote successfully updated...', 'success'));
        dispatch(getLead(leadId));
      } else if (type === 'lead_invoice') {
        //invoice
        const newFields = { invoice: url };
        await updateDoc(leadRef, newFields);
        dispatch(setAlert('Quote successfully updated...', 'success'));
        dispatch(getLead(leadId));
      }

      dispatch({ type: CLIENT_LEAD_FILE_URL, payload: null });
    } catch (error) {
      console.error(error.message);
    }
  };

//Upddate file deleted field//
export const deleteFileValue =
  ({ type, leadId }) =>
  async (dispatch) => {
    try {
      const leadRef = doc(firestore, 'client_leads', leadId);

      if (type === 'lead-quote') {
        await updateDoc(leadRef, {
          quote: deleteField(),
        });
        dispatch(setAlert('Quote successfully updated...', 'success'));
        dispatch(getLead(leadId));
      } else if (type === 'lead_pitch') {
        await updateDoc(leadRef, {
          pitch: deleteField(),
        });
        dispatch(setAlert('Quote successfully updated...', 'success'));
        dispatch(getLead(leadId));
      } else if (type === 'lead_invoice') {
        await updateDoc(leadRef, {
          invoice: deleteField(),
        });
        dispatch(setAlert('Quote successfully updated...', 'success'));
        dispatch(getLead(leadId));
      }
    } catch (error) {
      console.error(error.message);
    }
  };
