import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Box,
  Grid,
  Alert,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Typography,
  Button,
  useTheme,
} from '@mui/material';
import { fetchClient } from '../../../actions/clients/clients';
import { fetchClientTickets } from '../../../actions/techs/ticket';
import { clientProjects } from '../../../actions/techs/project';
import AddUser from './AddUser';
import UserItem from './UserItem';
import InClientTicket from './InClientTicket';
import AddProject from './AddProject';
import MailOutlineSharpIcon from '@mui/icons-material/MailOutlineSharp';
import AddLocationSharpIcon from '@mui/icons-material/AddLocationSharp';
import LocalPhoneSharpIcon from '@mui/icons-material/LocalPhoneSharp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import InClientProject from './InClientProject';
import { tokens } from '../../../theme';

const Client = ({
  client: { client, client_users, loading },
  fetchClient,
  fetchClientTickets,
  clientProjects,
  tickets,
  projects,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [showTickets, setShowTickets] = useState(true);
  const [showProjects, setShowProjects] = useState(true);
  const { clientId } = useParams();

  useEffect(() => {
    fetchClient(clientId);
    fetchClientTickets(clientId);
    clientProjects(clientId);
  }, [fetchClient, fetchClientTickets, clientId, clientProjects]);

  if (loading || client === null) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container>
        <Grid xs={1} item>
          <Link as={Link} to="/tech/tech-clients">
            <Button sx={{ color: colors.blueAccent[400] }}>
              <ArrowBackIos />
            </Button>
          </Link>
        </Grid>
        <Grid xs={7} item>
          <Typography variant="h4">{client.name}</Typography>
        </Grid>
        <Grid xs={1} item>
          <Link as={Link} to={`/tech/client-report/${clientId}`}>
            <Button size="small" variant="outlined" color="secondary">
              <ContentPasteSearchOutlinedIcon />
            </Button>
          </Link>
        </Grid>
        <Grid xs={1} item>
          <AddUser clientId={clientId} client={client} />
        </Grid>
        <Grid item xs={1}>
          <AddProject clientId={clientId} client={client} />
        </Grid>
        <Grid item xs={1}>
          <Link as={Link} to={`/tech/client-user-report/${clientId}`}>
            <Button
              size="small"
              variant="outlined"
              sx={{
                color: colors.blueAccent[200],
                borderColor: colors.blueAccent[200],
              }}
            >
              <PersonSearchIcon />
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Divider variant="middle" sx={{ mb: '10px', mt: '10px' }} />
      <Grid container>
        <Grid xs={8} item>
          <Typography
            variant="h5"
            sx={{
              ml: '10px',
              color: colors.blueAccent[100],
              fontWeight: 'bold',
            }}
          >
            Users:
          </Typography>
          {client_users.length === 0 ? (
            <Alert severity="warning" style={{ marginTop: '10px' }}>
              Client has no users...
            </Alert>
          ) : (
            <UserItem users={client_users} />
          )}
        </Grid>
        <Grid xs={4} item>
          <Grid>
            <Box>
              <Typography variant="h5" sx={{ color: colors.blueAccent[100] }}>
                Client Info:
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <MailOutlineSharpIcon />
                  </ListItemIcon>
                  <ListItemText primary={`${client.email}`} />
                </ListItem>
                <Divider variant="inset" />
                <ListItem>
                  <ListItemIcon>
                    <AddLocationSharpIcon />
                  </ListItemIcon>
                  <ListItemText primary={`${client.address}`} />
                </ListItem>
                <Divider variant="inset" />
                <ListItem>
                  <ListItemIcon>
                    <LocalPhoneSharpIcon />
                  </ListItemIcon>
                  <ListItemText primary={`${client.number}`} />
                </ListItem>
              </List>
              <>
                <Grid container>
                  <Grid xs={11} item>
                    <Typography
                      variant="h5"
                      sx={{ color: colors.blueAccent[100] }}
                    >
                      Tickets:
                    </Typography>
                  </Grid>
                  <Grid xs={1} item>
                    {showTickets ? (
                      <KeyboardArrowUpIcon
                        style={{ marginTop: '10px' }}
                        onClick={() => setShowTickets(false)}
                      />
                    ) : (
                      <KeyboardArrowDown
                        style={{ marginTop: '10px' }}
                        onClick={() => setShowTickets(true)}
                      />
                    )}
                  </Grid>
                </Grid>
                <Divider variant="middle" />
                {tickets === null ? (
                  <CircularProgress />
                ) : showTickets ? (
                  tickets.length === 0 ? (
                    <Typography style={{ marginTop: '10px' }}>
                      No Tickets
                    </Typography>
                  ) : (
                    tickets.map((ticket) => (
                      <InClientTicket
                        key={ticket.id}
                        ticket={ticket}
                        clientId={clientId}
                      />
                    ))
                  )
                ) : (
                  ''
                )}
              </>
              <>
                <Grid container>
                  <Grid xs={11} item>
                    <Typography
                      variant="h5"
                      sx={{ color: colors.blueAccent[100] }}
                    >
                      Projects:
                    </Typography>
                  </Grid>
                  <Grid xs={1} item>
                    {showProjects ? (
                      <KeyboardArrowUpIcon
                        style={{ marginTop: '10px' }}
                        onClick={() => setShowProjects(false)}
                      />
                    ) : (
                      <KeyboardArrowDown
                        style={{ marginTop: '10px' }}
                        onClick={() => setShowProjects(true)}
                      />
                    )}
                  </Grid>
                </Grid>

                {!projects ? (
                  <CircularProgress />
                ) : showProjects ? (
                  projects.length === 0 ? (
                    <Typography style={{ marginTop: '10px' }}>
                      No Projects{' '}
                    </Typography>
                  ) : (
                    projects.map((project) => (
                      <InClientProject
                        key={project.id}
                        project={project}
                        clientId={clientId}
                      />
                    ))
                  )
                ) : (
                  ''
                )}
              </>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  client: state.client,
  tickets: state.tech_tickets.client_tickets,
  projects: state.project.projects,
});

export default connect(mapStateToProps, {
  fetchClient,
  fetchClientTickets,
  clientProjects,
})(Client);
