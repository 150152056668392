import { firestore } from '../../firebase-config';
import {
  GET_PROJECT,
  GET_PROJECTS,
  CLEAR_PROJECTS,
  NEXT,
  MILESTONE,
  FEATURE,
} from '../types';
import { setAlert } from '../alert';
import {
  addDoc,
  doc,
  collection,
  query,
  orderBy,
  onSnapshot,
  getDoc,
  where,
  getDocs,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import moment from 'moment';

//Get Projects//
export const getProjects = () => async (dispatch) => {
  dispatch({ type: CLEAR_PROJECTS });
  const projectRef = collection(firestore, 'projects');
  const q = query(projectRef, where('complete', '==', false));
  onSnapshot(q, (snapshot) => {
    let projects = [];
    snapshot.docs.forEach((doc) => {
      projects.push({ ...doc.data(), id: doc.id });
    });
    dispatch({ type: GET_PROJECTS, payload: projects });
  });
  try {
  } catch (error) {
    console.error(error.message);
  }
};

//Get Client Projects//
export const clientProjects = (clientId) => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_PROJECTS });
    const projectRef = collection(firestore, 'projects');
    const q = query(
      projectRef,
      where('clientId', '==', clientId),
      orderBy('createdAt')
    );
    onSnapshot(q, (snapshot) => {
      let projects = [];
      snapshot.docs.forEach((doc) => {
        projects.push({ ...doc.data(), id: doc.id });
      });
      dispatch({ type: GET_PROJECTS, payload: projects });
    });
  } catch (error) {
    console.error(error.message);
  }
};

//Create Projects//
export const createProject =
  ({ name, desc, value, clientId, createdAt, clientName, complete, proUser }) =>
  async (dispatch) => {
    const project = collection(firestore, 'projects');
    const completedAt = '';

    try {
      await addDoc(project, {
        name,
        desc,
        value,
        clientId,
        createdAt,
        completedAt,
        clientName,
        complete,
        proUser,
      });

      dispatch(setAlert('Project Created Successfully', 'success'));
      dispatch(clientProjects(clientId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Fetch Project//
export const getProject = (projectId) => async (dispatch) => {
  try {
    const projectRef = doc(firestore, 'projects', projectId);
    const project = await getDoc(projectRef);
    const theProject = project.data();

    dispatch({ type: GET_PROJECT, payload: theProject });
  } catch (error) {
    console.error(error.message);
  }
};

//Assign Project User//
export const assignUser =
  ({ projectId, proUser }) =>
  async (dispatch) => {
    try {
      const projectRef = doc(firestore, 'projects', projectId);
      const newFields = { proUser };

      await updateDoc(projectRef, newFields);
      dispatch(setAlert('Next Created Successfully', 'success'));
      dispatch(getProject(projectId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Find all project next//
export const fetchProjectNext = (projectId) => async (dispatch) => {
  const q = query(
    collection(firestore, 'next'),
    where('projectId', '==', projectId),
    orderBy('createdAt')
  );

  try {
    const jam = await getDocs(q);
    const next = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    dispatch({ type: NEXT, payload: next });
  } catch (error) {
    console.error(error.message);
  }
};

//Find all project milestones//
export const fetchProjectMilestones = (projectId) => async (dispatch) => {
  const mileRef = collection(firestore, 'milestone');
  try {
    const q = query(
      mileRef,
      where('projectId', '==', projectId),
      orderBy('createdAt')
    );
    const jam = await getDocs(q);

    const milestones = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({ type: MILESTONE, payload: milestones });
  } catch (error) {
    console.error(error.message);
  }
};

//Fetch Feature Request//
export const fetchFeatureReq = (projectId) => async (dispatch) => {
  try {
    const q = query(
      collection(firestore, 'feature'),
      where('projectId', '==', projectId),
      orderBy('createdAt')
    );

    const jam = await getDocs(q);
    const feature = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({ type: FEATURE, payload: feature });
  } catch (error) {
    console.error(error.message);
  }
};

//Accept Feature Request//
export const acceptRequest =
  ({ projectId, text, createdAt, featureId }) =>
  async (dispatch) => {
    try {
      const projectRef = doc(firestore, 'feature', featureId);

      const newFields = { accepted: true };

      await updateDoc(projectRef, newFields);
      dispatch(addNext({ projectId, text, createdAt }));
      dispatch(fetchFeatureReq(projectId));
    } catch (error) {
      console.error(error.mesage);
    }
  };

//Add whats next//
export const addNext =
  ({ text, projectId, createdAt }) =>
  async (dispatch) => {
    try {
      const projectRef = collection(firestore, 'next');
      const completedAt = null;
      const howLong = '1 week';
      const guessEnd = '';
      const start = '';
      const assigned = null;
      await addDoc(projectRef, {
        text,
        projectId,
        createdAt,
        completedAt,
        assigned,
        howLong,
        guessEnd,
        start,
      });

      dispatch(setAlert('Next Created Successfully', 'success'));
      dispatch(fetchProjectNext(projectId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Update Next//
export const upDateNext =
  ({ id, start, guessEnd, howLong, assigned, projectId }) =>
  async (dispatch) => {
    const nextRef = doc(firestore, 'next', id);
    try {
      const newFields = { start, guessEnd, howLong, assigned };
      await updateDoc(nextRef, newFields);
      dispatch(setAlert('You have successfully updates next', 'success'));
      dispatch(fetchProjectNext(projectId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Complete Next//
export const completeNext =
  ({ id, completedAt, text, projectId, start, guessEnd, assigned }) =>
  async (dispatch) => {
    const nextRef = doc(firestore, 'next', id);
    const mileRef = collection(firestore, 'milestone');
    const newFields = { completedAt };
    await updateDoc(nextRef, newFields);
    let createdAt = completedAt;
    await addDoc(mileRef, {
      text,
      projectId,
      createdAt,
      start,
      guessEnd,
      assigned,
    });
    dispatch(
      setAlert('Congratulations on reaching a project Milestone!', 'success')
    );
    dispatch(fetchProjectMilestones(projectId));
    dispatch(fetchProjectNext(projectId));
    try {
    } catch (error) {
      console.error(error.message);
    }
  };

//Complete Project//
export const projectDone = (projectId) => async (dispatch) => {
  const projectRef = doc(firestore, 'projects', projectId);
  try {
    const newFields = { completedAt: moment().format(), complete: true };
    await updateDoc(projectRef, newFields);

    dispatch(setAlert('Congratulations on completing the project!', 'success'));
    dispatch(getProject(projectId));
  } catch (error) {
    console.error(error.message);
  }
};

//Delete Profect//
export const deleteProject =
  ({ projectId, clientId }) =>
  async (dispatch) => {
    try {
      await deleteDoc(doc(firestore, 'projects', projectId));
      dispatch(setAlert('Project has been deleted successfully', 'success'));
      dispatch(clientProjects(clientId));
    } catch (error) {
      dispatch(setAlert('Something went wrong... try again.', 'error'));
    }
  };
