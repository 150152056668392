import { USER_PROFILE, USER_BUSINESS } from '../types';
import { firestore } from '../../firebase-config';
import { doc, getDoc } from 'firebase/firestore';

export const getUserProfile = (credentials) => async (dispatch) => {
  try {
    const userProfile = doc(firestore, 'users', credentials);

    const profile = await getDoc(userProfile);
    const myProfile = profile.data();
    if (myProfile !== undefined) {
      dispatch({ type: USER_PROFILE, payload: myProfile });
    }
  } catch (error) {
    console.error(error.message);
  }
};

//Get Business as user//
export const getSupport = (businessId) => async (dispatch) => {
  try {
    const businessRef = doc(firestore, 'business', businessId);
    const business = await getDoc(businessRef);

    dispatch({ type: USER_BUSINESS, payload: business.data() });
  } catch (error) {
    console.error(error.message);
  }
};
