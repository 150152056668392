import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { tokens } from '../../../theme';
import moment from 'moment';

const style = {
  textAlign: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ConfirmLeadCreation = ({
  client,
  clientId,
  user,
  desc,
  collabSales,
  notes,
  setAlert,
  type,
  followUps,
  createALead,
  navToNewLead,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    if (desc.length < 3) {
      setAlert('Please give your lead a description', 'warning');
    } else if (client === null) {
      setAlert('Add a client to your lead', 'warning');
    } else {
      setOpen(true);
    }
  };

  const confirmCreation = async () => {
    let lead_notes = notes;
    let leadFollowUps = followUps;
    let createdBy = {
      id: user.credentials,
      name: user.name,
    };
    let collab = collabSales;
    let createdAt = moment().format();
    const res = await createALead({
      client,
      createdBy,
      createdAt,
      desc,
      collab,
      lead_notes,
      leadFollowUps,
      clientId,
      type,
    });
    handleClose();
    setCreating(true);
    navToNewLead(res);
  };

  return (
    <>
      <Button
        variant="outlined"
        sx={{
          color: colors.blueAccent[100],
          borderColor: colors.blueAccent[100],
        }}
        onClick={handleOpen}
      >
        Create Lead
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style }}>
          <Grid item>
            <Typography color="green" variant="h5">
              Summary of ToDo
            </Typography>
            <Typography color="grey" variant="caption">
              Once you happy with your lead click create...
            </Typography>
            <Divider
              variant="middle"
              sx={{ marginBottom: '15px', marginTop: '5px', color: 'green' }}
            />
            <Grid container>
              <Grid item xs={4}>
                <Typography>Type of lead: </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography>{type}</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4}>
                <Typography>Company name: </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography>{client && client.name}</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4}>
                <Typography>Description:</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography>{desc}</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4}>
                <Typography>Notes:</Typography>
              </Grid>
              <Grid item xs={5}>
                {notes.length === 0 ? (
                  <Typography color="orange">You have no notes...</Typography>
                ) : (
                  notes.map((note, index) => (
                    <Typography key={note._id}>
                      {index + 1}: {note.note}
                    </Typography>
                  ))
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4}>
                <Typography>Follow ups:</Typography>
              </Grid>
              <Grid item xs={5}>
                {followUps.length === 0 ? (
                  <Typography color="orange">
                    You have no follow ups...
                  </Typography>
                ) : (
                  followUps.map((followUp, index) => (
                    <Typography key={followUp._id}>
                      {index + 1}: {followUp.note}
                    </Typography>
                  ))
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4}>
                <Typography>Assigned to:</Typography>
              </Grid>
              <Grid item xs={5}>
                {collabSales.responsible !== undefined ? (
                  <Typography>
                    {collabSales.responsible.responsible} -{' '}
                    {collabSales.responsible.name}
                  </Typography>
                ) : (
                  <Typography color="lightblue">
                    This lead is for you
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Divider
              variant="middle"
              sx={{ marginBottom: '15px', marginTop: '5px', color: 'green' }}
            />
            {creating ? (
              <LoadingButton loading variant="contained" fullWidth>
                Logging In
              </LoadingButton>
            ) : (
              <Button
                variant="contained"
                color="success"
                onClick={confirmCreation}
                fullWidth
              >
                Create ToDo
              </Button>
            )}
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmLeadCreation;
