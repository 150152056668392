import { firestore } from '../../firebase-config';
import {
  MY_PROJECTS,
  MY_PROJECT,
  CLEAR_MY_PROJECTS,
  MY_PROJECT_MILE,
  MY_PROJECT_NEXT,
  MY_PROJECT_FEATURE,
} from '../types';
import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
  doc,
  getDoc,
  addDoc,
} from 'firebase/firestore';
import { setAlert } from '../alert';

//Get User Projects//
export const getProjects = (proId) => async (dispatch) => {
  dispatch({ type: CLEAR_MY_PROJECTS });
  const q = query(
    collection(firestore, 'projects'),
    where('proUser.id', '==', proId),
    orderBy('createdAt')
  );
  try {
    const jam = await getDocs(q);

    const projects = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    dispatch({ type: MY_PROJECTS, payload: projects });
  } catch (error) {
    console.error(error.message);
  }
};

//Get specific project//
export const getProject = (projectId) => async (dispatch) => {
  dispatch({ type: CLEAR_MY_PROJECTS });
  try {
    const projectRef = doc(firestore, 'projects', projectId);

    const project = await getDoc(projectRef);
    const theProject = project.data();

    dispatch({ type: MY_PROJECT, payload: theProject });
  } catch (error) {
    console.error(error.message);
  }
};

//Get Project Next//
export const proNext = (projectId) => async (dispatch) => {
  try {
    const q = query(
      collection(firestore, 'next'),
      where('projectId', '==', projectId),
      orderBy('createdAt')
    );
    const jam = await getDocs(q);

    const next = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({ type: MY_PROJECT_NEXT, payload: next });
  } catch (error) {
    console.error(error.messgae);
  }
};

//Get Project Milestones//
export const proMile = (projectId) => async (dispatch) => {
  try {
    const q = query(
      collection(firestore, 'milestone'),
      where('projectId', '==', projectId),
      orderBy('createdAt')
    );
    const jam = await getDocs(q);

    const milestones = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({ type: MY_PROJECT_MILE, payload: milestones });
  } catch (error) {
    console.error(error.messgae);
  }
};

//Request Feaute//
export const featureRequest =
  ({ text, createdAt, accepted, projectId }) =>
  async (dispatch) => {
    const feature = collection(firestore, 'feature');

    try {
      await addDoc(feature, {
        text,
        createdAt,
        accepted,
        projectId,
      });

      dispatch(
        setAlert('You have successfully requested a feature', 'success')
      );
      dispatch(getFeatureReq(projectId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Get feature project feature request//
export const getFeatureReq = (projectId) => async (dispatch) => {
  try {
    const q = query(
      collection(firestore, 'feature'),
      where('projectId', '==', projectId),
      orderBy('createdAt')
    );
    const jam = await getDocs(q);

    const feature = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({ type: MY_PROJECT_FEATURE, payload: feature });
  } catch (error) {
    console.error(error.message);
  }
};
