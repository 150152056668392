import { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  Box,
  Alert,
  AlertTitle,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Input,
} from '@mui/material';
import moment from 'moment';
import '../User.css';

const CreateTicket = ({
  submitTicket,
  userId,
  user_profile: { clientId, clientName, name, email },
  alert,
  isBusiness,
  business,
}) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    header: '',
    desc: '',
  });
  const [howbad, setHowBad] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [formError, setFormError] = useState(null);

  useEffect(() => {
    if (alert.length > 0) {
      handleClose();
      setSubmitting(false);
    }
  }, [alert]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const { header, desc } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (howbad === '' || header === '') {
      setFormError('***Header & How bad is it are required**');
      setTimeout(() => setFormError(null), 4000);
    } else {
      let createdAt = moment().format();
      let createdBy = name;
      let username = name;
      if (business) {
        let bizEmail = business.email;
        submitTicket({
          header,
          desc,
          howbad,
          userId,
          clientId,
          createdAt,
          clientName,
          createdBy,
          email,
          isBusiness,
          bizEmail,
          username,
        });
        setSubmitting(true);
        setFormData({
          header: '',
          desc: '',
        });
        setHowBad('');
      } else {
        submitTicket({
          header,
          desc,
          howbad,
          userId,
          clientId,
          createdAt,
          clientName,
          createdBy,
          email,
          username,
          isBusiness: false,
        });
        setSubmitting(true);
        setFormData({
          header: '',
          desc: '',
        });
        setHowBad('');
      }
    }
  };

  return (
    <>
      <Button variant="contained" size="small" onClick={handleOpen}>
        Create a ticket
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            Let us know how we can assist
          </Alert>
          {formError && (
            <Button variant="contained" color="error" id="error-btn">
              {formError}
            </Button>
          )}
          <form onSubmit={onSubmit}>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="how-bad-is-it">How bad is it?</InputLabel>
              <Select labelId="how-bad-is-it" value={howbad}>
                <MenuItem
                  value="Not so bad"
                  onClick={() => setHowBad('Not so bad')}
                >
                  Not so bad
                </MenuItem>
                <MenuItem
                  value="Really bad"
                  onClick={() => setHowBad('Really bad')}
                >
                  Really bad
                </MenuItem>
                <MenuItem
                  value="Its a disaster"
                  onClick={() => setHowBad('Its a disaster')}
                >
                  Its a disaster
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl margin="normal" fullWidth>
              <InputLabel>Ticket Header</InputLabel>
              <Input value={header} onChange={onChange} name="header" />
            </FormControl>

            <FormControl margin="normal" fullWidth>
              <InputLabel id="desc">Description</InputLabel>
              <Input
                multiline={true}
                minRows={2}
                value={desc}
                onChange={onChange}
                name="desc"
              />
            </FormControl>
            {submitting === true ? (
              <Button variant="contained" disabled>
                Submiting...
              </Button>
            ) : (
              <Button variant="contained" type="submit">
                Submit
              </Button>
            )}
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default CreateTicket;
