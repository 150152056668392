import React, { useEffect, useState } from 'react';
import { Card, Typography, CardContent, CardMedia } from '@mui/material';
import ReactAudioPlayer from 'react-audio-player';

import moment from 'moment';

const Texts = ({ text, userId }) => {
  const [author, setAuthor] = useState();
  const [img, setImg] = useState(null);
  const [voice, setVoice] = useState(null);
  useEffect(() => {
    if (text) {
      if (text.authorId === userId) {
        setAuthor('You');
      } else {
        setAuthor(text.authorName);
      }
      const audioChecker = text.text.split('%');
      const check = audioChecker[0].split('/');
      const loot = check[check.length - 1];
      if (loot === 'voiceNote') {
        return setVoice(text.text);
      }
      if (text.text.split('.').shift() === 'https://firebasestorage')
        setImg(text.text);
    }
  }, [text, userId]);
  const time = moment(text.createdAt).calendar();

  return (
    <Card id={text.authorId === userId ? 'my-text' : 'sender'}>
      <CardContent>
        {voice ? (
          <ReactAudioPlayer src={voice} controls />
        ) : img ? (
          <CardMedia component="img" image={img} alt="Paella dish" />
        ) : (
          <Typography>{text.text}</Typography>
        )}
      </CardContent>
      <Typography variant="caption">
        {author} - {time}
      </Typography>
    </Card>
  );
};

export default Texts;
