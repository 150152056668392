import { Typography, Grid, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import React from 'react';
import BusinessIcon from '@mui/icons-material/Business';

const BranchItem = ({ branch }) => {
  let navigate = useNavigate();

  return (
    <>
      <Grid
        onClick={() => navigate(`/tech/client-branch/${branch.id}`)}
        container
      >
        <Grid xs={2} item>
          <BusinessIcon />
        </Grid>
        <Grid xs={10} item>
          <Typography>{branch.name}</Typography>
        </Grid>
        <Divider sx={{ marginTop: '12px' }} />
      </Grid>
    </>
  );
};

export default BranchItem;
