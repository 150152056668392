import { useEffect, useRef, useState } from 'react';
import {
  CircularProgress,
  Container,
  Grid,
  List,
  Button,
  ListItem,
  Typography,
  Divider,
  Paper,
  Box,
  Alert,
  LinearProgress,
  TextField,
  FormControl,
  IconButton,
} from '@mui/material';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetchTicket,
  fetchTexts,
  ticketMsg,
  claimTicket,
  closeTicket,
} from '../../../actions/techs/ticket';
import { uploadFile } from '../../../actions/storage';
import moment from 'moment/moment';
import CloseTicket from '../../Techs/Tickets/CloseTicket';
import ClaimTicket from '../../Techs/Tickets/ClaimTicket';
import FileUpload from '../../Techs/Tickets/FileUpload';
import Text from '../../Techs/Tickets/Text';
import { setAlert } from '../../../actions/alert';

const BusinessTicket = ({
  fetchTicket,
  fetchTexts,
  ticketMsg,
  tech_tickets: { ticket, texts },
  upload: { progress, text_url },
  uploadFile,
  claimTicket,
  closeTicket,
  business: { business_profile, agent_profile },
  setAlert,
  alert,
}) => {
  const [techId, setTechId] = useState(null);
  const [techName, setTechName] = useState(null);
  const [text, setText] = useState('');
  const { ticketId } = useParams();

  let scroll = useRef(null);
  let closeBtn = useRef();

  useEffect(() => {
    if (texts) {
      if (scroll.current) {
        scroll.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [texts]);
  useEffect(() => {
    fetchTicket(ticketId);
    fetchTexts(ticketId);
  }, [fetchTicket, fetchTexts, ticketId]);

  useEffect(() => {
    if (business_profile) {
      setTechId(business_profile.id);
      setTechName(business_profile.name);
    } else if (agent_profile) {
      setTechId(agent_profile.id);
      setTechName(agent_profile.name);
    }
  }, [business_profile, agent_profile, setTechId, setTechName]);

  useEffect(() => {
    if (text_url) {
      let authorId = techId;
      let text = text_url;
      let authorName = techName;
      let createdAt = moment().format();
      ticketMsg({ ticketId, text, authorId, createdAt, authorName });
    }
  }, [text_url, ticketId, ticketMsg, techId, techName]);

  if (ticket === null || texts === null) return <CircularProgress />;

  const {
    header,
    createdBy,
    assignedTo,
    createdAt,
    email,
    isComplete,
    deviceToken,
    ticketNum,
    completedAt,
    desc,
  } = ticket;

  const onSubmit = (e) => {
    e.preventDefault();
    if (text === '') return setAlert('Cannot send a blank message!', 'warning');
    let authorId = techId;
    let authorName = techName;
    let createdAt = moment().format();
    ticketMsg({ ticketId, text, authorId, createdAt, authorName, deviceToken });

    setText('');
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container>
            <Grid container>
              <Grid xs={9} item>
                <List>
                  <ListItem>
                    <Typography variant="subtitle1">
                      Header: {header}
                    </Typography>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography variant="subtitle1">
                          User: {createdBy}
                        </Typography>
                      </Grid>
                      <Grid xs={6} item>
                        {isComplete ? (
                          <Button
                            variant="outlined"
                            color="success"
                            size="small"
                          >
                            Closed; {moment(completedAt).calendar()}
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            color="warning"
                            size="small"
                          >
                            Opened {moment(createdAt).calendar()}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <Typography variant="subtitle1">
                      Description: {desc}
                    </Typography>
                  </ListItem>
                </List>
                <Divider />
              </Grid>
              {isComplete === false && (
                <Grid item>
                  <List>
                    <ListItem>
                      <CloseTicket
                        closeTicket={closeTicket}
                        ticketId={ticketId}
                        alert={alert}
                        closeBtn={closeBtn}
                        email={email}
                        createdBy={createdBy}
                        deviceToken={deviceToken}
                        ticketNum={ticketNum}
                      />
                    </ListItem>
                    <ListItem>
                      <ClaimTicket
                        claimTicket={claimTicket}
                        techId={techId}
                        techName={techName}
                        ticketId={ticketId}
                        assignedTo={assignedTo}
                        email={email}
                        createdBy={createdBy}
                        deviceToken={deviceToken}
                      />
                    </ListItem>
                  </List>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Paper elevation={4} xs={12}>
            <Box p={3} xs={12}>
              <Grid container spacing={4}>
                <Grid id="chat-grid" item>
                  <List id="chat-messages">
                    {texts.length === 0
                      ? 'No texts'
                      : texts.map((text) => {
                          return (
                            <Text
                              key={text.id}
                              text={text}
                              techId={techId}
                              ticketId={ticketId}
                            />
                          );
                        })}
                    <ListItem ref={scroll}></ListItem>
                  </List>
                </Grid>
              </Grid>
            </Box>
            {isComplete ? (
              <Alert>Ticket marked as complete!</Alert>
            ) : (
              <form onSubmit={onSubmit}>
                <Grid container>
                  <Grid xs={9} item>
                    {progress > 0 && <LinearProgress />}
                    <FormControl fullWidth>
                      <TextField
                        label="type your message"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <IconButton xs={1}>
                      <FileUpload uploadFile={uploadFile} />
                    </IconButton>
                    <IconButton xs={1} sx={{ mt: 0.4 }} type="submit">
                      <SendOutlinedIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </form>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  tech_tickets: state.tech_tickets,
  business: state.business,
  upload: state.upload,
  alert: state.alert,
});

export default connect(mapStateToProps, {
  fetchTicket,
  claimTicket,
  closeTicket,
  setAlert,
  ticketMsg,
  fetchTexts,
  uploadFile,
})(BusinessTicket);
