import { firestore } from '../../firebase-config';
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  orderBy,
  getDoc,
  onSnapshot,
} from 'firebase/firestore';
import {
  BUSINESS_PROFILE,
  BUSINESS_USER,
  BUSINESS_USERS,
  ACTIVE_BIZ_TICKETS,
  BUSINESS_AGENTS,
  AGENT_PROFILE,
} from '../types';
import { setAlert } from '../alert';
import axios from 'axios';
const backend = process.env.REACT_APP_BACKEND_URL;

//Sign up a business//
export const signupBusiness =
  ({ name, email, number, address, password, isBusiness }) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const body = JSON.stringify({
        name,
        email,
        number,
        address,
        password,
        isBusiness,
      });
      const res = await axios.post(
        `${backend}/api/firebase-admin/create-user`,
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setAlert(msg.msg, 'success')));
      }
    } catch (error) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    }
  };

//Get Business Profile
export const getBusinessProfile = (credentials) => async (dispatch) => {
  try {
    const q = query(
      collection(firestore, 'business'),
      where('credentials', '==', credentials)
    );
    const myQuery = await getDocs(q);

    let profile = myQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({ type: BUSINESS_PROFILE, payload: profile[0] });
  } catch (error) {
    console.error(error.message);
  }
};

export const getSupportAgent = (credentials) => async (dispatch) => {
  try {
    const q = query(
      collection(firestore, 'support'),
      where('credentials', '==', credentials)
    );
    const myQuery = await getDocs(q);
    let profile = myQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({ type: AGENT_PROFILE, payload: profile[0] });
  } catch (error) {
    console.error(error.message);
  }
};

//Create Business User//
export const createUser =
  ({
    name,
    surname,
    position,
    email,
    cellphone,
    password,
    clientId,
    createdAt,
    clientName,
    isBusiness,
  }) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const body = JSON.stringify({ email, password, name });
      const res = await axios.post(
        `${backend}/api/firebase-admin/create-user`,
        body,
        config
      );
      const credentials = res.data;
      const photoUrl = 'photoUrl';
      const userProfile = collection(firestore, 'users');
      setDoc(doc(userProfile, credentials), {
        name,
        surname,
        email,
        cellphone,
        position,
        clientId,
        credentials,
        createdAt,
        clientName,
        photoUrl,
        isBusiness,
      });
      dispatch(setAlert('User Successfully Added', 'success'));
      dispatch(getUsers(clientId));
    } catch (error) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    }
  };

//Fetch business users//
export const getUsers = (clientId) => async (dispatch) => {
  try {
    const q = query(
      collection(firestore, 'users'),
      where('clientId', '==', clientId),
      orderBy('createdAt')
    );
    const jam = await getDocs(q);
    let users = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({ type: BUSINESS_USERS, payload: users });
  } catch (error) {
    console.error(error.message);
  }
};

//Get a user//
export const getUser = (userId) => async (dispatch) => {
  try {
    const userRef = doc(firestore, 'users', userId);

    const userDoc = await getDoc(userRef);
    const user = userDoc.data();
    dispatch({ type: BUSINESS_USER, payload: user });
  } catch (error) {
    console.error(error.message);
  }
};

//Fetch active busines tickets//
export const activeTickets = (businessId) => async (dispatch) => {
  try {
    const ticketRef = collection(firestore, 'tickets');
    const q = query(
      ticketRef,
      where('clientId', '==', businessId),
      where('isComplete', '==', false),
      orderBy('createdAt')
    );
    onSnapshot(q, (snapshot) => {
      let tickets = [];
      snapshot.docs.forEach((doc) => {
        tickets.push({ ...doc.data(), id: doc.id });
      });
      dispatch({ type: ACTIVE_BIZ_TICKETS, payload: tickets });
    });
  } catch (error) {
    console.error(error.message);
  }
};

//Create Support agent//
export const supportAgent = (formData) => async (dispatch) => {
  const {
    name,
    surname,
    email,
    password,
    businessId,
    businessName,
    createdAt,
    isAgent,
  } = formData;
  const support = collection(firestore, 'support');
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ email, password, name, isAgent });
    const res = await axios.post(
      `${backend}/api/firebase-admin/create-user`,
      body,
      config
    );
    const credentials = res.data;
    await addDoc(support, {
      name,
      surname,
      email,
      credentials,
      businessId,
      businessName,
      createdAt,
    });
    dispatch(setAlert('Agent Added Successfully', 'success'));
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
    }
  }
};

//Fetch support agents//
export const fetchAgents = (businessId) => async (dispatch) => {
  try {
    const support = collection(firestore, 'support');
    const q = query(
      support,
      where('businessId', '==', businessId),
      orderBy('createdAt')
    );

    onSnapshot(q, (snapshot) => {
      let agents = [];
      snapshot.docs.forEach((doc) => {
        agents.push({ ...doc.data(), id: doc.id });
      });
      dispatch({ type: BUSINESS_AGENTS, payload: agents });
    });
  } catch (error) {
    console.error(error.message);
  }
};
