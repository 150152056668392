import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { signupBusiness } from '../../actions/business';
import { Navigate, Link } from 'react-router-dom';
import {
  Container,
  Grid,
  Card,
  CardContent,
  TextField,
  CardActions,
  Button,
  Box,
} from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import LoadingButton from '@mui/lab/LoadingButton';
import './Business.css';

const BusinessSignup = ({ signupBusiness, isBusiness, alert }) => {
  const [logginIn, setLogging] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    address: '',
    password: '',
  });
  useEffect(() => {
    if (alert.length > 0) {
      setLogging(false);
      if (alert[0].alertType === 'success') {
        setFormData({
          name: '',
          email: '',
          number: '',
          address: '',
          password: '',
        });
        return <Navigate to="/business/sign-in" />;
      }
    }
  }, [alert]);

  const { name, email, number, address, password } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    let isBusiness = true;
    signupBusiness({ name, email, number, address, password, isBusiness });
    setLogging(true);
  };

  if (isBusiness) {
    return <Navigate to="/business/business-landing" />;
  }
  return (
    <Container sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box id="sign-in">
        <h2>Sign up your business</h2>
        <Card>
          <form onSubmit={onSubmit}>
            <CardContent id="form">
              <Grid item>
                <TextField
                  label="Business Name"
                  variant="outlined"
                  type="text"
                  name="name"
                  value={name}
                  onChange={onChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Office Address"
                  variant="outlined"
                  type="text"
                  name="address"
                  value={address}
                  onChange={onChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Office Number"
                  variant="outlined"
                  type="number"
                  name="number"
                  value={number}
                  onChange={onChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Business Email"
                  variant="outlined"
                  type="email"
                  name="email"
                  value={email}
                  onChange={onChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Password"
                  variant="outlined"
                  type="password"
                  name="password"
                  value={password}
                  onChange={onChange}
                  fullWidth
                  required
                />
              </Grid>
            </CardContent>
            <CardActions>
              {logginIn ? (
                <LoadingButton loading variant="contained" fullWidth>
                  Logging In
                </LoadingButton>
              ) : (
                <Button type="submit" variant="contained" fullWidth>
                  Sign up business <DoubleArrowIcon />
                </Button>
              )}
            </CardActions>
          </form>
        </Card>
        <Box
          m={3}
          display="flex"
          justifyContent="center"
          alignItems="center"
          as={Link}
          to="/business/sign-in"
        >
          <Button variant="outlined" color="primary" sx={{ height: 40 }}>
            Already have an account?
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
  isBusiness: state.business.isBusiness,
});

export default connect(mapStateToProps, { signupBusiness })(BusinessSignup);
