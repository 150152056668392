import { useState, useEffect } from 'react';
import { tokens } from '../../../theme';
import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Typography,
  useTheme,
} from '@mui/material';
import { RemoveCircle } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const DeleteLeadFile = ({
  type,
  deleteFile,
  fileName,
  updateLeadOnFileDelete,
}) => {
  const [file, setFile] = useState('');
  const [theName, setTheName] = useState('');
  const [deleting, setDeleting] = useState(false);
  useEffect(() => {
    const file = type.replace('_', ' ');
    setFile(file);
    const x = fileName.split('%');
    const fileRef = x[1].split('?');
    setTheName(fileRef[0].slice(2));
  }, [type, fileName]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const deleteMe = async () => {
    let name = theName;
    setDeleting(true);
    const res = await deleteFile({ type, name });
    if (res === 'success') {
      setDeleting(false);
      handleClose();
      updateLeadOnFileDelete(type);
    }
  };
  return (
    <>
      <Button sx={{ color: colors.redAccent[400] }} onClick={handleOpen}>
        <RemoveCircle fontSize="small" />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{ color: colors.blueAccent[400], textAlign: 'center' }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
            gutterBottom
          >
            Would you like to delete the {file}?
          </Typography>
          <Divider sx={{ mb: '10px' }} />
          <Grid container>
            <Grid item xs={6}>
              {deleting ? (
                <LoadingButton
                  loading
                  fullWidth
                  loadingPosition="center"
                  variant="outlined"
                >
                  ...
                </LoadingButton>
              ) : (
                <Button
                  variant="outlined"
                  sx={{
                    color: colors.blueAccent[200],
                    borderColor: colors.blueAccent[200],
                  }}
                  onClick={deleteMe}
                >
                  Yes
                </Button>
              )}
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                sx={{
                  color: colors.redAccent[700],
                  borderColor: colors.redAccent[700],
                }}
                onClick={handleClose}
              >
                No
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteLeadFile;
