import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography, Modal, Button, Box, Grid } from '@mui/material';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import { changeAdmin } from '../../../actions/clients/clients';

const ToggleAdmin = ({ user, changeAdmin, userId, note }) => {
  const [open, setOpen] = useState(false);
  const [admin, setAdmin] = useState();
  useEffect(() => {
    if (note) {
      if (note.length > 0) {
        setOpen(false);
      }
    }
  }, [note]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (user) {
      if (user.admin === undefined) {
        setAdmin(false);
      } else {
        setAdmin(user.admin);
      }
    }
  }, [user]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const toggle = () => {
    const a = !admin;
    changeAdmin({ userId, a });
  };
  return (
    <div>
      <Button onClick={handleOpen}>
        <AdminPanelSettingsOutlinedIcon />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {admin === false ? (
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Would you like to make {user.name} admin
            </Typography>
          ) : (
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Would you like to remove {user.name} admin privileges
            </Typography>
          )}

          <Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={toggle}
                fullWidth
              >
                Yes
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClose}
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  note: state.alert,
});

export default connect(mapStateToProps, { changeAdmin })(ToggleAdmin);
