import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  fetchTicket,
  fetchTexts,
  ticketMsg,
  claimTicket,
  closeTicket,
} from '../../../actions/techs/ticket';
import { uploadFile } from '../../../actions/storage';
import { useParams } from 'react-router-dom';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import {
  CircularProgress,
  Container,
  Paper,
  Typography,
  Box,
  Divider,
  Grid,
  List,
  FormControl,
  TextField,
  IconButton,
  ListItem,
  LinearProgress,
  Button,
  Alert,
} from '@mui/material';
import Text from './Text';
import moment from 'moment';
import FileUpload from './FileUpload';
import ClaimTicket from './ClaimTicket';
import CloseTicket from './CloseTicket';
import { setAlert } from '../../../actions/alert';

const TechTicket = ({
  fetchTicket,
  tech_tickets: { ticket, texts },
  techId,
  fetchTexts,
  setAlert,
  ticketMsg,
  techName,
  uploadFile,
  upload: { progress, text_url },
  claimTicket,
  closeTicket,
  alert,
}) => {
  const [text, setText] = useState('');
  const { ticketId } = useParams();
  let scroll = useRef(null);
  let closeBtn = useRef();

  useEffect(() => {
    if (texts) {
      if (scroll.current) {
        scroll.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [texts]);

  useEffect(() => {
    if (ticketId) {
      fetchTicket(ticketId);
    }
  }, [fetchTicket, ticketId]);

  useEffect(() => {
    const unsub = fetchTexts(ticketId);
    return unsub;
  }, [fetchTexts, ticketId]);

  useEffect(() => {
    if (text_url) {
      let authorId = techId;
      let text = text_url;
      let authorName = techName;
      let createdAt = moment().format();
      ticketMsg({ ticketId, text, authorId, createdAt, authorName });
    }
  }, [text_url, ticketId, ticketMsg, techId, techName]);

  if (ticket === null || texts === null) return <CircularProgress />;
  const {
    header,
    createdBy,
    assignedTo,
    createdAt,
    email,
    isComplete,
    deviceToken,
    ticketNum,
    username,
    completedAt,
    desc,
    review,
  } = ticket;

  const onSubmit = (e) => {
    e.preventDefault();
    if (text === '') return setAlert('Cannot send a blank message!', 'warning');
    let authorId = techId;
    let authorName = techName;
    let createdAt = moment().format();
    ticketMsg({ ticketId, text, authorId, createdAt, authorName, deviceToken });

    setText('');
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container>
            <Grid xs={9} item>
              <List>
                <ListItem>
                  <Typography variant="subtitle1">Header: {header}</Typography>
                </ListItem>
                <Divider />
                <ListItem>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">
                        User: {username}
                      </Typography>
                    </Grid>
                    <Grid xs={6} item>
                      {isComplete ? (
                        <Button variant="outlined" color="success" size="small">
                          Closed; {moment(completedAt).calendar()}
                        </Button>
                      ) : (
                        <Button variant="outlined" color="warning" size="small">
                          Opened {moment(createdAt).calendar()}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
                <ListItem>
                  <Typography variant="subtitle1">
                    Description: {desc}
                  </Typography>
                </ListItem>
                {review === null && isComplete && (
                  <>
                    <Divider />
                    <ListItem>
                      <Typography variant="subtitle1">
                        Ticket needs review
                      </Typography>
                    </ListItem>
                  </>
                )}
              </List>
              <Divider />
            </Grid>
            {isComplete === false && (
              <Grid item>
                <List>
                  <ListItem>
                    <CloseTicket
                      closeTicket={closeTicket}
                      ticketId={ticketId}
                      alert={alert}
                      closeBtn={closeBtn}
                      email={email}
                      createdBy={createdBy}
                      deviceToken={deviceToken}
                      ticketNum={ticketNum}
                      username={username}
                    />
                  </ListItem>
                  <ListItem>
                    <ClaimTicket
                      claimTicket={claimTicket}
                      techId={techId}
                      techName={techName}
                      ticketId={ticketId}
                      assignedTo={assignedTo}
                      email={email}
                      createdBy={createdBy}
                      username={username}
                      deviceToken={deviceToken}
                    />
                  </ListItem>
                </List>
              </Grid>
            )}
            <Divider />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Paper elevation={4} xs={12}>
            <Box p={3} xs={12}>
              <Grid container spacing={4}>
                <Grid id="chat-grid" item>
                  <List id="chat-messages">
                    {texts.length === 0
                      ? 'No texts'
                      : texts.map((text) => {
                          return (
                            <Text
                              key={text.id}
                              text={text}
                              techId={techId}
                              ticketId={ticketId}
                            />
                          );
                        })}
                    <ListItem ref={scroll}></ListItem>
                  </List>
                </Grid>
              </Grid>
            </Box>
            {isComplete ? (
              <Alert>Ticket marked as complete!</Alert>
            ) : (
              <form onSubmit={onSubmit}>
                <Grid container>
                  <Grid xs={9} item>
                    {progress > 0 && <LinearProgress />}
                    <FormControl fullWidth>
                      <TextField
                        label="type your message"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <IconButton xs={1}>
                      <FileUpload uploadFile={uploadFile} />
                    </IconButton>
                    <IconButton xs={1} sx={{ mt: 0.4 }} type="submit">
                      <SendOutlinedIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </form>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  tech_tickets: state.tech_tickets,
  techId: state.auth.user.uid,
  techName: state.tech.tech_profile.name,
  upload: state.upload,
  alert: state.alert,
});
export default connect(mapStateToProps, {
  fetchTicket,
  fetchTexts,
  setAlert,
  ticketMsg,
  uploadFile,
  claimTicket,
  closeTicket,
})(TechTicket);
