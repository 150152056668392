import {
  PIPELINE_CLIENT_LEADS,
  CLOSED_CLIENT_LEADS,
  CLIENT_LEAD,
  SUBMIT_CLIENT_TO_LEAD,
  ADD_NOTE_TO_LEAD,
  ADD_FOLLOWUP_TO_LEAD,
  UPDATE_LEAD_COLLAB,
  CLIENT_LEAD_NOTES,
  CLIENT_LEAD_FOLLOWUPS,
  CLIENT_LEAD_TEXTS,
  CLIENT_LEAD_FILE_URL,
} from '../../actions/types';
const initState = {
  pipeline_leads: null,
  closed_leads: null,
  loading: true,
  lead: null,
  client: null,
  lead_notes: [],
  lead_followUps: [],
  collabSales: {},
  notes: null,
  follow_ups: null,
  texts: null,
  leadFileUrl: null,
};

function salesReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case PIPELINE_CLIENT_LEADS:
      return {
        ...state,
        pipeline_leads: payload,
        loading: false,
      };
    case CLOSED_CLIENT_LEADS:
      return {
        ...state,
        closed_leads: payload,
      };
    case CLIENT_LEAD:
      return {
        ...state,
        lead: payload,
        loading: false,
      };
    case SUBMIT_CLIENT_TO_LEAD:
      return {
        ...state,
        client: payload,
      };
    case ADD_NOTE_TO_LEAD:
      return {
        ...state,
        lead_notes: payload,
      };
    case ADD_FOLLOWUP_TO_LEAD:
      return {
        ...state,
        lead_followUps: payload,
      };
    case UPDATE_LEAD_COLLAB:
      return {
        ...state,
        collabSales: payload,
      };
    case CLIENT_LEAD_NOTES:
      return {
        ...state,
        notes: payload,
      };
    case CLIENT_LEAD_FOLLOWUPS:
      return {
        ...state,
        follow_ups: payload,
      };
    case CLIENT_LEAD_TEXTS:
      return {
        ...state,
        texts: payload,
      };
    case CLIENT_LEAD_FILE_URL:
      return {
        ...state,
        leadFileUrl: payload,
      };
    default:
      return state;
  }
}

export default salesReducer;
