import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Grid, Alert, CircularProgress, Typography } from '@mui/material';
import { fetchAgents } from '../../actions/business';
import AddSupportAgent from './AddSupportAgent';

const BusinessAgents = ({
  business: { business_profile, business_agents },
  fetchAgents,
}) => {
  useEffect(() => {
    fetchAgents(business_profile.id);
  }, [fetchAgents, business_profile]);
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <AddSupportAgent business_profile={business_profile} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Alert icon={false} variant="filled" severity="info">
            Support Agents
          </Alert>
          {business_agents == null ? (
            <CircularProgress />
          ) : business_agents.length === 0 ? (
            <Typography>You have no agents</Typography>
          ) : (
            business_agents.map((agent) => (
              <Alert
                variant="outlined"
                style={{ marginTop: '10px' }}
                key={agent.id}
              >
                {agent.name} {agent.surname}
              </Alert>
            ))
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  business: state.business,
});

export default connect(mapStateToProps, { fetchAgents })(BusinessAgents);
