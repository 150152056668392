import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { firestore } from '../../firebase-config';
import {
  MY_PASSWORDS,
  MY_MASTER_PASSWORD,
  VIEW_PASSWORDS,
  DECRYPTED_PASSWORD,
  CLEAR_DECRYPTED,
} from '../types';
import { setAlert } from '../alert';
import axios from 'axios';
const backend = process.env.REACT_APP_BACKEND_URL;

//Clear decrypted password//
export const clearPassword = () => (dispatch) => {
  dispatch({ type: CLEAR_DECRYPTED });
};

//Create master password//
export const createMasterPassword =
  ({ pwd, userId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = JSON.stringify({ pwd });

    try {
      console.log(userId);
      const res = await axios.post(
        `${backend}/api/firebase-admin/create-master-password/${userId}`,
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setAlert(msg.msg, 'success')));
      }
      dispatch(getMasterPassword(userId));
    } catch (error) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    }
  };

//Get master password//
export const getMasterPassword = (userId) => async (dispatch) => {
  try {
    const myMpRef = doc(firestore, 'master-password', userId);
    const mpRef = await getDoc(myMpRef);
    const myMP = mpRef.data();

    dispatch({ type: MY_MASTER_PASSWORD, payload: myMP });
  } catch (error) {
    console.error(error.message);
  }
};

//Login with master password//
export const loginWithMasterPassword =
  ({ pwdId, password }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ password });
    try {
      const res = await axios.post(
        `${backend}/api/firebase-admin/master-password-login/${pwdId}`,
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setAlert(msg.msg, 'success')));
      }

      dispatch({ type: VIEW_PASSWORDS, payload: true });
    } catch (error) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    }
  };

//Encrypt password//
export const createSavedPassword =
  ({ userId, pwd, name }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ pwd, name });
    try {
      const res = await axios.post(
        `${backend}/api/firebase-admin/create-saved-password/${userId}`,
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setAlert(msg.msg, 'success')));
      }
      dispatch(myPasswords(userId));
    } catch (error) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    }
  };

//Fetch Passwords//
export const myPasswords = (userId) => async (dispatch) => {
  try {
    const q = query(
      collection(firestore, 'passwords'),
      where('userId', '==', userId)
    );
    const getMyPasswords = await getDocs(q);

    const passwords = getMyPasswords.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    dispatch({ type: MY_PASSWORDS, payload: passwords });
  } catch (error) {
    console.error(error.message);
  }
};

//Decrypt password//
export const decryptMyPassword =
  ({ userId, pwdId }) =>
  async (dispatch) => {
    dispatch({ type: CLEAR_DECRYPTED });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ pwdId });
    try {
      const res = await axios.put(
        `${backend}/api/firebase-admin/decrypt-my-password/${userId}`,
        body,
        config
      );
      dispatch({ type: DECRYPTED_PASSWORD, payload: res.data });
    } catch (error) {
      console.error(error.message);
    }
  };

//Update password//
export const updatePassword =
  ({ pwdId, newPwd, userId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ pwdId, newPwd });
    try {
      const res = await axios.put(
        `${backend}/api/firebase-admin/update-my-password/${userId}`,
        body,
        config
      );

      const msgs = res.data.message;
      if (msgs) {
        msgs.forEach((msg) => dispatch(setAlert(msg.msg, 'success')));
      }
      dispatch(myPasswords(userId));
    } catch (error) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
      dispatch(myPasswords(userId));
    }
  };

//Delete password//
export const deletePassword =
  ({ pwdId, userId }) =>
  async (dispatch) => {
    try {
      const res = await deleteDoc(doc(firestore, 'passwords', pwdId));
      console.log(res);
      dispatch(setAlert('Password successfully deleted!', 'success'));
      dispatch(myPasswords(userId));
    } catch (error) {
      console.error(error.message);
      dispatch(setAlert('Something went wrong please try again...', 'error'));
    }
  };
