import { USER_PROFILE, USER_LOGOUT, USER_BUSINESS } from '../../actions/types';

const initState = {
  isUser: false,
  loading: true,
  user_profile: null,
  business: null,
};

function userReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_PROFILE:
      return {
        ...state,
        user_profile: payload,
        isUser: true,
        loading: false,
      };
    case USER_BUSINESS:
      return {
        ...state,
        business: payload,
      };
    case USER_LOGOUT:
      return {
        ...state,
        isUser: false,
        user_profile: null,
        loading: false,
      };
    default:
      return state;
  }
}

export default userReducer;
