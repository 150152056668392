import {
  MY_TICKETS,
  MY_TICKET,
  TICKET_TEXTS,
  CLEAR_TICKET,
  COMPLETED_TICKETS,
  TICKETS_NEED_REVIEW,
} from '../../actions/types';

const initState = {
  tickets: null,
  ticket: null,
  needs_review: null,
  complete_tickets: null,
  texts: null,
  loading: true,
};

function ticketReducer(state = initState, action) {
  const { payload, type } = action;

  switch (type) {
    case MY_TICKETS:
      return {
        ...state,
        tickets: payload,
        loading: false,
      };
    case COMPLETED_TICKETS:
      return {
        ...state,
        complete_tickets: payload,
        loading: false,
      };
    case MY_TICKET:
      return {
        ...state,
        ticket: payload,
        loading: false,
      };
    case TICKET_TEXTS:
      return {
        ...state,
        texts: payload,
        loading: false,
      };
    case TICKETS_NEED_REVIEW:
      return {
        ...state,
        needs_review: payload,
      };
    case CLEAR_TICKET:
      return {
        ...state,
        ticket: null,
        texts: null,
      };
    default:
      return state;
  }
}

export default ticketReducer;
