import { firestore } from '../../firebase-config';
import {
  collection,
  getDocs,
  addDoc,
  doc,
  getDoc,
  query,
  where,
  orderBy,
  setDoc,
  updateDoc,
  onSnapshot,
  deleteDoc,
} from 'firebase/firestore';
import {
  GET_CLIENTS,
  CLIENT_USERS,
  GET_CLIENT,
  CLEAR_CLIENT,
  HOME_USERS,
  GET_USER,
  CLEAR_USER,
  USER_DEVICE,
  CLIENT_DEVICE,
  OLD_USERS,
  DEPARTMENTS,
  BRANCHES,
  DEPARTMENT_INFO,
  BRANCH_INFO,
} from '../types';
import { setAlert } from '../alert';
import axios from 'axios';
import moment from 'moment';
const backend = process.env.REACT_APP_BACKEND_URL;

/////////////////////////
//Fetch all the clients//
/////////////////////////
export const getClients = () => async (dispatch) => {
  dispatch({ type: CLEAR_CLIENT });
  const clientRef = collection(firestore, 'clients');

  const data = await getDocs(clientRef);
  try {
    let clients = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({ type: GET_CLIENTS, payload: clients });
  } catch (error) {
    console.error(error.message);
  }
};

///////////////////
//Create a client//
///////////////////
export const createClient =
  ({ name, email, number, address, createdAt }) =>
  async (dispatch) => {
    const clientRef = collection(firestore, 'clients');
    try {
      await addDoc(clientRef, {
        name,
        email,
        number,
        address,
        createdAt,
      });
      dispatch(setAlert('Client successfully created', 'success'));
    } catch (error) {
      console.error(error.message);
    }
  };

//Fetch a specific client//
export const fetchClient = (clientId) => async (dispatch) => {
  dispatch({ type: CLEAR_CLIENT });
  try {
    const clientRef = doc(firestore, 'clients', clientId);
    const client = await getDoc(clientRef);
    const q = query(
      collection(firestore, 'users'),
      where('clientId', '==', clientId),
      orderBy('createdAt')
    );
    const jam = await getDocs(q);

    const a = client.data();
    a.id = clientId;

    let clientUsers = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    dispatch({ type: CLIENT_USERS, payload: clientUsers });
    dispatch({ type: GET_CLIENT, payload: a });
  } catch (error) {
    console.error(error.message);
  }
};

//Fetch all home users//
export const homeUsers = () => async (dispatch) => {
  const q = query(
    collection(firestore, 'users'),
    where('clientId', '==', 'Home User'),
    orderBy('createdAt')
  );

  const jam = await getDocs(q);

  let homeUsers = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  dispatch({ type: HOME_USERS, payload: homeUsers });
  try {
  } catch (error) {
    console.error(error.message);
  }
};

//Create a user//
export const createUser =
  ({
    name,
    surname,
    position,
    email,
    cellphone,
    password,
    clientId,
    createdAt,
    clientName,
    isBusiness,
  }) =>
  async (dispatch) => {
    const userProfile = collection(firestore, 'users');
    try {
      dispatch({ type: CLEAR_CLIENT });
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const body = JSON.stringify({ email, password });
      const res = await axios.post(
        `${backend}/api/firebase-admin/create-user`,
        body,
        config
      );
      const credentials = res.data;
      const photoUrl = 'photoUrl';
      await setDoc(doc(userProfile, credentials), {
        name,
        surname,
        email,
        cellphone,
        position,
        clientId,
        credentials,
        createdAt,
        clientName,
        photoUrl,
        isBusiness,
      });
      dispatch(setAlert('User Successfully Added', 'success'));
      dispatch(fetchClient(clientId));
    } catch (error) {
      const errors = error.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'error')));
      }
    }
  };

//Update a user//
export const updateUser =
  ({ name, surname, cellphone, position, userId }) =>
  async (dispatch) => {
    const userRef = doc(firestore, 'users', userId);
    try {
      const newFields = {
        name: name,
        surname: surname,
        cellphone: cellphone,
        position: position,
      };
      await updateDoc(userRef, newFields);
      dispatch(setAlert('User updated successfully', 'success'));
      dispatch(fetchUser(userId));
    } catch (error) {
      console.log(error.message);
      dispatch(setAlert('something went wrong', 'error'));
    }
  };

//Fetch a specific user//
export const fetchUser = (userId) => async (dispatch) => {
  dispatch({ type: CLEAR_USER });
  try {
    const userRef = doc(firestore, 'users', userId);

    const userDoc = await getDoc(userRef);
    const user = userDoc.data();

    dispatch({ type: GET_USER, payload: user });
  } catch (error) {
    console.error(error.message);
  }
};

//Add user device data//
export const addDeviceData =
  ({
    deviceType,
    deviceModel,
    serialNumber,
    purchaseDate,
    warranty,
    os,
    officeVersion,
    antiVirus,
    software,
    deviceSpecs,
    userId,
    clientId,
    userName,
  }) =>
  async (dispatch) => {
    const deviceRef = collection(firestore, 'device');
    const createdAt = moment().format();

    try {
      const data = await addDoc(deviceRef, {
        deviceType,
        deviceModel,
        os,
        officeVersion,
        serialNumber,
        purchaseDate,
        warranty,
        antiVirus,
        software,
        deviceSpecs,
        userId,
        clientId,
        createdAt,
        userName,
      });

      const a = doc(firestore, 'device', data.id);
      const b = await getDoc(a);
      const device = b.data();
      dispatch(setAlert('Device data has been successfully added', 'success'));
      dispatch({ type: USER_DEVICE, payload: device });
    } catch (error) {
      console.error(error.message);
    }
  };

//Get user device//
export const userDevice = (userId) => async (dispatch) => {
  try {
    const q = query(
      collection(firestore, 'device'),
      where('userId', '==', userId)
    );
    const jam = await getDocs(q);

    let device = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    if (device.length > 0) {
      dispatch({ type: USER_DEVICE, payload: device[0] });
    }
  } catch (error) {
    console.error(error.message);
  }
};

//Edit a user device//
export const editDevice =
  ({
    deviceType,
    deviceModel,
    os,
    officeVersion,
    serialNumber,
    purchaseDate,
    warranty,
    antiVirus,
    software,
    deviceSpecs,
    deviceId,
  }) =>
  async (dispatch) => {
    const deviceRef = doc(firestore, 'device', deviceId);
    const newFields = {
      deviceType: deviceType,
      deviceModel: deviceModel,
      os: os,
      officeVersion: officeVersion,
      antiVirus: antiVirus,
      software: software,
      deviceSpecs: deviceSpecs,
      serialNumber: serialNumber,
      purchaseDate: purchaseDate,
      warranty: warranty,
    };

    try {
      await updateDoc(deviceRef, newFields);
      const a = doc(firestore, 'device', deviceId);
      const b = await getDoc(a);
      const device = b.data();
      dispatch(setAlert('Device successfully updated', 'success'));
      dispatch({ type: USER_DEVICE, payload: device });
    } catch (error) {
      console.error(error.message);
    }
  };

//Make user admin//
export const changeAdmin =
  ({ userId, a }) =>
  async (dispatch) => {
    try {
      const userRef = doc(firestore, 'users', userId);
      const newFields = { admin: a };
      await updateDoc(userRef, newFields);
      dispatch(setAlert('User privelages changed', 'success'));
      dispatch(fetchUser(userId));
      dispatch(userDevice(userId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Fetch client devices//
export const clientDevices = (clientId) => async (dispatch) => {
  try {
    const deviceRef = collection(firestore, 'device');
    const q = query(deviceRef, where('clientId', '==', clientId));

    onSnapshot(q, (snapshot) => {
      let devices = [];
      snapshot.docs.forEach((doc) => {
        devices.push({ ...doc.data(), id: doc.id });
      });

      dispatch({ type: CLIENT_DEVICE, payload: devices });
    });
  } catch (error) {
    console.error(error.message);
  }
};

//Decommision Device//
export const decomMissionDevice =
  ({ deviceId }) =>
  async (dispatch) => {
    const deviceRef = doc(firestore, 'device', deviceId);
    try {
      const newFields = { decommission: true, userId: null, userName: null };
      await updateDoc(deviceRef, newFields);
      dispatch(setAlert('Device successfully updated', 'success'));
      dispatch({ type: USER_DEVICE, payload: null });
    } catch (error) {
      console.error(error.message);
    }
  };

//Delete a device//
export const deleteDevice =
  ({ deviceId, clientId }) =>
  async (dispatch) => {
    try {
      await deleteDoc(doc(firestore, 'device', deviceId));
      dispatch(setAlert('Device has been successfully deleted', 'success'));
      dispatch(clientDevices(clientId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Delte a user//
export const deleteUser =
  ({ userId, clientId, username, createdAt, surname }) =>
  async (dispatch) => {
    try {
      const oldUser = collection(firestore, 'old-user');
      const deletedAt = moment().format();
      await addDoc(oldUser, {
        userId,
        clientId,
        deletedAt,
        username,
        createdAt,
        surname,
      });
      await deleteDoc(doc(firestore, 'users', userId));
      await axios.post(`${backend}/api/firebase-admin/delete-a-user/${userId}`);
      dispatch(setAlert('User has been successfully deleted', 'success'));
    } catch (error) {
      console.error(error.message);
    }
  };

//Get old users//
export const getOldUsers = (clientId) => async (dispatch) => {
  try {
    const old_users = collection(firestore, 'old-user');
    const q = query(old_users, where('clientId', '==', clientId));

    onSnapshot(q, (snapshot) => {
      let users = [];
      snapshot.docs.forEach((doc) => {
        users.push({ ...doc.data(), id: doc.id });
      });

      dispatch({ type: OLD_USERS, payload: users });
    });
  } catch (error) {
    console.error(error.message);
  }
};

//Create client branch//
export const createBranch =
  ({ name, clientId, createdBy }) =>
  async (dispatch) => {
    const branchRef = collection(firestore, 'branch');
    const createdAt = moment().format();
    const users = [];
    try {
      await addDoc(branchRef, {
        name,
        clientId,
        createdAt,
        users,
        createdBy,
      });
      dispatch(setAlert('Branch successfully created...', 'success'));
      dispatch(getBranches(clientId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Get client branches//
export const getBranches = (clientId) => async (dispatch) => {
  const branchRef = collection(firestore, 'branch');
  const q = query(branchRef, where('clientId', '==', clientId));

  onSnapshot(q, (snapshot) => {
    let branches = [];
    snapshot.docs.forEach((doc) => {
      branches.push({ ...doc.data(), id: doc.id });
    });
    //dispatch loot
    dispatch({ type: BRANCHES, payload: branches });
  });
  try {
  } catch (error) {
    console.error(error.message);
  }
};

//Get a branch//
export const getBranch = (branchId) => async (dispatch) => {
  const branchRef = doc(firestore, 'branch', branchId);
  try {
    const branchDoc = await getDoc(branchRef);
    const branch = branchDoc.data();
    dispatch({ type: BRANCH_INFO, payload: branch });
    let clientId = branch.clientId;

    dispatch(fetchClient(clientId));
  } catch (error) {
    console.error(error.message);
  }
};

//Add users to branch//
export const addToBranch =
  ({ branchId, newUsers }) =>
  async (dispatch) => {
    const branchRef = doc(firestore, 'branch', branchId);
    const branchDoc = await getDoc(branchRef);
    const branch = branchDoc.data();

    try {
      let users = branch.users;
      let newArr = users.concat(newUsers);
      const newFields = { users: newArr };
      await updateDoc(branchRef, newFields);

      dispatch(getBranch(branchId));
      dispatch(setAlert('Users added successfully', 'success'));
    } catch (error) {
      console.error(error.message);
      dispatch(setAlert('Something went wrong', 'error'));
    }
  };

//Remove users from branch//
export const removeFromBranch =
  ({ branchId, removeFromArr }) =>
  async (dispatch) => {
    const branchRef = doc(firestore, 'branch', branchId);
    const branchDoc = await getDoc(branchRef);
    const branch = branchDoc.data();
    const a = branch.users.length - removeFromArr.length;

    try {
      removeFromArr.forEach((id) => {
        let currentUsers = branch.users;

        async function removeFunc(arr) {
          let userArr = arr;

          arr.forEach((user, index) => {
            let userId = user.userId;
            if (userId === id) {
              userArr.splice(index, 1);
            }
          });

          if (userArr.length === a) {
            const newFields = { users: userArr };
            await updateDoc(branchRef, newFields);
            dispatch(getBranch(branchId));
            dispatch(setAlert('Branch successfully edited', 'success'));
          }
        }

        removeFunc(currentUsers);
      });
    } catch (error) {
      console.error(error.message);
    }
  };

//Create client department//
export const createDepartment =
  ({ name, clientId, createdBy }) =>
  async (dispatch) => {
    const depRef = collection(firestore, 'departments');
    const createdAt = moment().format();
    const users = [];
    try {
      await addDoc(depRef, {
        name,
        clientId,
        createdAt,
        users,
        createdBy,
      });
      dispatch(setAlert('Department successfully created...', 'success'));
      dispatch(getDepartments(clientId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Get client departments//
export const getDepartments = (clientId) => async (dispatch) => {
  const depRef = collection(firestore, 'departments');
  const q = query(depRef, where('clientId', '==', clientId));

  try {
    onSnapshot(q, (snapshot) => {
      let departments = [];
      snapshot.docs.forEach((doc) => {
        departments.push({ ...doc.data(), id: doc.id });
      });

      //dispatch the loot
      dispatch({ type: DEPARTMENTS, payload: departments });
    });
  } catch (error) {
    console.error(error.mesasage);
  }
};

//Get department//
export const getDepartment = (departmentId) => async (dispatch) => {
  const depRef = doc(firestore, 'departments', departmentId);
  try {
    const depDoc = await getDoc(depRef);
    const department = depDoc.data();
    dispatch({ type: DEPARTMENT_INFO, payload: department });
    let clientId = department.clientId;

    dispatch(fetchClient(clientId));
  } catch (error) {
    console.error(error.message);
  }
};

//Add users to department//
export const addToDepartment =
  ({ departmentId, newUsers }) =>
  async (dispatch) => {
    const depRef = doc(firestore, 'departments', departmentId);
    const depDoc = await getDoc(depRef);
    const dep = depDoc.data();

    try {
      const users = dep.users;
      const newArr = users.concat(newUsers);
      const newFields = { users: newArr };
      await updateDoc(depRef, newFields);
      dispatch(getDepartment(departmentId));
      dispatch(setAlert('Users added successfully', 'success'));
    } catch (error) {
      console.error(error.message);
      dispatch(setAlert('Something went wrong', 'error'));
    }
  };

//Remove users from department//
export const removeFromDepartment =
  ({ departmentId, removeFromArr }) =>
  async (dispatch) => {
    const depRef = doc(firestore, 'departments', departmentId);
    const depDoc = await getDoc(depRef);
    const dep = depDoc.data();
    const a = dep.users.length - removeFromArr.length;
    try {
      removeFromArr.forEach((id) => {
        let currentUsers = dep.users;

        async function removeFunc(arr) {
          let userArr = arr;

          arr.forEach((user, index) => {
            let userId = user.userId;
            if (userId === id) {
              userArr.splice(index, 1);
            }
          });

          if (userArr.length === a) {
            const newFields = { users: userArr };
            await updateDoc(depRef, newFields);
            dispatch(getDepartment(departmentId));
            dispatch(setAlert('Department successfully edited', 'success'));
          }
        }

        removeFunc(currentUsers);
      });
    } catch (error) {
      console.error(error.mesasage);
    }
  };
