import {
  GET_LEADS,
  GET_LEAD,
  CLEAR_LEADS,
  LEAD_NOTES,
  FOLLOW_UPS,
  DONE_FOLLOW_UPS,
  SCHEDULED_FOLLOWS,
  ADD_NOTE,
  ADD_FOLLOW,
  ADD_COLLAB,
  ADD_CLIENT_TO_LEAD,
  LEAD_FILE_UPLOAD_STATUS,
  LEAD_FILE_URL,
  GET_LEAD_TEXTS,
  REMOVE_COLLAB,
  GET_CLOSED_LEADS,
  GET_COLLAB_LEADS,
} from '../../actions/types';

const initState = {
  lead: null,
  leads: null,
  closed_leads: null,
  loading: true,
  notes: null,
  follow: null,
  done: null,
  scheduled: null,
  lead_notes: [],
  leadFollowUps: [],
  collabCacheAdd: [],
  collabCacheRemove: [],
  client: null,
  fileUploadStatus: 0,
  leadFileUrl: null,
  texts: null,
  collab_leads: null,
};

function leadsReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_COLLAB_LEADS:
      return {
        ...state,
        collab_leads: payload,
      };
    case GET_LEADS:
      return {
        ...state,
        leads: payload,
        loading: false,
      };
    case GET_CLOSED_LEADS:
      return {
        ...state,
        closed_leads: payload,
      };
    case GET_LEAD:
      return {
        ...state,
        lead: payload,
        loading: false,
      };
    case CLEAR_LEADS:
      return {
        ...state,
        lead: null,
        leads: null,
      };
    case LEAD_NOTES:
      return {
        ...state,
        notes: payload,
      };
    case FOLLOW_UPS:
      return {
        ...state,
        follow: payload,
      };
    case DONE_FOLLOW_UPS:
      return {
        ...state,
        done: payload,
      };
    case SCHEDULED_FOLLOWS:
      return {
        ...state,
        scheduled: payload,
      };
    case ADD_NOTE:
      return {
        ...state,
        lead_notes: payload,
      };
    case ADD_FOLLOW:
      return {
        ...state,
        leadFollowUps: payload,
      };
    case ADD_COLLAB:
      return {
        ...state,
        collabCacheAdd: payload,
      };
    case REMOVE_COLLAB:
      return {
        ...state,
        collabCacheRemove: payload,
      };
    case ADD_CLIENT_TO_LEAD:
      return {
        ...state,
        client: payload,
      };
    case LEAD_FILE_UPLOAD_STATUS:
      return {
        ...state,
        fileUploadStatus: payload,
      };
    case LEAD_FILE_URL:
      return {
        ...state,
        leadFileUrl: payload,
      };
    case GET_LEAD_TEXTS:
      return {
        ...state,
        texts: payload,
      };
    default:
      return state;
  }
}

export default leadsReducer;
