import React, { useState, useEffect } from 'react';
import {
  Alert,
  Button,
  Modal,
  Box,
  List,
  ListItem,
  ListItemText,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { tokens } from '../../../theme';

const TicketReport = ({ ticket }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const [minutes, setMinutes] = useState(null);
  const [hours, setHours] = useState(null);
  const [days, setDays] = useState(null);
  const [closed, setClosed] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    function diff_minutes(d1, d2) {
      if (d2 == null) {
        setClosed(false);
      } else {
        var diff = (d2.getTime() - d1.getTime()) / 1000;
        diff /= 60;

        if (diff < 60) {
          let time = Math.round(diff);
          setMinutes(time);
        } else {
          diff /= 60;
          if (diff < 24) {
            let time = Math.round(diff);
            setHours(time);
          } else {
            diff /= 24;
            let time = Math.round(diff);
            setDays(time);
          }
        }
      }
    }

    let d1 = new Date(ticket.createdAt);
    let d2 = new Date(ticket.completedAt);

    diff_minutes(d1, d2);
  }, [ticket]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <>
      <Button onClick={handleOpen} sx={{ color: colors.grey[400] }} fullWidth>
        {ticket.header}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Alert severity="info">Ticket Report</Alert>
          <List>
            <ListItem>
              <ListItemText primary={`Ticket header : ${ticket.header}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Ticket Description : ${ticket.desc}`} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`Ticket opened : ${moment(ticket.createdAt).format(
                  'MMMM Do YYYY, h:mm'
                )}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`Ticket closed : ${moment(ticket.completedAt).format(
                  'MMMM Do YYYY, h:mm'
                )}`}
              />
            </ListItem>
            <ListItem>
              {minutes ? (
                <ListItemText primary={`Duration : ${minutes} minutes `} />
              ) : hours ? (
                <ListItemText primary={`Duration : ${hours} hour/s`} />
              ) : days ? (
                <ListItemText primary={`Duration : ${days} day/s`} />
              ) : closed ? (
                <ListItemText primary={`Duration : Ticket incomplete`} />
              ) : (
                ''
              )}
            </ListItem>
          </List>
        </Box>
      </Modal>
    </>
  );
};

export default TicketReport;
