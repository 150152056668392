import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

const AssignToDepartment = ({ departments, assignedToDo, assignThisDude }) => {
  const [open, setOpen] = useState(false);
  const [chosen, setChosen] = useState(null);
  const [depSelected, setDepSelected] = useState(false);
  useEffect(() => {
    if (assignedToDo && assignedToDo.responsible === 'Department') {
      setChosen(assignedToDo.id);
      setDepSelected(true);
    } else {
      setChosen(null);
      setDepSelected(false);
    }
  }, [assignedToDo]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const selectDep = ({ dep }) => {
    const responsible = {
      responsible: 'Department',
      id: dep.id,
      name: dep.name,
    };

    assignThisDude({ responsible });
    handleClose();
  };

  return (
    <Container>
      {depSelected ? (
        <Button
          variant="contained"
          color="success"
          sx={{
            color: 'white',
          }}
          size="small"
          onClick={handleOpen}
        >
          <PeopleOutlineIcon />
        </Button>
      ) : (
        <Button
          variant="outlined"
          size="small"
          color="success"
          onClick={handleOpen}
        >
          <PeopleOutlineIcon />
        </Button>
      )}
      <Dialog open={open} onClose={handleClose} scroll="paper">
        <DialogTitle id="scroll-dialog-title">
          Select department to assign?
        </DialogTitle>
        <Divider variant="middle" />

        <DialogContent>
          {departments.length === 0 ? (
            <Typography>No departments</Typography>
          ) : (
            departments.map((dep) => (
              <Grid key={dep.id} container spacing={2}>
                <Grid item>
                  {chosen === dep.id ? (
                    <RadioButtonCheckedIcon />
                  ) : (
                    <RadioButtonUncheckedIcon
                      onClick={() => selectDep({ dep })}
                    />
                  )}
                </Grid>
                <Grid item>{dep.name}</Grid>
              </Grid>
            ))
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default AssignToDepartment;
