import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { CircularProgress, Container, Typography, Grid } from '@mui/material';
import { getUsers, activeTickets } from '../../actions/business';
import AddBusinessUser from './AddBusinessUser';
import UserItem from './UserItem';
import BusinessTickets from './Tickets/BusinessTickets';

const BusinessLanding = ({
  user,
  business: {
    isBusiness,
    business_profile,
    users,
    active_tickets,
    agent_profile,
  },
  getUsers,
  activeTickets,
}) => {
  useEffect(() => {
    if (business_profile) {
      const clientId = business_profile.id;
      getUsers(clientId);
      activeTickets(clientId);
    }
  }, [getUsers, business_profile, activeTickets]);

  useEffect(() => {
    if (agent_profile) {
      const clientId = agent_profile.businessId;
      getUsers(clientId);
      activeTickets(clientId);
    }
  }, [getUsers, activeTickets, agent_profile]);

  if (isBusiness === false) {
    return <Navigate to="/" />;
  }
  if (user && user.emailVerified === false) {
    return <Navigate to="/email-verification" />;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Welcome to your dashboard
      </Typography>

      <Grid container spacing={5}>
        <Grid xs={12} md={6} item>
          <Typography gutterBottom sx={{ marginBottom: '17px' }}>
            Tickets
          </Typography>
          {active_tickets === null ? (
            <CircularProgress />
          ) : active_tickets.length === 0 ? (
            <Typography>You have no active tickets</Typography>
          ) : (
            active_tickets.map((ticket) => (
              <BusinessTickets key={ticket.id} ticket={ticket} />
            ))
          )}
        </Grid>
        <Grid xs={12} md={6} item>
          <Grid container>
            <Grid item xs={8}>
              <Typography>Users</Typography>
            </Grid>
            <Grid item xs={4}>
              <AddBusinessUser business_profile={business_profile} />
            </Grid>
          </Grid>
          {users === null ? (
            <CircularProgress />
          ) : users.length === 0 ? (
            <Typography>You have no users</Typography>
          ) : (
            users.map((user) => <UserItem key={user.id} u={user} />)
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  business: state.business,
});

export default connect(mapStateToProps, {
  getUsers,
  activeTickets,
})(BusinessLanding);
