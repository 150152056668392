//NOTIFICATION ACTIONS//
export const SET_ALERT = ' SET_ALERT';
export const REMOVE_ALERT = ' REMOVE_ALERT';

//AUTH ACTIONS//
export const PERSIST_LOGIN = 'PERSIST_LOGIN';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';

//UPLOAD ACTIONS//
export const PROGRESS = 'PROGRESS';
export const TEXT_URL = 'TEXT_URL';
export const CLEAR_UPLOAD = 'CLEAR_UPLOAD';

//TECH TICKETS//
export const GET_TECHS = 'GET_TECHS';
export const TECH_PROFILE = 'TECH_PROFILE';
export const TECH_LOGOUT = 'TECH_LOGOUT';
export const PENDING_TICKETS = 'PENDING_TICKETS';
export const CLIENT_TICKETS = 'CLIENT_TICKETS';
export const ADMIN_TICKET = 'ADMIN_TICKET';
export const ADMIN_TEXTS = 'ADMIN_TEXTS';
export const CLEAR_ADMIN_TICKET = 'CLEAR_ADMIN_TICKET';
export const USER_TICKETS = 'USER_TICKETS';
export const CUSTOM_TICKET_RANGE = 'CUSTOM_TICKET_RANGE';
export const TIME_ON_TICKETS = 'TIME_ON_TICKETS';
export const TIME_ON_CUSTOM_TICKETS = 'TIME_ON_CUSTOM_TICKETS';
export const STILL_ACTIVE = 'STILL_ACTIVE';
export const CUSTOM_STILL_ACTIVE = 'CUSTOM_STILL_ACTIVE';
export const TICKETS_THAT_NEED_REVIEW = 'TICKETS_THAT_NEED_REVIEW';

//TECH LEADS//
export const GET_LEADS = 'GET_LEADS';
export const GET_LEAD = 'GET_LEAD';
export const CLEAR_LEADS = 'CLEAR_LEADS';
export const ADD_NOTE = 'ADD_NOTE';
export const ADD_FOLLOW = 'ADD_FOLLOW';
export const ADD_COLLAB = 'ADD_COLLAB';
export const REMOVE_COLLAB = 'REMOVE_COLLAB';
export const ADD_CLIENT_TO_LEAD = 'ADD_CLIENT_TO_LEAD';
export const LEAD_FILE_UPLOAD_STATUS = 'LEAD_FILE_UPLOAD_STATUS';
export const LEAD_FILE_URL = 'LEAD_FILE_URL';
export const GET_LEAD_TEXTS = 'GET_LEAD_TEXTS';
export const GET_CLOSED_LEADS = 'GET_CLOSED_LEADS';
export const LEAD_NOTES = 'LEAD_NOTES';
export const FOLLOW_UPS = 'FOLLOW_UPS';
export const DONE_FOLLOW_UPS = 'DONE_FOLLOW_UPS';
export const SCHEDULED_FOLLOWS = 'SCHEDULED_FOLLOWS';
export const GET_COLLAB_LEADS = 'GET_COLLAB_LEADS';

//TECH TODOS//
export const UPDATE_TASKLIST = 'UPDATE_TASKLIST';
export const ASSIGN_TODO = 'ASSIGN_TODO';
export const TODO_DEADLINE = 'TODO_DEADLINE';
export const MY_TODOS = 'MY_TODOS';
export const MY_COMPLETE_TODOS = 'MY_COMPLETE_TODOS';
export const COMPLETE_ASSIGNED_TODOS = 'COMPLETE_ASSIGNED_TODOS';
export const MY_TODO = 'MY_TODO';
export const PREV_TODO = 'PREV_TODO';
export const TODO_TEXTS = 'TODO_TEXTS';
export const ASSIGNED_TODOS = 'ASSIGNED_TODOS';
export const CLEAR_TODOS = 'CLEAR_TODOS';
export const TODO_ADD_COLLAB = 'TODO_ADD_COLLAB';
export const TODO_REMOVE_COLLAB = 'TODO_REMOVE_COLLAB';

//PROJECT ACTIONS//
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT = 'GET_PROJECT';
export const CLEAR_PROJECTS = 'CLEAR_PROJECTS';
export const NEXT = 'NEXT';
export const MILESTONE = 'MILESTONE';
export const FEATURE = 'FEATURE';

//CLIENT ACTIONS//
export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_CLIENT = 'GET_CLIENT';
export const CLIENT_USERS = 'CLIENT_USERS';
export const CLEAR_CLIENT = 'CLEAR_CLIENT';
export const GET_USER = 'GET_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const USER_DEVICE = 'USER_DEVICE';
export const CLIENT_DEVICE = 'CLIENT_DEVICE';
export const OLD_USERS = 'OLD_USERS';
export const DEPARTMENTS = 'DEPARTMENTS';
export const BRANCHES = 'BRANCHES';
export const BRANCH_INFO = 'BRANCH_INFO';
export const DEPARTMENT_INFO = 'DEPARTMENT_INFO';

//USER ACTIONS//
export const USER_PROFILE = 'USER_PROFILE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const MY_TICKET = 'MY_TICKET';
export const MY_TICKETS = 'MY_TICKETS';
export const TICKET_TEXTS = 'TICKET_TEXTS';
export const CLEAR_TICKET = 'CLEAR_TICKET';
export const COMPLETED_TICKETS = 'COMPLETED_TICKETS';
export const MY_PROJECTS = 'MY_PROJECTS';
export const MY_PROJECT = 'MY_PROJECT';
export const MY_PROJECT_NEXT = 'MY_PROJECT_NEXT';
export const MY_PROJECT_MILE = 'MY_PROJECT_MILE';
export const CLEAR_MY_PROJECTS = 'CLEAR_MY_PROJECTS';
export const MY_PROJECT_FEATURE = 'MY_PROJECT_FEATURE';
export const HOME_USERS = 'HOME_USERS';
export const USER_BUSINESS = 'USER_BUSINESS';
export const CLEAR_USER_ADMIN = 'CLEAR_USER_ADMIN';
export const ACTIVE_CLIENT_DEVICES = 'ACTIVE_CLIENT_DEVICES';
export const DECOMM_CLIENT_DEVICES = 'DECOMM_CLIENT_DEVICES';
export const USER_LIST = 'USER_LIST';
export const CURRENT_USER_CLIENT = 'CURRENT_USER_CLIENT';
export const MY_MASTER_PASSWORD = 'MY_MASTER_PASSWORD';
export const MY_PASSWORDS = 'MY_PASSWORDS';
export const CLEAR_PASSWORDS = 'CLEAR_PASSWORDS';
export const VIEW_PASSWORDS = 'VIEW_PASSWORDS';
export const DECRYPTED_PASSWORD = 'DECRYPTED_PASSWORD';
export const CLEAR_DECRYPTED = 'CLEAR_DECRYPTED';
export const ADMIN_TICKET_REPORT = 'ADMIN_TICKET_REPORT';
export const ADMIN_ACTIVE_TICKETS = 'ADMIN_ACTIVE_TICKETS';
export const CLEAR_TICKET_REPORT = 'CLEAR_TICKET_REPORT';
export const CUSTOM_ADMIN_TICKETS = 'CUSTOM_ADMIN_TICKETS';
export const CUSTOM_TICKETS_ACTIVE = 'CUSTOM_TICKETS_ACTIVE';
export const CUSTOM_TICKETS_TIME = 'CUSTOM_TICKETS_TIME';
export const MY_DEPARTMENTS = 'MY_DEPARTMENTS';
export const MY_DEPARTMENT_INFO = 'MY_DEPARTMENT_INFO';
export const MY_BRANCHES = 'MY_BRANCHES';
export const MY_BRANCH_INFO = 'MY_BRANCH_INFO';
export const PIPELINE_CLIENT_LEADS = 'PIPELINE_CLIENT_LEADS';
export const CLOSED_CLIENT_LEADS = 'CLOSED_CLIENT_LEADS';
export const CLIENT_LEAD = 'CLIENT_LEAD';
export const SUBMIT_CLIENT_TO_LEAD = 'SUBMIT_CLIENT_TO_LEAD';
export const ADD_NOTE_TO_LEAD = 'ADD_NOTE_TO_LEAD';
export const ADD_FOLLOWUP_TO_LEAD = 'ADD_FOLLOWUP_TO_LEAD';
export const UPDATE_LEAD_COLLAB = 'UPDATE_LEAD_COLLAB';
export const CLIENT_LEAD_FILE_URL = 'CLIENT_LEAD_FILE_URL';
export const CLIENT_LEAD_NOTES = 'CLIENT_LEAD_NOTES';
export const CLIENT_LEAD_FOLLOWUPS = 'CLIENT_LEAD_FOLLOWUPS';
export const CLIENT_LEAD_TEXTS = 'CLIENT_LEAD_TEXTS';
export const TICKETS_NEED_REVIEW = 'TICKETS_NEED_REVIEW';

//Todo Actions//
export const CLIENT_UPDATE_TASKLIST = 'CLIENT_UPDATE_TASKLIST';
export const CLIENT_ASSIGN_TODO = 'CLIENT_ASSIGN_TODO';
export const CLIENT_TODO_DEADLINE = 'CLIENT_TODO_DEADLINE';
export const CLIENT_MY_TODOS = 'CLIENT_MY_TODOS';
export const CLIENT_MY_TODO = 'CLIENT_MY_TODO';
export const CLIENT_CLEAR_TODOS = 'CLIENT_CLEAR_TODOS';

//Business Actions//
export const BUSINESS_PROFILE = 'BUSINESS_PROFILE';
export const BUSINESS_LOGOUT = 'BUSINESS_LOGOUT';
export const BUSINESS_USERS = 'BUSINESS_USERS';
export const BUSINESS_USER = 'BUSINESS_USER';
export const ACTIVE_BIZ_TICKETS = 'ACTIVE_BIZ_TICKETS';
export const BUSINESS_AGENTS = 'BUSINESS_AGENTS';
export const AGENT_PROFILE = 'AGENT_PROFILE';
