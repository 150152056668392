import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Box, Grid, Alert, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { createDepartment } from '../../../actions/users/admin';

const CreateDepartment = ({ clientId, alert, createDepartment, user }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [creating, setCreating] = useState(false);
  const [formError, setFormError] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (alert && alert.length > 0 && creating) {
      handleClose();
    }
  }, [alert, creating]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const create = () => {
    if (name.length < 3) {
      setFormError('Please give the department a name');
      setTimeout(() => setFormError(null), 4000);
    } else {
      let createdBy = {
        org: user.clientName,
        name: user.name,
      };
      createDepartment({ name, clientId, createdBy });
      setCreating(true);
    }
  };
  return (
    <div>
      <Button sx={{ marginTop: '10px' }} size="small" onClick={handleOpen}>
        <AddIcon />
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style }}>
          <Grid item>
            <Alert icon={false} variant="filled" severity="info">
              Give the department a name
            </Alert>
            {formError && (
              <Button variant="contained" color="error" id="error-btn">
                {formError}
              </Button>
            )}
            <Grid item style={{ marginBottom: '10px' }}>
              <TextField
                label="Department name?"
                variant="outlined"
                type="text"
                name="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            {creating ? (
              <Button variant="contained" disabled>
                Creating...
              </Button>
            ) : (
              <Button
                variant="contained"
                color="info"
                onClick={create}
                fullWidth
              >
                Add department
              </Button>
            )}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default connect(mapStateToProps, { createDepartment })(CreateDepartment);
