import {
  UPDATE_TASKLIST,
  ASSIGN_TODO,
  TODO_DEADLINE,
  MY_TODOS,
  ASSIGNED_TODOS,
  MY_TODO,
  TODO_TEXTS,
  CLEAR_TODOS,
  TODO_ADD_COLLAB,
  TODO_REMOVE_COLLAB,
  MY_COMPLETE_TODOS,
  COMPLETE_ASSIGNED_TODOS,
  PREV_TODO,
} from '../types';
import { firestore } from '../../firebase-config';
import {
  addDoc,
  collection,
  getDocs,
  orderBy,
  query,
  where,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore';
import moment from 'moment';
import { setAlert } from '../alert';

//Fetch my todos//
export const fetchTodos = (techId) => async (dispatch) => {
  dispatch({ type: CLEAR_TODOS });
  try {
    const q = query(
      collection(firestore, 'todo'),
      where('createdBy.id', '==', techId),
      where('todoDone', '==', false),
      orderBy('createdAt')
    );
    const jam = await getDocs(q);

    const todos = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    dispatch({ type: MY_TODOS, payload: todos });
  } catch (error) {
    console.error(error.message);
  }
};

//fetch completed Todos//
export const fetchCompleteTodos = (techId) => async (dispatch) => {
  try {
    const q = query(
      collection(firestore, 'todo'),
      where('createdBy.id', '==', techId),
      where('todoDone', '==', true),
      orderBy('createdAt')
    );
    const jam = await getDocs(q);

    const todos = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    dispatch({ type: MY_COMPLETE_TODOS, payload: todos });
  } catch (error) {
    console.error(error.message);
  }
};
//fetch complete assigned Todos//
export const fetchCompleAssignedTodos = (techId) => async (dispatch) => {
  const todoRef = collection(firestore, 'todo');
  try {
    const q = query(
      todoRef,
      where('todoDone', '==', true),
      orderBy('createdAt')
    );
    onSnapshot(q, (snapshot) => {
      let todos = [];
      snapshot.docs.forEach((doc) => {
        const data = doc.data();
        if (
          data.assignedTo &&
          data.assignedTo.some((user) => user.credentials === techId)
        ) {
          if (data.todoDone === false) {
            todos.push({ ...data, id: doc.id });
          }
        }
      });
      dispatch({ type: COMPLETE_ASSIGNED_TODOS, payload: todos });
    });
  } catch (error) {
    console.error(error.message);
  }
};

//Fetch assigned Todos//
export const fetchAssignedTodos = (techId) => async (dispatch) => {
  const todoRef = collection(firestore, 'todo');
  try {
    const q = query(
      todoRef,
      where('todoDone', '==', false),
      orderBy('createdAt')
    );
    onSnapshot(q, (snapshot) => {
      let todos = [];
      snapshot.docs.forEach((doc) => {
        const data = doc.data();
        if (
          data.assignedTo &&
          data.assignedTo.some((user) => user.credentials === techId)
        ) {
          if (data.todoDone === false) {
            todos.push({ ...data, id: doc.id });
          }
        }
      });
      dispatch({ type: ASSIGNED_TODOS, payload: todos });
    });
  } catch (error) {
    console.error(error.message);
  }
};

//Fetch Todo//
export const fetchTodo = (todoId) => async (dispatch) => {
  dispatch({ type: CLEAR_TODOS });
  const todoRef = doc(firestore, 'todo', todoId);

  try {
    const todoDoc = await getDoc(todoRef);
    const todo = todoDoc.data();
    let arr = todo.assignedTo;
    const prevTodo = todo.prevTodo;
    if (prevTodo !== undefined) {
      dispatch(fetchPrevTodo(prevTodo));
    }
    dispatch(updateCollab(arr));
    dispatch(getTodoTexts(todoId));
    dispatch({ type: MY_TODO, payload: todo });
  } catch (error) {
    console.error(error.message);
  }
};

//Create a new todo//
export const createTodo =
  ({ todoName, todoDescription, taskList, assignedTo, deadline, createdBy }) =>
  async (dispatch) => {
    try {
      const todoRef = collection(firestore, 'todo');
      const createdAt = moment().format();
      const todoDone = false;
      try {
        const todo = await addDoc(todoRef, {
          todoName,
          todoDescription,
          taskList,
          assignedTo,
          deadline,
          createdBy,
          createdAt,
          todoDone,
        });
        dispatch(setAlert('ToDo created successfully', 'success'));
        return todo.id;
      } catch (error) {
        console.error(error.message);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

//Update TaskList//
export const updateTaskList = (taskList) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_TASKLIST, payload: taskList });
  } catch (error) {
    console.error(error.message);
  }
};

//Collab on lead//
export const updateCollab = (arr) => (dispatch) => {
  try {
    dispatch({ type: TODO_ADD_COLLAB, payload: arr });
  } catch (error) {
    console.error(error.message);
  }
};

//Confirm collab//
export const confirmCollab = (tech) => async (dispatch) => {
  try {
    dispatch({ type: ASSIGN_TODO, payload: tech });
  } catch (error) {
    console.error(error.message);
  }
};

//Remove from collab//
export const removeFromCollab = (arr) => (dispatch) => {
  try {
    dispatch({ type: TODO_REMOVE_COLLAB, payload: arr });
  } catch (error) {
    console.error(error.message);
  }
};

//Set todo deadline//
export const setTodoDeadline =
  ({ todoDeadline }) =>
  async (dispatch) => {
    try {
      dispatch({ type: TODO_DEADLINE, payload: todoDeadline });
    } catch (error) {
      console.error(error.message);
    }
  };

//Remove todo deadline//
export const removeTodoDeadline = () => (dispatch) => {
  try {
    dispatch({ type: TODO_DEADLINE, payload: null });
  } catch (error) {
    console.error(error.message);
  }
};

//Get Todo Texts//
export const getTodoTexts = (todoId) => async (dispatch) => {
  const textsRef = collection(firestore, 'todoText');

  const q = query(
    textsRef,
    where('todoId', '==', todoId),
    orderBy('createdAt')
  );

  const unsub = onSnapshot(q, (snapshot) => {
    let texts = [];
    snapshot.docs.forEach((doc) => {
      texts.push({ ...doc.data(), id: doc.id });
    });
    dispatch({ type: TODO_TEXTS, payload: texts });
  });
  return unsub;
};

//Send todo text//
export const sendText =
  ({ authorId, authorName, createdAt, todoId, text }) =>
  async (dispatch) => {
    const textRef = collection(firestore, 'todoText');
    try {
      await addDoc(textRef, { authorId, authorName, createdAt, text, todoId });
    } catch (error) {
      console.error(error.message);
    }
  };

//update task list//
export const theTaskList =
  ({ myArr, todoId }) =>
  async (dispatch) => {
    const todoRef = doc(firestore, 'todo', todoId);
    try {
      const newFields = { taskList: myArr };
      await updateDoc(todoRef, newFields);
      dispatch(fetchTodo(todoId));
      dispatch(setAlert('Todo Updated...', 'success'));
    } catch (error) {
      console.error(error.message);
    }
  };

//Set task as complete//
export const setTaskAsComplete =
  ({ todoId, task, finalTask }) =>
  async (dispatch) => {
    const todoRef = doc(firestore, 'todo', todoId);
    try {
      const todoDoc = await getDoc(todoRef);
      const todo = todoDoc.data();
      const taskList = todo.taskList;

      const newTaskList = taskList.map((tsk) => {
        if (tsk.id === task.id) {
          return { ...tsk, done: true };
        }
        return tsk;
      });

      await updateDoc(todoRef, { taskList: newTaskList });

      if (finalTask) {
        let res = await dispatch(setTodoAsComplete({ todo, todoId }));
        return res;
      } else {
        dispatch(fetchTodo(todoId));
        dispatch(setAlert('Todo Updated...', 'success'));
        return undefined;
      }
    } catch (error) {
      console.error(error.message);
    }
  };

//Set task not complete//
export const setTaskAsInComplete =
  ({ todoId, task, todoComplete }) =>
  async (dispatch) => {
    const todoRef = doc(firestore, 'todo', todoId);
    try {
      const todoDoc = await getDoc(todoRef);
      const todo = todoDoc.data();
      const taskList = todo.taskList;

      const newTaskList = taskList.map((tsk) => {
        if (tsk.id === task.id) {
          return { ...tsk, done: false };
        }
        return tsk;
      });

      await updateDoc(todoRef, { taskList: newTaskList });
      if (todoComplete) {
        dispatch(setTodoAsInComplete(todoId));
      } else {
        dispatch(fetchTodo(todoId));
        dispatch(setAlert('Todo Updated...', 'success'));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

//Update Collaboration//
export const theCollab =
  ({ myArr, todoId }) =>
  async (dispatch) => {
    const todoRef = doc(firestore, 'todo', todoId);
    try {
      const newFields = { assignedTo: myArr };
      await updateDoc(todoRef, newFields);
      dispatch(fetchTodo(todoId));
      dispatch(setAlert('Todo Updated...', 'success'));
    } catch (error) {
      console.error(error.message);
    }
  };

//Todo is complete//
export const setTodoAsComplete =
  ({ todo, todoId }) =>
  async (dispatch) => {
    const {
      todoName,
      todoDescription,
      taskList,
      assignedTo,
      deadline,
      createdBy,
    } = todo;
    const currentTodo = doc(firestore, 'todo', todoId);
    const todoRef = collection(firestore, 'todo');
    try {
      const todoDone = false;
      const createdAt = moment().format();
      const prevTodo = todoId;
      const newTaskList = taskList.map((tsk) => {
        return { ...tsk, done: false };
      });
      const completedAt = moment().format();
      const newFields = { todoDone: true, completedAt };

      if (deadline.type === 'Daily') {
        const newTodo = await addDoc(todoRef, {
          todoName,
          todoDescription,
          assignedTo,
          createdBy,
          deadline,
          prevTodo,
          todoDone,
          createdAt,
          taskList: newTaskList,
        });
        await updateDoc(currentTodo, newFields);
        dispatch(
          setAlert(
            'Todo Updated, your next todo has been scheduled...',
            'success'
          )
        );
        return newTodo.id;
      } else if (deadline.type === 'Weekly') {
        const newTodo = await addDoc(todoRef, {
          todoName,
          todoDescription,
          assignedTo,
          createdBy,
          deadline,
          prevTodo,
          todoDone,
          createdAt,
          taskList: newTaskList,
        });
        await updateDoc(currentTodo, newFields);
        dispatch(
          setAlert(
            'Todo Updated, your next todo has been scheduled...',
            'success'
          )
        );
        return newTodo.id;
      } else if (deadline.type === 'Monthly') {
        const newTodo = await addDoc(todoRef, {
          todoName,
          todoDescription,
          assignedTo,
          createdBy,
          prevTodo,
          deadline,
          todoDone,
          createdAt,
          taskList: newTaskList,
        });
        await updateDoc(currentTodo, newFields);
        dispatch(
          setAlert(
            'Todo Updated, your next todo has been scheduled...',
            'success'
          )
        );
        return newTodo.id;
      }
      await updateDoc(currentTodo, newFields);
      dispatch(fetchTodo(todoId));
      dispatch(setAlert('Todo Updated...', 'success'));
      return undefined;
    } catch (error) {
      console.error(error.message);
    }
  };

//Todo not complete//
export const setTodoAsInComplete = (todoId) => async (dispatch) => {
  const todoRef = doc(firestore, 'todo', todoId);
  try {
    let newFields = { todoDone: false };
    await updateDoc(todoRef, newFields);
    dispatch(fetchTodo(todoId));
    dispatch(setAlert('Todo Updated...', 'success'));
  } catch (error) {
    console.error(error.message);
  }
};

//Fetch Previous todo//
export const fetchPrevTodo = (todoId) => async (dispatch) => {
  const todoRef = doc(firestore, 'todo', todoId);
  try {
    const todoDoc = await getDoc(todoRef);
    const todo = todoDoc.data();
    dispatch({ type: PREV_TODO, payload: todo });
  } catch (error) {
    console.error(error.message);
  }
};
