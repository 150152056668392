import {
  Container,
  Grid,
  Box,
  Alert,
  List,
  ListItem,
  Typography,
  CircularProgress,
  Divider,
  Button,
  useTheme,
  Paper,
  FormControl,
  TextField,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getLead,
  getLeadNotes,
  getFollowUps,
  getDoneFollowUps,
  changeLeadStage,
  updateLeadFile,
  sendLeadText,
  fetchLeadText,
  updateCollab,
  updateLeadCollab,
  removeFromCollab,
} from '../../../actions/techs/sales';
import { useParams, Link } from 'react-router-dom';
import { ArrowBack, Launch } from '@mui/icons-material';
import AddLeadNote from './AddLeadNote';
import LeadStage from './LeadStage';
import ListOfTechs from '../ListOfTechs';
import ScheduleFollow from './ScheduleFollow';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import FollowUpInfo from './FollowUpInfo';
import moment from 'moment';
import { uploadLeadFile } from '../../../actions/storage';
import { tokens } from '../../../theme';
import UploadLeadFile from './UploadLeadFile';
import { setAlert } from '../../../actions/alert';
import LeadTexts from './LeadTexts';
const LeadInfo = ({
  leads: {
    lead,
    notes,
    follow,
    done,
    leadFileUrl,
    texts,
    collabCacheAdd,
    collabCacheRemove,
  },
  getLead,
  getLeadNotes,
  getFollowUps,
  getDoneFollowUps,
  tech,
  changeLeadStage,
  alert,
  uploadLeadFile,
  updateLeadFile,
  setAlert,
  sendLeadText,
  fetchLeadText,
  updateCollab,
  removeFromCollab,
  updateLeadCollab,
}) => {
  const { leadId } = useParams();
  const [text, setText] = useState('');
  const [leadStage, setLeadStage] = useState(null);
  const [collab, setCollab] = useState([]);
  const [invoice, setInvoice] = useState(undefined);
  const [quote, setQuote] = useState(undefined);
  const [pitch, setPitch] = useState(undefined);
  const [createdBy, setCreatedBy] = useState(null);
  const [updatingFile, setUpdatingFile] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  useEffect(() => {
    if (leadFileUrl) {
      let type = leadFileUrl.type;
      let url = leadFileUrl.url;
      updateLeadFile({ type, url, leadId });
    }
  }, [leadFileUrl, updateLeadFile, leadId]);

  useEffect(() => {
    if (alert.length > 0 && updatingFile) {
      setUpdatingFile(false);
    }
  }, [alert, updatingFile]);

  useEffect(() => {
    getLead(leadId);
    getLeadNotes(leadId);
    getFollowUps(leadId);
    getDoneFollowUps(leadId);
    fetchLeadText(leadId);
  }, [
    getLead,
    getLeadNotes,
    getFollowUps,
    getDoneFollowUps,
    fetchLeadText,
    leadId,
  ]);

  useEffect(() => {
    if (lead) {
      setCreatedBy(lead.createdBy);
      setLeadStage(lead.stage);
      setInvoice(lead.invoice);
      setQuote(lead.quote);
      setPitch(lead.pitch);
      removeFromCollab(lead.collab);
      setCollab(lead.collab);
    }
  }, [lead, removeFromCollab]);

  if (lead === null || texts === null || createdBy === null) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  const addTech = (tech) => {
    //Check to see if tech is part of orignal team//
    const loot = lead.collab.filter((collab) => {
      let id = collab.id;
      let techId = tech.id;
      return id === techId;
    });
    if (loot.length === 0) {
      //If tech is not part of the original team add them to add cache//
      let arr = collabCacheAdd;
      arr.push(tech);
      updateCollab(arr);
    }
  };

  const removeTech = (techId) => {
    //Check to see if tech is part of orignal team//
    const loot = lead.collab.filter((collab) => {
      let id = collab.id;
      return id !== techId;
    });
    removeFromCollab(loot);
    //just remove from add cache//
    const arr = collabCacheAdd.filter((tech) => {
      let id = tech.id;
      return id !== techId;
    });
    updateCollab(arr);
  };

  const upadateMyCollab = () => {
    let arr = collabCacheAdd.concat(collabCacheRemove);

    updateLeadCollab({ arr, leadId });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (text === '') return setAlert('Cannot send a blank message!', 'warning');
    let authorId = tech.id;
    let authorName = tech.name;
    let createdAt = moment().format();
    sendLeadText({ authorId, authorName, createdAt, text, leadId });
    setText('');
  };

  const uploadThisFile = ({ selected, type }) => {
    let file = selected;
    uploadLeadFile({ type, file });
    setUpdatingFile(true);
  };

  return (
    <Container>
      <Grid container>
        <Grid xs={1} item>
          <Link
            as={Link}
            to={`/tech/sales-pipeline`}
            style={{ color: colors.redAccent[500] }}
          >
            <ArrowBack />
          </Link>
        </Grid>
        <Grid xs={11} item>
          <Typography
            variant="h5"
            sx={{ color: colors.grey[500], fontWeight: 'bold' }}
          >
            {lead.desc} for {lead.name}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mb: '15px' }} />

      <Grid container>
        <Grid item xs={12} md={4}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              color: colors.greenAccent[400],
              fontWeight: 'bold',
            }}
          >
            Lead details:
          </Typography>
          <Divider sx={{ mb: '10px' }} />
          <Grid container>
            <Grid item md={5}>
              <Typography
                gutterBottom
                sx={{
                  color: colors.grey[500],
                  fontWeight: 'bold',
                  fontSize: 15,
                  mt: '10px',
                }}
              >
                Lead stage:
              </Typography>
            </Grid>
            <Grid item md={5}>
              <LeadStage
                leadStage={leadStage}
                changeLeadStage={changeLeadStage}
                leadId={leadId}
                alert={alert}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={8} item>
              <Typography
                gutterBottom
                sx={{
                  color: colors.grey[500],
                  fontWeight: 'bold',
                  fontSize: 15,
                  mt: '10px',
                }}
              >
                Lead Qoute
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <UploadLeadFile
                uploadThisFile={uploadThisFile}
                type="lead_quote"
              />
            </Grid>
          </Grid>
          <Divider variant="middle" sx={{ mb: '10px' }} />
          {quote === undefined ? (
            <Typography sx={{ mt: '5px', color: colors.redAccent[700] }}>
              No quote added
            </Typography>
          ) : (
            <div style={{ marginTop: '10px' }}>
              <a href={`${quote}`} target="_blank" rel="noreferrer">
                View Quote <Launch sx={{ ml: '10px' }} />
              </a>
            </div>
          )}
          <Grid container>
            <Grid xs={8} item>
              <Typography
                gutterBottom
                sx={{
                  color: colors.grey[500],
                  fontWeight: 'bold',
                  fontSize: 15,
                  mt: '10px',
                }}
              >
                Lead Pitch
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <UploadLeadFile
                uploadThisFile={uploadThisFile}
                type="lead_pitch"
              />
            </Grid>
          </Grid>
          <Divider variant="middle" sx={{ mb: '10px' }} />
          {pitch === undefined ? (
            <Typography sx={{ mt: '5px', color: colors.redAccent[700] }}>
              No pitch added
            </Typography>
          ) : (
            <a href={`${pitch}`} target="_blank" rel="noreferrer">
              View Pitch <Launch />
            </a>
          )}
          <Grid container>
            <Grid xs={8} item>
              <Typography
                gutterBottom
                sx={{
                  color: colors.grey[500],
                  fontWeight: 'bold',
                  fontSize: 15,
                  mt: '10px',
                }}
              >
                Lead Invoice
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <UploadLeadFile
                uploadThisFile={uploadThisFile}
                type="lead_invoice"
              />
            </Grid>
          </Grid>
          <Divider variant="middle" sx={{ mb: '10px' }} />
          {invoice === undefined ? (
            <Typography sx={{ mt: '5px', color: colors.redAccent[700] }}>
              No invoice added
            </Typography>
          ) : (
            <a href={`${invoice}`} target="_blank" rel="noreferrer">
              View Invoice <Launch />
            </a>
          )}

          {updatingFile && (
            <>
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  borderColor: colors.redAccent[600],
                  color: colors.redAccent[600],
                  mt: '15px',
                }}
              >
                Uploading your file...
              </Button>
              <LinearProgress
                sx={{ backgroundColor: colors.redAccent[600], mt: '5px' }}
                color="secondary"
              />
            </>
          )}

          <Typography
            sx={{
              color: colors.blueAccent[100],
              fontWeight: 'bold',
              fontSize: 15,
              mt: '10px',
            }}
          >
            Completed follow ups
          </Typography>
          <Divider variant="middle" sx={{ mt: '10px', mb: '10px' }} />

          {done === null ? (
            <CircularProgress />
          ) : done.length === 0 ? (
            <Alert severity="warning" sx={{ mt: '15px' }}>
              You have not completed any follow ups
            </Alert>
          ) : (
            done.map((fl) => (
              <FollowUpInfo
                key={fl.id}
                fl={fl}
                leadId={leadId}
                toLead={false}
              />
            ))
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container>
            <Grid item xs={8}>
              <Typography
                variant="h6"
                sx={{ color: colors.greenAccent[400], fontWeight: 'bold' }}
              >
                Lead notes
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <AddLeadNote leadId={leadId} tech={tech} />
            </Grid>
          </Grid>

          {notes === null ? (
            <CircularProgress />
          ) : notes.length === 0 ? (
            <Alert>Lead has no notes</Alert>
          ) : (
            notes.map((note) => (
              <div key={note.id}>
                <Typography gutterBottom sx={{ marginTop: '20px' }}>
                  {note.note}
                </Typography>
                <Divider variant="middle" />
              </div>
            ))
          )}

          <Grid container>
            <Grid item xs={8}>
              <Typography
                variant="h6"
                sx={{
                  color: colors.greenAccent[400],
                  fontWeight: 'bold',
                  mt: '10px',
                }}
                gutterBottom
              >
                Lead follow ups
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <ScheduleFollow leadId={leadId} tech={tech} />
            </Grid>
          </Grid>

          <Divider sx={{ mb: '10px' }} />
          {follow == null ? (
            <CircularProgress />
          ) : follow.length === 0 ? (
            <Alert severity="warning">Lead has no follow ups</Alert>
          ) : (
            follow.map((fl) => (
              <FollowUpInfo
                key={fl.id}
                fl={fl}
                leadId={leadId}
                toLead={false}
              />
            ))
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container>
            <Grid item xs={9}>
              <Typography
                variant="h6"
                sx={{ color: colors.greenAccent[400], fontWeight: 'bold' }}
                gutterBottom
              >
                Collaboration
              </Typography>
            </Grid>
            <Grid item xs={1}>
              {lead.collab.length === 0 ? (
                <Typography sx={{ mt: '7px' }}>0</Typography>
              ) : (
                <Typography sx={{ mt: '7px' }}>{lead.collab.length}</Typography>
              )}
            </Grid>
            <Grid item xs={1}>
              {createdBy.id === tech.id && (
                <ListOfTechs
                  addTech={addTech}
                  removeTech={removeTech}
                  upadateMyCollab={upadateMyCollab}
                  collab={collab}
                />
              )}
            </Grid>
          </Grid>

          <Paper>
            <Box p={3} xs={12}>
              <Grid container spacing={4}>
                <Grid id="chat-grid" item>
                  <List id="chat-messages">
                    {texts.length === 0
                      ? 'no texts'
                      : texts.map((text) => (
                          <LeadTexts
                            key={text.id}
                            text={text}
                            techId={tech.id}
                          />
                        ))}
                    <ListItem></ListItem>
                  </List>
                </Grid>
              </Grid>
            </Box>
            <form onSubmit={onSubmit}>
              <Grid container>
                <Grid xs={10} item>
                  <FormControl fullWidth>
                    <TextField
                      label="type your message"
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <IconButton xs={1} sx={{ mt: 0.4 }} type="submit">
                    <SendOutlinedIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  leads: state.leads,
  tech: state.tech.tech_profile,
  alert: state.alert,
});

export default connect(mapStateToProps, {
  getLead,
  getLeadNotes,
  getFollowUps,
  getDoneFollowUps,
  changeLeadStage,
  uploadLeadFile,
  updateLeadFile,
  setAlert,
  sendLeadText,
  fetchLeadText,
  updateCollab,
  updateLeadCollab,
  removeFromCollab,
})(LeadInfo);
