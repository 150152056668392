import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Grid,
  Alert,
  TextField,
  Modal,
  Button,
  Box,
} from '@mui/material';
import { featureRequest } from '../../../actions/users/project';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import Add from '@mui/icons-material/Add';

import moment from 'moment';

const RequestFeature = ({ featureRequest, projectId }) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const create = (e) => {
    e.preventDefault();

    let createdAt = moment().format();
    let accepted = false;

    featureRequest({
      text,
      createdAt,
      accepted,
      projectId,
    });

    setOpen(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <Container>
      <Button
        color="warning"
        variant="outlined"
        size="small"
        onClick={handleOpen}
      >
        <Add color="warning" />
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style }}>
          <Grid item>
            <Alert icon={false} variant="filled" severity="warning">
              Would you like to request a feature?
            </Alert>
            <Grid item style={{ marginBottom: '10px', marginTop: '10px' }}>
              <TextField
                label="Whats the feature?"
                variant="outlined"
                type="text"
                name="text"
                value={text}
                onChange={(e) => setText(e.target.value)}
                fullWidth
                required
              />
            </Grid>

            <Button
              onClick={create}
              variant="contained"
              color="warning"
              fullWidth
            >
              Request Feature <SkipNextIcon />
            </Button>
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
};

export default connect(null, { featureRequest })(RequestFeature);
