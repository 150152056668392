import {
  MY_TICKET,
  MY_TICKETS,
  TICKET_TEXTS,
  CLEAR_TICKET,
  COMPLETED_TICKETS,
  CLEAR_UPLOAD,
  TICKETS_NEED_REVIEW,
} from '../types';
import axios from 'axios';

import { firestore } from '../../firebase-config';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  query,
  where,
  onSnapshot,
  orderBy,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import { setAlert } from '../alert';
import moment from 'moment';

const backend = process.env.REACT_APP_BACKEND_URL;

//Fetch Tickets//
export const fetchTickets = (userId) => async (dispatch) => {
  const ticketsRef = collection(firestore, 'tickets');

  const q = query(
    ticketsRef,
    where('userId', '==', userId),
    where('isComplete', '==', false),
    orderBy('createdAt')
  );

  onSnapshot(q, (snapshot) => {
    let tickets = [];
    snapshot.docs.forEach((doc) => {
      tickets.push({ ...doc.data(), id: doc.id });
    });

    dispatch({ type: MY_TICKETS, payload: tickets });
  });
};

//Fetch completed tickets//
export const completedTickets = (userId) => async (dispatch) => {
  try {
    const ticketsRef = collection(firestore, 'tickets');

    const q = query(
      ticketsRef,
      where('userId', '==', userId),
      where('isComplete', '==', true),
      orderBy('createdAt')
    );
    onSnapshot(q, (snapshot) => {
      let tickets = [];
      snapshot.docs.forEach((doc) => {
        tickets.push({ ...doc.data(), id: doc.id });
      });

      dispatch({ type: COMPLETED_TICKETS, payload: tickets });
    });
  } catch (error) {
    console.error('Error fetching competed Tickets: ', error.message);
  }
};

//Fetch texts//
export const fetchTexts = (ticketId) => (dispatch) => {
  const textsRef = collection(firestore, 'texts');

  const q = query(
    textsRef,
    where('ticketId', '==', ticketId),
    orderBy('createdAt')
  );

  const unsub = onSnapshot(q, (snapshot) => {
    let texts = [];
    snapshot.docs.forEach((doc) => {
      texts.push({ ...doc.data(), id: doc.id });
    });
    dispatch({ type: TICKET_TEXTS, payload: texts });
  });
  return unsub;
};

//Create a ticket//
export const submitTicket =
  ({
    header,
    howbad,
    desc,
    userId,
    clientId,
    createdAt,
    clientName,
    createdBy,
    email,
    isBusiness,
    bizEmail,
    username,
  }) =>
  async (dispatch) => {
    const ticket = collection(firestore, 'tickets');
    const data = await getDocs(ticket);

    let tickets = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    const num = tickets.length + 1;
    const ticketNum = `CIT-00${num}`;

    let isComplete = false;
    let notified = false;
    let deviceToken = '';
    let review = null;

    try {
      await addDoc(ticket, {
        header,
        howbad,
        desc,
        userId,
        clientId,
        createdAt,
        isComplete,
        clientName,
        createdBy,
        email,
        ticketNum,
        notified,
        deviceToken,
        isBusiness,
        username,
        review,
      });
      const user = createdBy;
      dispatch(ticketCreatedNotification({ user, isBusiness, bizEmail }));

      dispatch(setAlert('Ticket Created Successfully', 'success'));
    } catch (error) {
      console.error(error.message);
    }
  };

//Fetch a ticket//
export const fetchTicket = (ticketId) => async (dispatch) => {
  dispatch({ type: CLEAR_TICKET });
  const ticketRef = doc(firestore, 'tickets', ticketId);

  const ticket = await getDoc(ticketRef);
  const myTicket = ticket.data();

  dispatch({ type: MY_TICKET, payload: myTicket });
  try {
  } catch (error) {
    console.error(error.message);
  }
};

//Send Text//
export const ticketMsg =
  ({ text, authorId, authorName, ticketId, createdAt }) =>
  async (dispatch) => {
    const message = collection(firestore, 'texts');
    try {
      await addDoc(message, {
        text,
        authorId,
        authorName,
        ticketId,
        createdAt,
      });
      dispatch({ type: CLEAR_UPLOAD });
    } catch (error) {
      console.error(error.message);
    }
  };

//Close Ticket
export const closeTicket =
  ({ ticketId, user, email }) =>
  async (dispatch) => {
    const ticketRef = doc(firestore, 'tickets', ticketId);
    const newFields = { isComplete: moment().format() };
    await updateDoc(ticketRef, newFields);
    dispatch(ticketClosedNotification({ ticketId, user, email }));
    dispatch(setAlert('You have successfully closed the ticket', 'success'));
    dispatch(fetchTicket(ticketId));
    dispatch(fetchTexts(ticketId));
  };

//Ticket Closed Notification//
export const ticketClosedNotification =
  ({ email, user, ticketId }) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const body = JSON.stringify({ email, user, ticketId });
      await axios.post(`${backend}/api/appemails/ticket-closed`, body, config);
    } catch (error) {
      console.error(error.message);
    }
  };

//Get Tickets that need review//
export const ticketsThatNeedReview = (userId) => async (dispatch) => {
  const ticketRef = collection(firestore, 'tickets');
  try {
    const q = query(
      ticketRef,
      where('userId', '==', userId),
      where('isComplete', '==', true),
      where('review', '==', null),
      orderBy('createdAt')
    );

    onSnapshot(q, (snapshot) => {
      let tickets = [];
      snapshot.docs.forEach((doc) => {
        tickets.push({ ...doc.data(), id: doc.id });
      });

      dispatch({ type: TICKETS_NEED_REVIEW, payload: tickets });
    });
  } catch (error) {
    console.error(error.message);
  }
};

//Review the ticket//
export const reviewTheTicket =
  ({ ticketId, text }) =>
  async (dispatch) => {
    const review = collection(firestore, 'reviews');
    const ticketRef = doc(firestore, 'tickets', ticketId);
    let rating = 3;
    try {
      const res = await addDoc(review, {
        text,
        rating,
        ticketId,
      });

      const newFields = { review: res.id };
      await updateDoc(ticketRef, newFields);
      dispatch(setAlert('Thank you for your review', 'success'));
      dispatch(fetchTicket(ticketId));
      dispatch(fetchTexts(ticketId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Ticket notificatin email//
export const ticketCreatedNotification =
  ({ user, isBusiness, bizEmail }) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const body = JSON.stringify({ user, isBusiness, bizEmail });
      await axios.post(`${backend}/api/appemails/ticket-created`, body, config);
    } catch (error) {
      console.log('Error sending ticket creation notification: ', error);
    }
  };
