import { Button, Modal, Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { connect } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';

import { decomMissionDevice } from '../../../actions/clients/clients';

const DecomisionDevice = ({ device, alert, decomMissionDevice }) => {
  const [open, setOpen] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    if (alert.length > 0 && updating) {
      setUpdating(false);
      handleClose();
    }
  }, [alert, updating]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const decom = () => {
    setUpdating(true);
    let deviceId = device.id;
    decomMissionDevice({ deviceId });
  };

  return (
    <div>
      <Button color="error" onClick={handleOpen}>
        Decomission
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Would you like to decommision {device.deviceModel}
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button onClick={handleClose} color="error">
              Cancel
            </Button>
            {updating ? (
              <LoadingButton loading variant="contained">
                Logging In
              </LoadingButton>
            ) : (
              <Button onClick={decom} color="secondary">
                Confirm
              </Button>
            )}
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default connect(mapStateToProps, { decomMissionDevice })(
  DecomisionDevice
);
