import React from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { CircularProgress, Box } from '@mui/material/';

const PrivateRoute = ({ component: Component, tech: { isTech, loading } }) => {
  if (loading)
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
  if (isTech) return <Component />;

  return <Navigate to="/" />;
};

const mapStateToProps = (state) => ({
  tech: state.tech,
});

export default connect(mapStateToProps)(PrivateRoute);
