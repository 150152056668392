import {
  BUSINESS_PROFILE,
  BUSINESS_LOGOUT,
  BUSINESS_USERS,
  BUSINESS_USER,
  BUSINESS_AGENTS,
  ACTIVE_BIZ_TICKETS,
  AGENT_PROFILE,
} from '../../actions/types';

const initState = {
  isBusiness: false,
  loading: true,
  business_profile: null,
  users: null,
  user: null,
  active_tickets: null,
  business_agents: null,
  agent_profile: null,
};

function businessReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case BUSINESS_PROFILE:
      return {
        ...state,
        business_profile: payload,
        isBusiness: true,
        loading: false,
      };
    case AGENT_PROFILE:
      return {
        ...state,
        agent_profile: payload,
        isBusiness: true,
        loading: false,
      };
    case BUSINESS_LOGOUT:
      return {
        ...state,
        business_profile: null,
        isBusiness: false,
      };
    case BUSINESS_USERS:
      return {
        ...state,
        users: payload,
      };
    case BUSINESS_USER:
      return {
        ...state,
        user: payload,
      };
    case ACTIVE_BIZ_TICKETS:
      return {
        ...state,
        active_tickets: payload,
      };
    case BUSINESS_AGENTS:
      return {
        ...state,
        business_agents: payload,
      };
    default:
      return state;
  }
}

export default businessReducer;
