import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import {
  getProject,
  proMile,
  proNext,
  getFeatureReq,
} from '../../../actions/users/project';
import {
  CircularProgress,
  Grid,
  Container,
  List,
  ListItem,
  Typography,
  Divider,
  ListItemButton,
  Alert,
  ListItemText,
  Box,
  Button,
} from '@mui/material';
import { Apps, DoneAll } from '@mui/icons-material';
import RequestFeature from './RequestFeature';
import moment from 'moment';

const MyProject = ({
  getProject,
  project_: { project, next, milestones, feature },
  proMile,
  proNext,
  getFeatureReq,
}) => {
  let { projectId } = useParams();
  useEffect(() => {
    getProject(projectId);
    proMile(projectId);
    proNext(projectId);
    getFeatureReq(projectId);
  }, [projectId, getProject, proMile, proNext, getFeatureReq]);

  if (project === null) return <CircularProgress />;

  const creation = moment(project.createdAt).format('ll');

  return (
    <Container>
      <Box sx={{ flexGrow: 1 }}>
        <Grid spacing={2} container>
          <Grid md={4} xs={12} item>
            <Grid container>
              <Grid xs={1} item>
                <Link
                  as={Link}
                  to="/user/user-landing"
                  style={{ color: 'black' }}
                >
                  <Apps style={{ marginTop: '15px' }} />
                </Link>
              </Grid>
              <Grid xs={11} item>
                <Alert severity="info">Project Details</Alert>
              </Grid>
            </Grid>

            <List>
              <ListItem>
                <Typography>
                  <b>Client Name:</b> {project.clientName}
                </Typography>
              </ListItem>
              <Divider />
              <ListItem>
                <Typography>
                  <b>Project Name:</b> {project.name}
                </Typography>
              </ListItem>
              <Divider />
              <ListItem>
                <Typography>
                  <b>Project Description:</b> {project.desc}
                </Typography>
              </ListItem>
              <Divider />
              <ListItem>
                <Typography>
                  <b>Creation Date:</b> {creation}
                </Typography>
              </ListItem>
              <Divider />
              <Typography
                variant="h6"
                sx={{ color: 'orange' }}
                align="center"
                mt={2}
                gutterBottom={true}
              >
                Feature Requests...
              </Typography>
              {!feature ? (
                <CircularProgress />
              ) : feature.length === 0 ? (
                <>
                  <Typography ml={2} mt={2} mb={2}>
                    No Feature Request
                  </Typography>
                  <Divider />
                </>
              ) : (
                feature.map((f) => (
                  <List key={f.id}>
                    <ListItemButton disabled>
                      <ListItem>
                        <ListItemText primary={`${f.text}`} />
                      </ListItem>
                    </ListItemButton>
                    <Divider />
                  </List>
                ))
              )}
            </List>
          </Grid>
          <Grid md={4} mt={0.7} xs={12} item>
            <Alert severity="success">Milestones</Alert>
            {!milestones ? (
              <CircularProgress />
            ) : milestones.length === 0 ? (
              <Typography mt={1}>No milestones reached...</Typography>
            ) : (
              milestones.map((mile) => (
                <Grid mt={2.4} key={mile.id}>
                  <List>
                    <ListItemButton disabled>
                      <ListItemText primary={`${mile.text}`} />
                    </ListItemButton>
                  </List>
                  <Divider />
                </Grid>
              ))
            )}
          </Grid>
          <Grid md={4} xs={12} item>
            <Grid container>
              <Grid xs={10} item>
                <Alert severity="warning">What's next</Alert>
              </Grid>
              <Grid xs={1} mt={1} item>
                {project.complete ? (
                  <Button disableFocusRipple={true} disableRipple={true}>
                    <DoneAll color="warning" />
                  </Button>
                ) : (
                  <RequestFeature projectId={projectId} />
                )}
              </Grid>
            </Grid>

            {!next ? (
              <CircularProgress />
            ) : next.length === 0 ? (
              <Typography mt={1}>Nothing added...</Typography>
            ) : (
              next.map((nx) => (
                <Grid key={nx.id}>
                  <List>
                    <ListItemButton disabled>
                      <ListItem>
                        <ListItemText primary={`${nx.text}`} />
                      </ListItem>
                    </ListItemButton>
                  </List>
                  <Divider />
                </Grid>
              ))
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  project_: state.project_,
});

export default connect(mapStateToProps, {
  getProject,
  proMile,
  proNext,
  getFeatureReq,
})(MyProject);
