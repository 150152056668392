import { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchMyLeads } from '../../../actions/techs/sales';
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import LeadItem from './LeadItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { tokens } from '../../../theme';

const ClosedLeads = ({ leads, fetchMyLeads, leadId, tech }) => {
  let navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  useEffect(() => {
    let id = tech.id;
    fetchMyLeads(id);
  }, [fetchMyLeads, tech]);

  if (leads === null) {
    return <CircularProgress />;
  }

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Grid container>
        <Button onClick={goBack}>
          <ArrowBackIcon />
        </Button>

        <Typography variant="h4" sx={{ ml: '20px' }}>
          Closed leads
        </Typography>
      </Grid>
      <Divider variant="middle" sx={{ mb: '10px', mt: '10px' }} />
      <Grid container>
        <Grid item md={8} xs={12}>
          {leads.length === 0 ? (
            <Alert severity="warning">You have no closed leads</Alert>
          ) : (
            <LeadItem leads={leads} />
          )}
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography
            sx={{
              color: colors.grey[500],
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Review
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  tech: state.tech.tech_profile,
  leads: state.leads.closed_leads,
});

export default connect(mapStateToProps, { fetchMyLeads })(ClosedLeads);
