import {
  CircularProgress,
  Container,
  List,
  Typography,
  Grid,
} from '@mui/material';
import { fetchTickets } from '../../actions/techs/ticket';
import { getProjects } from '../../actions/techs/project';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Tickets from './Tickets/Tickets';
import Projects from './Projects/Projects';

const TechLanding = ({
  getProjects,
  fetchTickets,

  tech_tickets: { pending_tickets },
  projects: { projects },
}) => {
  useEffect(() => {
    fetchTickets();
    getProjects();
  }, [fetchTickets, getProjects]);

  if (pending_tickets === null) return <CircularProgress />;

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Welcome to the Tech Landing!{' '}
      </Typography>
      <Grid container spacing={5}>
        <Grid xs={12} md={6} item>
          <Typography variant="h5" gutterBottom>
            Pending Tickets
          </Typography>

          {pending_tickets.length === 0 ? (
            <Typography>No Tickets </Typography>
          ) : (
            <List>
              {pending_tickets.map((ticket) => (
                <Tickets key={ticket.id} ticket={ticket} />
              ))}
            </List>
          )}
        </Grid>
        <Grid xs={12} md={6} item>
          <Grid container>
            <Grid xs={8} item>
              <Typography variant="h5" gutterBottom>
                Ongoing Projects;
              </Typography>
            </Grid>
          </Grid>

          {projects === null ? (
            <CircularProgress />
          ) : projects.length === 0 ? (
            <Typography>No Projects </Typography>
          ) : (
            <Projects projects={projects} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  tech_tickets: state.tech_tickets,
  projects: state.project,
});

export default connect(mapStateToProps, {
  fetchTickets,
  getProjects,
})(TechLanding);
