import React from 'react';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

const Users = ({ user }) => {
  return (
    <>
      <ListItem key={user.id} disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <PersonOutlineIcon />
          </ListItemIcon>
          <ListItemText primary={`${user.name} ${user.surname}`} />
        </ListItemButton>
      </ListItem>
    </>
  );
};

export default Users;
