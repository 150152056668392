import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  useTheme,
} from '@mui/material';
import { useState, useEffect } from 'react';
import TheUser from './TheUser';
import { tokens } from '../../../theme';

const ListOfUsers = ({
  client_users,
  info,
  addThisUser,
  removeThisUser,
  updateUsers,
  removeArr,
  dontRemove,
  alert,
  updating,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    if (alert.length > 0) {
      handleClose();
    }
  }, [alert]);
  const currentSquad = info.users;
  return (
    <Container>
      <Button
        variant="outlined"
        onClick={handleOpen}
        size="small"
        sx={{
          color: colors.greenAccent[500],
          borderColor: colors.greenAccent[500],
        }}
      >
        Update Users
      </Button>
      <Dialog open={open} onClose={handleClose} scroll="paper">
        <DialogTitle id="scroll-dialog-title">
          Who would you like to add to: {info.name}
        </DialogTitle>
        <DialogContent>
          {client_users && client_users.length === 0
            ? 'No users'
            : client_users.map((user) => (
                <TheUser
                  user={user}
                  key={user.id}
                  addThisUser={addThisUser}
                  currentSquad={currentSquad}
                  removeThisUser={removeThisUser}
                  removeArr={removeArr}
                  dontRemove={dontRemove}
                />
              ))}
        </DialogContent>
        <Divider />
        <DialogActions>
          {updating ? (
            <Button variant="outlined" disabled fullWidth>
              Updating...
            </Button>
          ) : (
            <Button variant="outlined" onClick={updateUsers} fullWidth>
              Update users
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ListOfUsers;
