import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  adminTicketReport,
  customTicketReport,
} from '../../../actions/users/admin';
import {
  Box,
  Grid,
  Alert,
  CircularProgress,
  Typography,
  Button,
  Container,
  Divider,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import moment from 'moment';
import TicketReport from '../../Techs/Clients/TicketReport';

const TicketReview = ({
  adminTicketReport,
  customTicketReport,
  user_admin: {
    custom_tickets,
    tickets,
    active_tickets,
    loading,
    custom_active,
  },
  clientId,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  useEffect(() => {
    adminTicketReport(clientId);
  }, [adminTicketReport, clientId]);

  const navigate = useNavigate();

  if (loading) {
    return <CircularProgress color="info" />;
  }

  const search = () => {
    const first = moment(startDate).hour(7).format();
    const last = moment(endDate).hour(20).format();
    customTicketReport({ clientId, first, last });
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Button onClick={goBack}>
        <ArrowBackIosIcon sx={{ color: 'black' }} />
      </Button>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid xs={6} item>
            <Alert severity="warning"> Ticket Summary</Alert>
            {tickets && (
              <>
                <Typography>
                  {tickets.length} ticket/s were created this month
                </Typography>
                <Typography>{active_tickets} still active</Typography>
              </>
            )}
            <Divider sx={{ height: '10px' }} light={true} />
            {tickets && tickets.length === 0 ? (
              <Typography style={{ marginBottom: '10px' }}>
                No tickets this month...
              </Typography>
            ) : (
              tickets &&
              tickets.map((tkt) => <TicketReport key={tkt.id} ticket={tkt} />)
            )}
          </Grid>
          <Grid xs={6} item>
            <Alert severity="warning">Custom Date Range</Alert>
            <Grid container>
              <Grid xs={5} item>
                <b>Start Date: </b>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </Grid>
              <Grid xs={5} item>
                <b>End Date: </b>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                />
              </Grid>
              <Grid xs={2} item>
                <Button variant="contained" color="success" onClick={search}>
                  <QueryStatsIcon color="white" />
                </Button>
              </Grid>
            </Grid>
            {custom_tickets === null ? (
              <Typography style={{ marginBottom: '10px' }}>
                Select and search a custom date range...
              </Typography>
            ) : custom_tickets.length === 0 ? (
              <Typography style={{ marginBottom: '10px' }}>
                There are no tickets in the selected time range
              </Typography>
            ) : (
              custom_tickets.map((ticket) => (
                <TicketReport key={ticket.id} ticket={ticket} />
              ))
            )}
            {custom_tickets && (
              <>
                <Typography>
                  {custom_tickets.length} tickets/s in this time range{' '}
                </Typography>
                <Typography> {custom_active} : Still active</Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user_admin: state.user_admin,
  clientId: state.user.user_profile.clientId,
});

export default connect(mapStateToProps, {
  adminTicketReport,
  customTicketReport,
})(TicketReview);
