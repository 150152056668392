import { Grid, Alert, Container, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { completedTickets } from '../../actions/users/ticket';
import MyTickets from './Tickets/MyTickets';
import PasswordManager from './PasswordManager/PasswordManager';
import CreatePassword from './PasswordManager/CreatePassword';

const UserProfile = ({ user, completedTickets, tickets }) => {
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    if (user) {
      setUserId(user.uid);
    } else {
      setUserId(null);
    }
  }, [user]);
  useEffect(() => {
    if (userId) {
      completedTickets(userId);
    }
  }, [completedTickets, userId]);

  if (userId === null) return <CircularProgress />;
  return (
    <Container>
      <Grid container>
        <Grid item md={5}>
          <Alert sx={{ mb: 2 }}>Completed Tickets</Alert>
          {tickets === null ? (
            <CircularProgress />
          ) : tickets.length === 0 ? (
            <Alert severity="info">No completed tickets</Alert>
          ) : (
            tickets.map((ticket) => (
              <MyTickets key={ticket.id} ticket={ticket} />
            ))
          )}
        </Grid>
        <Grid item md={5}>
          <Grid container>
            <Grid item xs={8}>
              <Alert severity="info">Password manager</Alert>
            </Grid>
            <Grid item xs={4}>
              <CreatePassword userId={userId} />
            </Grid>
          </Grid>

          <PasswordManager userId={userId} />
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  tickets: state.ticket.complete_tickets,
});

export default connect(mapStateToProps, { completedTickets })(UserProfile);
