import { GET_TECHS, TECH_PROFILE } from '../types';
import { firestore } from '../../firebase-config';
import { collection, addDoc, getDocs, where, query } from 'firebase/firestore';
import { setAlert } from '../alert';
import axios from 'axios';
const backend = process.env.REACT_APP_BACKEND_URL;

export const getTechs = () => async (dispatch) => {
  const techProfile = collection(firestore, 'techs');

  try {
    const data = await getDocs(techProfile);
    let techs = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({ type: GET_TECHS, payload: techs });
  } catch (error) {
    console.error(error.message);
  }
};

export const signUpTech = (formData) => async (dispatch) => {
  const { name, surname, email, password } = formData;
  const techProfile = collection(firestore, 'techs');
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ email, password, name });
    const res = await axios.post(
      `${backend}/api/firebase-admin/create-user`,
      body,
      config
    );
    const credentials = res.data;
    await addDoc(techProfile, {
      name,
      surname,
      email,
      credentials,
    });
    dispatch(setAlert('Tech Added Successfully', 'success'));
  } catch (error) {
    console.error(error.message);
  }
};

export const getTechProfile = (credentials) => async (dispatch) => {
  try {
    const q = query(
      collection(firestore, 'techs'),
      where('credentials', '==', credentials)
    );
    const myQuery = await getDocs(q);

    let profile = myQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({ type: TECH_PROFILE, payload: profile[0] });
  } catch (error) {
    console.error(error.message);
  }
};
