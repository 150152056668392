import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Grid,
  CardContent,
  Container,
  TextField,
  Alert,
  AlertTitle,
  Button,
  CardActions,
} from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { signUpTech } from '../../actions/techs/';
import { setAlert } from '../../actions/alert';

import './TechLogin.css';

const AddTech = ({ signUpTech, setAlert }) => {
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    password: '',
    password2: '',
  });
  let navigate = useNavigate();
  const { name, surname, email, password, password2 } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (password !== password2) {
      setAlert('passwords do not match', 'error');
    } else {
      signUpTech(formData);
      navigate('/tech/tech-admin');
    }
  };
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} id="tech-sign-in">
          <Alert severity="info">
            <AlertTitle>Sign up a tech:</AlertTitle>
          </Alert>
          <Card>
            <form onSubmit={onSubmit}>
              <CardContent id="form">
                <Grid item>
                  <TextField
                    label="Tech Name"
                    variant="outlined"
                    name="name"
                    value={name}
                    onChange={onChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Tech Surname"
                    variant="outlined"
                    name="surname"
                    value={surname}
                    onChange={onChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Tech Email"
                    placeholder="Tech Email"
                    type="email"
                    name="email"
                    variant="outlined"
                    value={email}
                    onChange={onChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Password"
                    placeholder="Enter Password"
                    type="password"
                    variant="outlined"
                    name="password"
                    value={password}
                    onChange={onChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    variant="outlined"
                    type="password"
                    name="password2"
                    value={password2}
                    onChange={onChange}
                    fullWidth
                    required
                  />
                </Grid>
              </CardContent>
              <CardActions>
                <Button type="submit" variant="contained" fullWidth>
                  Create Technician <DoubleArrowIcon />
                </Button>
              </CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default connect(null, { setAlert, signUpTech })(AddTech);
