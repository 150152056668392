import { Typography, Grid, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import React from 'react';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

const DepartmentItem = ({ department }) => {
  let navigate = useNavigate();

  return (
    <>
      <Grid
        onClick={() => navigate(`/tech/client-department/${department.id}`)}
        container
      >
        <Grid xs={2} item>
          <BusinessCenterIcon />
        </Grid>
        <Grid xs={10} item>
          <Typography>{department.name}</Typography>
        </Grid>
        <Divider sx={{ marginTop: '12px' }} />
      </Grid>
    </>
  );
};

export default DepartmentItem;
