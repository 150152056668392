import {
  UPDATE_TASKLIST,
  ASSIGN_TODO,
  TODO_DEADLINE,
  MY_TODOS,
  MY_COMPLETE_TODOS,
  ASSIGNED_TODOS,
  TODO_TEXTS,
  MY_TODO,
  CLEAR_TODOS,
  TODO_ADD_COLLAB,
  TODO_REMOVE_COLLAB,
  COMPLETE_ASSIGNED_TODOS,
  PREV_TODO,
} from '../../actions/types';

const initState = {
  taskList: [],
  assignedToDo: null,
  loading: true,
  todoDeadline: null,
  myTodos: null,
  prevTodo: null,
  assigned: null,
  completeAssigned: null,
  myTodo: null,
  doneTodos: null,
  texts: [],
  collabCacheAdd: [],
  collabCacheRemove: [],
};

function todoReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_TASKLIST:
      return {
        ...state,
        taskList: payload,
      };
    case ASSIGN_TODO:
      return {
        ...state,
        assignedToDo: payload,
      };
    case COMPLETE_ASSIGNED_TODOS:
      return {
        ...state,
        completeAssigned: payload,
      };
    case TODO_DEADLINE:
      return {
        ...state,
        todoDeadline: payload,
      };
    case MY_TODOS:
      return {
        ...state,
        myTodos: payload,
        loading: false,
      };
    case MY_COMPLETE_TODOS:
      return {
        ...state,
        doneTodos: payload,
      };
    case ASSIGNED_TODOS:
      return {
        ...state,
        assigned: payload,
      };
    case MY_TODO:
      return {
        ...state,
        myTodo: payload,
        loading: false,
      };
    case PREV_TODO:
      return {
        ...state,
        prevTodo: payload,
      };
    case TODO_ADD_COLLAB:
      return {
        ...state,
        collabCacheAdd: payload,
      };
    case TODO_REMOVE_COLLAB:
      return {
        ...state,
        collabCacheRemove: payload,
      };
    case CLEAR_TODOS:
      return {
        ...state,
        taskList: [],
        assignedToDo: null,
        loading: true,
        todoDeadline: null,
        myTodos: null,
        assigned: null,
        myTodo: null,
        prevTodo: null,
        doneTodos: null,
        texts: [],
        collabCacheAdd: [],
        collabCacheRemove: [],
      };
    case TODO_TEXTS:
      return {
        ...state,
        texts: payload,
      };
    default:
      return state;
  }
}

export default todoReducer;
