import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  fetchTodos,
  fetchCompleteTodos,
  fetchAssignedTodos,
} from '../../../actions/techs/todo';
import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { AddBoxOutlined } from '@mui/icons-material';
import TodoItem from './TodoItem';
import { tokens } from '../../../theme';
import moment from 'moment';

const ITTodos = ({
  fetchTodos,
  fetchCompleteTodos,
  tech_profile,
  fetchAssignedTodos,
  todo: { loading, myTodos, assigned },
}) => {
  const [todos, setTodos] = useState([]);
  useEffect(() => {
    if (tech_profile) {
      fetchTodos(tech_profile.credentials);
      fetchCompleteTodos(tech_profile.credentials);
      fetchAssignedTodos(tech_profile.credentials);
    }
  }, [fetchTodos, fetchCompleteTodos, tech_profile, fetchAssignedTodos]);
  useEffect(() => {
    if (myTodos && assigned) {
      const theTodos = myTodos.concat(assigned);
      const transformedTodos = theTodos.map((todo) => {
        return {
          ...todo,
          type: todo.deadline.type,
          deadline:
            todo.deadline.type === 'Once'
              ? moment(todo.deadline.deadline).format('ll')
              : todo.deadline.deadline,
        };
      });

      setTodos(transformedTodos);
    }
  }, [myTodos, assigned, setTodos]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  let navigate = useNavigate();
  if (loading || myTodos === null)
    return (
      <Container>
        <CircularProgress />
      </Container>
    );

  const navToAddTodo = () => {
    navigate('/tech/create-todo');
  };

  const navToComplete = () => {
    navigate('/tech/complete-todos');
  };
  return (
    <Container>
      <Grid container>
        <Grid xs={8} item>
          <Typography variant="h5">Call IT Services TODO's</Typography>
        </Grid>
        <Grid item xs={2}>
          <Button
            size="small"
            sx={{ color: colors.grey[200] }}
            onClick={navToAddTodo}
          >
            <AddBoxOutlined />
          </Button>
        </Grid>

        <Grid item xs={2}>
          <Button
            sx={{ color: colors.grey[200], borderColor: colors.grey[200] }}
            onClick={navToComplete}
            variant="outlined"
          >
            Complete Todos
          </Button>
        </Grid>
      </Grid>
      <Divider sx={{ marginBottom: '5px', marginTop: '5px' }} />
      <Grid container>
        <Grid xs={8} item>
          <Typography variant="h6">Your TODO's</Typography>
          {todos.length === 0 ? 'You have no todos' : <TodoItem todo={todos} />}
        </Grid>
        <Grid item xs={4}>
          <Typography>Todo Review</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  tech_profile: state.tech.tech_profile,
  todo: state.todo,
});

export default connect(mapStateToProps, {
  fetchTodos,
  fetchCompleteTodos,
  fetchAssignedTodos,
})(ITTodos);
