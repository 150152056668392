import {
  CLIENT_USERS,
  GET_CLIENTS,
  GET_CLIENT,
  CLEAR_CLIENT,
  HOME_USERS,
  GET_USER,
  CLEAR_USER,
  USER_DEVICE,
  CLIENT_DEVICE,
  OLD_USERS,
  DEPARTMENTS,
  BRANCHES,
  DEPARTMENT_INFO,
  BRANCH_INFO,
} from '../../actions/types';

const initState = {
  clients: null,
  client: null,
  client_users: null,
  home_users: null,
  old_users: [],
  loading: true,
  user: null,
  device: null,
  client_devices: null,
  branches: [],
  departments: [],
  department_info: null,
  branch_info: null,
};

function clientReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case HOME_USERS:
      return {
        ...state,
        home_users: payload,
        loading: false,
      };
    case GET_CLIENTS:
      return {
        ...state,
        clients: payload,
        loading: false,
      };
    case GET_CLIENT:
      return {
        ...state,
        client: payload,
        loading: false,
      };
    case CLIENT_USERS:
      return {
        ...state,
        client_users: payload,
      };
    case OLD_USERS:
      return {
        ...state,
        old_users: payload,
        loading: false,
      };
    case BRANCHES:
      return {
        ...state,
        branches: payload,
      };
    case DEPARTMENTS:
      return {
        ...state,
        departments: payload,
      };
    case BRANCH_INFO:
      return {
        ...state,
        branch_info: payload,
      };

    case DEPARTMENT_INFO:
      return {
        ...state,
        department_info: payload,
      };

    case GET_USER:
      return {
        ...state,
        user: payload,
        loading: false,
      };
    case CLEAR_USER:
      return {
        ...state,
        user: null,
        device: null,
      };
    case USER_DEVICE:
      return {
        ...state,
        device: payload,
      };
    case CLIENT_DEVICE:
      return {
        ...state,
        client_devices: payload,
      };
    case CLEAR_CLIENT:
      return {
        ...state,
        clients: null,
        client: null,
        client_users: null,
        user: null,
        device: null,
        loading: true,
      };
    default:
      return state;
  }
}

export default clientReducer;
