import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { deletePassword } from '../../../actions/users/passwords';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Alert,
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';

const DeletePassword = ({ deletePassword, password, alert, userId }) => {
  const [open, setOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    if (alert.length > 0 && deleting) {
      setDeleting(false);
      setOpen(false);
    }
  }, [alert, deleting]);

  const style = {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const deletePass = () => {
    const pwdId = password.id;
    setDeleting(true);
    deletePassword({ pwdId, userId });
  };
  return (
    <>
      <IconButton
        sx={{ color: 'red' }}
        edge="end"
        aria-label="delete"
        onClick={handleOpen}
      >
        <DeleteIcon />
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Alert severity="warning">
            Are you sure you want to delete password!?
          </Alert>
          <Grid container>
            <Grid item>
              <Typography variant="h6" component="h2">
                You cannot undo this!
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Button onClick={handleClose}>Cancel</Button>
            </Grid>
            {deleting ? (
              <Button variant="contained" color="error" disabled>
                Deleting {password.name}...
              </Button>
            ) : (
              <Button variant="outlined" color="error" onClick={deletePass}>
                Delete {password.name}
              </Button>
            )}
            <Grid item></Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default connect(mapStateToProps, { deletePassword })(DeletePassword);
