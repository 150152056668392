import Dropzone from 'react-dropzone';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const FileUpload = ({ uploadFile }) => {
  const types = ['image/png', 'image/jpeg'];
  const upload = async (files) => {
    let selected = files[0];
    if (selected && types.includes(selected.type)) {
      uploadFile(selected);
    } else {
      console.log('Invalid File');
    }
  };
  return (
    <>
      <Dropzone onDrop={upload}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <AttachFileIcon />
          </div>
        )}
      </Dropzone>
    </>
  );
};

export default FileUpload;
