import { Container } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';

const MyToDo = () => {
  return (
    <Container>
      <h2>My Todo...</h2>
    </Container>
  );
};

export default connect()(MyToDo);
