import {
  Alert,
  CircularProgress,
  Typography,
  Box,
  Grid,
  List,
  ListItem,
  Divider,
  Container,
  Button,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchClient } from '../../../actions/clients/clients';
import {
  getProject,
  fetchProjectMilestones,
  fetchProjectNext,
  fetchFeatureReq,
} from '../../../actions/techs/project';
import moment from 'moment';
import { ArrowBack, DoneAll } from '@mui/icons-material';
import AddNext from './AddNext';
import CompleteNext from './CompleteNext';
import MilestoneInfo from './MilestoneInfo';
import CompleteProject from './CompleteProject';
import SelectProjectUser from './SelectProjectUser';
import ViewFeature from './ViewFeature';
import { tokens } from '../../../theme';

const Project = ({
  project: { project, next, milestones, feature },
  getProject,
  fetchProjectMilestones,
  fetchProjectNext,
  fetchClient,
  users,
  fetchFeatureReq,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { projectId } = useParams();
  const [pending, setPending] = useState('Start Up...');
  const [complete, setComplete] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [proUser, setProUser] = useState(null);
  let navigate = useNavigate();

  useEffect(() => {
    if (project) {
      setProUser(project.proUser);
      setClientId(project.clientId);
      if (project.completedAt) {
        setComplete(true);
      } else {
        setComplete(false);
      }
    }
  }, [project]);

  useEffect(() => {
    if (proUser === 'none') {
      fetchClient(clientId);
    }
  }, [proUser, fetchClient, clientId]);

  useEffect(() => {
    getProject(projectId);
    fetchProjectMilestones(projectId);
    fetchProjectNext(projectId);
    fetchFeatureReq(projectId);
  }, [
    getProject,
    projectId,
    fetchProjectMilestones,
    fetchFeatureReq,
    fetchProjectNext,
  ]);

  useEffect(() => {
    if (next && milestones) {
      if (next.length === 0) {
        setPending('Start Up...');
      } else {
        if (next.length === milestones.length) {
          setPending(false);
        } else {
          setPending(true);
        }
      }
    }
  }, [next, milestones]);

  if (!project || proUser === null) return <CircularProgress />;
  const creation = moment(project.createdAt).format('ll');

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <Grid md={4} xs={12} item>
            <Grid container>
              <Grid xs={1} item>
                <ArrowBack onClick={goBack} />
              </Grid>
              <Grid xs={11} item>
                <Typography
                  variant="h5"
                  sx={{
                    color: colors.grey[500],
                    fontWeight: 'bold',
                    ml: '10px',
                  }}
                >
                  Project details;
                </Typography>
              </Grid>
            </Grid>

            <List>
              <ListItem>
                <Typography>
                  <b>Client Name:</b> {project.clientName}
                </Typography>
              </ListItem>
              <Divider />
              <ListItem>
                <Typography>
                  <b>Project Name:</b> {project.name}
                </Typography>
              </ListItem>
              <Divider />
              <ListItem>
                <Typography>
                  <b>Project Description:</b> {project.desc}
                </Typography>
              </ListItem>
              <Divider />
              <ListItem>
                <Typography>
                  <b>Creation Date:</b> {creation}
                </Typography>
              </ListItem>
              <Divider />
              <ListItem>
                <Grid container>
                  <Grid xs={6} item>
                    <Typography>
                      <b>Value: R</b> {project.value}
                    </Typography>
                  </Grid>
                  <Grid xs={6} item>
                    {complete ? (
                      <Alert icon={false} severity="info">
                        Project Complete
                      </Alert>
                    ) : (
                      <CompleteProject
                        projectId={projectId}
                        pending={pending}
                      />
                    )}
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
              <ListItem>
                <Grid container>
                  <Grid xs={6} item>
                    <Typography>
                      <b>Project User:</b>
                    </Typography>
                  </Grid>
                  <Grid xs={6} item>
                    {proUser === 'none' && users ? (
                      <SelectProjectUser users={users} projectId={projectId} />
                    ) : (
                      <Button color="success" size="small">
                        {proUser.name}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
              <Typography
                variant="h6"
                sx={{ color: 'orange' }}
                align="center"
                mt={2}
                gutterBottom={true}
              >
                Feature Requests...
              </Typography>
              {!feature ? (
                <CircularProgress />
              ) : feature.length === 0 ? (
                <>
                  <Typography ml={2} mt={2} mb={2}>
                    No Feature Request
                  </Typography>
                  <Divider />
                </>
              ) : (
                feature.map((f) => (
                  <ViewFeature key={f.id} f={f} projectId={projectId} />
                ))
              )}
            </List>
          </Grid>
          <Grid md={4} mt={0.7} xs={12} item>
            <Typography
              variant="h5"
              sx={{
                color: colors.grey[500],
                fontWeight: 'bold',
                ml: '10px',
              }}
            >
              Milestones!
            </Typography>
            {!milestones ? (
              <CircularProgress />
            ) : milestones.length === 0 ? (
              <Typography mt={1}>No Milestones</Typography>
            ) : (
              milestones.map((milestone) => (
                <MilestoneInfo key={milestone.id} milestone={milestone} />
              ))
            )}
          </Grid>
          <Grid md={4} xs={12} item>
            <Grid container>
              <Grid xs={8} item>
                <Typography
                  variant="h5"
                  sx={{
                    color: colors.grey[500],
                    fontWeight: 'bold',
                    ml: '10px',
                  }}
                >
                  Whats Next
                </Typography>
              </Grid>
              <Grid xs={2} item>
                {complete ? (
                  <Button disableFocusRipple={true} disableRipple={true}>
                    <DoneAll color="warning" />
                  </Button>
                ) : (
                  <AddNext projectId={projectId} />
                )}
              </Grid>
            </Grid>
            <List>
              {!next ? (
                <CircularProgress />
              ) : next.length === 0 ? (
                <Typography>No Next</Typography>
              ) : (
                next.map((next) => (
                  <CompleteNext
                    next={next}
                    key={next.id}
                    projectId={projectId}
                  />
                ))
              )}
            </List>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  project: state.project,
  users: state.client.client_users,
});

export default connect(mapStateToProps, {
  getProject,
  fetchProjectMilestones,
  fetchProjectNext,
  fetchClient,
  fetchFeatureReq,
})(Project);
