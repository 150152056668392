import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';
import { Card, Grid, Typography, Button, Box } from '@mui/material';
import { useSpring, animated } from 'react-spring';

import './Support.css';
import Initialize from './Initialize';
import logobp from '../../images/logobp.png';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';

const Support = ({ isUser, isBusiness, isTech }) => {
  const [initialize, setInitialize] = useState(true);

  useEffect(() => {
    if (initialize) {
      if (isUser) {
        setInitialize(false);
      } else if (isBusiness) {
        setInitialize(false);
      } else if (isTech) {
        setInitialize(false);
      } else {
        setInitialize(false);
      }
    }
  }, [initialize, isUser, isBusiness, isTech]);

  const logoDiv = useSpring({
    opacity: 1,
    marginTop: 0,
    from: {
      marginTop: -1000,
      opacity: 0.5,
    },
    delay: 500,
    immediate: false,
  });

  if (initialize) return <Initialize />;

  if (isUser) return <Navigate to="/user/user-landing" />;

  if (isBusiness) return <Navigate to="/business/business-landing" />;

  if (isTech) return <Navigate to="/tech/tech-landing" />;

  return (
    <>
      <div id="home">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} id="login-container">
            <Card variant="outlined" id="user-login-card">
              <Typography
                align="center"
                variant="h4"
                sx={{
                  color: 'white',
                }}
                gutterBottom
              >
                Sign in to get help from your company
              </Typography>

              <Typography
                align="center"
                variant="h4"
                sx={{ color: 'white' }}
                gutterBottom
              >
                support department
              </Typography>

              <Box
                m={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
                as={Link}
                to="/user/sign-in"
              >
                <Button
                  variant="contained"
                  sx={{ height: 40, backgroundColor: 'white', color: 'black' }}
                  color="secondary"
                >
                  User sign in
                </Button>
              </Box>
            </Card>
            <Card variant="outlined" id="business-login-card">
              <Typography
                align="center"
                variant="h4"
                sx={{ color: 'white' }}
                gutterBottom
              >
                For an effective way to support
              </Typography>
              <Typography
                align="center"
                variant="h4"
                sx={{ color: 'white' }}
                gutterBottom
              >
                your remote staff
              </Typography>
              <Typography
                align="center"
                variant="h4"
                sx={{ color: 'white' }}
                gutterBottom
              >
                sign into your business account
              </Typography>

              <Box
                m={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
                as={Link}
                to="/business/sign-in"
              >
                <Button
                  variant="contained"
                  sx={{ height: 40, backgroundColor: 'white', color: 'black' }}
                  color="secondary"
                >
                  Business sign in
                </Button>
              </Box>
            </Card>
            <div className="shooting-star star1"></div>
            <div className="shooting-star star2"></div>
            <div className="shooting-star star3"></div>
            <div className="shooting-star star4"></div>
            <div className="shooting-star star5"></div>
            <div className="shooting-star star6"></div>
            <div className="shooting-star star7"></div>
            <div className="shooting-star star8"></div>
            <div className="shooting-star star9"></div>
          </Grid>
          <Grid item xs={12} md={6}>
            <animated.div style={logoDiv}>
              <div id="home-logo-image-div">
                <img alt="callitservices" id="home-logo-image" src={logobp} />
              </div>
            </animated.div>
          </Grid>
        </Grid>
      </div>
      <Grid container id="app-store-container">
        <Grid item xs={12} md={4}>
          <Typography
            variant="h4"
            sx={{ color: 'vlack', marginTop: '25px' }}
            align="center"
            gutterBottom
          >
            Download the app
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            endIcon={<AppleIcon />}
            id="app-store-button"
            href="https://apps.apple.com/us/app/call-it-services/id1635325800"
            target="_blank"
          >
            Available on the iStore
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            endIcon={<AndroidIcon />}
            id="app-store-button"
            href="https://play.google.com/store/apps/details?id=co.za.callitservices.itservices_app&pli=1"
            target="_blank"
          >
            Available on the Play Store
          </Button>
        </Grid>
      </Grid>
      <Grid container className="footer">
        <Grid item xs={12} md={6}>
          <div id="copyright-5129-particle" className="g-content g-particle">
            &copy; 2024 Call IT Services
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="g-branding">
            Developed by
            <a href="https://www.callitservices.co.za/"> Call IT Services</a>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  isUser: state.user.isUser,
  isBusiness: state.business.isBusiness,
  isTech: state.tech.isTech,
});
export default connect(mapStateToProps)(Support);
