import {
  CLIENT_UPDATE_TASKLIST,
  CLIENT_ASSIGN_TODO,
  CLIENT_TODO_DEADLINE,
  CLIENT_MY_TODOS,
  CLIENT_MY_TODO,
  CLIENT_CLEAR_TODOS,
} from '../../actions/types';

const initState = {
  taskList: [],
  assignedToDo: null,
  loading: true,
  todoDeadline: null,
  myTodos: null,
  myTodo: null,
};

function todoReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case CLIENT_UPDATE_TASKLIST:
      return {
        ...state,
        taskList: payload,
      };
    case CLIENT_ASSIGN_TODO:
      return {
        ...state,
        assignedToDo: payload,
      };
    case CLIENT_TODO_DEADLINE:
      return {
        ...state,
        todoDeadline: payload,
      };
    case CLIENT_MY_TODOS:
      return {
        ...state,
        myTodos: payload,
        loading: false,
      };
    case CLIENT_MY_TODO:
      return {
        ...state,
        myTodo: payload,
        loading: false,
      };
    case CLIENT_CLEAR_TODOS:
      return {
        ...state,
        taskList: [],
        assignedToDo: null,
        loading: true,
        todoDeadline: null,
        myTodos: null,
        myTodo: null,
      };
    default:
      return state;
  }
}

export default todoReducer;
