import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';

import {
  Container,
  Grid,
  CircularProgress,
  Alert,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Divider,
} from '@mui/material';
import CreateTicket from './Tickets/CreateTicket';
import MyTickets from './Tickets/MyTickets';
import { fetchTickets, submitTicket } from '../../actions/users/ticket';
import { getSupport } from '../../actions/users';
import { getProjects } from '../../actions/users/project';
import { setAlert } from '../../actions/alert';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';

const UserLanding = ({
  fetchTickets,
  user,
  submitTicket,
  profile: { user_profile, business },
  tickets,
  alert,
  setAlert,
  getProjects,
  projects,
  getSupport,
}) => {
  const [userId, setUserId] = useState(null);
  const [usr, setUser] = useState(null);
  const [isBusiness, setIsBusiness] = useState(null);
  const [businessId, setBusinessId] = useState(null);
  const [proId, setProId] = useState(null);
  let navigate = useNavigate();

  useEffect(() => {
    if (isBusiness) {
      getSupport(businessId);
    }
  }, [isBusiness, getSupport, businessId]);

  useEffect(() => {
    if (user_profile) {
      setProId(user_profile.credentials);
      setIsBusiness(user_profile.isBusiness);
      setBusinessId(user_profile.clientId);
    }
  }, [user_profile]);
  useEffect(() => {
    if (user) {
      setUser(user);
      setUserId(user.uid);
    } else {
      setUserId(null);
    }
  }, [user, setUserId]);

  useEffect(() => {
    fetchTickets(userId);
    getProjects(proId);
  }, [fetchTickets, userId, proId, getProjects]);

  if (
    tickets === null ||
    userId === null ||
    proId === null ||
    projects === null
  )
    return <CircularProgress />;

  if (usr && usr.emailVerified === false) {
    return <Navigate to="/email-verification" />;
  }

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <CreateTicket
            submitTicket={submitTicket}
            userId={userId}
            user_profile={user_profile}
            alert={alert}
            setAlert={setAlert}
            isBusiness={isBusiness}
            business={business}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Alert severity="warning">Your open tickets</Alert>
          {tickets.length === 0 ? (
            <Typography>You have no tickets</Typography>
          ) : (
            tickets.map((ticket) => (
              <MyTickets key={ticket.id} ticket={ticket} />
            ))
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Alert severity="info">Your Projects;</Alert>
          {projects.length === 0 ? (
            <Typography>You have no projects</Typography>
          ) : (
            projects.map((project) => (
              <List key={project.id}>
                <ListItemButton
                  onClick={() => navigate(`/user/my-project/${project.id}`)}
                >
                  <ListItem
                    secondaryAction={
                      <IconButton edge="end">
                        <ArrowForwardIos />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={`${project.name}`} />
                  </ListItem>
                </ListItemButton>
                <Divider />
              </List>
            ))
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  profile: state.user,
  tickets: state.ticket.tickets,
  projects: state.project_.projects,
  alert: state.alert,
});

export default connect(mapStateToProps, {
  fetchTickets,
  submitTicket,
  setAlert,
  getProjects,
  getSupport,
})(UserLanding);
