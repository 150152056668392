import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { editDevice } from '../../../actions/clients/clients';
import {
  Container,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const EditDeviceDetails = ({ device, editDevice, note }) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (note) {
      if (note.length > 0) {
        setOpen(false);
      }
    }
  }, [note]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [deviceType, setDeviceType] = useState(device.deviceType);
  const [deviceModel, setDeviceModal] = useState(device.deviceModel);
  const [serialNumber, setSerialNumber] = useState(device.serialNumber);
  const [warranty, setWarranty] = useState(new Date(device.warranty));
  const [purchaseDate, setPurchaseDate] = useState(
    new Date(device.purchaseDate)
  );
  const [deviceSpecs, setDeviceSpecs] = useState(device.deviceSpecs);
  const [os, setOS] = useState(device.os);
  const [officeVersion, setOfficeVersion] = useState(device.officeVersion);
  const [antiVirus, setAntiVirus] = useState(device.antiVirus);
  const [list, setList] = useState(device.software);
  const deviceId = device.id;

  const soft = list.toString();

  const editData = () => {
    const software = soft.split(',');
    let pd = moment(purchaseDate).format();
    let wt = moment(warranty).format();
    editDevice({
      deviceType,
      deviceModel,
      serialNumber,
      purchaseDate: pd,
      warranty: wt,
      os,
      officeVersion,
      antiVirus,
      software,
      deviceSpecs,
      deviceId,
    });
  };

  return (
    <Container>
      <Button
        variant="outlined"
        severity="primary"
        onClick={handleOpen}
        size="small"
      >
        Edit user device details
      </Button>
      <Dialog open={open} onClose={handleClose} scroll="paper">
        <DialogTitle id="scroll-dialog-title">Edit Device details</DialogTitle>
        <DialogContent dividers={true}>
          <Grid mt={2} item>
            <TextField
              label={`${deviceType}`}
              variant="outlined"
              type="text"
              name="deviceType"
              value={deviceType}
              onChange={(e) => setDeviceType(e.target.value)}
              autoComplete="false"
              fullWidth
              required
            />
          </Grid>
          <Grid mt={2} item>
            <TextField
              label={`${deviceModel}`}
              variant="outlined"
              type="text"
              name="deviceModel"
              value={deviceModel}
              onChange={(e) => setDeviceModal(e.target.value)}
              autoComplete="false"
              fullWidth
              required
            />
          </Grid>
          <Grid mt={2} item>
            <TextField
              label="Serial Number"
              variant="outlined"
              type="text"
              name="serialNumber"
              value={serialNumber}
              onChange={(e) => setSerialNumber(e.target.value)}
              autoComplete="false"
              fullWidth
              required
            />
          </Grid>
          <Grid mt={2} item>
            <TextField
              label={`${deviceSpecs}`}
              variant="outlined"
              type="text"
              name="deviceSpecs"
              value={deviceSpecs}
              onChange={(e) => setDeviceSpecs(e.target.value)}
              autoComplete="false"
              fullWidth
              required
            />
          </Grid>
          <Grid mt={2} item>
            <TextField
              label={`${os}`}
              variant="outlined"
              type="text"
              name="os"
              value={os}
              onChange={(e) => setOS(e.target.value)}
              autoComplete="false"
              fullWidth
              required
            />
          </Grid>
          <Grid mt={2} item>
            <TextField
              label={`${officeVersion}`}
              variant="outlined"
              type="text"
              name="officeVersion"
              value={officeVersion}
              onChange={(e) => setOfficeVersion(e.target.value)}
              autoComplete="false"
              fullWidth
              required
            />
          </Grid>
          <Grid mt={2} item>
            <TextField
              label={`${antiVirus}`}
              variant="outlined"
              type="text"
              name="antiVirus"
              value={antiVirus}
              onChange={(e) => setAntiVirus(e.target.value)}
              autoComplete="false"
              fullWidth
              required
            />
          </Grid>
          <Grid mt={2} item>
            <TextField
              label="Other software"
              variant="outlined"
              type="text"
              multiline={true}
              name="soft"
              value={soft}
              autoComplete="false"
              onChange={(e) => setList(e.target.value)}
              fullWidth
            />
          </Grid>
          <Stack mt={2} mb={2} direction="row" spacing={2}>
            <div>
              Purchase date:
              <DatePicker
                selected={purchaseDate}
                onChange={(date) => setPurchaseDate(date)}
              />
            </div>
            <div>
              Warranty expiring:
              <DatePicker
                selected={warranty}
                onChange={(date) => setWarranty(date)}
              />
            </div>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={editData} fullWidth>
            Edit Device data
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  note: state.alert,
});

export default connect(mapStateToProps, { editDevice })(EditDeviceDetails);
