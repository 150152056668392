import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getProjects } from '../../../actions/users/project';
import { currentUserClient } from '../../../actions/users/admin';
import {
  Alert,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';

const UserProjectDash = ({
  currentUserClient,
  getProjects,
  projects,
  profile,
  client,
}) => {
  const { clientId } = useParams();
  const [userId, setUserId] = useState(null);
  const [usr, setUser] = useState(null);
  useEffect(() => {
    currentUserClient(clientId);
  }, [clientId, currentUserClient]);

  useEffect(() => {
    if (profile) {
      setUser(profile);
      setUserId(profile.credentials);
    } else {
      setUserId(null);
    }
  }, [profile, setUserId]);
  useEffect(() => {
    if (userId) {
      getProjects(userId);
    }
  }, [userId, getProjects]);

  if (usr === null || projects === null || client === null)
    return <CircularProgress />;
  return (
    <Container>
      <Typography variant="h5">{client.name} Projects</Typography>
      <Divider
        variant="middle"
        sx={{ marginBottom: '5px', marginTop: '5px' }}
      />
      <Grid container>
        <Grid xs={6} item>
          <Typography variant="h6">Hello {usr.name}</Typography>
          {projects.length === 0 ? (
            <Alert sx={{ marginTop: '10px' }}>No Projects</Alert>
          ) : (
            projects.map((project) => (
              <ListItem
                key={project.id}
                secondaryAction={
                  <IconButton edge="end" color="warning">
                    {/* <RemoveIcon /> */}
                  </IconButton>
                }
              >
                <IconButton>{/* <RadioButtonUncheckedIcon /> */}</IconButton>

                <ListItemText primary={`${project.title}`} />
              </ListItem>
            ))
          )}
        </Grid>
        <Grid xs={6} item>
          <Typography variant="h6">Projects Overview</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  projects: state.project_.projects,
  profile: state.user.user_profile,
  client: state.user_admin.client,
});

export default connect(mapStateToProps, { getProjects, currentUserClient })(
  UserProjectDash
);
