import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import tech from './techs';
import tech_tickets from './techs/ticket';
import project from './techs/project';
import client from './clients/client';
import user from './users';
import ticket from './users/tickets';
import upload from './storage';
import project_ from './users/projects';
import user_admin from './users/admin';
import business from './business';
import leads from './techs/leads';
import todo from './techs/todo';
import password from './users/password';
import client_todo from './users/todo';
import client_sales from './users/sales';
export default combineReducers({
  alert,
  auth,
  tech,
  tech_tickets,
  project,
  client,
  user,
  ticket,
  upload,
  project_,
  client_sales,
  business,
  leads,
  todo,
  user_admin,
  client_todo,
  password,
});
