import { useState } from 'react';
import { connect } from 'react-redux';
import {
  decryptMyPassword,
  clearPassword,
} from '../../../actions/users/passwords';
import {
  Avatar,
  Button,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  Typography,
  Box,
  Alert,
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeletePassword from './DeletePassword';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditPassword from './EditPassword';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const MyPasswords = ({
  password,
  decryptMyPassword,
  userId,
  decryptedPassword,
  clearPassword,
}) => {
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    clearPassword();
    setOpen(false);
  };

  const style = {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  const decryptMe = () => {
    let pwdId = password.id;
    decryptMyPassword({ userId, pwdId });
  };

  const copyPassword = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <>
      <ListItem
        secondaryAction={
          <Grid container>
            <Grid item>
              <EditPassword password={password} userId={userId} />
            </Grid>
            <Grid item>
              <DeletePassword password={password} userId={userId} />
            </Grid>
          </Grid>
        }
      >
        <ListItemAvatar>
          <Avatar>
            <RemoveRedEyeIcon onClick={handleOpen} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={`${password.name}`} />
      </ListItem>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Show password for {password.name}
          </Typography>
          <hr />
          {decryptedPassword ? (
            <Grid container>
              <Grid item xs={10}>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Password is: {decryptedPassword}
                </Typography>
                {copied && (
                  <Alert
                    severity="success"
                    style={{
                      marginTop: '5px',
                      marginLeft: '40px',
                    }}
                  >
                    Password copied successfully!%
                  </Alert>
                )}
              </Grid>
              <Grid item xs={2}>
                <CopyToClipboard text={decryptedPassword} onCopy={copyPassword}>
                  <ContentCopyIcon style={{ marginTop: '10px' }} />
                </CopyToClipboard>
              </Grid>
            </Grid>
          ) : (
            <Button onClick={decryptMe}>Show password</Button>
          )}
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  decryptedPassword: state.password.decryptedPassword,
});

export default connect(mapStateToProps, { decryptMyPassword, clearPassword })(
  MyPasswords
);
