import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { deleteDevice } from '../../../actions/clients/clients';
import {
  Grid,
  Typography,
  Divider,
  Modal,
  Box,
  Alert,
  Button,
} from '@mui/material';
import LaptopIcon from '@mui/icons-material/Laptop';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';
import RemoveIcon from '@mui/icons-material/Remove';

const Device = ({ device, deleteDevice, alert, clientId }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (alert.length > 0) {
      handleClose();
    }
  }, [alert]);

  const delDevice = () => {
    const deviceId = device.id;
    deleteDevice({ deviceId, clientId });
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  if (device.userName === null) {
    return (
      <>
        <Grid container spacing={2}>
          <Grid xs={5} item>
            <Typography gutterBottom>
              <DesktopAccessDisabledIcon
                sx={{ marginRight: '20px', color: 'red' }}
              />
              Offline
            </Typography>
          </Grid>
          <Grid xs={5} item>
            <Typography gutterBottom>
              <LaptopIcon sx={{ marginRight: '20px' }} />
              {device.deviceType}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <RemoveIcon sx={{ color: 'red' }} onClick={handleOpen} />
          </Grid>
        </Grid>
        <Divider sx={{ marginTop: '12px' }} />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Alert severity="warning">
              Are you sure you want to delete the device?
            </Alert>
            <Grid container>
              <Grid item>
                <Button onClick={delDevice}>Yes</Button>
              </Grid>
              <Grid item>
                <Button color="secondary" onClick={handleClose}>
                  No
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </>
    );
  } else {
    return (
      <>
        <Grid container spacing={2}>
          <Grid xs={6} item>
            <Typography gutterBottom>
              <LaptopIcon sx={{ marginRight: '20px' }} />
              {device.deviceModel}
            </Typography>
          </Grid>
          <Grid xs={6} item>
            <Typography gutterBottom>
              <PersonOutlineIcon sx={{ marginRight: '20px' }} />
              {device.userName}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ marginTop: '12px' }} />
      </>
    );
  }
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default connect(mapStateToProps, { deleteDevice })(Device);
