import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  createMasterPassword,
  loginWithMasterPassword,
  getMasterPassword,
  myPasswords,
  decryptMyPassword,
} from '../../../actions/users/passwords';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import MyPasswords from './MyPasswords';
import CreatePassword from './CreatePassword';

const PasswordManager = ({
  alert,
  createMasterPassword,
  loginWithMasterPassword,
  myPasswords,
  getMasterPassword,
  userId,
  password: { myMP, savedPasswords, loading, viewPasswords },
  decryptMyPassword,
}) => {
  const [newMaster, setNewMaster] = useState('');
  const [password, setPassword] = useState('');
  const [creatingMaster, setCreatingMaster] = useState(false);
  const [goingIn, setGoingIn] = useState(false);

  useEffect(() => {
    if (viewPasswords) {
      myPasswords(userId);
    }
  }, [viewPasswords, myPasswords, userId]);

  useEffect(() => {
    if (alert.length > 0) {
      if (goingIn) {
        setGoingIn(false);
      }
      if (creatingMaster) {
        setCreatingMaster(false);
      }
    }
  }, [alert, goingIn, creatingMaster]);

  useEffect(() => {
    getMasterPassword(userId);
  }, [getMasterPassword, userId]);

  if (loading) return <CircularProgress />;

  const createMaster = () => {
    let pwd = newMaster;
    setCreatingMaster(true);
    createMasterPassword({ pwd, userId });
  };

  const enterMaster = () => {
    setGoingIn(true);
    let pwdId = userId;
    loginWithMasterPassword({ pwdId, password });
  };

  return (
    <div>
      {myMP ? (
        <>
          {viewPasswords === true ? (
            savedPasswords && savedPasswords.length > 0 ? (
              savedPasswords.map((password) => (
                <MyPasswords
                  key={password.id}
                  password={password}
                  userId={userId}
                />
              ))
            ) : (
              <>
                <Alert
                  severity="warning"
                  style={{ marginTop: '15px', marginBottom: '15px' }}
                >
                  You do not have any passwords...
                </Alert>
                <CreatePassword userId={userId} />
              </>
            )
          ) : (
            <>
              <Alert severity="warning" style={{ marginTop: '15px' }}>
                Please enter your master password to access your password
                manager
              </Alert>
              <Grid item>
                <TextField
                  label="Password"
                  placeholder="Enter Password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              {goingIn === true ? (
                <Button
                  style={{ marginTop: '15px' }}
                  variant="outlined"
                  fullWidth
                >
                  Signing In... <DoubleArrowIcon />
                </Button>
              ) : (
                <Button
                  style={{ marginTop: '15px' }}
                  onClick={enterMaster}
                  variant="contained"
                  fullWidth
                >
                  Submit <DoubleArrowIcon />
                </Button>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <Alert severity="warning" style={{ marginTop: '15px' }}>
            Please create a master password to use the password manager
          </Alert>
          <Grid item>
            <TextField
              label="Password"
              placeholder="Enter Password"
              type="text"
              onChange={(e) => setNewMaster(e.target.value)}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
          {creatingMaster === true ? (
            <Button style={{ marginTop: '15px' }} variant="outlined" fullWidth>
              Creating master password... <DoubleArrowIcon />
            </Button>
          ) : (
            <Button
              style={{ marginTop: '15px' }}
              onClick={createMaster}
              variant="contained"
              fullWidth
            >
              Submit <DoubleArrowIcon />
            </Button>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
  password: state.password,
});
export default connect(mapStateToProps, {
  createMasterPassword,
  loginWithMasterPassword,
  myPasswords,
  getMasterPassword,
  decryptMyPassword,
})(PasswordManager);
