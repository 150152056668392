import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  CircularProgress,
  Paper,
  Grid,
  Typography,
  Divider,
  List,
  FormControl,
  TextField,
  IconButton,
  ListItem,
  LinearProgress,
  Button,
} from '@mui/material';
import { connect } from 'react-redux';
import {
  fetchTicket,
  fetchTexts,
  ticketMsg,
  closeTicket,
  reviewTheTicket,
} from '../../../actions/users/ticket';
import { uploadFile } from '../../../actions/storage';
import { Box } from '@mui/system';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import '../User.css';
import { setAlert } from '../../../actions/alert';
import Text from '../Text';
import FileUpload from '../FileUpload';
import MarkComplete from '../MarkComplete';
import moment from 'moment';
import ReviewTicket from './ReviewTicket';

const MyTicket = ({
  fetchTicket,
  ticket: { ticket, texts },
  ticketMsg,
  userId,
  setAlert,
  fetchTexts,
  userName,
  uploadFile,
  closeTicket,
  alert,
  upload: { progress, text_url },
  reviewTheTicket,
}) => {
  const [text, setText] = useState('');
  const [techName, setTechName] = useState(null);
  const [email, setEmail] = useState(null);
  const { ticketId } = useParams();

  let scroll = useRef(null);
  let closeBtn = useRef();

  useEffect(() => {
    if (texts) {
      if (scroll.current) {
        scroll.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [texts]);

  useEffect(() => {
    fetchTicket(ticketId);
  }, [fetchTicket, ticketId]);

  useEffect(() => {
    if (ticket) {
      setEmail(ticket.email);
      if (ticket.assignedTo) {
        setTechName(ticket.assignedTo.name);
      }
    }
  }, [ticket]);

  useEffect(() => {
    const unsub = fetchTexts(ticketId);
    return unsub;
  }, [fetchTexts, ticketId]);

  useEffect(() => {
    if (text_url) {
      let authorId = userId;
      let text = text_url;
      let authorName = userName;
      let createdAt = moment().format();
      ticketMsg({ ticketId, text, authorId, createdAt, authorName });
    }
  }, [text_url, ticketId, ticketMsg, userId, userName]);

  if (ticket === null || texts === null) return <CircularProgress />;
  const { header, isComplete, review } = ticket;

  const onSubmit = (e) => {
    e.preventDefault();
    if (text === '') return setAlert('Cannot send a blank message!', 'warning');
    let authorId = userId;
    let authorName = userName;
    let createdAt = moment().format();
    ticketMsg({ ticketId, text, authorId, createdAt, authorName });
    setText('');
  };
  return (
    <Container>
      <div id="chat-paper">
        <List>
          <ListItem>
            {techName ? (
              <Grid container spacing={3}>
                <Grid xs={6} item>
                  <Button variant="outlined" size="small">
                    Ticket assigned to: {techName}
                  </Button>
                </Grid>
                <Grid xs={6} item>
                  {isComplete === false ? (
                    <MarkComplete
                      closeTicket={closeTicket}
                      ticketId={ticketId}
                      closeBtn={closeBtn}
                      alert={alert}
                      userName={userName}
                      email={email}
                    />
                  ) : (
                    <Button variant="contained" color="success" size="small">
                      Ticket marked as complete
                    </Button>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Button variant="outlined" color="warning" size="small">
                Ticket is being reviewed
              </Button>
            )}
          </ListItem>
          <Divider />

          <ListItem>
            <Typography variant="h5" gutterBottom>
              Header: {header}
            </Typography>
          </ListItem>
        </List>
        <Divider />
      </div>
      <Paper id="chat-paper" elevation={4}>
        <Box p={3} xs={12}>
          <Grid container spacing={4}>
            <Grid id="chat-grid" item>
              <List id="chat-messages">
                {texts.length === 0
                  ? 'No texts'
                  : texts.map((text) => (
                      <Text key={text.id} text={text} userId={userId} />
                    ))}
              </List>
              <ListItem ref={scroll}></ListItem>
            </Grid>
          </Grid>
          {progress && <LinearProgress />}
          {isComplete === false ? (
            <form onSubmit={onSubmit}>
              <Grid container>
                <Grid xs={9} item>
                  <FormControl fullWidth>
                    <TextField
                      label="type your message"
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item>
                  <IconButton xs={1}>
                    <FileUpload uploadFile={uploadFile} />
                  </IconButton>

                  <IconButton xs={1} sx={{ mt: 0.4 }} type="submit">
                    <SendOutlinedIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </form>
          ) : review === null ? (
            <ReviewTicket
              alert={alert}
              ticketId={ticketId}
              reviewTheTicket={reviewTheTicket}
            />
          ) : (
            'Thank you for reviewing the ticket'
          )}
        </Box>
      </Paper>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  ticket: state.ticket,
  userId: state.auth.user.uid,
  userName: state.user.user_profile.name,
  upload: state.upload,
  alert: state.alert,
});

export default connect(mapStateToProps, {
  fetchTicket,
  ticketMsg,
  setAlert,
  fetchTexts,
  closeTicket,
  uploadFile,
  reviewTheTicket,
})(MyTicket);
