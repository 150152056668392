import {
  CLEAR_USER_ADMIN,
  USER_LIST,
  CURRENT_USER_CLIENT,
  ACTIVE_CLIENT_DEVICES,
  DECOMM_CLIENT_DEVICES,
  ADMIN_ACTIVE_TICKETS,
  ADMIN_TICKET_REPORT,
  CLEAR_TICKET_REPORT,
  CUSTOM_TICKETS_ACTIVE,
  CUSTOM_ADMIN_TICKETS,
  CUSTOM_TICKETS_TIME,
  MY_BRANCHES,
  MY_BRANCH_INFO,
  MY_DEPARTMENTS,
  MY_DEPARTMENT_INFO,
} from '../../actions/types';

const initState = {
  active_devices: null,
  decom_devices: null,
  users: null,
  client: null,
  tickets: null,
  custom_tickets: null,
  custom_active: null,
  time_on_tickets: null,
  active_tickets: 0,
  loading: true,
  branches: [],
  branch_info: null,
  departments: [],
  department_info: null,
};

function userAdminReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case ACTIVE_CLIENT_DEVICES:
      return {
        ...state,
        active_devices: payload,
      };
    case DECOMM_CLIENT_DEVICES:
      return {
        ...state,
        decom_devices: payload,
      };
    case USER_LIST:
      return {
        ...state,
        users: payload,
        loading: false,
      };
    case CURRENT_USER_CLIENT:
      return {
        ...state,
        client: payload,
        loading: false,
      };
    case CLEAR_USER_ADMIN:
      return {
        ...state,
        users: null,
        devices: null,
        loading: true,
      };
    case ADMIN_ACTIVE_TICKETS:
      return {
        ...state,
        active_tickets: payload,
      };
    case ADMIN_TICKET_REPORT:
      return {
        ...state,
        tickets: payload,
        loading: false,
      };
    case CUSTOM_ADMIN_TICKETS:
      return {
        ...state,
        custom_tickets: payload,
      };
    case CUSTOM_TICKETS_ACTIVE:
      return {
        ...state,
        custom_active: payload,
      };
    case CUSTOM_TICKETS_TIME:
      return {
        ...state,
        time_on_tickets: payload,
      };

    case CLEAR_TICKET_REPORT:
      return {
        ...state,
        tickets: null,
        active_tickets: 0,
        loading: true,
      };
    case MY_BRANCHES:
      return {
        ...state,
        branches: payload,
      };
    case MY_BRANCH_INFO:
      return {
        ...state,
        branch_info: payload,
      };
    case MY_DEPARTMENTS:
      return {
        ...state,
        departments: payload,
      };
    case MY_DEPARTMENT_INFO:
      return {
        ...state,
        department_info: payload,
      };

    default:
      return state;
  }
}
export default userAdminReducer;
