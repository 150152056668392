import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import {
  fetchClient,
  getOldUsers,
  getBranches,
  getDepartments,
} from '../../../actions/clients/clients';
import UserItem from './UserItem';
import CreateDepartment from './CreateDepartment';
import CreateBranch from './CreateBranch';
import DepartmentItem from './DepartmentItem';
import BranchItem from './BranchItem';
import { tokens } from '../../../theme';
import { ArrowBackIosOutlined } from '@mui/icons-material';

const UserReview = ({
  client: { client, client_users, loading, old_users, departments, branches },
  fetchClient,
  getOldUsers,
  getBranches,
  getDepartments,
  tech,
}) => {
  const { clientId } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let navigate = useNavigate();

  useEffect(() => {
    fetchClient(clientId);
    getOldUsers(clientId);
    getBranches(clientId);
    getDepartments(clientId);
  }, [clientId, fetchClient, getOldUsers, getBranches, getDepartments]);

  if (loading || client === null) {
    return <CircularProgress />;
  }

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Grid container>
        <Grid xs={1} item>
          <Button sx={{ color: colors.blueAccent[400] }} onClick={goBack}>
            <ArrowBackIosOutlined />
          </Button>
        </Grid>
        <Grid xs={8} item>
          <Typography
            variant="h5"
            sx={{ color: colors.grey[500], fontWeight: 'bold' }}
          >
            {client.name} employees
          </Typography>
        </Grid>
      </Grid>

      <Divider variant="middle" sx={{ mt: '10px' }} />
      <Grid container>
        <Grid item xs={7}>
          <Typography
            variant="h6"
            sx={{
              mb: '10px',
              fontWeight: 'bold',
              color: colors.blueAccent[100],
            }}
          >
            Active users
          </Typography>
          {client_users.length === 0 ? (
            <Alert severity="warning" style={{ marginTop: '10px' }}>
              Client has no users...
            </Alert>
          ) : (
            <UserItem users={client_users} />
          )}
        </Grid>
        <Grid item xs={5}>
          <Typography variant="h5" sx={{ color: colors.blueAccent[100] }}>
            Former users
          </Typography>
          {old_users && old_users.length === 0 ? (
            <Alert severity="warning" style={{ marginTop: '10px' }}>
              Client has no users...
            </Alert>
          ) : (
            'Show old users'
          )}
          <Divider variant="middle" sx={{ mt: '10px' }} />

          <Grid container>
            <Grid xs={10} item>
              <Typography variant="h5" sx={{ color: colors.blueAccent[100] }}>
                Departments
              </Typography>
            </Grid>
            <Grid xs={2} item>
              <CreateDepartment clientId={clientId} tech={tech} />
            </Grid>
          </Grid>

          {departments && departments.length === 0 ? (
            <CreateDepartment clientId={clientId} tech={tech} />
          ) : (
            departments.map((department) => (
              <DepartmentItem
                key={department.id}
                department={department}
                clientId={clientId}
              />
            ))
          )}
          <Divider variant="middle" sx={{ mt: '10px' }} />
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="h5" sx={{ color: colors.blueAccent[100] }}>
                Branches
              </Typography>
            </Grid>
            <Grid xs={2} item>
              <CreateBranch clientId={clientId} tech={tech} />
            </Grid>
          </Grid>

          {branches && branches.length === 0 ? (
            <CreateBranch clientId={clientId} tech={tech} />
          ) : (
            branches.map((branch) => (
              <BranchItem key={branch.id} branch={branch} clientId={clientId} />
            ))
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: state.client,
  tech: state.tech.tech_profile,
});

export default connect(mapStateToProps, {
  fetchClient,
  getOldUsers,
  getBranches,
  getDepartments,
})(UserReview);
