import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PersonIcon from '@mui/icons-material/Person';

const AssignToUser = ({ users, assignedToDo, assignThisDude }) => {
  const [open, setOpen] = useState(false);
  const [chosen, setChosen] = useState(null);
  const [userSelected, setUserSelected] = useState(false);

  useEffect(() => {
    if (assignedToDo && assignedToDo.responsible === 'User') {
      setChosen(assignedToDo.id);
      setUserSelected(true);
    } else {
      setChosen(null);
      setUserSelected(false);
    }
  }, [assignedToDo]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const selectUser = ({ user }) => {
    const responsible = {
      responsible: 'User',
      id: user.id,
      name: user.name,
    };

    assignThisDude({ responsible });
    handleClose();
  };
  return (
    <Container>
      {userSelected ? (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleOpen}
          sx={{ color: 'white' }}
          size="small"
        >
          <PersonIcon />
        </Button>
      ) : (
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={handleOpen}
        >
          <PersonIcon />
        </Button>
      )}

      <Dialog open={open} onClose={handleClose} scroll="paper">
        <DialogTitle id="scroll-dialog-title">
          Select user to assign?
        </DialogTitle>
        <Divider variant="middle" />

        <DialogContent>
          {users.length === 0 ? (
            <Typography>No users</Typography>
          ) : (
            users.map((user) => (
              <Grid key={user.id} container spacing={2}>
                <Grid item>
                  {chosen === user.id ? (
                    <RadioButtonCheckedIcon />
                  ) : (
                    <RadioButtonUncheckedIcon
                      onClick={() => selectUser({ user })}
                    />
                  )}
                </Grid>
                <Grid item>{user.name}</Grid>
              </Grid>
            ))
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default AssignToUser;
