import React from 'react';
import { connect } from 'react-redux';
import { verifyEmail } from '../../actions/auth';
import { Box, Button, Typography, Container } from '@mui/material';
import { Navigate } from 'react-router-dom';

const VerifyEmail = ({ user, verifyEmail }) => {
  const verify = () => {
    verifyEmail();
  };

  if (user === null) {
    return <Navigate to="/" />;
  }
  return (
    <Container sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: '60%', textAlign: 'center' }}>
        <Typography>
          We have sent an email to <b>{user.email}</b>
        </Typography>
        <Typography>
          Please click on the link in the email to verify your account
        </Typography>
        <Typography>Check your spam folder just in case </Typography>
        <Typography>If you have not recieved any email please</Typography>
        <Button variant="outlined" onClick={verify}>
          Resend email verification
        </Button>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { verifyEmail })(VerifyEmail);
