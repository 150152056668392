import { PROGRESS, TEXT_URL, CLEAR_UPLOAD } from '../../actions/types';

const initState = {
  text_url: null,
  progress: null,
};

function uploadReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case PROGRESS:
      return {
        ...state,
        progress: payload,
      };
    case TEXT_URL:
      return {
        ...state,
        text_url: payload,
      };
    case CLEAR_UPLOAD:
      return {
        ...state,
        text_url: null,
        progress: null,
      };
    default:
      return state;
  }
}

export default uploadReducer;
