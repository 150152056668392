import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  doc,
  getDoc,
  where,
  getDocs,
  updateDoc,
  setDoc,
} from 'firebase/firestore';
import {
  GET_LEADS,
  GET_LEAD,
  CLEAR_LEADS,
  LEAD_NOTES,
  FOLLOW_UPS,
  DONE_FOLLOW_UPS,
  ADD_NOTE,
  ADD_FOLLOW,
  ADD_COLLAB,
  SCHEDULED_FOLLOWS,
  ADD_CLIENT_TO_LEAD,
  LEAD_FILE_URL,
  GET_LEAD_TEXTS,
  REMOVE_COLLAB,
  GET_CLOSED_LEADS,
  GET_COLLAB_LEADS,
} from '../types';
import { firestore } from '../../firebase-config';
import moment from 'moment';
import { setAlert } from '../alert';

// const backend = process.env.REACT_APP_BACKEND_URL;

//Create Lead//
export const createALead =
  ({
    client,
    createdBy,
    createdAt,
    desc,
    collab,
    lead_notes,
    leadFollowUps,
    type,
  }) =>
  async (dispatch) => {
    const lead = collection(firestore, 'leads');
    const pipeline = true;
    const { name, email } = client;
    let stage = 'Recon';

    try {
      const result = await addDoc(lead, {
        name,
        email,
        client,
        pipeline,
        createdBy,
        createdAt,
        desc,
        collab,
        stage,
        type,
      });

      let leadId = result.id;
      if (lead_notes.length > 0) {
        ////Create the notes//
        lead_notes.forEach((lnote) => {
          const { createdAt, createdBy, note } = lnote;

          dispatch(addLeadNote({ leadId, note, createdAt, createdBy }));
        });
      }

      if (leadFollowUps.length > 0) {
        ///create Followups
        leadFollowUps.forEach((followUp) => {
          const { createdAt, createdBy, date, note } = followUp;
          dispatch(
            createFollowUp({ leadId, note, createdAt, date, createdBy })
          );
        });
      }

      dispatch(setAlert('Lead Created Successfully', 'success'));

      return leadId;
    } catch (error) {
      console.error(error.messagge);
    }
  };

//Notify collaborators//
export const notifyCollaborators = (collab) => async (dispatch) => {
  try {
    //
  } catch (error) {
    console.error(error.message);
  }
};

//Add note to new lead//
export const updateNoteToNewLead = (arr) => async (dispatch) => {
  try {
    dispatch({ type: ADD_NOTE, payload: arr });
  } catch (error) {
    console.error(error.message);
  }
};

//Add Followup to new note//
export const updateFollowToNewNote = (followup) => async (dispatch) => {
  try {
    dispatch({ type: ADD_FOLLOW, payload: followup });
  } catch (error) {
    console.error(error.message);
  }
};

//Collab on lead//
export const updateCollab = (arr) => (dispatch) => {
  try {
    dispatch({ type: ADD_COLLAB, payload: arr });
  } catch (error) {
    console.error(error.message);
  }
};

//Remove from collab//
export const removeFromCollab = (arr) => (dispatch) => {
  try {
    dispatch({ type: REMOVE_COLLAB, payload: arr });
  } catch (error) {
    console.error(error.message);
  }
};

//update lead collaboration//
export const updateLeadCollab =
  ({ arr, leadId }) =>
  async (dispatch) => {
    dispatch(updateCollab([]));
    dispatch(removeFromCollab([]));
    const leadRef = doc(firestore, 'leads', leadId);
    const newFields = { collab: arr };
    await updateDoc(leadRef, newFields);
    dispatch(setAlert('Lead collab updated...', 'success'));
    dispatch(getLead(leadId));
  };

//Add client to lead//
export const updateClientToLead = (client) => (dispatch) => {
  try {
    dispatch({ type: ADD_CLIENT_TO_LEAD, payload: client });
  } catch (error) {
    console.error(error.message);
  }
};

//get my leads//
export const fetchMyLeads = (id) => async (dispatch) => {
  dispatch({ type: CLEAR_LEADS });

  const leadsRef = collection(firestore, 'leads');
  try {
    const q = query(
      leadsRef,
      where('createdBy.id', '==', id),
      orderBy('createdAt')
    );
    onSnapshot(q, (snapshot) => {
      let leads = [];
      snapshot.docs.forEach((doc) => {
        leads.push({ ...doc.data(), id: doc.id });
      });

      const pipeline = leads.filter((lead) => {
        return lead.pipeline === true;
      });

      const closed = leads.filter((lead) => {
        return lead.pipeline === false;
      });

      dispatch({ type: GET_LEADS, payload: pipeline });
      dispatch({ type: GET_CLOSED_LEADS, payload: closed });
    });
  } catch (error) {
    console.error(error.message);
  }
};

//Get coloab leads//
export const getCollabLeads = (techId) => async (dispatch) => {
  const leadsRef = collection(firestore, 'leads');
  try {
    const q = query(leadsRef, orderBy('createdAt'));
    onSnapshot(q, (snapshot) => {
      let leads = [];
      snapshot.docs.forEach((doc) => {
        const data = doc.data();
        if (data.collab && data.collab.some((user) => user.id === techId)) {
          leads.push({ ...data, id: doc.id });
        }
      });
      dispatch({ type: GET_COLLAB_LEADS, payload: leads });
    });
  } catch (error) {
    console.error(error.message);
  }
};

//Get Lead//
export const getLead = (leadId) => async (dispatch) => {
  dispatch({ type: CLEAR_LEADS });
  const leadRef = doc(firestore, 'leads', leadId);
  try {
    const lead = await getDoc(leadRef);
    const theLead = lead.data();
    dispatch({ type: GET_LEAD, payload: theLead });
  } catch (error) {
    console.error(error.message);
  }
};

//Add Lead Notes//
export const addLeadNote =
  ({ leadId, note, createdAt, createdBy }) =>
  async (dispatch) => {
    const noteRef = collection(firestore, 'leadNotes');

    try {
      await addDoc(noteRef, {
        note,
        leadId,
        createdAt,
        createdBy,
      });
      dispatch(setAlert('Note added successfully', 'success'));
      dispatch(getLeadNotes(leadId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Get Lead Notes//
export const getLeadNotes = (leadId) => async (dispatch) => {
  const noteRef = collection(firestore, 'leadNotes');
  const q = query(noteRef, where('leadId', '==', leadId), orderBy('createdAt'));
  try {
    const jam = await getDocs(q);
    const notes = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({ type: LEAD_NOTES, payload: notes });
  } catch (error) {
    console.error(error.message);
  }
};

//Schedule Lead follow up//
export const createFollowUp =
  ({ leadId, note, createdAt, date, createdBy }) =>
  async (dispatch) => {
    const followUpRef = collection(firestore, 'followUps');
    let complete = false;
    try {
      await addDoc(followUpRef, {
        leadId,
        note,
        createdAt,
        date,
        createdBy,
        complete,
      });
      dispatch(setAlert('Follow successfully created', 'success'));
      dispatch(getFollowUps(leadId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Get incomplete Lead Follow ups//
export const getFollowUps = (leadId) => async (dispatch) => {
  const followUpRef = collection(firestore, 'followUps');
  try {
    const q = query(
      followUpRef,
      where('leadId', '==', leadId),
      where('complete', '==', false),
      orderBy('createdAt')
    );
    const jam = await getDocs(q);
    const follows = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({ type: FOLLOW_UPS, payload: follows });
  } catch (error) {
    console.error(error.message);
  }
};

//Complete a folow up//
export const followUpDone =
  ({ leadId, fid }) =>
  async (dispatch) => {
    const followUpRef = doc(firestore, 'followUps', fid);
    try {
      const complete = true;
      const completedAt = moment().format();
      const newFields = { complete, completedAt };
      await updateDoc(followUpRef, newFields);
      dispatch(setAlert('Well done on following up!', 'success'));
      dispatch(getFollowUps(leadId));
      dispatch(getDoneFollowUps(leadId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Get completed follow ups//
export const getDoneFollowUps = (leadId) => async (dispatch) => {
  const followUpRef = collection(firestore, 'followUps');
  try {
    const q = query(
      followUpRef,
      where('leadId', '==', leadId),
      where('complete', '==', true),
      orderBy('createdAt')
    );
    const jam = await getDocs(q);
    const done = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({ type: DONE_FOLLOW_UPS, payload: done });
  } catch (error) {
    console.error(error.message);
  }
};

//Get Scheduled Follow Ups//
export const getScheduledFollowUps = (techId) => async (dispatch) => {
  const followUpRef = collection(firestore, 'followUps');
  try {
    const q = query(
      followUpRef,
      where('complete', '==', false),
      where('createdBy.id', '==', techId),
      orderBy('createdAt')
    );
    const jam = await getDocs(q);
    const followUp = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({ type: SCHEDULED_FOLLOWS, payload: followUp });
  } catch (error) {
    console.error(error.message);
  }
};

//change lead stage//
export const changeLeadStage =
  ({ newStage, leadId }) =>
  async (dispatch) => {
    const leadRef = doc(firestore, 'leads', leadId);
    try {
      const newFields = { stage: newStage };
      await updateDoc(leadRef, newFields);
      dispatch(setAlert('Lead stage updated...', 'success'));
      dispatch(getLead(leadId));
    } catch (error) {
      console.error(error.message);
    }
  };

export const updateLeadFile =
  ({ type, url, leadId }) =>
  async (dispatch) => {
    try {
      const leadRef = doc(firestore, 'leads', leadId);

      if (type === 'lead_quote') {
        //quote
        const newFields = { quote: url };
        await updateDoc(leadRef, newFields);
        dispatch(setAlert('Quote successfully updated...', 'success'));
        dispatch(getLead(leadId));
      } else if (type === 'lead_pitch') {
        //pitch
        const newFields = { pitch: url };
        await updateDoc(leadRef, newFields);
        dispatch(setAlert('Quote successfully updated...', 'success'));
        dispatch(getLead(leadId));
      } else if (type === 'lead_invoice') {
        //invoice
        const newFields = { invoice: url };
        await updateDoc(leadRef, newFields);
        dispatch(setAlert('Quote successfully updated...', 'success'));
        dispatch(getLead(leadId));
      }

      dispatch({ type: LEAD_FILE_URL, payload: null });
    } catch (error) {
      console.error(error.message);
    }
  };

//Fetch Lead text//
export const fetchLeadText = (leadId) => async (dispatch) => {
  const textsRef = collection(firestore, 'leadText');

  const q = query(
    textsRef,
    where('leadId', '==', leadId),
    orderBy('createdAt')
  );

  const unsub = onSnapshot(q, (snapshot) => {
    let texts = [];
    snapshot.docs.forEach((doc) => {
      texts.push({ ...doc.data(), id: doc.id });
    });
    dispatch({ type: GET_LEAD_TEXTS, payload: texts });
  });
  return unsub;
};

//Send lead text//
export const sendLeadText =
  ({ authorId, authorName, createdAt, text, leadId }) =>
  async (dispatch) => {
    const textRef = collection(firestore, 'leadText');
    try {
      await addDoc(textRef, { authorId, authorName, createdAt, text, leadId });
    } catch (error) {
      console.error(error.message);
    }
  };
//Lead to client//
export const leadToClient =
  ({ leadId, name, email, number, address, createdAt }) =>
  async (dispatch) => {
    const leadRef = doc(firestore, 'leads', leadId);
    const clientRef = collection(firestore, 'clients');

    try {
      const pipeline = false;
      const newFields = { pipeline };
      await updateDoc(leadRef, newFields);
      await setDoc(doc(clientRef, leadId), {
        name,
        email,
        number,
        address,
        createdAt,
      });
      dispatch(setAlert('Conrgadulations on getting a new client', 'success'));
      dispatch(getLead(leadId));
    } catch (error) {
      console.error(error.message);
    }
  };
