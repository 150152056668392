import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, Link } from 'react-router-dom';
import {
  Card,
  Grid,
  CardContent,
  Container,
  TextField,
  Button,
  CardActions,
  Box,
  Modal,
  Alert,
} from '@mui/material';
import { signIn, resetMyPassword } from '../../actions/auth';
import LoadingButton from '@mui/lab/LoadingButton';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import './Business.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const BusinessSignin = ({ isBusiness, signIn, alert }) => {
  const [logginIn, setLogging] = useState(false);
  useEffect(() => {
    if (alert.length > 0) {
      setLogging(false);
    }
  }, [alert]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetting, setResetting] = useState(false);
  const [resetMe, setResetMe] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setLogging(true);
    signIn({ email, password });
  };

  const passwordReset = () => {
    let email = resetMe;
    resetMyPassword(email);
    setResetting(true);
  };

  if (isBusiness) {
    return <Navigate to="/business/business-landing" />;
  }
  return (
    <Container>
      <Grid container spacing={2} id="sign-in-container">
        <Grid id="sign-in">
          <h2>Sign into your account</h2>
          <Card>
            <form onSubmit={onSubmit}>
              <CardContent>
                <Grid item>
                  <TextField
                    label="Tech Email"
                    type="email"
                    placeholder="Tech Email"
                    variant="outlined"
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Password"
                    placeholder="Enter Password"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
              </CardContent>
              <CardActions>
                {logginIn ? (
                  <LoadingButton loading variant="contained" fullWidth>
                    Logging In
                  </LoadingButton>
                ) : (
                  <Button type="submit" variant="outlined" fullWidth>
                    Logon <DoubleArrowIcon />
                  </Button>
                )}
              </CardActions>
            </form>
          </Card>
          <Button fullWidth onClick={handleOpen}>
            Reset Password
          </Button>

          <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
              <Alert severity="warning">Reset your password</Alert>
              <TextField
                sx={{ marginBottom: '10px', marginTop: '10px' }}
                variant="outlined"
                label="Enter your email here..."
                placeholder="Enter your email here..."
                onChange={(e) => setResetMe(e.target.value)}
                fullWidth
                required
              />
              {resetting ? (
                <LoadingButton loading variant="contained" fullWidth>
                  Sending password reset link...
                </LoadingButton>
              ) : (
                <Button fullWidth variant="outlined" onClick={passwordReset}>
                  Send password reset link
                </Button>
              )}
            </Box>
          </Modal>

          <Box
            m={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            as={Link}
            to="/business/sign-up"
          >
            <Button
              variant="outlined"
              color="primary"
              sx={{ height: 40, marginTop: '10px' }}
            >
              Signup your business
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
  isBusiness: state.business.isBusiness,
});

export default connect(mapStateToProps, { signIn, resetMyPassword })(
  BusinessSignin
);
