import {
  ACTIVE_CLIENT_DEVICES,
  DECOMM_CLIENT_DEVICES,
  USER_LIST,
  CURRENT_USER_CLIENT,
  ADMIN_TICKET_REPORT,
  ADMIN_ACTIVE_TICKETS,
  CLEAR_TICKET_REPORT,
  CUSTOM_ADMIN_TICKETS,
  CUSTOM_TICKETS_ACTIVE,
  CUSTOM_TICKETS_TIME,
  MY_BRANCHES,
  MY_BRANCH_INFO,
  MY_DEPARTMENTS,
  MY_DEPARTMENT_INFO,
} from '../types';
import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
  addDoc,
} from 'firebase/firestore';
import { firestore } from '../../firebase-config';
import { setAlert } from '../alert';
import axios from 'axios';
import moment from 'moment';
const backend = process.env.REACT_APP_BACKEND_URL;

//Create a new user//
export const createAnewUser =
  ({
    name,
    surname,
    position,
    email,
    cellphone,
    password,
    clientId,
    createdAt,
    clientName,
  }) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const body = JSON.stringify({
        name,
        surname,
        position,
        email,
        cellphone,
        password,
        clientId,
        createdAt,
        clientName,
      });
      await axios.post(
        `${backend}/api/firebase-admin/client-create-user`,
        body,
        config
      );
      dispatch(setAlert('User Successfully Added', 'success'));
      dispatch(getClientUsers(clientId));
      return 'success';
    } catch (error) {
      console.error(error.message);
    }
  };

//Gety client devices//
export const getClientDevices = (clientId) => async (dispatch) => {
  try {
    const q = query(
      collection(firestore, 'device'),
      where('clientId', '==', clientId),
      orderBy('createdAt')
    );

    const jam = await getDocs(q);
    let decom = [];
    let active = [];

    const devices = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    function whosWho(decom, active, devices) {
      devices.forEach((device) => {
        let compare = device.decommission;
        if (compare) {
          decom.push(device);
        } else {
          active.push(device);
        }
      });
    }

    whosWho(decom, active, devices);

    dispatch({ type: ACTIVE_CLIENT_DEVICES, payload: active });
    dispatch({ type: DECOMM_CLIENT_DEVICES, payload: decom });
  } catch (error) {
    console.error(error.message);
  }
};

//Get client users//
export const getClientUsers = (clientId) => async (dispatch) => {
  try {
    const q = query(
      collection(firestore, 'users'),
      where('clientId', '==', clientId),
      orderBy('createdAt')
    );
    const jam = await getDocs(q);

    let clientUsers = jam.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    dispatch({ type: USER_LIST, payload: clientUsers });
  } catch (error) {
    console.error(error.message);
  }
};

//Get current user client//
export const currentUserClient = (clientId) => async (dispatch) => {
  const clientRef = doc(firestore, 'clients', clientId);
  try {
    const a = await getDoc(clientRef);
    const client = a.data();
    dispatch({ type: CURRENT_USER_CLIENT, payload: client });
  } catch (error) {
    console.error(error.message);
  }
};

//Fetch client tickets//
export const adminTicketReport = (clientId) => async (dispatch) => {
  dispatch({ type: CLEAR_TICKET_REPORT });
  const firstday = moment().date(1).format();
  try {
    const ticketsRef = collection(firestore, 'tickets');
    const q = query(
      ticketsRef,
      where('createdAt', '>=', firstday),
      where('clientId', '==', clientId),
      orderBy('createdAt')
    );

    onSnapshot(q, (snapshot) => {
      let tickets = [];
      snapshot.docs.forEach((doc) => {
        tickets.push({ ...doc.data(), id: doc.id });
      });

      const num = tickets.filter((ticket) => {
        return ticket.isComplete === false;
      });
      dispatch({ type: ADMIN_ACTIVE_TICKETS, payload: num.length });
      dispatch({ type: ADMIN_TICKET_REPORT, payload: tickets });
    });
  } catch (error) {
    console.error(error.message);
  }
};

//Fetch custom ticket range//
export const customTicketReport =
  ({ clientId, first, last }) =>
  async (dispatch) => {
    try {
      const ticketRef = collection(firestore, 'tickets');
      const q = query(
        ticketRef,
        where('createdAt', '>=', first),
        where('clientId', '==', clientId),
        orderBy('createdAt')
      );

      onSnapshot(q, (snapshot) => {
        let tickets = [];
        snapshot.docs.forEach((doc) => {
          tickets.push({ ...doc.data(), id: doc.id });
        });

        const active = tickets.filter((ticket) => {
          return ticket.isComplete === false;
        });
        const loot = tickets.filter((ticket) => {
          return ticket.completedAt <= last;
        });

        //Calculate time spent on tickets//
        const hours = [];
        const minutes = [];
        loot.forEach((ticket) => {
          function diff_minutes(d1, d2) {
            var diff = (d2.getTime() - d1.getTime()) / 1000;
            diff /= 60;

            if (diff < 60) {
              let time = Math.round(diff);
              minutes.push(time);
            } else if (diff > 60) {
              diff /= 60;
              let time = Math.round(diff);
              hours.push(time);
            }
          }

          let d1 = new Date(ticket.createdAt);
          let d2 = new Date(ticket.completedAt);

          diff_minutes(d1, d2);
        });

        const sumh = hours.reduce((index, value) => {
          return index + value;
        }, 0);

        const summ = minutes.reduce((index, value) => {
          return index + value;
        }, 0);

        const time = sumh + ':' + summ;

        dispatch({ type: CUSTOM_TICKETS_TIME, payload: time });
        dispatch({ type: CUSTOM_ADMIN_TICKETS, payload: loot });
        dispatch({ type: CUSTOM_TICKETS_ACTIVE, payload: active.length });
      });
    } catch (error) {
      console.error('Error fetching custom ticket range', error.message);
    }
  };

//Create client branch//
export const createBranch =
  ({ name, clientId, createdBy }) =>
  async (dispatch) => {
    const branchRef = collection(firestore, 'branch');
    const createdAt = moment().format();
    const users = [];
    try {
      await addDoc(branchRef, {
        name,
        clientId,
        createdAt,
        users,
        createdBy,
      });
      dispatch(setAlert('Branch successfully created...', 'success'));
      dispatch(getBranches(clientId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Get client branches//
export const getBranches = (clientId) => async (dispatch) => {
  const branchRef = collection(firestore, 'branch');
  const q = query(branchRef, where('clientId', '==', clientId));

  onSnapshot(q, (snapshot) => {
    let branches = [];
    snapshot.docs.forEach((doc) => {
      branches.push({ ...doc.data(), id: doc.id });
    });
    //dispatch loot
    dispatch({ type: MY_BRANCHES, payload: branches });
  });
  try {
  } catch (error) {
    console.error(error.message);
  }
};

//Get a branch//
export const getBranch = (branchId) => async (dispatch) => {
  const branchRef = doc(firestore, 'branch', branchId);
  try {
    const branchDoc = await getDoc(branchRef);
    const branch = branchDoc.data();
    dispatch({ type: MY_BRANCH_INFO, payload: branch });
    let clientId = branch.clientId;

    dispatch(currentUserClient(clientId));
  } catch (error) {
    console.error(error.message);
  }
};

//Add users to branch//
export const addToBranch =
  ({ branchId, newUsers }) =>
  async (dispatch) => {
    const branchRef = doc(firestore, 'branch', branchId);
    const branchDoc = await getDoc(branchRef);
    const branch = branchDoc.data();

    try {
      let users = branch.users;
      let newArr = users.concat(newUsers);
      const newFields = { users: newArr };
      await updateDoc(branchRef, newFields);

      dispatch(getBranch(branchId));
      dispatch(setAlert('Users added successfully', 'success'));
    } catch (error) {
      console.error(error.message);
      dispatch(setAlert('Something went wrong', 'error'));
    }
  };

//Remove users from branch//
export const removeFromBranch =
  ({ branchId, removeFromArr }) =>
  async (dispatch) => {
    const branchRef = doc(firestore, 'branch', branchId);
    const branchDoc = await getDoc(branchRef);
    const branch = branchDoc.data();
    const a = branch.users.length - removeFromArr.length;

    try {
      removeFromArr.forEach((id) => {
        let currentUsers = branch.users;

        async function removeFunc(arr) {
          let userArr = arr;

          arr.forEach((user, index) => {
            let userId = user.userId;
            if (userId === id) {
              userArr.splice(index, 1);
            }
          });

          if (userArr.length === a) {
            const newFields = { users: userArr };
            await updateDoc(branchRef, newFields);
            dispatch(getBranch(branchId));
            dispatch(setAlert('Branch successfully edited', 'success'));
          }
        }

        removeFunc(currentUsers);
      });
    } catch (error) {
      console.error(error.message);
    }
  };

//Create client department//
export const createDepartment =
  ({ name, clientId, createdBy }) =>
  async (dispatch) => {
    const depRef = collection(firestore, 'departments');
    const createdAt = moment().format();
    const users = [];
    try {
      await addDoc(depRef, {
        name,
        clientId,
        createdAt,
        users,
        createdBy,
      });
      dispatch(setAlert('Department successfully created...', 'success'));
      dispatch(getDepartments(clientId));
    } catch (error) {
      console.error(error.message);
    }
  };

//Get client departments//
export const getDepartments = (clientId) => async (dispatch) => {
  const depRef = collection(firestore, 'departments');
  const q = query(depRef, where('clientId', '==', clientId));

  try {
    onSnapshot(q, (snapshot) => {
      let departments = [];
      snapshot.docs.forEach((doc) => {
        departments.push({ ...doc.data(), id: doc.id });
      });

      //dispatch the loot
      dispatch({ type: MY_DEPARTMENTS, payload: departments });
    });
  } catch (error) {
    console.error(error.mesasage);
  }
};

//Get department//
export const getDepartment = (departmentId) => async (dispatch) => {
  const depRef = doc(firestore, 'departments', departmentId);
  try {
    const depDoc = await getDoc(depRef);
    const department = depDoc.data();
    dispatch({ type: MY_DEPARTMENT_INFO, payload: department });
    let clientId = department.clientId;

    dispatch(currentUserClient(clientId));
  } catch (error) {
    console.error(error.message);
  }
};

//Add users to department//
export const addToDepartment =
  ({ departmentId, newUsers }) =>
  async (dispatch) => {
    const depRef = doc(firestore, 'departments', departmentId);
    const depDoc = await getDoc(depRef);
    const dep = depDoc.data();

    try {
      const users = dep.users;
      const newArr = users.concat(newUsers);
      const newFields = { users: newArr };
      await updateDoc(depRef, newFields);
      dispatch(getDepartment(departmentId));
      dispatch(setAlert('Users added successfully', 'success'));
    } catch (error) {
      console.error(error.message);
      dispatch(setAlert('Something went wrong', 'error'));
    }
  };

//Remove users from department//
export const removeFromDepartment =
  ({ departmentId, removeFromArr }) =>
  async (dispatch) => {
    const depRef = doc(firestore, 'departments', departmentId);
    const depDoc = await getDoc(depRef);
    const dep = depDoc.data();
    const a = dep.users.length - removeFromArr.length;
    try {
      removeFromArr.forEach((id) => {
        let currentUsers = dep.users;

        async function removeFunc(arr) {
          let userArr = arr;

          arr.forEach((user, index) => {
            let userId = user.userId;
            if (userId === id) {
              userArr.splice(index, 1);
            }
          });

          if (userArr.length === a) {
            const newFields = { users: userArr };
            await updateDoc(depRef, newFields);
            dispatch(getDepartment(departmentId));
            dispatch(setAlert('Department successfully edited', 'success'));
          }
        }

        removeFunc(currentUsers);
      });
    } catch (error) {
      console.error(error.mesasage);
    }
  };
