import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  fetchTickets,
  submitTicket,
  ticketsThatNeedReview,
} from '../../../actions/users/ticket';
import {
  Alert,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import MyTickets from './MyTickets';
import CreateTicket from './CreateTicket';
const UserHelpdesk = ({
  fetchTickets,
  submitTicket,
  ticketsThatNeedReview,
  ticket: { tickets, needs_review },
  alert,
  profile,
}) => {
  const [userId, setUserId] = useState(null);
  const [usr, setUser] = useState(null);
  useEffect(() => {
    if (profile) {
      setUser(profile);
      setUserId(profile.credentials);
    } else {
      setUserId(null);
    }
  }, [profile, setUserId]);
  useEffect(() => {
    if (userId) {
      fetchTickets(userId);
      ticketsThatNeedReview(userId);
    }
  }, [userId, fetchTickets, ticketsThatNeedReview]);

  if (usr === null || tickets === null || needs_review === null)
    return <CircularProgress />;

  return (
    <Container>
      <Grid container>
        <Grid xs={8} item>
          <Typography variant="h5">Helpdesk</Typography>
          <Typography variant="subtitle1">Hello {usr.name}</Typography>
        </Grid>
        <Grid xs={4} item>
          <CreateTicket
            submitTicket={submitTicket}
            user_profile={profile}
            userId={userId}
            alert={alert}
          />
        </Grid>
      </Grid>

      <Divider
        variant="middle"
        sx={{ marginBottom: '5px', marginTop: '5px' }}
      />
      <Grid container>
        <Grid xs={6} item>
          <Typography fontWeight="bold" mb="10px">
            Active tickets
          </Typography>
          {tickets.length === 0 ? (
            <Alert>There are no active tickets</Alert>
          ) : (
            tickets.map((ticket) => (
              <MyTickets key={ticket.id} ticket={ticket} />
            ))
          )}
        </Grid>
        <Grid xs={6} item>
          <Typography fontWeight="bold">Ticket review</Typography>
          {needs_review.length === 0 ? (
            <Alert>There are tickets that need review</Alert>
          ) : (
            needs_review.map((ticket) => (
              <MyTickets key={ticket.id} ticket={ticket} />
            ))
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  profile: state.user.user_profile,
  ticket: state.ticket,
  alert: state.alert,
});

export default connect(mapStateToProps, {
  fetchTickets,
  submitTicket,
  ticketsThatNeedReview,
})(UserHelpdesk);
