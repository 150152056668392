import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Grid,
  Box,
  Alert,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material';
import { DomainAddOutlined, Home } from '@mui/icons-material';
import { getClients } from '../../../actions/clients/clients';
import './Clients.css';
import ClientItem from './ClientItem';
import { tokens } from '../../../theme';

const Clients = ({ getClients, clients }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  useEffect(() => {
    getClients();
  }, [getClients]);

  if (clients === null) {
    return <CircularProgress />;
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid xs={8} item>
          <Typography sx={{ ml: '20px' }} variant="h4">
            Clients
          </Typography>
        </Grid>
        <Grid xs={2} item>
          <Link to="/tech/home-users" style={{ textDecoration: 'none' }}>
            <Button
              variant="outlined"
              sx={{ color: colors.grey[200], borderColor: colors.grey[200] }}
            >
              <Home />
            </Button>
          </Link>
        </Grid>
        <Grid item xs={2}>
          <Link to="/tech/add-client" style={{ textDecoration: 'none' }}>
            <Button
              variant="outlined"
              sx={{ color: colors.grey[200], borderColor: colors.grey[200] }}
            >
              <DomainAddOutlined />
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {clients.length === 0 ? (
            <Alert
              icon={false}
              severity="warning"
              style={{ marginTop: '10px' }}
            >
              No Clients
            </Alert>
          ) : (
            <ClientItem clients={clients} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  clients: state.client.clients,
});

export default connect(mapStateToProps, { getClients })(Clients);
