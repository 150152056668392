import {
  MY_MASTER_PASSWORD,
  MY_PASSWORDS,
  CLEAR_PASSWORDS,
  VIEW_PASSWORDS,
  DECRYPTED_PASSWORD,
  CLEAR_DECRYPTED,
} from '../../actions/types';

const initState = {
  myMP: null,
  savedPasswords: null,
  loading: true,
  viewPasswords: false,
  decryptedPassword: null,
};

function passwordReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_PASSWORDS:
      return {
        ...state,
        myMP: null,
        savedPasswords: null,
        loading: true,
      };

    case MY_MASTER_PASSWORD:
      return {
        ...state,
        myMP: payload,
        loading: false,
      };

    case VIEW_PASSWORDS:
      return {
        ...state,
        viewPasswords: payload,
        loading: false,
      };

    case MY_PASSWORDS:
      return {
        ...state,
        savedPasswords: payload,
        loading: false,
      };

    case DECRYPTED_PASSWORD:
      return {
        ...state,
        decryptedPassword: payload,
      };
    case CLEAR_DECRYPTED:
      return {
        ...state,
        decryptedPassword: null,
      };

    default:
      return state;
  }
}

export default passwordReducer;
