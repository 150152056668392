import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Grid,
  Alert,
  AlertTitle,
  Card,
  CardContent,
  TextField,
  CardActions,
  Button,
} from '@mui/material';
import { createClient } from '../../../actions/clients/clients';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import moment from 'moment';

const AddClient = ({ createClient }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    address: '',
  });

  const { name, email, number, address } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = (e) => {
    e.preventDefault();
    let createdAt = moment().format();
    createClient({ name, email, address, number, createdAt });
    setFormData({
      name: '',
      email: '',
      number: '',
      address: '',
    });
  };
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} id="tech-sign-in">
          <Alert icon={false} variant="filled" severity="success">
            <AlertTitle>Add A Client:</AlertTitle>
          </Alert>
          <Card>
            <form onSubmit={onSubmit}>
              <CardContent id="form">
                <Grid item>
                  <TextField
                    label="Client Name"
                    variant="outlined"
                    type="text"
                    name="name"
                    value={name}
                    onChange={onChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Client Email"
                    variant="outlined"
                    type="email"
                    name="email"
                    value={email}
                    onChange={onChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Office Number"
                    variant="outlined"
                    type="number"
                    name="number"
                    value={number}
                    onChange={onChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Office Address"
                    variant="outlined"
                    type="text"
                    name="address"
                    value={address}
                    onChange={onChange}
                    fullWidth
                    required
                  />
                </Grid>
              </CardContent>
              <CardActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  fullWidth
                >
                  Create Client <DoubleArrowIcon />
                </Button>
              </CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default connect(null, { createClient })(AddClient);
