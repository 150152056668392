import {
  Alert,
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  Modal,
} from '@mui/material';
import { useState, useEffect } from 'react';

const ReviewTicket = ({ ticketId, reviewTheTicket, alert }) => {
  useEffect(() => {
    if (alert.length > 0) {
      handleClose();
      setSubmitting(false);
      handleClose();
    }
  }, [alert]);
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [formError, setFormError] = useState(null);
  const [text, setText] = useState('');
  //   const [rating, setRating] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (text.length <= 3) {
      setFormError('Please tell us about your experience');
      setTimeout(() => setFormError(null), 4000);
    } else {
      setSubmitting(true);
      reviewTheTicket({ ticketId, text });
    }
  };

  return (
    <div>
      <Button variant="contained" onClick={handleOpen} fullWidth>
        Review Ticket
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Alert>How would you rate your experience</Alert>
          {formError && (
            <Button variant="contained" color="error" id="error-btn">
              {formError}
            </Button>
          )}
          <form onSubmit={onSubmit}>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="desc">Comment</InputLabel>
              <Input
                multiline={true}
                minRows={2}
                value={text}
                onChange={(e) => setText(e.target.value)}
                name="text"
              />
            </FormControl>
            {submitting === true ? (
              <Button variant="contained" disabled>
                Submiting...
              </Button>
            ) : (
              <Button variant="contained" type="submit">
                Submit
              </Button>
            )}
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default ReviewTicket;
