import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { updatePassword } from '../../../actions/users/passwords';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import random from 'random-string';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';

const EditPassword = ({ password, updatePassword, userId, alert }) => {
  const [open, setOpen] = useState(false);
  const [newPwd, setNewPwd] = useState('');
  const [formError, setFormError] = useState(null);
  const [editing, setEditing] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setNewPwd('');
    setOpen(false);
  };

  useEffect(() => {
    if (alert.length > 0 && editing) {
      setEditing(false);
      setNewPwd('');
      setOpen(false);
    }
  }, [alert, editing]);

  const genPass = () => {
    const password = random({
      length: 10,
      special: true,
    });
    setNewPwd(password);
  };

  const updatePass = () => {
    //Make sure password is not blank//
    if (newPwd.length === 0) {
      setFormError('Please enter a password');
      return setTimeout(() => {
        setFormError(null);
      }, 3000);
    }
    let pwdId = password.id;
    setEditing(true);
    updatePassword({ pwdId, newPwd, userId });
  };

  return (
    <div>
      <IconButton
        sx={{ color: 'orange' }}
        edge="end"
        aria-label="delete"
        onClick={handleOpen}
      >
        <ModeEditOutlineRoundedIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update password for: {password.name} </DialogTitle>
        <DialogContent dividers={true}>
          {formError && <Alert severity="error">{formError}</Alert>}
          <DialogContentText>
            All passwords are encrypted and none of our staff have access to
            them, the only person who can see and decrypt the passwords you
            create is you.
          </DialogContentText>

          <Grid container>
            <Grid item xs={8}>
              <TextField
                margin="dense"
                label="Please enter or genrate a password"
                type="text"
                fullWidth
                variant="standard"
                value={newPwd}
                onChange={(e) => setNewPwd(e.target.value)}
                name="newPwd"
                required
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                size="sm"
                color="secondary"
                onClick={genPass}
                style={{ marginTop: '10px' }}
              >
                Generate Password
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
          {editing === true ? (
            <Button variant="outlined">Updating password...</Button>
          ) : (
            <Button variant="outlined" color="success" onClick={updatePass}>
              Update password
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default connect(mapStateToProps, { updatePassword })(EditPassword);
