import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchTodos } from '../../../actions/users/todo';
import { currentUserClient } from '../../../actions/users/admin';
import TodoItem from './TodoItem';
import { AddBoxOutlined } from '@mui/icons-material';
import { tokens } from '../../../theme';

const CompanyTodos = ({
  todo: { loading, myTodos },
  client,
  currentUserClient,
  fetchTodos,
}) => {
  let { clientId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  useEffect(() => {
    currentUserClient(clientId);
    fetchTodos(clientId);
  }, [clientId, currentUserClient, fetchTodos]);

  if (loading || client === null) {
    return <CircularProgress />;
  }

  const navToAddTodo = () => {
    navigate(`/user/create-todo/${clientId}`);
  };

  return (
    <Container>
      <Grid container>
        <Grid xs={10} item>
          <Typography variant="h5">{client.name} TODO's</Typography>
        </Grid>
        <Grid item xs={2}>
          <Button
            size="small"
            sx={{ color: colors.grey[200] }}
            onClick={navToAddTodo}
          >
            <AddBoxOutlined />
          </Button>
        </Grid>
      </Grid>
      <Divider sx={{ marginBottom: '5px', marginTop: '5px' }} />
      <Grid container>
        <Grid xs={6} item>
          <Typography variant="h6">Your TODO's</Typography>
          {myTodos.length === 0
            ? 'You have no todos'
            : myTodos.map((todo) => <TodoItem key={todo.id} todo={todo} />)}
        </Grid>
        <Grid xs={6} item>
          <Typography variant="6">TODO overview</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  todo: state.client_todo,
  client: state.user_admin.client,
});

export default connect(mapStateToProps, { currentUserClient, fetchTodos })(
  CompanyTodos
);
