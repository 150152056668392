import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getScheduledFollowUps,
  fetchMyLeads,
  getCollabLeads,
} from '../../../actions/techs/sales';
import LeadItem from './LeadItem';
import FollowUpInfo from './FollowUpInfo';
import { tokens } from '../../../theme';
import { useNavigate } from 'react-router-dom';

const Pipeline = ({
  tech,
  getLeads,
  leads: { leads, scheduled, collab_leads },
  getScheduledFollowUps,
  fetchMyLeads,
  getCollabLeads,
}) => {
  let navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [leadArray, setLeadArray] = useState([]);

  useEffect(() => {
    const techId = tech.id;
    getScheduledFollowUps(techId);
    fetchMyLeads(techId);
    getCollabLeads(techId);
  }, [getLeads, getScheduledFollowUps, tech, fetchMyLeads, getCollabLeads]);

  useEffect(() => {
    if (leads && collab_leads) {
      setLeadArray(leads.concat(collab_leads));
    }
  }, [setLeadArray, leads, collab_leads]);

  const goToAddLead = () => {
    navigate('/tech/add-tech-lead');
  };

  const goToClosedLeads = () => {
    navigate('/tech/closed-leads');
  };

  return (
    <Container>
      <Grid container>
        <Grid xs={9} item>
          <Typography variant="h4" sx={{ ml: '20px' }}>
            Sales
          </Typography>
        </Grid>
        <Grid xs={3} item>
          <Button
            variant="outlined"
            onClick={goToAddLead}
            sx={{
              color: colors.grey[500],
              borderColor: colors.grey[500],
              mr: '5px',
            }}
          >
            Add Lead
          </Button>

          <Button variant="outlined" color="success" onClick={goToClosedLeads}>
            Closed Leads
          </Button>
        </Grid>
      </Grid>
      <Divider variant="middle" sx={{ mb: '10px', mt: '10px' }} />
      <Grid container spacing={2}>
        <Grid md={8} xs={12} item>
          <Typography
            sx={{ color: colors.grey[500], fontWeight: 'bold', ml: '10px' }}
          >
            Current pipeline
          </Typography>

          <Divider variant="middle" sx={{ mb: '10px', mt: '10px' }} />
          {leadArray === null ? (
            <CircularProgress />
          ) : leadArray.length === 0 ? (
            <Alert severity="warning">You have no leads</Alert>
          ) : (
            <LeadItem leads={leadArray} />
          )}
        </Grid>

        <Grid item md={3} xs={12}>
          <Typography sx={{ color: colors.grey[500], fontWeight: 'bold' }}>
            Scheduled Follow Ups
          </Typography>
          <Divider variant="middle" sx={{ mb: '10px', mt: '10px' }} />
          {scheduled == null ? (
            <CircularProgress />
          ) : scheduled.length === 0 ? (
            <Alert>You have no scheduled follow ups</Alert>
          ) : (
            scheduled.map((sched) => (
              <FollowUpInfo
                key={sched.id}
                fl={sched}
                toLead={true}
                leadId={sched.leadId}
              />
            ))
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  tech: state.tech.tech_profile,
  leads: state.leads,
});

export default connect(mapStateToProps, {
  getScheduledFollowUps,
  fetchMyLeads,
  getCollabLeads,
})(Pipeline);
