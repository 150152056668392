import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  MenuItem,
  Menu,
  Button,
  useTheme,
  Grid,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from 'react-router-dom';
import { logOut } from '../../actions/auth';
import {
  Fingerprint,
  ExitToApp,
  LightModeOutlined,
  DarkModeOutlined,
  SettingsOutlined,
  Notifications,
  LogoutOutlined,
} from '@mui/icons-material';
import { ColorModeContext } from '../../theme';
import logobp from '../../images/logobp.png';

const MenuAppBar = ({
  tech: { isTech },
  user: { isUser },
  business: { isBusiness, business_profile, agent_profile },
  logOut,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goodbye = async () => {
    await logOut();
    navigate('/');
  };

  const logout = async () => {
    await logOut();
    navigate('/');
  };

  const busOut = async () => {
    await logOut();
    navigate('/');
  };

  const goHome = () => {
    navigate('/');
  };
  return (
    <Box sx={{ flexGrow: 1 }} style={{ marginBottom: '20px' }}>
      <AppBar
        position="static"
        style={{ background: 'transparent', boxShadow: 'none' }}
      >
        <Toolbar>
          {isTech || isUser ? (
            ''
          ) : (
            <Box
              display="flex"
              mt="25px"
              ml="5px"
              justifyContent="center"
              alignItems="center"
            >
              {window.location.pathname !== '/' && (
                <img
                  alt="callitservices"
                  width="220px"
                  src={logobp}
                  onClick={goHome}
                />
              )}
            </Box>
          )}
          {/* Mobile menu */}
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenu}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            {isTech ? (
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => navigate('/tech/tech-clients')}>
                  Clients
                </MenuItem>
                <MenuItem onClick={() => navigate('/tech/tech-admin')}>
                  Team
                </MenuItem>
                <MenuItem onClick={goodbye}>Logout</MenuItem>
              </Menu>
            ) : isUser ? (
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={logout}>Log out</MenuItem>
              </Menu>
            ) : isBusiness ? (
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={busOut}>Log out</MenuItem>
              </Menu>
            ) : (
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => navigate('/tech/log-in')}>
                  <Fingerprint />
                </MenuItem>
              </Menu>
            )}
          </Box>
          {/* Desktop Menu */}
          <Grid container display="flex" justifyContent="flex-end" p={2}>
            {isTech ? (
              <Box>
                <IconButton>
                  <Notifications />
                </IconButton>
                <IconButton onClick={colorMode.toggleColorMode}>
                  {theme.palette.mode === 'dark' ? (
                    <LightModeOutlined />
                  ) : (
                    <DarkModeOutlined />
                  )}
                </IconButton>
                <IconButton>
                  <SettingsOutlined />
                </IconButton>
                <IconButton onClick={goodbye}>
                  <LogoutOutlined />
                </IconButton>
              </Box>
            ) : isUser ? (
              <Box>
                <IconButton>
                  <Notifications />
                </IconButton>
                <IconButton onClick={colorMode.toggleColorMode}>
                  {theme.palette.mode === 'dark' ? (
                    <LightModeOutlined />
                  ) : (
                    <DarkModeOutlined />
                  )}
                </IconButton>
                <IconButton>
                  <SettingsOutlined />
                </IconButton>
                <IconButton onClick={logout}>
                  <LogoutOutlined />
                </IconButton>
              </Box>
            ) : isBusiness ? (
              <>
                {business_profile ? (
                  <>
                    {' '}
                    <Typography
                      as={Link}
                      to="/business/business-agents"
                      sx={{ minWidth: 70, mr: 2, mt: 1 }}
                      style={{ color: 'black', textDecoration: 'none' }}
                    >
                      Support Agents
                    </Typography>
                    <Typography
                      as={Link}
                      to="/business/business-landing"
                      sx={{ minWidth: 70, mr: 2, mt: 1 }}
                      style={{ color: 'black', textDecoration: 'none' }}
                    >
                      Welcome {business_profile.name}
                    </Typography>
                    <Button variant="outlined" color="error" onClick={busOut}>
                      <ExitToApp />
                    </Button>
                  </>
                ) : (
                  <>
                    <Typography
                      as={Link}
                      to="/business/business-landing"
                      sx={{ minWidth: 70, mr: 2, mt: 1 }}
                      style={{ color: 'black', textDecoration: 'none' }}
                    >
                      Welcome {agent_profile.name}
                    </Typography>
                    <Button variant="outlined" color="error" onClick={busOut}>
                      <ExitToApp />
                    </Button>
                  </>
                )}
              </>
            ) : (
              <>
                {window.location.pathname === '/' && (
                  <Typography
                    as={Link}
                    to="/tech/log-in"
                    sx={{ minWidth: 70 }}
                    style={{ color: 'white', textDecoration: 'none' }}
                  >
                    <Button variant="outlined">
                      <Fingerprint sx={{ color: 'white' }} />
                    </Button>
                  </Typography>
                )}
              </>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  tech: state.tech,
  user: state.user,
  business: state.business,
});

export default connect(mapStateToProps, { logOut })(MenuAppBar);
