import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Grid, Alert, Button, CircularProgress } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { getTechs } from '../../actions/techs/';
import TechItem from './TechItem';

const AddTech = ({ getTechs, techs }) => {
  useEffect(() => {
    getTechs();
  }, [getTechs]);

  if (techs === null) {
    return <CircularProgress />;
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Link to="/tech/add-tech" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              endIcon={<PeopleAltIcon />}
              color="success"
              id="add-client"
            >
              Add Tech
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12} md={8}>
          <Alert icon={false} variant="filled" severity="info">
            Techs
          </Alert>
          {techs.legnth === 0 ? (
            <Alert icon={false} severity="warning">
              No Techs
            </Alert>
          ) : (
            techs.map((tech) => <TechItem key={tech.id} tech={tech} />)
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  techs: state.tech.techs,
});
export default connect(mapStateToProps, { getTechs })(AddTech);
