import { CircularProgress, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const TheUser = ({
  user,
  addThisUser,
  currentSquad,
  removeThisUser,
  removeArr,
  dontRemove,
}) => {
  const [squad, setSquad] = useState(null);
  const [add, setAdd] = useState(false);
  const [remove, setRemove] = useState(false);
  const [userName, setUsername] = useState(null);
  const [userSurname, setUsersurname] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    let currentUserId = user.id;
    setUsername(user.name);
    setUsersurname(user.surname);
    setUserId(currentUserId);
    const decide = currentSquad.filter((usr) => {
      return usr.userId === currentUserId;
    });
    if (decide.length === 0) {
      setSquad(false);
    } else {
      setSquad(true);
    }
  }, [squad, user, currentSquad]);

  if (squad === null) return <CircularProgress />;

  const pickMe = () => {
    addThisUser({ userName, userSurname, userId });
    setAdd(true);
  };

  const unPickMe = () => {
    removeThisUser(userId);
    setAdd(false);
  };

  const removeMe = () => {
    removeArr(userId);
    setRemove(true);
  };

  const dontRemoveMe = () => {
    dontRemove(userId);
    setRemove(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item>
        {squad ? (
          remove ? (
            <RadioButtonUncheckedIcon onClick={dontRemoveMe} />
          ) : (
            <RadioButtonCheckedIcon onClick={removeMe} />
          )
        ) : add ? (
          <RadioButtonCheckedIcon onClick={unPickMe} />
        ) : (
          <RadioButtonUncheckedIcon onClick={pickMe} />
        )}
      </Grid>
      <Grid item>
        {user.name} {user.surname}
      </Grid>
    </Grid>
  );
};

export default TheUser;
