import { GET_TECHS, TECH_PROFILE, TECH_LOGOUT } from '../../actions/types';

const initState = {
  isTech: false,
  loading: true,
  techs: null,
  tech_profile: null,
};

function techReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case TECH_PROFILE:
      return {
        ...state,
        tech_profile: payload,
        isTech: true,
        loading: false,
      };

    case GET_TECHS:
      return {
        ...state,
        techs: payload,
      };
    case TECH_LOGOUT:
      return {
        ...state,
        isTech: false,
        tech_profile: null,
        techs: null,
      };
    default:
      return state;
  }
}

export default techReducer;
