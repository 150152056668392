import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
  clientTicketReport,
  customTicketReport,
  timeOnTickets,
} from '../../../actions/techs/ticket';
import { clientDevices } from '../../../actions/clients/clients';
import {
  Box,
  Grid,
  Button,
  CircularProgress,
  Typography,
  Divider,
  Container,
  useTheme,
} from '@mui/material';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import TicketReport from './TicketReport';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import moment from 'moment';
import Device from './Device';
import { tokens } from '../../../theme';

const ClientReport = ({
  clientTicketReport,
  tech_tickets: {
    client_tickets,
    custom_tickets,
    time_on_tickets,
    time_on_custom_tickets,
    stillActive,
    customStillActive,
  },
  client: { client, client_devices },
  clientDevices,
  customTicketReport,
  timeOnTickets,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  let navigate = useNavigate();

  const { clientId } = useParams();

  useEffect(() => {
    clientTicketReport(clientId);
    clientDevices(clientId);
  }, [clientTicketReport, clientDevices, clientId]);

  useEffect(() => {
    if (client_tickets) {
      timeOnTickets({ client_tickets });
    }
  }, [timeOnTickets, client_tickets]);

  if ((client_tickets === null, client === null || client_devices === null)) {
    return <CircularProgress color="info" />;
  }

  const search = () => {
    const first = moment(startDate).hour(7).format();
    const last = moment(endDate).hour(20).format();
    customTicketReport({ clientId, first, last });
  };

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container>
        <Grid item>
          <Link as={Link} to={`/tech/tech-clients/${clientId}`}>
            <Button sx={{ color: colors.blueAccent[400] }} onClick={goBack}>
              <ArrowBackIos />
            </Button>
          </Link>
        </Grid>
        <Grid item>
          <Typography
            variant="h5"
            sx={{ color: colors.grey[500], fontWeight: 'bold' }}
          >
            {client.name}: IT report
          </Typography>
        </Grid>
      </Grid>

      <Divider variant="middle" sx={{ mt: '10px', mb: '10px' }} />
      <Container>
        <Grid container spacing={2}>
          <Grid xs={6} item>
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', color: colors.grey[500] }}
            >
              Ticket report
            </Typography>
            <Divider sx={{ mb: '10px', mt: '10px' }} />
            {client_tickets.length > 0 ? (
              client_tickets.map((ticket) => (
                <TicketReport key={ticket.id} ticket={ticket} />
              ))
            ) : (
              <Typography style={{ marginBottom: '10px' }}>
                No tickets this month...
              </Typography>
            )}
            <Typography>
              {client.name} created {client_tickets.length} tickets/s this month
            </Typography>
            <Typography>Total Time: {time_on_tickets}</Typography>
            <Typography> {stillActive} : Still active</Typography>
            <Divider style={{ marginBottom: '10px', marginTop: '10px' }} />
            <Typography>View a custom ticket range</Typography>
            <Grid container>
              <Grid xs={5} item>
                <b>Start Date: </b>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </Grid>
              <Grid xs={5} item>
                <b>End Date: </b>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                />
              </Grid>
              <Grid xs={2} item>
                <Button variant="contained" onClick={search} color="success">
                  <QueryStatsIcon color="white" />
                </Button>
              </Grid>
            </Grid>
            {custom_tickets === null ? (
              <Typography>
                Select and search to view custom date range
              </Typography>
            ) : custom_tickets.length === 0 ? (
              <Typography style={{ marginBottom: '10px' }}>
                No tickets in selected time range
              </Typography>
            ) : (
              custom_tickets.map((ticket) => (
                <TicketReport key={ticket.id} ticket={ticket} />
              ))
            )}
            {custom_tickets && (
              <>
                <Typography>
                  {client.name} created {custom_tickets.length} tickets/s in
                  this time range
                </Typography>
                <Typography>Total Time: {time_on_custom_tickets}</Typography>
                <Typography> {customStillActive} : Still active</Typography>
              </>
            )}
          </Grid>
          <Grid xs={6} item>
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', color: colors.grey[500] }}
            >
              Device report
            </Typography>
            <Divider variant="middle" sx={{ mb: '10px', mt: '10px' }} />

            {client_devices.length > 0 ? (
              client_devices.map((device) => (
                <Device key={device.id} device={device} clientId={clientId} />
              ))
            ) : (
              <Typography>Client has no listed devices</Typography>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  client: state.client,
  tech_tickets: state.tech_tickets,
});

export default connect(mapStateToProps, {
  clientTicketReport,
  clientDevices,
  customTicketReport,
  timeOnTickets,
})(ClientReport);
