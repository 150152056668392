import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Box,
  Grid,
  Alert,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Button,
} from '@mui/material';
import CorporateFareSharpIcon from '@mui/icons-material/CorporateFareSharp';
import MailOutlineSharpIcon from '@mui/icons-material/MailOutlineSharp';
import AddLocationSharpIcon from '@mui/icons-material/AddLocationSharp';
import LocalPhoneSharpIcon from '@mui/icons-material/LocalPhoneSharp';
import BackSpaceIcon from '@mui/icons-material/ArrowBackIos';

import {
  getClientDevices,
  getClientUsers,
  currentUserClient,
} from '../../../actions/users/admin';
import Device from './Device';
import Users from './Users';

const ClientAdmin = ({
  getClientDevices,
  getClientUsers,
  currentUserClient,
  user_admin: { users, active_devices, decom_devices, loading, client },
  clientId,
}) => {
  const [showActiveDevices, setShowActiveDevices] = useState(true);
  useEffect(() => {
    if (clientId) {
      getClientDevices(clientId);
      getClientUsers(clientId);
      currentUserClient(clientId);
    }
  }, [clientId, getClientDevices, getClientUsers, currentUserClient]);
  const navigate = useNavigate();

  if (loading) {
    return <CircularProgress />;
  }

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Grid container spacing={4}>
            <Grid item xs={2}>
              <Button color="primary" onClick={goBack}>
                <BackSpaceIcon />
              </Button>
            </Grid>
            <Grid item>
              <Link as={Link} to="/user/ticket-review">
                <Button variant="outlined">Tickets</Button>
              </Link>
            </Grid>
            <Grid item>
              <Link as={Link} to="/user/project-review">
                <Button variant="outlined" color="secondary">
                  Projects
                </Button>
              </Link>
            </Grid>
          </Grid>

          <Box id="client-info">
            <List>
              <ListItem>
                <ListItemIcon>
                  <CorporateFareSharpIcon />
                </ListItemIcon>
                <ListItemText primary={`${client.name}`} />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemIcon>
                  <MailOutlineSharpIcon />
                </ListItemIcon>
                <ListItemText primary={`${client.email}`} />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemIcon>
                  <AddLocationSharpIcon />
                </ListItemIcon>
                <ListItemText primary={`${client.address}`} />
              </ListItem>
              <Divider variant="inset" component={'li'} />
              <ListItem>
                <ListItemIcon>
                  <LocalPhoneSharpIcon />
                </ListItemIcon>
                <ListItemText primary={`${client.number}`} />
              </ListItem>
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container>
            <Grid item xs={6}>
              <Button
                variant={showActiveDevices ? 'contained' : 'outlined'}
                onClick={() => setShowActiveDevices(true)}
              >
                Active Devices
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant={showActiveDevices ? 'outlined' : 'contained'}
                onClick={() => setShowActiveDevices(false)}
              >
                Offline Devices
              </Button>
            </Grid>
          </Grid>

          {active_devices && showActiveDevices && active_devices.length > 0
            ? active_devices.map((device) => (
                <Device key={device.id} device={device} />
              ))
            : ''}
          {decom_devices &&
          showActiveDevices === false &&
          decom_devices.length > 0
            ? decom_devices.map((device) => (
                <Device key={device.id} device={device} />
              ))
            : ''}
        </Grid>
        <Grid item xs={12} md={4}>
          <Alert style={{ marginBottom: '10px' }} severity="info">
            Users
          </Alert>
          {users && users.length > 0
            ? users.map((user) => <Users key={user.id} user={user} />)
            : 'You have no users'}
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user_admin: state.user_admin,
  clientId: state.user.user_profile.clientId,
});

export default connect(mapStateToProps, {
  getClientDevices,
  getClientUsers,
  currentUserClient,
})(ClientAdmin);
