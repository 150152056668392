import {
  CircularProgress,
  Container,
  Divider,
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getBranch,
  addToBranch,
  removeFromBranch,
} from '../../../actions/clients/clients';
import { useEffect, useState } from 'react';
import ListOfUsers from './ListOfUsers';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { tokens } from '../../../theme';

const Branch = ({
  client: { client_users, loading, branch_info },
  getBranch,
  addToBranch,
  removeFromBranch,
  alert,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { branchId } = useParams();
  const [addToArray, setAddToArray] = useState([]);
  const [removeFromArr, setRemoveFromArr] = useState([]);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (alert.length > 0) {
      setUpdating(false);
    }
  }, [alert]);

  useEffect(() => {
    getBranch(branchId);
  }, [branchId, getBranch]);

  const navigate = useNavigate();

  if (loading || client_users === null || branch_info === null)
    return <CircularProgress />;

  const addThisUser = ({ userId, userName, userSurname }) => {
    const myArr = addToArray;
    let newUser = {
      name: userName,
      userId: userId,
      surname: userSurname,
    };
    myArr.push(newUser);
    setAddToArray(myArr);
  };

  const removeThisUser = (userId) => {
    const myArr = addToArray.filter((usr) => {
      return usr.userId !== userId;
    });

    setAddToArray(myArr);
  };

  const removeArr = (userId) => {
    let myArr = removeFromArr;
    myArr.push(userId);
    setRemoveFromArr(myArr);
  };
  const dontRemove = (userId) => {
    const myArr = removeFromArr.filter((id) => {
      return userId !== id;
    });

    setRemoveFromArr(myArr);
  };

  const updateUsers = () => {
    if (addToArray.length > 0) {
      let newUsers = addToArray;
      addToBranch({ branchId, newUsers });
    }
    if (removeFromArr.length > 0) {
      removeFromBranch({ branchId, removeFromArr });
    }

    setUpdating(true);

    setAddToArray([]);
    setRemoveFromArr([]);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Grid spacing={2} container>
        <Grid xs={1} item>
          <ArrowBackIosIcon onClick={() => goBack()} />
        </Grid>
        <Grid xs={9} item>
          <Typography variant="h5">
            Branch: {branch_info && branch_info.name}
          </Typography>
        </Grid>
        <Grid xs={2} item>
          {branch_info && (
            <ListOfUsers
              client_users={client_users}
              info={branch_info}
              addThisUser={addThisUser}
              removeThisUser={removeThisUser}
              updateUsers={updateUsers}
              removeArr={removeArr}
              dontRemove={dontRemove}
              alert={alert}
              updating={updating}
            />
          )}
        </Grid>
      </Grid>
      <Divider variant="middle" sx={{ mt: '10px', mb: '10px' }} />

      <Grid container>
        <Grid xs={6} item>
          <Typography
            sx={{
              color: colors.grey[500],
              fontWeight: 'bold',
              ml: '10px',
            }}
          >
            Branch Users
          </Typography>
          <Divider variant="middle" sx={{ mt: '10px', mb: '10px' }} />
          {branch_info.users.length === 0
            ? 'No Users'
            : branch_info.users.map((user) => (
                <ListItem key={user.id} disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <PersonOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary={`${user.name} ${user.surname}`} />
                  </ListItemButton>
                </ListItem>
              ))}
        </Grid>
        <Grid xs={6} item>
          <Typography
            sx={{
              color: colors.grey[500],
              fontWeight: 'bold',
              ml: '10px',
            }}
            severity="info"
          >
            Department Activity
          </Typography>
          <Divider variant="middle" sx={{ mt: '10px', mb: '10px' }} />
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: state.client,
  alert: state.alert,
});

export default connect(mapStateToProps, {
  getBranch,
  addToBranch,
  removeFromBranch,
})(Branch);
