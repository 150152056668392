import { useTheme, Button, Box } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { tokens } from '../../../theme';
import { DataGrid } from '@mui/x-data-grid';

const TodoItem = ({ todo }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const navToToDO = (id) => {
    navigate(`/tech/tech-todo/${id}`);
  };

  const columns = [
    { field: 'todoName', headerName: 'Name', flex: 1 },
    { field: 'todoDescription', headerName: 'Description', flex: 1 },
    { field: 'type', headerName: 'Type', flex: 1 },
    { field: 'deadline', headerName: 'Deadline', flex: 1 },
    {
      field: 'id',
      headerName: 'View',
      renderCell: ({ row: { id } }) => {
        return (
          <Button
            sx={{
              color: colors.blueAccent[100],
              borderColor: colors.blueAccent[100],
            }}
            size="small"
            onClick={() => navToToDO(id)}
          >
            <ArrowForwardIos />
          </Button>
        );
      },
    },
  ];
  return (
    <Box
      sx={{
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
          color: colors.grey[900],
        },
        '& .MuiDataGrid-virtualScroller': {
          backgroundColor: colors.grey[900],
        },
        '& .MuiDataGrid-main': {
          backgroundColor: colors.blueAccent[100],
        },
        '& .MuiDataGrid-footerContainer': {
          bordertop: 'none',
          backgroundColor: colors.grey[700],
        },
      }}
    >
      <DataGrid rows={todo} columns={columns} />
    </Box>
  );
};

export default TodoItem;
