import { Card, CardContent, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { tokens } from '../../../theme';

const TodoText = ({ text, techId }) => {
  const [author, setAuthor] = useState();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    if (text) {
      if (text.authorId === techId) {
        setAuthor('You');
      } else {
        setAuthor(text.authorName);
      }
    }
  }, [text, techId]);
  const time = moment(text.createdAt).calendar();
  const myText = {
    backgroundColor: colors.greenAccent[400],
    marginBottom: '5%',
    marginLeft: '20%',
    width: '80%',
    textAlign: ' right',
  };

  const sender = {
    backgroundColor: colors.grey[800],
    marginBottom: '5%',
    width: '80%',
  };
  return (
    <Card style={text.authorId === techId ? myText : sender}>
      <CardContent>
        <Typography>{text.text}</Typography>
      </CardContent>
      <Typography variant="caption" sx={{ flexDirection: 'column' }}>
        {author}: {time}
      </Typography>
    </Card>
  );
};

export default TodoText;
