import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import DomainIcon from '@mui/icons-material/Domain';

const AssignToBranch = ({ branches, assignedToDo, assignThisDude }) => {
  const [open, setOpen] = useState(false);
  const [chosen, setChosen] = useState(null);
  const [branchSelected, setBranchSelected] = useState(false);

  useEffect(() => {
    if (assignedToDo && assignedToDo.responsible === 'Branch') {
      setChosen(assignedToDo.id);
      setBranchSelected(true);
    } else {
      setChosen(null);
      setBranchSelected(false);
    }
  }, [assignedToDo]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const selectBranch = ({ branch }) => {
    const responsible = {
      responsible: 'Branch',
      id: branch.id,
      name: branch.name,
    };

    assignThisDude({ responsible });
    handleClose();
  };

  return (
    <Container>
      {branchSelected ? (
        <Button
          variant="contained"
          color="warning"
          onClick={handleOpen}
          sx={{ color: 'white' }}
          size="small"
        >
          <DomainIcon />
        </Button>
      ) : (
        <Button
          variant="outlined"
          size="small"
          color="warning"
          onClick={handleOpen}
        >
          <DomainIcon />
        </Button>
      )}

      <Dialog open={open} onClose={handleClose} scroll="paper">
        <DialogTitle id="scroll-dialog-title">
          Select branch would to assign?
        </DialogTitle>
        <Divider variant="middle" />

        <DialogContent>
          {branches.length === 0 ? (
            <Typography>No branches</Typography>
          ) : (
            branches.map((branch) => (
              <Grid key={branch.id} container spacing={2}>
                <Grid item>
                  {chosen === branch.id ? (
                    <RadioButtonCheckedIcon />
                  ) : (
                    <RadioButtonUncheckedIcon
                      onClick={() => selectBranch({ branch })}
                    />
                  )}
                </Grid>
                <Grid item>{branch.name}</Grid>
              </Grid>
            ))
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default AssignToBranch;
