import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@mui/material';
import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import { tokens } from '../../theme';
import TheTech from './TheTech';
import { getTechs } from '../../actions/techs';
import GroupIcon from '@mui/icons-material/Group';

const ListOfTechs = ({
  techs,
  getTechs,
  addTech,
  removeTech,
  upadateMyCollab,
  collab,
}) => {
  useEffect(() => {
    getTechs();
  }, [getTechs]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  if (techs === null) return <CircularProgress />;

  const techChosen = (tech) => {
    addTech(tech);
  };

  const techRemove = (techId) => {
    removeTech(techId);
  };

  const updateCollab = () => {
    upadateMyCollab();
    handleClose();
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        sx={{
          color: colors.grey[500],
          borderColor: colors.grey[500],
        }}
      >
        <GroupIcon />
      </Button>
      <Dialog open={open} onClose={handleClose} scroll="paper">
        <DialogTitle id="scroll-dialog-title">
          Who would you like to add to:
        </DialogTitle>
        <DialogContent>
          {techs.length === 0
            ? 'No Techs'
            : techs.map((tech) => (
                <TheTech
                  key={tech.id}
                  tech={tech}
                  techChosen={techChosen}
                  techRemove={techRemove}
                  collab={collab}
                />
              ))}
        </DialogContent>
        <Button
          variant="outlined"
          size="small"
          sx={{
            borderColor: colors.redAccent[700],
            color: colors.redAccent[700],
            mb: '5px',
            ml: '28px',
            width: '80%',
          }}
          onClick={updateCollab}
        >
          Update collab
        </Button>
      </Dialog>
    </>
  );
};
const mapStateToProps = (state) => ({
  techs: state.tech.techs,
});
export default connect(mapStateToProps, { getTechs })(ListOfTechs);
