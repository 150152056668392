import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { tokens } from '../../../theme';
import {
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
  Divider,
  CircularProgress,
} from '@mui/material';
import {
  getLead,
  getLeadNotes,
  getFollowUps,
} from '../../../actions/techs/sales';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ReviewAndClose = ({
  leads: { lead },
  getLead,
  getLeadNotes,
  getFollowUps,
}) => {
  const { leadId } = useParams();
  useEffect(() => {
    getLead(leadId);
    getLeadNotes(leadId);
    getFollowUps(leadId);
  }, [getLead, leadId, getLeadNotes, getFollowUps]);
  let navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (lead === null) {
    return <CircularProgress />;
  }

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Container>
      <Grid container>
        <Grid xs={1} item>
          <Button onClick={goBack}>
            <ArrowBackIcon style={{ color: colors.redAccent[500] }} />
          </Button>
        </Grid>
        <Grid item>
          <Typography
            variant="h5"
            sx={{ color: colors.grey[500], fontWeight: 'bold' }}
          >
            Review and close the lead
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mb: '15px' }} />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  leads: state.leads,
});

export default connect(mapStateToProps, {
  getLead,
  getLeadNotes,
  getFollowUps,
})(ReviewAndClose);
