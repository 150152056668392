import { useState } from 'react';
import { connect } from 'react-redux';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Box, Divider, IconButton, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { tokens } from '../../theme';
import {
  HomeOutlined,
  MenuOutlined,
  DomainOutlined,
  LiveHelpOutlined,
  Groups2Outlined,
  ReceiptLongOutlined,
  Diversity2Outlined,
  FormatListNumberedOutlined,
  ListAltOutlined,
} from '@mui/icons-material';
import logobp from '../../images/logobp.png';

const Item = ({ title, icon, to, selected, setSelected }) => {
  let navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navToScreen = () => {
    setSelected(title);
    navigate(`${to}`);
  };

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={navToScreen}
      icon={icon}
    >
      <Typography variant="h6">{title}</Typography>
    </MenuItem>
  );
};

const MySideBar = ({
  user: { isUser, user_profile },
  tech: { isTech, tech_profile },
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState('');

  if (isTech) {
    return (
      <Box
        sx={{
          '& .pro-sidebar-root': {
            background: `${colors.primary[400]} !important`,
          },
          '& .pro-icon-wrapper': {
            backgroundColor: 'transparent !important',
          },
          '& .pro-inner-item': {
            padding: '5px 35px 5px 20px !important',
          },
          '& .pro-inner-item:hover': {
            color: '#868dfb !important',
          },
          '& .pro-menu-item.active': {
            color: '#6870fa !important',
          },
        }}
      >
        <Sidebar className="sidebar" collapsed={isCollapsed}>
          <Menu>
            {!isCollapsed && (
              <>
                <Box
                  display="flex"
                  mt="25px"
                  ml="5px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img alt="callitservices" width="220px" src={logobp} />
                </Box>
              </>
            )}
            <Divider
              variant="middle"
              sx={{ marginBottom: '5px', marginTop: '5px' }}
            />
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlined /> : undefined}
              style={{
                margin: '10px 0 20px 0',
                color: colors.primary[500],
              }}
            >
              {!isCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="15px"
                >
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    color={colors.grey[100]}
                  >
                    {tech_profile.name}
                  </Typography>
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlined />
                  </IconButton>
                </Box>
              )}
            </MenuItem>
            <Item
              title="Dashboard"
              icon={<HomeOutlined />}
              selected={selected}
              setSelected={setSelected}
              to="/"
            />
            <Item
              title="Helpdesk"
              icon={<LiveHelpOutlined />}
              selected={selected}
              setSelected={setSelected}
              to="/tech/tech-helpesk"
            />
            <Item
              title="Sales"
              icon={<ReceiptLongOutlined />}
              selected={selected}
              setSelected={setSelected}
              to="/tech/sales-pipeline"
            />
            <Item
              title="Projects"
              icon={<ListAltOutlined />}
              selected={selected}
              setSelected={setSelected}
              to="/tech/tech-projects"
            />
            <Item
              title="TODOS"
              icon={<FormatListNumberedOutlined />}
              selected={selected}
              setSelected={setSelected}
              to="/tech/it-todo"
            />
            <Item
              title="Clients"
              icon={<Groups2Outlined />}
              selected={selected}
              setSelected={setSelected}
              to="/tech/tech-clients"
            />
            <Item
              title="Team"
              icon={<Diversity2Outlined />}
              selected={selected}
              setSelected={setSelected}
              to="/tech/tech-admin"
            />
          </Menu>
        </Sidebar>
      </Box>
    );
  } else if (isUser) {
    return (
      <Box
        sx={{
          '& .pro-sidebar-inner': {
            background: `${colors.primary[400]} !important`,
          },
          '& .pro-icon-wrapper': {
            backgroundColor: 'transparent !important',
          },
          '& .pro-inner-item': {
            padding: '5px 35px 5px 20px !important',
          },
          '& .pro-inner-item:hover': {
            color: '#868dfb !important',
          },
          '& .pro-menu-item.active': {
            color: '#6870fa !important',
          },
        }}
      >
        <Sidebar className="sidebar" collapsed={isCollapsed}>
          <Menu>
            {!isCollapsed && (
              <>
                <Box
                  display="flex"
                  mt="25px"
                  ml="5px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img alt="callitservices" width="220px" src={logobp} />
                </Box>
              </>
            )}
            <Divider
              variant="middle"
              sx={{ marginBottom: '5px', marginTop: '5px' }}
            />
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlined /> : undefined}
              style={{
                margin: '10px 0 20px 0',
                color: colors.primary[500],
              }}
            >
              {!isCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="15px"
                >
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    color={colors.grey[100]}
                  >
                    {user_profile.name}
                  </Typography>
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlined />
                  </IconButton>
                </Box>
              )}
            </MenuItem>
            <Item
              title="Dashboard"
              icon={<HomeOutlined />}
              selected={selected}
              setSelected={setSelected}
              to="/"
            />
            <Item
              title="Helpdesk"
              icon={<LiveHelpOutlined />}
              selected={selected}
              setSelected={setSelected}
              to="/user/helpdesk"
            />

            <Item
              title="Sales"
              icon={<ReceiptLongOutlined />}
              selected={selected}
              setSelected={setSelected}
              to={`/user/sales-pipeline/${user_profile.clientId}`}
            />
            <Item
              title="Projects"
              icon={<ListAltOutlined />}
              selected={selected}
              setSelected={setSelected}
              to={`/user/user-projects/${user_profile.clientId}`}
            />

            <Item
              title="TODOS"
              icon={<FormatListNumberedOutlined />}
              selected={selected}
              setSelected={setSelected}
              to={`/user/company-todos/${user_profile.clientId}`}
            />
            <Item
              title="Management"
              icon={<DomainOutlined />}
              selected={selected}
              setSelected={setSelected}
              to={`/user/company-management/${user_profile.clientId}`}
            />
          </Menu>
        </Sidebar>
      </Box>
    );
  } else {
    return '';
  }
};

const mapStateToProps = (state) => ({
  tech: state.tech,
  user: state.user,
});

export default connect(mapStateToProps)(MySideBar);
