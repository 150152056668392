import { SIGN_IN, PERSIST_LOGIN, SIGN_OUT } from '../../actions/types';

const initState = {
  user: null,
  loading: true,
};

function authReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case PERSIST_LOGIN:
      return {
        ...state,
        user: payload,
        loading: false,
      };
    case SIGN_IN:
      return {
        ...state,
        user: payload,
        loading: false,
      };
    case SIGN_OUT:
      return {
        ...state,
        user: null,
        loading: false,
      };
    default:
      return state;
  }
}

export default authReducer;
