import React, { useEffect, useState } from 'react';
import {
  Container,
  Alert,
  Grid,
  CircularProgress,
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getDepartment,
  addToDepartment,
  removeFromDepartment,
} from '../../../actions/users/admin';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ListOfUsers from './ListOfUsers';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

const Department = ({
  user_admin: { users, loading, department_info },
  getDepartment,
  addToDepartment,
  removeFromDepartment,
  alert,
}) => {
  const { departmentId } = useParams();
  const [addToArray, setAddToArray] = useState([]);
  const [removeFromArr, setRemoveFromArr] = useState([]);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (alert.length > 0) {
      setUpdating(false);
    }
  }, [alert]);

  useEffect(() => {
    getDepartment(departmentId);
  }, [departmentId, getDepartment]);
  const navigate = useNavigate();

  if (loading || users === null || department_info === null)
    return <CircularProgress />;

  const addThisUser = ({ userId, userName, userSurname }) => {
    const myArr = addToArray;
    let newUser = {
      name: userName,
      userId: userId,
      surname: userSurname,
    };
    myArr.push(newUser);
    setAddToArray(myArr);
  };

  const removeThisUser = (userId) => {
    const newArr = addToArray.filter((usr) => {
      return usr.userId !== userId;
    });

    setAddToArray(newArr);
  };

  const removeArr = (userId) => {
    let myArr = removeFromArr;
    myArr.push(userId);
    setRemoveFromArr(myArr);
  };
  const dontRemove = (userId) => {
    const myArr = removeFromArr.filter((id) => {
      return userId !== id;
    });

    setRemoveFromArr(myArr);
  };

  const updateUsers = () => {
    if (addToArray.length > 0) {
      let newUsers = addToArray;
      addToDepartment({ departmentId, newUsers });
    }
    if (removeFromArr.length > 0) {
      removeFromDepartment({ departmentId, removeFromArr });
    }

    setUpdating(true);

    setAddToArray([]);
    setRemoveFromArr([]);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Grid spacing={2} container>
        <Grid xs={1} item>
          <ArrowBackIosIcon onClick={() => goBack()} />
        </Grid>
        <Grid xs={8} item>
          <Typography variant="h5">
            Department: {department_info && department_info.name}
          </Typography>
        </Grid>
        <Grid xs={2} item>
          {department_info && (
            <ListOfUsers
              users={users}
              info={department_info}
              addThisUser={addThisUser}
              removeThisUser={removeThisUser}
              updateUsers={updateUsers}
              removeArr={removeArr}
              dontRemove={dontRemove}
              alert={alert}
              updating={updating}
            />
          )}
        </Grid>
      </Grid>
      <Divider variant="middle" sx={{ mb: '10px', mt: '5px' }} />

      <Grid container>
        <Grid xs={6} item>
          <Alert>Department Users</Alert>
          {department_info.users.length === 0
            ? 'No Users'
            : department_info.users.map((user) => (
                <ListItem key={user.id} disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <PersonOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary={`${user.name} ${user.surname}`} />
                  </ListItemButton>
                </ListItem>
              ))}
        </Grid>
        <Grid xs={6} item>
          <Alert severity="info">Department Activity</Alert>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user_admin: state.user_admin,
  alert: state.alert,
});

export default connect(mapStateToProps, {
  getDepartment,
  addToDepartment,
  removeFromDepartment,
})(Department);
