import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RemoveIcon from '@mui/icons-material/Remove';
import { v4 as uuidv4 } from 'uuid';

import {
  Container,
  CircularProgress,
  Typography,
  Grid,
  Divider,
  Card,
  CardContent,
  TextField,
  ListItem,
  ListItemText,
  IconButton,
  Button,
  useTheme,
} from '@mui/material';
import {
  getBranches,
  getDepartments,
  currentUserClient,
  getClientUsers,
} from '../../../actions/users/admin';
import {
  assignToDo,
  unAssignTodo,
  updateTaskList,
  removeTodoDeadline,
} from '../../../actions/users/todo';
import AddTask from './AddTask';
import AssignToBranch from '../../layout/AssignToBranch';
import AssignToDepartment from '../../layout/AssignToDepartment';
import AssignToUser from '../../layout/AssignToUser';
import OnceOffDeadline from './OnceOffDeadline';
import DailyDeadline from './DailyDeadline';
import WeeklyDeadline from './WeeklyDeadline';
import MonthlyDeadline from './MonthlyDeadline';
import moment from 'moment';
import BackSpaceIcon from '@mui/icons-material/ArrowBackIos';
import CreateTodo from './CreateTodo';
import { setAlert } from '../../../actions/alert';
import { tokens } from '../../../theme';

const AddTodo = ({
  user_admin: { client, loading, branches, departments, users },
  todo: { taskList, assignedToDo, todoDeadline },
  getBranches,
  getDepartments,
  currentUserClient,
  getClientUsers,
  updateTaskList,
  assignToDo,
  unAssignTodo,
  removeTodoDeadline,
  setAlert,
}) => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [deadlineType, setDeadlineType] = useState(null);
  const [dday, setDday] = useState(null);
  const [todoName, setToDoName] = useState('');
  const [todoDescription, setToDoDescription] = useState('');

  useEffect(() => {
    getBranches(clientId);
    getDepartments(clientId);
    currentUserClient(clientId);
    getClientUsers(clientId);
  }, [
    clientId,
    getBranches,
    getDepartments,
    currentUserClient,
    getClientUsers,
  ]);

  useEffect(() => {
    if (todoDeadline) {
      setDeadlineType(todoDeadline.type);
      setDday(todoDeadline.deadline);
    }
  }, [todoDeadline]);

  const addToTaskArr = (title) => {
    const myArr = taskList;
    const id = uuidv4();

    const newTask = {
      done: false,
      id,
      complete: false,
      title: title,
    };

    myArr.push(newTask);
    updateTaskList(myArr);
  };

  const removeFromTaskArr = (taskId) => {
    const newArr = taskList.filter((task) => {
      return task.id !== taskId;
    });

    updateTaskList(newArr);
  };

  const assignThisDude = ({ responsible }) => {
    assignToDo({ responsible });
  };

  const removeThisDude = () => {
    unAssignTodo();
  };

  const removeDeadline = () => {
    removeTodoDeadline(null);
    setDeadlineType(null);
  };

  const goBack = () => {
    updateTaskList([]);
    removeThisDude();
    removeDeadline();
    navigate(-1);
  };

  const navigateToCreatedToDo = (todoId) => {
    updateTaskList([]);
    removeThisDude();
    removeDeadline();
    navigate(`/user/my-todo/${todoId}`);
  };

  const buttonStyles = {
    marginBottom: '8px',
    color: colors.grey[500],
  };

  if (loading || client === null || users === null) {
    return <CircularProgress />;
  }
  return (
    <Container>
      <Grid container>
        <Grid item xs={1}>
          <Button size="small" sx={{ ...buttonStyles }} onClick={goBack}>
            <BackSpaceIcon />
          </Button>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h5" color="green">
            Add Todo
          </Typography>
        </Grid>

        <Grid item xs={1}>
          <CreateTodo
            setAlert={setAlert}
            todoName={todoName}
            todoDescription={todoDescription}
            taskList={taskList}
            assignedToDo={assignedToDo}
            todoDeadline={todoDeadline}
            clientId={clientId}
            navigateToCreatedToDo={navigateToCreatedToDo}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={2}>
        <Grid xs={4} item>
          <Typography variant="h6" color="grey" gutterBottom>
            Todo details:
          </Typography>

          <Card sx={{ marginBottom: '10px' }}>
            <CardContent>
              <Grid item>
                <TextField
                  label="Todo Name"
                  type="text"
                  placeholder="Todo Name"
                  variant="outlined"
                  value={todoName}
                  onChange={(e) => setToDoName(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Todo description"
                  placeholder="Todo description"
                  rows={4}
                  value={todoDescription}
                  onChange={(e) => setToDoDescription(e.target.value)}
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item>
                <AddTask addToTaskArr={addToTaskArr} />
                {taskList.length === 0 ? (
                  <Typography textAlign="center" mt="5px">
                    Add your first task
                  </Typography>
                ) : (
                  taskList.map((task) => (
                    <ListItem
                      key={task.id}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          color="warning"
                          onClick={() => removeFromTaskArr(task.id)}
                        >
                          <RemoveIcon />
                        </IconButton>
                      }
                    >
                      <IconButton>
                        <RadioButtonUncheckedIcon />
                      </IconButton>

                      <ListItemText primary={`${task.title}`} />
                    </ListItem>
                  ))
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={4} item>
          <Typography variant="h6" color="grey" gutterBottom sx={{ m: '3px' }}>
            Assign Todo:
          </Typography>
          <AssignToBranch
            branches={branches}
            assignedToDo={assignedToDo}
            assignThisDude={assignThisDude}
          />
          <AssignToDepartment
            departments={departments}
            assignedToDo={assignedToDo}
            assignThisDude={assignThisDude}
          />
          <AssignToUser
            users={users}
            assignedToDo={assignedToDo}
            assignThisDude={assignThisDude}
          />
          <Divider
            variant="middle"
            sx={{ marginBottom: '5px', marginTop: '5px' }}
          />
          {assignedToDo && (
            <ListItem
              secondaryAction={
                <IconButton onClick={removeThisDude} color="warning">
                  <RemoveIcon />
                </IconButton>
              }
            >
              <ListItemText primary={`Assigned to: ${assignedToDo.name}`} />
            </ListItem>
          )}
        </Grid>
        <Grid xs={4} item>
          <Typography variant="h6" color="grey" gutterBottom>
            Todo deadlines:
          </Typography>
          <OnceOffDeadline deadlineType={deadlineType} />
          <Divider
            variant="middle"
            sx={{ marginBottom: '5px', marginTop: '5px' }}
          />
          <DailyDeadline deadlineType={deadlineType} />
          <Divider
            variant="middle"
            sx={{ marginBottom: '5px', marginTop: '5px' }}
          />
          <WeeklyDeadline deadlineType={deadlineType} />
          <Divider
            variant="middle"
            sx={{ marginBottom: '5px', marginTop: '5px' }}
          />
          <MonthlyDeadline deadlineType={deadlineType} />

          <Divider
            variant="middle"
            sx={{ marginBottom: '15px', marginTop: '5px' }}
          />

          {deadlineType && deadlineType === 'Once' ? (
            <ListItem
              secondaryAction={
                <IconButton onClick={removeDeadline} color="warning">
                  <RemoveIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={` Deadline day: ${moment(dday).format(
                  'MMMM Do YYYY'
                )}`}
              />
            </ListItem>
          ) : deadlineType === 'Daily' ? (
            <ListItem
              secondaryAction={
                <IconButton onClick={removeDeadline} color="warning">
                  <RemoveIcon />
                </IconButton>
              }
            >
              <ListItemText primary={`Deadline day: Daily @ ${dday}`} />
            </ListItem>
          ) : deadlineType === 'Weekly' ? (
            <ListItem
              secondaryAction={
                <IconButton onClick={removeDeadline} color="warning">
                  <RemoveIcon />
                </IconButton>
              }
            >
              <ListItemText primary={`Deadline day: Weekly on ${dday}`} />
            </ListItem>
          ) : deadlineType === 'Monthly' ? (
            <ListItem
              secondaryAction={
                <IconButton onClick={removeDeadline} color="warning">
                  <RemoveIcon />
                </IconButton>
              }
            >
              <ListItemText primary={`Deadline day: Monthly on the ${dday}`} />
            </ListItem>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user_admin: state.user_admin,
  user: state.user.user_profile,
  todo: state.client_todo,
});

export default connect(mapStateToProps, {
  getBranches,
  getDepartments,
  currentUserClient,
  getClientUsers,
  updateTaskList,
  assignToDo,
  unAssignTodo,
  removeTodoDeadline,
  setAlert,
})(AddTodo);
