import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Grid,
  useTheme,
  Container,
  Divider,
  Button,
  ListItem,
  ListItemText,
  IconButton,
  List,
  ListItemButton,
} from '@mui/material';
import {
  createALead,
  updateNoteToNewLead,
  updateFollowToNewNote,
  updateCollab,
  updateClientToLead,
  removeFromCollab,
} from '../../../actions/techs/sales';
import { getTechs } from '../../../actions/techs/index';
import { v4 as uuidv4 } from 'uuid';
import { tokens } from '../../../theme';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ListOfClients from '../Clients/ListOfClients';
import NoteToLead from './NoteToLead';
import FollowUpToNote from './FollowUpToNote';
import RemoveIcon from '@mui/icons-material/Remove';
import ListOfTechs from '../ListOfTechs';
import { Cancel, Email, Phone, PushPinOutlined } from '@mui/icons-material/';
import ConfirmLeadCreation from './ConfirmLeadCreation';
import { setAlert } from '../../../actions/alert';

const AddLead = ({
  createALead,
  getTechs,
  removeFromCollab,
  setAlert,
  tech_profile,
  updateNoteToNewLead,
  updateFollowToNewNote,
  updateCollab,
  updateClientToLead,
  leads: { lead_notes, leadFollowUps, collabCacheAdd, client },
}) => {
  const [type, setType] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [clientId, setClientId] = useState(null);
  const [desc, setDesc] = useState('');
  const [value, setValue] = useState(0);
  let navigate = useNavigate();
  useEffect(() => {
    getTechs();
  }, [getTechs]);

  useEffect(() => {
    if (client) {
      setClientId(client.id);
    } else {
      setClientId(null);
    }
  }, [client, setClientId]);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    address: '',
  });

  const { name, email, number, address } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const addClient = (client) => {
    updateClientToLead(client);
  };

  const addToNoteArr = ({ createdAt, createdBy, note }) => {
    const _id = uuidv4();
    const newNote = {
      _id,
      createdAt,
      createdBy,
      note,
    };
    let arr = lead_notes.concat(newNote);
    updateNoteToNewLead(arr);
  };

  const removeNote = (id) => {
    const arr = lead_notes.filter((note) => {
      return note._id !== id;
    });
    updateNoteToNewLead(arr);
  };

  const addToFollowUpArr = ({ createdAt, createdBy, date, note }) => {
    const _id = uuidv4();
    const newFollowU = {
      _id,
      createdAt,
      createdBy,
      date,
      note,
    };
    let arr = leadFollowUps.concat(newFollowU);
    updateFollowToNewNote(arr);
  };

  const removeClient = () => {
    updateClientToLead(null);
  };

  const removeFollowup = (id) => {
    const arr = leadFollowUps.filter((followUp) => {
      return followUp._id !== id;
    });
    updateFollowToNewNote(arr);
  };

  const addTech = (tech) => {
    //Check to see if tech is part of orignal team//
    const loot = collabCacheAdd.filter((collab) => {
      let id = collab.id;
      let techId = tech.id;
      return id === techId;
    });
    if (loot.length === 0) {
      //If tech is not part of the original team add them to add cache//
      let arr = collabCacheAdd;
      arr.push(tech);
      updateCollab(arr);
    }
  };

  const removeTech = (techId) => {
    //Check to see if tech is part of orignal team//
    const loot = collabCacheAdd.filter((collab) => {
      let id = collab.id;
      return id !== techId;
    });
    removeFromCollab(loot);
    //just remove from add cache//
    const arr = collabCacheAdd.filter((tech) => {
      let id = tech.id;
      return id !== techId;
    });
    updateCollab(arr);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    updateClientToLead(formData);
  };

  const goBack = () => {
    navigate(-1);
  };

  const navToNewLead = (leadId) => {
    removeClient();
    updateCollab([]);
    updateNoteToNewLead([]);
    updateFollowToNewNote([]);
    navigate(`/tech/lead-info/${leadId}`);
  };

  const upadateMyCollab = () => {
    //
  };

  return (
    <Container>
      <Box>
        <Grid container>
          <Grid xs={1} item>
            <Button onClick={goBack} sx={{ color: colors.redAccent[400] }}>
              <ArrowBackIosIcon />
            </Button>
          </Grid>
          <Grid xs={8} item>
            <Typography variant="h5" sx={{ color: colors.blueAccent[100] }}>
              Add a new lead
            </Typography>
          </Grid>

          <Grid xs={3} item>
            <ConfirmLeadCreation
              type={type}
              client={client}
              desc={desc}
              lead_notes={lead_notes}
              leadFollowUps={leadFollowUps}
              collab={collabCacheAdd}
              setAlert={setAlert}
              createALead={createALead}
              tech={tech_profile}
              value={value}
              navToNewLead={navToNewLead}
            />
          </Grid>
        </Grid>
        <Divider variant="middle" sx={{ mb: '10px', mt: '10px' }} />
        <Grid container>
          <Grid xs={4} item>
            <Typography sx={{ color: colors.grey[300], fontWeight: 'bold' }}>
              Lead deatils;
            </Typography>
            <Divider
              variant="middle"
              sx={{ mb: '10px', mt: '10px', color: colors.greenAccent[400] }}
            />
            <Grid sx={{ mb: '10px' }} item>
              <Typography
                sx={{
                  textAlign: 'center',
                  mb: '5px',
                  color: colors.grey[500],
                  fontWeight: 'bold',
                }}
              >
                Lead type
              </Typography>
              <Grid container>
                <Grid xs={4} item>
                  <Button
                    onClick={() => setType('Client')}
                    fullWidth
                    variant={type === 'Client' ? 'contained' : 'outlined'}
                    sx={{
                      color: type !== 'Client' ? colors.grey[500] : '',
                      borderColor:
                        type === 'Client'
                          ? colors.blueAccent[100]
                          : colors.grey[600],
                      backgroundColor:
                        type === 'Client' ? colors.blueAccent[100] : '',
                    }}
                  >
                    Client
                  </Button>
                </Grid>
                <Grid xs={4} item>
                  <Button
                    onClick={() => setType('Sale')}
                    fullWidth
                    variant={type === 'Sale' ? 'contained' : 'outlined'}
                    sx={{
                      color: type !== 'Sale' ? colors.grey[500] : '',
                      borderColor:
                        type === 'Sale'
                          ? colors.blueAccent[100]
                          : colors.grey[500],
                      backgroundColor:
                        type === 'Sale' ? colors.blueAccent[100] : '',
                    }}
                  >
                    Sale
                  </Button>
                </Grid>
                <Grid xs={4} item>
                  <Button
                    onClick={() => setType('Project')}
                    fullWidth
                    variant={type === 'Project' ? 'contained' : 'outlined'}
                    sx={{
                      color: type !== 'Project' ? colors.grey[500] : '',
                      borderColor:
                        type === 'Project'
                          ? colors.blueAccent[100]
                          : colors.grey[500],
                      backgroundColor:
                        type === 'Project' ? colors.blueAccent[100] : '',
                    }}
                  >
                    Project
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <TextField
                label="Lead Description"
                multiline
                maxRows={4}
                name="desc"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                autoComplete="false"
                fullWidth
                required
              />
            </Grid>
            <Grid item>
              <TextField
                label="Value"
                type="number"
                name="value"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                autoComplete="false"
                fullWidth
                required
              />
            </Grid>

            <Grid sx={{ mt: '15px' }}>
              <ListOfClients addClient={addClient} clientId={clientId} />
            </Grid>
            <Typography sx={{ textAlign: 'center', mb: '5px' }}>or</Typography>

            {client ? (
              <>
                <Grid container>
                  <Grid xs={10} item>
                    <Typography
                      sx={{
                        mt: '8px',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color: colors.grey[500],
                      }}
                    >
                      You have added; {client.name}
                    </Typography>
                  </Grid>
                  <Grid xs={2} item>
                    <Button onClick={() => removeClient()}>
                      <Cancel sx={{ color: colors.redAccent[400] }} />
                    </Button>
                  </Grid>
                </Grid>
                <Divider variant="middle" sx={{ mt: '5px' }} />
                <List>
                  <ListItem>
                    <ListItemButton>
                      <Email />
                    </ListItemButton>
                    <ListItemText primary={client.email} />
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <Phone />
                    </ListItemButton>
                    <ListItemText primary={client.number} />
                  </ListItem>
                  <ListItem>
                    <ListItemButton>
                      <PushPinOutlined />
                    </ListItemButton>
                    <ListItemText primary={client.address} />
                  </ListItem>
                </List>
              </>
            ) : (
              <form onSubmit={onSubmit}>
                <Grid item>
                  <TextField
                    label="Company Name"
                    type="text"
                    name="name"
                    value={name}
                    onChange={onChange}
                    autoComplete="false"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item>
                  <TextField
                    label="Company Email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={onChange}
                    autoComplete="false"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Company Number"
                    type="number"
                    name="number"
                    value={number}
                    onChange={onChange}
                    autoComplete="false"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Company Address"
                    type="text"
                    name="address"
                    value={address}
                    onChange={onChange}
                    autoComplete="false"
                    fullWidth
                    required
                  />
                </Grid>

                <Button
                  sx={{ backgroundColor: 'green', mt: '15px' }}
                  fullWidth
                  variant="contained"
                  type="submit"
                >
                  Confirm client details
                </Button>
              </form>
            )}
          </Grid>
          <Grid xs={4} item>
            <Typography sx={{ color: colors.grey[300], fontWeight: 'bold' }}>
              Notes and Follow ups
            </Typography>
            <Divider sx={{ mb: '10px', mt: '10px' }} variant="middle" />
            <Grid container>
              <Grid item xs={6}>
                <NoteToLead tech={tech_profile} addToNoteArr={addToNoteArr} />
              </Grid>
              <Grid item xs={6}>
                <FollowUpToNote
                  tech={tech_profile}
                  addToFollowUpArr={addToFollowUpArr}
                />
              </Grid>
            </Grid>

            <Typography
              sx={{
                textAlign: 'center',
                color: colors.greenAccent[500],
                borderColor: colors.greenAccent[500],
              }}
            >
              Lead notes
            </Typography>
            <Divider variant="middle" />
            {lead_notes.length === 0 ? (
              <Typography
                sx={{ textAlign: 'center', mt: '5px', color: colors.grey[400] }}
              >
                Add a lead note
              </Typography>
            ) : (
              lead_notes.map((note) => (
                <ListItem
                  key={note._id}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      color="warning"
                      onClick={() => removeNote(note._id)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={`${note.note}`} />
                </ListItem>
              ))
            )}

            <Typography
              sx={{
                textAlign: 'center',
                mt: '10px',
                color: colors.greenAccent[500],
                borderColor: colors.greenAccent[500],
              }}
            >
              Lead follow ups
            </Typography>
            <Divider variant="middle" />
            {leadFollowUps.length === 0 ? (
              <Typography
                sx={{ textAlign: 'center', mt: '5px', color: colors.grey[400] }}
              >
                Add a lead follow up
              </Typography>
            ) : (
              leadFollowUps.map((followUp) => (
                <ListItem
                  key={followUp._id}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      color="warning"
                      onClick={() => removeFollowup(followUp._id)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={`${followUp.note}`} />
                </ListItem>
              ))
            )}
          </Grid>
          <Grid xs={4} item>
            <Grid container>
              <Grid item xs={10}>
                <Typography
                  sx={{ color: colors.grey[300], fontWeight: 'bold' }}
                >
                  Collaborate
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <ListOfTechs
                  addTech={addTech}
                  removeTech={removeTech}
                  upadateMyCollab={upadateMyCollab}
                  collab={collabCacheAdd}
                />
              </Grid>
            </Grid>

            {collabCacheAdd.length === 0 ? (
              <Typography
                sx={{
                  color: colors.grey[500],
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                Would you like to collaborate?
              </Typography>
            ) : (
              collabCacheAdd.map((collab) => (
                <Container key={collab.id}>
                  <ListItem
                    secondaryAction={
                      <IconButton
                        edge="end"
                        color="warning"
                        onClick={() => removeTech(collab.id)}
                      >
                        <RemoveIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={`${collab.name}`} />
                  </ListItem>
                </Container>
              ))
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  tech_profile: state.tech.tech_profile,
  leads: state.leads,
});

export default connect(mapStateToProps, {
  createALead,
  updateNoteToNewLead,
  updateFollowToNewNote,
  updateCollab,
  updateClientToLead,
  setAlert,
  getTechs,
  removeFromCollab,
})(AddLead);
