import React from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { CircularProgress, Box } from '@mui/material/';

const PrivateRoute = ({
  component: Component,
  business: { isBusiness, loading },
}) => {
  if (loading)
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
  if (isBusiness) return <Component />;

  return <Navigate to="/" />;
};

const mapStateToProps = (state) => ({
  business: state.business,
});

export default connect(mapStateToProps)(PrivateRoute);
