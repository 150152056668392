import { IconButton, ListItem, ListItemText } from '@mui/material';
import { Create, ArrowForwardIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import React from 'react';

const TodoItem = ({ todo }) => {
  const navigate = useNavigate();

  const navToToDO = () => {
    navigate(`/user/my-todo/${todo.id}`);
  };
  return (
    <ListItem
      secondaryAction={
        <IconButton edge="end" color="warning" onClick={navToToDO}>
          <ArrowForwardIos />
        </IconButton>
      }
    >
      <IconButton>
        <Create />
      </IconButton>

      <ListItemText primary={`${todo.todoName}`} />
    </ListItem>
  );
};

export default TodoItem;
