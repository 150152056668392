import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { ArrowBack, Launch, SendOutlined } from '@mui/icons-material';
import { tokens } from '../../../theme';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  getLead,
  changeLeadStage,
  updateLeadFile,
  getFollowUps,
  getLeadNotes,
  fetchLeadText,
  sendLeadText,
  deleteFileValue,
  realTimeLeadCollabUpdate,
} from '../../../actions/users/sales';
import { uploadClientLeadFile, deleteFile } from '../../../actions/storage';
import ToggleStage from './ToggleStage';
import UploadLeadFile from './UploadLeadFile';
import AddLeadToNote from './AddLeadToNote';
import ScheduleFollow from './ScheduleFollow';
import FollowUpInfo from './FollowUpInfo';
import LeadTexts from './LeadText';
import DeleteLeadFile from './DeleteLeadFile';
import AssignToUser from '../../layout/AssignToUser';
import AssignToBranch from '../../layout/AssignToBranch';
import AssignToDepartment from '../../layout/AssignToDepartment';
import { setAlert } from '../../../actions/alert';
import moment from 'moment';

const ClientLeadInfo = ({
  client_sales: { lead, leadFileUrl, notes, follow_ups, texts, collabSales },
  user_admin: { branches, departments, users },
  user_profile,
  getLead,
  changeLeadStage,
  alert,
  uploadClientLeadFile,
  updateLeadFile,
  getFollowUps,
  getLeadNotes,
  fetchLeadText,
  sendLeadText,
  setAlert,
  deleteFile,
  deleteFileValue,
  realTimeLeadCollabUpdate,
}) => {
  const { leadId } = useParams();
  const [leadStage, setLeadStage] = useState(null);
  const [text, setText] = useState('');
  const [collab, setCollab] = useState([]);
  const [invoice, setInvoice] = useState(undefined);
  const [quote, setQuote] = useState(undefined);
  const [pitch, setPitch] = useState(undefined);
  const [updatingFile, setUpdatingFile] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (user_profile) {
      setUserId(user_profile.credentials);
    }
  }, [user_profile]);

  useEffect(() => {
    getLead(leadId);
    getFollowUps(leadId);
    getLeadNotes(leadId);
    fetchLeadText(leadId);
  }, [leadId, getLead, getFollowUps, getLeadNotes, fetchLeadText]);

  useEffect(() => {
    if (lead) {
      setLeadStage(lead.stage);
      setInvoice(lead.invoice);
      setQuote(lead.quote);
      setPitch(lead.pitch);
      setCollab(lead.collab);
    }
  }, [lead]);

  useEffect(() => {
    if (leadFileUrl) {
      let type = leadFileUrl.type;
      let url = leadFileUrl.url;
      updateLeadFile({ type, url, leadId });
    }
  }, [leadFileUrl, updateLeadFile, leadId]);

  useEffect(() => {
    if (alert.length > 0 && updatingFile) {
      setUpdatingFile(false);
    }
  }, [alert, updatingFile]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (lead === null || texts === null) return <CircularProgress />;

  const uploadThisFile = ({ selected, type }) => {
    let file = selected;
    uploadClientLeadFile({ type, file });
    setUpdatingFile(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (text === '') return setAlert('Cannot send a blank message!', 'warning');
    let authorId = userId;
    let authorName = user_profile.name;
    let createdAt = moment().format();
    sendLeadText({ authorId, authorName, createdAt, text, leadId });
    setText('');
  };

  const updateLeadOnFileDelete = (type) => {
    deleteFileValue({ type, leadId });
  };

  const assignThisDude = ({ responsible }) => {
    console.log(collab);
    realTimeLeadCollabUpdate({ responsible, leadId });
  };

  // const removeCollab = () => {
  //   let responsible = {};
  //   realTimeLeadCollabUpdate({ responsible, leadId });
  // };

  return (
    <Container>
      <Grid container>
        <Grid xs={1} item>
          <Link
            as={Link}
            to={`/user/sales-pipeline/${user_profile.clientId}`}
            style={{ color: colors.redAccent[500] }}
          >
            <ArrowBack />
          </Link>
        </Grid>
        <Grid xs={11} item>
          <Typography
            variant="h5"
            sx={{ color: colors.grey[500], fontWeight: 'bold' }}
          >
            {lead.desc}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mb: '15px' }} />
      <Grid container>
        <Grid item xs={12} md={4}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              color: colors.greenAccent[400],
              fontWeight: 'bold',
            }}
          >
            Lead details:
          </Typography>
          <Divider sx={{ mb: '10px' }} />
          <Grid container>
            <Grid item md={5}>
              <Typography
                gutterBottom
                sx={{
                  color: colors.grey[500],
                  fontWeight: 'bold',
                  fontSize: 15,
                  mt: '10px',
                }}
              >
                Lead stage:
              </Typography>
            </Grid>
            <Grid item md={5}>
              <ToggleStage
                leadStage={leadStage}
                changeLeadStage={changeLeadStage}
                leadId={leadId}
                alert={alert}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={10} item>
              <Typography
                gutterBottom
                sx={{
                  color: colors.grey[500],
                  fontWeight: 'bold',
                  fontSize: 15,
                  mt: '10px',
                }}
              >
                Lead Qoute
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {quote === undefined ? (
                <UploadLeadFile
                  uploadThisFile={uploadThisFile}
                  type="lead_quote"
                />
              ) : (
                <DeleteLeadFile
                  deleteFile={deleteFile}
                  type="lead_quote"
                  fileName={quote}
                  updateLeadOnFileDelete={updateLeadOnFileDelete}
                />
              )}
            </Grid>
          </Grid>
          <Divider variant="middle" sx={{ mb: '10px' }} />
          {quote === undefined ? (
            <Typography sx={{ mt: '5px', color: colors.redAccent[700] }}>
              No quote added
            </Typography>
          ) : (
            <div style={{ marginTop: '10px' }}>
              <a href={`${quote}`} target="_blank" rel="noreferrer">
                View Quote <Launch sx={{ ml: '10px' }} />
              </a>
            </div>
          )}
          <Grid container>
            <Grid xs={10} item>
              <Typography
                gutterBottom
                sx={{
                  color: colors.grey[500],
                  fontWeight: 'bold',
                  fontSize: 15,
                  mt: '10px',
                }}
              >
                Lead Pitch
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {pitch === undefined ? (
                <UploadLeadFile
                  uploadThisFile={uploadThisFile}
                  type="lead_pitch"
                />
              ) : (
                <DeleteLeadFile
                  deleteFile={deleteFile}
                  type="lead_pitch"
                  fileName={pitch}
                  updateLeadOnFileDelete={updateLeadOnFileDelete}
                />
              )}
            </Grid>
          </Grid>
          <Divider variant="middle" sx={{ mb: '10px' }} />
          {pitch === undefined ? (
            <Typography sx={{ mt: '5px', color: colors.redAccent[700] }}>
              No pitch added
            </Typography>
          ) : (
            <a href={`${pitch}`} target="_blank" rel="noreferrer">
              View Pitch <Launch />
            </a>
          )}
          <Grid container>
            <Grid xs={10} item>
              <Typography
                gutterBottom
                sx={{
                  color: colors.grey[500],
                  fontWeight: 'bold',
                  fontSize: 15,
                  mt: '10px',
                }}
              >
                Lead Invoice
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {invoice === undefined ? (
                <UploadLeadFile
                  uploadThisFile={uploadThisFile}
                  type="lead_invoice"
                />
              ) : (
                <DeleteLeadFile
                  deleteFile={deleteFile}
                  type="lead_invoice"
                  fileName={invoice}
                  updateLeadOnFileDelete={updateLeadOnFileDelete}
                />
              )}
            </Grid>
          </Grid>
          <Divider variant="middle" sx={{ mb: '10px' }} />
          {invoice === undefined ? (
            <Typography sx={{ mt: '5px', color: colors.redAccent[700] }}>
              No invoice added
            </Typography>
          ) : (
            <a href={`${invoice}`} target="_blank" rel="noreferrer">
              View Invoice <Launch />
            </a>
          )}

          {updatingFile && (
            <>
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  borderColor: colors.redAccent[600],
                  color: colors.redAccent[600],
                  mt: '15px',
                }}
              >
                Uploading your file...
              </Button>
              <LinearProgress
                sx={{ backgroundColor: colors.redAccent[600], mt: '5px' }}
                color="secondary"
              />
            </>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container>
            <Grid item xs={8}>
              <Typography
                variant="h6"
                sx={{ color: colors.greenAccent[400], fontWeight: 'bold' }}
              >
                Lead notes
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <AddLeadToNote leadId={leadId} user={user_profile} />
            </Grid>
          </Grid>

          {notes === null ? (
            <CircularProgress />
          ) : notes.length === 0 ? (
            <Alert>Lead has no notes</Alert>
          ) : (
            notes.map((note) => (
              <div key={note.id}>
                <Typography gutterBottom sx={{ marginTop: '20px' }}>
                  {note.note}
                </Typography>
                <Divider variant="middle" />
              </div>
            ))
          )}

          <Grid container>
            <Grid item xs={8}>
              <Typography
                variant="h6"
                sx={{
                  color: colors.greenAccent[400],
                  fontWeight: 'bold',
                  mt: '10px',
                }}
                gutterBottom
              >
                Lead follow ups
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <ScheduleFollow leadId={leadId} user={user_profile} />
            </Grid>
          </Grid>
          <Divider sx={{ mb: '10px' }} />
          {follow_ups == null ? (
            <CircularProgress />
          ) : follow_ups.length === 0 ? (
            <Alert severity="warning">Lead has no follow ups</Alert>
          ) : (
            follow_ups.map((fl) => (
              <FollowUpInfo
                key={fl.id}
                fl={fl}
                leadId={leadId}
                toLead={false}
              />
            ))
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container>
            <Grid item xs={3}>
              <Typography
                variant="h6"
                sx={{ color: colors.greenAccent[400], fontWeight: 'bold' }}
                gutterBottom
              >
                Collab:
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <AssignToBranch
                branches={branches}
                assignedToDo={collabSales.responsible}
                assignThisDude={assignThisDude}
              />
            </Grid>
            <Grid item xs={3}>
              <AssignToDepartment
                departments={departments}
                assignedToDo={collabSales.responsible}
                assignThisDude={assignThisDude}
              />
            </Grid>
            <Grid item xs={3}>
              <AssignToUser
                users={users}
                assignedToDo={collabSales.responsible}
                assignThisDude={assignThisDude}
              />
            </Grid>
          </Grid>
          <Paper>
            <Box p={3} xs={12}>
              <Grid container spacing={4}>
                <Grid id="chat-grid" item>
                  {texts.length === 0
                    ? 'no texts'
                    : texts.map((text) => (
                        <LeadTexts key={text.id} text={text} userId={userId} />
                      ))}
                </Grid>
              </Grid>
            </Box>
            <form onSubmit={onSubmit}>
              <Grid container>
                <Grid xs={10} item>
                  <FormControl fullWidth>
                    <TextField
                      label="type your message"
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <IconButton xs={1} sx={{ mt: 0.4 }} type="submit">
                    <SendOutlined />
                  </IconButton>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  client_sales: state.client_sales,
  user_profile: state.user.user_profile,
  alert: state.alert,
  user_admin: state.user_admin,
});

export default connect(mapStateToProps, {
  getLead,
  changeLeadStage,
  uploadClientLeadFile,
  updateLeadFile,
  getFollowUps,
  getLeadNotes,
  sendLeadText,
  fetchLeadText,
  deleteFile,
  setAlert,
  deleteFileValue,
  realTimeLeadCollabUpdate,
})(ClientLeadInfo);
