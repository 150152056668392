import {
  SIGN_IN,
  SIGN_OUT,
  PERSIST_LOGIN,
  TECH_LOGOUT,
  USER_LOGOUT,
  BUSINESS_LOGOUT,
} from '../types';
import { auth } from '../../firebase-config';
import {
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  getAuth,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { setAlert } from '../alert';

//Persist Login//
export const persistLogin = (currentUser) => async (dispatch) => {
  try {
    dispatch({ type: PERSIST_LOGIN, payload: currentUser });
  } catch (error) {
    console.error(error.message);
  }
};

//Sign in
export const signIn =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      const a = await signInWithEmailAndPassword(auth, email, password);
      const user = a.user;
      dispatch({ type: SIGN_IN, payload: user });
      dispatch(setAlert('Welcome to support app', 'success'));
    } catch (error) {
      const msg = error.message;
      let a = msg.split('/').pop();
      let b = a.split(')').shift();
      dispatch(setAlert(b, 'error'));
    }
  };

//Send email verification//
export const verifyEmail = () => async (dispatch) => {
  try {
    const auth = getAuth();
    await sendEmailVerification(auth.currentUser);
    dispatch(
      setAlert('Email sent successfully, please check your inbox', 'success')
    );
  } catch (error) {
    console.error(error.message);
  }
};

export const resetMyPassword = (email) => async (dispatch) => {
  try {
    const auth = getAuth();
    await sendPasswordResetEmail(auth, email);
    dispatch(
      setAlert('Email sent successfully, please check your inbox', 'success')
    );
  } catch (error) {
    console.error('Password reset error; ', error.message);
  }
};

//Logout
export const logOut = () => async (dispatch) => {
  await signOut(auth);
  dispatch({ type: SIGN_OUT });
  dispatch({ type: TECH_LOGOUT });
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: BUSINESS_LOGOUT });
};
