import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Grid,
  Alert,
  Modal,
  Button,
  Box,
  Typography,
  List,
  ListItemButton,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
} from '@mui/material';
import { acceptRequest } from '../../../actions/techs/project';
import DoneAll from '@mui/icons-material/DoneAll';
import Cancel from '@mui/icons-material/Cancel';
import ViewAgenda from '@mui/icons-material/ViewAgenda';
import moment from 'moment';

const ViewFeature = ({ acceptRequest, projectId, f }) => {
  const [open, setOpen] = useState(false);
  const accepted = f.accepted;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const accept = (e) => {
    e.preventDefault();
    let text = f.text;
    let createdAt = moment().format();
    let featureId = f.id;
    acceptRequest({ projectId, text, createdAt, featureId });

    setOpen(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <Container>
      {accepted ? (
        <>
          <Button variant="outlined" fullWidth disabled disableElevation>
            <b>Accepted request;</b> {f.text}
          </Button>
        </>
      ) : (
        <List>
          <ListItemButton onClick={handleOpen}>
            <ListItem
              secondaryAction={
                <IconButton disabled edge="end">
                  <ViewAgenda color="dark" />
                </IconButton>
              }
            >
              <ListItemText primary={`${f.text}`} />
            </ListItem>
          </ListItemButton>
          <Divider />
        </List>
      )}

      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style }}>
          <Grid item>
            <Alert icon={false} variant="filled" severity="info">
              {f.text}
            </Alert>
            <Typography align="center" mt={1} mb={2}>
              Would you like to accept this feature request?
            </Typography>

            <Grid sx={{ align: 'center' }} spacing={4} container>
              <Grid xs={6} item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                  fullWidth
                >
                  No <Cancel />
                </Button>
              </Grid>
              <Grid xs={6} item>
                <Button
                  onClick={accept}
                  variant="outlined"
                  color="success"
                  fullWidth
                >
                  Yes <DoneAll />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
};

export default connect(null, { acceptRequest })(ViewFeature);
