import React from 'react';
import { useTheme, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { tokens } from '../../../theme';
import moment from 'moment';
import { ArrowForwardIos } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';

const SalesPipeline = ({ sales }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    { field: 'name', headerName: 'Client', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'desc', headerName: 'Lead description', flex: 1 },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      renderCell: ({ row: { createdAt } }) => {
        return <Typography>{moment(createdAt).format('ll')}</Typography>;
      },
    },
    {
      field: 'id',
      headerName: 'View',
      renderCell: ({ row: { id } }) => {
        return (
          <Link
            to={`/user/sales/client-lead/${id}`}
            style={{ textDecoration: 'none' }}
          >
            <Button
              variant="outlined"
              sx={{
                color: colors.blueAccent[100],
                borderColor: colors.blueAccent[100],
              }}
            >
              <ArrowForwardIos />
            </Button>
          </Link>
        );
      },
    },
  ];
  return (
    <Box
      sx={{
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
          color: colors.grey[900],
        },
        '& .MuiDataGrid-virtualScroller': {
          backgroundColor: colors.grey[900],
        },
        '& .MuiDataGrid-main': {
          backgroundColor: colors.blueAccent[100],
        },
        '& .MuiDataGrid-footerContainer': {
          bordertop: 'none',
          backgroundColor: colors.grey[700],
        },
      }}
    >
      <DataGrid rows={sales} columns={columns} />
    </Box>
  );
};

export default SalesPipeline;
