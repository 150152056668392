import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteUser } from '../../../actions/clients/clients';
import { Box, Button, Modal, Alert, Grid } from '@mui/material';

const DeleteUser = ({ user, deleteUser, alert, userId }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (alert.length > 0) {
      navigate(-1);
    }
  }, [alert, navigate]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const delUser = () => {
    let username = user.name;
    let clientId = user.clientId;
    let createdAt = user.createdAt;
    let surname = user.surname;

    deleteUser({ userId, clientId, username, createdAt, surname });
  };

  return (
    <>
      <Button
        variant="outlined"
        size="sm"
        color="secondary"
        onClick={handleOpen}
      >
        Delete
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Alert severity="warning">
            Are you sure you want to delete <b>{user.name}</b> ?
          </Alert>
          <Grid container>
            <Grid item>
              <Button onClick={delUser}>Yes</Button>
            </Grid>
            <Grid item>
              <Button color="secondary" onClick={handleClose}>
                No
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default connect(mapStateToProps, { deleteUser })(DeleteUser);
