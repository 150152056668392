import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getProjects } from '../../../actions/techs/project';
import {
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { tokens } from '../../../theme';
import Projects from './Projects';

const TechProjectDash = ({ projects: { projects }, getProjects }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  useEffect(() => {
    getProjects();
  }, [getProjects]);

  if (projects === null) return <CircularProgress />;
  return (
    <Container>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h4" sx={{ ml: '20px' }}>
            Projects
          </Typography>
        </Grid>
        <Grid item></Grid>
      </Grid>
      <Divider variant="middle" sx={{ mb: '10px', mt: '10px' }} />
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography
            sx={{ color: colors.grey[500], fontWeight: 'bold', ml: '10px' }}
          >
            Current projects
          </Typography>
          <Divider variant="middle" sx={{ mb: '10px', mt: '10px' }} />
          {projects === null ? (
            <CircularProgress />
          ) : projects.length === 0 ? (
            <Typography>No Projects</Typography>
          ) : (
            <Projects projects={projects} />
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography
            sx={{ color: colors.grey[500], fontWeight: 'bold', ml: '10px' }}
          >
            Project overview
          </Typography>
          <Divider variant="middle" sx={{ mb: '10px', mt: '10px' }} />
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  projects: state.project,
});

export default connect(mapStateToProps, { getProjects })(TechProjectDash);
