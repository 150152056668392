import {
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import {
  fetchCompleteTodos,
  fetchCompleAssignedTodos,
} from '../../../actions/techs/todo';
import { connect } from 'react-redux';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment';
import TodoItem from './TodoItem';

const CompleteTodos = ({
  fetchCompleteTodos,
  fetchCompleAssignedTodos,
  todo: { doneTodos, completeAssigned },
  tech_profile,
}) => {
  const [todos, setTodos] = useState([]);
  useEffect(() => {
    if (tech_profile) {
      fetchCompleteTodos(tech_profile.credentials);
      fetchCompleAssignedTodos(tech_profile.credentials);
    }
  }, [fetchCompleteTodos, fetchCompleAssignedTodos, tech_profile]);
  useEffect(() => {
    if (doneTodos && completeAssigned) {
      const theTodos = doneTodos.concat(completeAssigned);
      const transformedTodos = theTodos.map((todo) => {
        return {
          ...todo,
          type: todo.deadline.type,
          deadline:
            todo.deadline.type === 'Once'
              ? moment(todo.deadline.deadline).format('ll')
              : todo.deadline.deadline,
        };
      });
      setTodos(transformedTodos);
    }
  }, [doneTodos, completeAssigned]);
  let navigate = useNavigate();

  if (doneTodos === null || completeAssigned === null)
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Grid container>
        <Grid xs={1} item>
          <ArrowBack onClick={goBack} />
        </Grid>
        <Grid item>
          <Typography variant="h5">Completed Todos</Typography>
        </Grid>
      </Grid>
      <Divider sx={{ marginBottom: '5px', marginTop: '5px' }} />
      <Grid container>
        <Grid xs={8} item>
          <Typography variant="h6">Your complete Todo's</Typography>
          {todos.length === 0 ? 'You have no todos' : <TodoItem todo={todos} />}
        </Grid>
        <Grid xs={4} item>
          <Typography variant="h6">Review</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  tech_profile: state.tech.tech_profile,
  todo: state.todo,
});

export default connect(mapStateToProps, {
  fetchCompleteTodos,
  fetchCompleAssignedTodos,
})(CompleteTodos);
