import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Divider } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AddLocationAltSharpIcon from '@mui/icons-material/AddLocationAltSharp';

const UserItem = ({ u }) => {
  let navigate = useNavigate();
  return (
    <>
      <Grid
        container
        spacing={2}
        id="client-details"
        onClick={() => navigate(`/business/business-user/${u.id}`)}
      >
        <Grid xs={6} item>
          <Typography gutterBottom>
            <PersonOutlineIcon sx={{ marginRight: '20px' }} />
            {u.name}
          </Typography>
        </Grid>
        <Grid xs={6} item>
          <Typography gutterBottom>
            <AddLocationAltSharpIcon sx={{ marginRight: '20px' }} />
            {u.position}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: '12px' }} />
    </>
  );
};

export default UserItem;
