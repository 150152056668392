import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Alert from './components/layout/Alert';
import { ColorModeContext, useMode } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';

import Support from './components/layout/Support';
import VerifyEmail from './components/layout/VerifyEmail';
import SideBar from './components/layout/SideBar';

import UserRoute from './components/Routing/UserRoute';
import User from './components/Users/User';
import UserLanding from './components/Users/UserLanding';
import UserProfile from './components/Users/UserProfile';
import UserHelpdesk from './components/Users/Tickets/UserHelpdesk';
import UserProjectDash from './components/Users/Projects/UserProjectDash';
import SalesDashBoard from './components/Users/Sales/SalesDashBoard';
import MyTicket from './components/Users/Tickets/MyTicket';
import MyProject from './components/Users/Projects/MyProject';
import ClientAdmin from './components/Users/Admin/ClientAdmin';
import TicketReview from './components/Users/Admin/TicketReview';
import ProjectReview from './components/Users/Admin/ProjectReview';
import CompanyManagement from './components/Users/Admin/CompanyManagement';
import CompanyBranch from './components/Users/Admin/Branch';
import CompanyDepartment from './components/Users/Admin/Department';
import AddTodo from './components/Users/Todo/AddTodo';
import CompanyTodos from './components/Users/Todo/CompanyTodos';
import MyToDo from './components/Users/Todo/MyToDo';
import AddClientLead from './components/Users/Sales/AddClientLead';
import ClientLeadInfo from './components/Users/Sales/ClientLeadInfo';

import TechRoute from './components/Routing/TechRoute';
import TechLogin from './components/Techs/TechLogin';
import TechLanding from './components/Techs/TechLanding';
import Helpdesk from './components/Techs/Tickets/Helpdesk';
import Clients from './components/Techs/Clients/Clients';
import AddClient from './components/Techs/Clients/AddClient';
import Client from './components/Techs/Clients/Client';
import HomeClients from './components/Techs/Clients/HomeClients';
import TechAdmin from './components/Techs/TechAdmin';
import AddTech from './components/Techs/AddTech';
import TechTicket from './components/Techs/Tickets/TechTicket';
import Project from './components/Techs/Projects/Project';
import UserAdmin from './components/Techs/Clients/User';
import ClientReport from './components/Techs/Clients/ClientReport';
import UserReview from './components/Techs/Clients/UserReview';
import Department from './components/Techs/Clients/Department';
import Branch from './components/Techs/Clients/Branch';
import Pipeline from './components/Techs/Sales/Pipeline';
import AddLead from './components/Techs/Sales/AddLead';
import LeadInfo from './components/Techs/Sales/LeadInfo';
import ReviewAndClose from './components/Techs/Sales/ReviewAndClose';
import ClosedLeads from './components/Techs/Sales/ClosedLeads';
import ITTodos from './components/Techs/Techtodos/ITTodos';
import CreateTodo from './components/Techs/Techtodos/CreateTodo';
import TechTodo from './components/Techs/Techtodos/TechTodo';
import CompleteTodos from './components/Techs/Techtodos/CompleteTodos';
import HowTos from './components/Techs/HowTos/HowTos';
import TechProjectDash from './components/Techs/Projects/TechProjectDash';

import BusinessRoute from './components/Routing/BusinessRoute';
import BusinessSignin from './components/Business/BusinessSignin';
import BusinessLanding from './components/Business/BusinessLanding';
import BusinessSignup from './components/Business/BusinessSignup';
import BusinessUser from './components/Business/BusinessUser';
import BusinessTicket from './components/Business/Tickets/BusinessTicket';
import BusinessAgents from './components/Business/BusinessAgents';

import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase-config';
import { Provider } from 'react-redux';
import store from './store';
import { persistLogin } from './actions/auth';
import { getTechProfile } from './actions/techs';
import { getUserProfile } from './actions/users';
import { getBusinessProfile, getSupportAgent } from './actions/business/';
import Navbar from './components/layout/Navbar';
import { useState } from 'react';

function App() {
  onAuthStateChanged(auth, (currentUser) => {
    if (currentUser) {
      store.dispatch(persistLogin(currentUser));
      let credentials = currentUser.uid;
      let displayName = currentUser.displayName;
      let domain = currentUser.email.split('@').pop();
      if (domain === 'callitservices.co.za') {
        store.dispatch(getTechProfile(credentials));
      } else if (displayName === 'Business') {
        store.dispatch(getBusinessProfile(credentials));
      } else if (displayName === 'Agent') {
        store.dispatch(getSupportAgent(credentials));
      } else {
        store.dispatch(getUserProfile(credentials));
      }
    }
  });
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  <Router />;

  return (
    <Provider store={store}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <SideBar isSidebar={isSidebar} />
            <main className="content">
              <Navbar setIsSidebar={setIsSidebar} />
              <Alert />
              <Routes>
                <Route path="/" element={<Support />} />
                <Route path="tech/log-in" element={<TechLogin />} />
                <Route path="business/sign-up" element={<BusinessSignup />} />
                <Route path="business/sign-in" element={<BusinessSignin />} />
                <Route path="user/sign-in" element={<User />} />
                <Route path="email-verification" element={<VerifyEmail />} />
                <Route
                  path="tech/tech-landing"
                  element={<TechRoute component={TechLanding} />}
                />
                <Route
                  exact
                  path="tech/tech-helpesk"
                  element={<TechRoute component={Helpdesk} />}
                />
                <Route
                  exact
                  path="tech/it-todo"
                  element={<TechRoute component={ITTodos} />}
                />
                <Route
                  exact
                  path="tech/complete-todos"
                  element={<TechRoute component={CompleteTodos} />}
                />
                <Route
                  exact
                  path="tech/create-todo"
                  element={<TechRoute component={CreateTodo} />}
                />
                <Route
                  exact
                  path="tech/tech-todo/:todoId"
                  element={<TechRoute component={TechTodo} />}
                />
                <Route
                  exact
                  path="tech/tech-projects"
                  element={<TechRoute component={TechProjectDash} />}
                />
                <Route
                  exact
                  path="tech/tech-clients"
                  element={<TechRoute component={Clients} />}
                />
                <Route
                  exact
                  path="tech/home-users"
                  element={<TechRoute component={HomeClients} />}
                />
                <Route
                  exact
                  path="tech/tech-clients/:clientId"
                  element={<TechRoute component={Client} />}
                />
                <Route
                  exact
                  path="tech/client-report/:clientId"
                  element={<TechRoute component={ClientReport} />}
                />
                <Route
                  exact
                  path="tech/client-user-report/:clientId"
                  element={<TechRoute component={UserReview} />}
                />
                <Route
                  exact
                  path="tech/client-branch/:branchId"
                  element={<TechRoute component={Branch} />}
                />
                <Route
                  exact
                  path="tech/client-department/:departmentId"
                  element={<TechRoute component={Department} />}
                />
                <Route
                  exact
                  path="tech/add-client"
                  element={<TechRoute component={AddClient} />}
                />
                <Route
                  exact
                  path="tech/tech-admin"
                  element={<TechRoute component={TechAdmin} />}
                />
                <Route
                  exact
                  path="tech/add-tech"
                  element={<TechRoute component={AddTech} />}
                />
                <Route
                  exact
                  path="tech/tech-ticket/:ticketId"
                  element={<TechRoute component={TechTicket} />}
                />
                <Route
                  exact
                  path="tech/project/:projectId"
                  element={<TechRoute component={Project} />}
                />
                <Route
                  exact
                  path="tech/user-admin/:userId"
                  element={<TechRoute component={UserAdmin} />}
                />
                <Route
                  exact
                  path="tech/sales-pipeline"
                  element={<TechRoute component={Pipeline} />}
                />
                <Route
                  exact
                  path="tech/add-tech-lead"
                  element={<TechRoute component={AddLead} />}
                />
                <Route
                  exact
                  path="tech/lead-info/:leadId"
                  element={<TechRoute component={LeadInfo} />}
                />
                <Route
                  exact
                  path="tech/review-and-close/:leadId"
                  element={<TechRoute component={ReviewAndClose} />}
                />
                <Route
                  exact
                  path="tech/how-tos"
                  element={<TechRoute component={HowTos} />}
                />
                <Route
                  exact
                  path="tech/closed-leads"
                  element={<TechRoute component={ClosedLeads} />}
                />
                <Route
                  exact
                  path="user/client-admin"
                  element={<UserRoute component={ClientAdmin} />}
                />
                <Route
                  exact
                  path="user/ticket-review"
                  element={<UserRoute component={TicketReview} />}
                />
                <Route
                  exact
                  path="/user/sales-pipeline/:clientId"
                  element={<UserRoute component={SalesDashBoard} />}
                />
                <Route
                  exact
                  path="/user/sales/create-lead/:clientId"
                  element={<UserRoute component={AddClientLead} />}
                />
                <Route
                  exact
                  path="/user/sales/client-lead/:leadId"
                  element={<UserRoute component={ClientLeadInfo} />}
                />
                <Route
                  exact
                  path="user/project-review"
                  element={<UserRoute component={ProjectReview} />}
                />
                <Route
                  exact
                  path="user/user-landing"
                  element={<UserRoute component={UserLanding} />}
                />
                <Route
                  exact
                  path="/user/helpdesk"
                  element={<UserRoute component={UserHelpdesk} />}
                />
                <Route
                  exact
                  path="user/user-projects/:clientId"
                  element={<UserRoute component={UserProjectDash} />}
                />
                <Route
                  exact
                  path="user/my-ticket/:ticketId"
                  element={<UserRoute component={MyTicket} />}
                />
                <Route
                  exact
                  path="user/my-project/:projectId"
                  element={<UserRoute component={MyProject} />}
                />
                <Route
                  exact
                  path="user/user-profile"
                  element={<UserRoute component={UserProfile} />}
                />
                <Route
                  exact
                  path="user/company-management/:clientId"
                  element={<UserRoute component={CompanyManagement} />}
                />
                <Route
                  exact
                  path="user/company-branch/:branchId"
                  element={<UserRoute component={CompanyBranch} />}
                />
                <Route
                  exact
                  path="user/company-department/:departmentId"
                  element={<UserRoute component={CompanyDepartment} />}
                />
                <Route
                  exact
                  path="user/create-todo/:clientId"
                  element={<UserRoute component={AddTodo} />}
                />
                <Route
                  exact
                  path="user/company-todos/:clientId"
                  element={<UserRoute component={CompanyTodos} />}
                />
                <Route
                  exact
                  path="user/my-todo/:todoId"
                  element={<UserRoute component={MyToDo} />}
                />
                <Route
                  exact
                  path="business/business-landing"
                  element={<BusinessRoute component={BusinessLanding} />}
                />
                <Route
                  exact
                  path="business/business-user/:userId"
                  element={<BusinessRoute component={BusinessUser} />}
                />
                <Route
                  exact
                  path="/business/biz-ticket/:ticketId"
                  element={<BusinessRoute component={BusinessTicket} />}
                />
                <Route
                  exact
                  path="/business/business-agents"
                  element={<BusinessRoute component={BusinessAgents} />}
                />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Provider>
  );
}

export default App;
