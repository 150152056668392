import React from 'react';
import { Grid, Typography, Divider } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LaptopIcon from '@mui/icons-material/Laptop';
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';

const Device = ({ device }) => {
  if (device.userName === null) {
    return (
      <>
        <Grid container spacing={2}>
          <Grid xs={6} item>
            <Typography gutterBottom>
              <DesktopAccessDisabledIcon
                sx={{ marginRight: '20px', color: 'red' }}
              />
              Decommisioned
            </Typography>
          </Grid>
          <Grid xs={6} item>
            <Typography gutterBottom>
              <LaptopIcon sx={{ marginRight: '20px' }} />
              {device.deviceModel}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ marginTop: '12px' }} />
      </>
    );
  } else {
    return (
      <>
        <Grid container spacing={2}>
          <Grid xs={6} item>
            <Typography gutterBottom>
              <PersonOutlineIcon sx={{ marginRight: '20px' }} />
              {device.userName}
            </Typography>
          </Grid>
          <Grid xs={6} item>
            <Typography gutterBottom>
              <LaptopIcon sx={{ marginRight: '20px' }} />
              {device.deviceModel}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ marginTop: '12px' }} />
      </>
    );
  }
};

export default Device;
