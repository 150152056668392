import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Alert,
  CircularProgress,
  Container,
  Typography,
  Divider,
} from '@mui/material';
import { homeUsers } from '../../../actions/clients/clients';
import './Clients.css';
import HomeClient from './HomeClient';

const HomeClients = ({ homeUsers, clients }) => {
  useEffect(() => {
    homeUsers();
  }, [homeUsers]);

  if (clients === null) {
    return <CircularProgress />;
  }
  return (
    <Container sx={{ flexGrow: 1 }}>
      <Grid id="add-client" container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Home clients</Typography>
          <Divider variant="middle" sx={{ mb: '10px', mt: '5px' }} />
          {clients.length === 0 ? (
            <Alert
              icon={false}
              severity="warning"
              style={{ marginTop: '10px' }}
            >
              No Clients
            </Alert>
          ) : (
            clients.map((client) => (
              <HomeClient key={client.id} client={client} />
            ))
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  clients: state.client.home_users,
});

export default connect(mapStateToProps, { homeUsers })(HomeClients);
