import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Alert,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

import {
  getBranches,
  getDepartments,
  currentUserClient,
  getClientUsers,
  createAnewUser,
} from '../../../actions/users/admin';
import Users from './Users';
import DepartmentItem from './DepartmentItem';
import BranchItem from './BranchItem';
import CreateBranch from './CreateBranch';
import CreateDepartment from './CreateDepartment';
import AddUser from './AddUser';

const CompanyManagement = ({
  user_admin: { client, loading, branches, departments, users },
  user,
  getBranches,
  getDepartments,
  currentUserClient,
  getClientUsers,
  createAnewUser,
}) => {
  const { clientId } = useParams();

  // const navigate = useNavigate();

  useEffect(() => {
    getBranches(clientId);
    getDepartments(clientId);
    currentUserClient(clientId);
    getClientUsers(clientId);
  }, [
    clientId,
    getBranches,
    getDepartments,
    currentUserClient,
    getClientUsers,
  ]);

  if (loading || client === null || users === null) {
    return <CircularProgress />;
  }

  // const goToToDo = () => {
  //   navigate(`/user/create-todo/${clientId}`);
  // };

  // const viewToDos = () => {
  //   navigate(`/user/company-todos/${clientId}`);
  // };

  return (
    <Container>
      <Typography variant="h6">{client.name} management</Typography>
      <Divider
        variant="middle"
        sx={{ marginBottom: '5px', marginTop: '5px' }}
      />

      <Grid container>
        <Grid xs={8} item>
          <Grid container>
            <Grid item xs={11}>
              <Alert variant="outlined" sx={{ marginTop: '5px' }}>
                Current users
              </Alert>
            </Grid>
            <Grid item xs={1}>
              <AddUser
                client={client}
                createAnewUser={createAnewUser}
                clientId={clientId}
              />
            </Grid>
          </Grid>

          {users !== null && users.length === 0 ? (
            <Alert>No users...</Alert>
          ) : (
            users.map((user) => <Users key={user.id} user={user} />)
          )}
        </Grid>
        <Grid xs={4} item>
          <Grid xs={12} item>
            <Grid container>
              <Grid xs={10} item>
                <Alert variant="outlined" severity="info">
                  Departments
                </Alert>
              </Grid>
              <Grid xs={2} item>
                <CreateDepartment clientId={clientId} user={user} />
              </Grid>
            </Grid>
            {departments.length === 0 ? (
              <Alert severity="warning">No departments</Alert>
            ) : (
              departments.map((dep) => (
                <DepartmentItem key={dep.id} department={dep} />
              ))
            )}
          </Grid>
          <Grid xs={12} item>
            <Grid container>
              <Grid item xs={10}>
                <Alert variant="outlined" severity="warning">
                  Branches
                </Alert>
              </Grid>
              <Grid xs={2} item>
                <CreateBranch clientId={clientId} user={user} />
              </Grid>
            </Grid>

            {branches.length === 0 ? (
              <Alert severity="warning" sx={{ marginTop: '5px' }}>
                No branches
              </Alert>
            ) : (
              branches.map((branch) => (
                <BranchItem key={branch.id} branch={branch} />
              ))
            )}
          </Grid>
          <Grid xs={12} item>
            <Alert
              variant="outlined"
              sx={{ marginTop: '5px' }}
              severity="error"
            >
              Former users
            </Alert>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user_admin: state.user_admin,
  user: state.user.user_profile,
});

export default connect(mapStateToProps, {
  getBranches,
  getDepartments,
  currentUserClient,
  getClientUsers,
  createAnewUser,
})(CompanyManagement);
