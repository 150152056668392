import Dropzone from 'react-dropzone';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const UploadLeadFile = ({ uploadThisFile, type }) => {
  const types = ['image/png', 'image/jpeg', 'application/pdf'];
  const upload = async (files) => {
    let selected = files[0];

    if (selected && types.includes(selected.type)) {
      uploadThisFile({ selected, type });
    } else {
      console.log('Invalid File');
    }
  };
  return (
    <>
      <Dropzone onDrop={upload}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <AttachFileIcon />
          </div>
        )}
      </Dropzone>
    </>
  );
};

export default UploadLeadFile;
