import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createSavedPassword } from '../../../actions/users/passwords';
import random from 'random-string';

import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Grid,
  Alert,
} from '@mui/material';

const CreatePassword = ({ createSavedPassword, alert, userId }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [pwd, setPwd] = useState('');
  const [formError, setFormError] = useState(null);
  const [adding, setAdding] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setName('');
    setPwd('');
    setOpen(false);
  };

  useEffect(() => {
    if (alert.length > 0 && adding) {
      setName('');
      setPwd('');
      setAdding(false);
      setOpen(false);
    }
  }, [alert, adding]);

  const createNewPassword = () => {
    //Make sure password has a name//
    if (name.length === 0) {
      setFormError('Please enter a name for your password');

      return setTimeout(() => {
        setFormError(null);
      }, 3000);
    }

    //Make sure password is not blank//
    if (pwd.length === 0) {
      setFormError('Please enter a password');
      return setTimeout(() => {
        setFormError(null);
      }, 3000);
    }

    //Create a password//
    setAdding(true);
    createSavedPassword({ userId, pwd, name });
  };

  const genPass = () => {
    const password = random({
      length: 10,
      special: true,
    });
    setPwd(password);
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        style={{ marginTop: '5px' }}
      >
        New password
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add a new password</DialogTitle>
        <DialogContent dividers={true}>
          {formError && <Alert severity="error">{formError}</Alert>}
          <DialogContentText>
            All passwords are encrypted and none of our staff have access to
            them, the only person who can see and decrypt the passwords you
            create is you.
          </DialogContentText>
          <TextField
            margin="dense"
            label="What is this password for?"
            type="text"
            name="name"
            value={name}
            fullWidth
            variant="standard"
            onChange={(e) => setName(e.target.value)}
            required
          />
          <Grid container>
            <Grid item xs={8}>
              <TextField
                margin="dense"
                label="Please enter or genrate a password"
                type="text"
                fullWidth
                variant="standard"
                name="pwd"
                value={pwd}
                onChange={(e) => setPwd(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                size="sm"
                color="secondary"
                onClick={genPass}
                style={{ marginTop: '10px' }}
              >
                Generate Password
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
          {adding === true ? (
            <Button variant="outlined">Creating password...</Button>
          ) : (
            <Button
              variant="outlined"
              color="success"
              onClick={createNewPassword}
            >
              Create password
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default connect(mapStateToProps, { createSavedPassword })(
  CreatePassword
);
