import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { addDeviceData } from '../../../actions/clients/clients';
import {
  Container,
  Grid,
  TextField,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const AddDeviceDetails = ({ addDeviceData, userId, note, clientId, user }) => {
  const [open, setOpen] = useState(false);
  const [purchased, setPurchaseDate] = useState(new Date());
  const [deviceWarranty, setWarranty] = useState(new Date());
  useEffect(() => {
    if (note) {
      if (note.length > 0) {
        setOpen(false);
      }
    }
  }, [note]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setFormData({
      deviceType: '',
      deviceModel: '',
      serialNumber: '',
      os: '',
      officeVersion: '',
      antiVirus: '',
      deviceSpecs: '',
      list: '',
    });
    setPurchaseDate('');
    setWarranty('');
    setOpen(false);
  };

  const [formData, setFormData] = useState({
    deviceType: '',
    deviceModel: '',
    serialNumber: '',
    deviceSpecs: '',
    os: '',
    officeVersion: '',
    antiVirus: '',
    list: '',
  });

  const {
    deviceType,
    deviceModel,
    os,
    officeVersion,
    antiVirus,
    list,
    deviceSpecs,
    serialNumber,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const addData = () => {
    const software = list.split(',');
    const userName = user.name;
    const purchaseDate = moment(purchased).format();
    const warranty = moment(deviceWarranty).format();
    addDeviceData({
      deviceType,
      deviceModel,
      serialNumber,
      purchaseDate,
      warranty,
      deviceSpecs,
      os,
      officeVersion,
      antiVirus,
      software,
      userId,
      clientId,
      userName,
    });
    setFormData({
      deviceType: '',
      deviceModel: '',
      serialNumber: '',
      deviceSpecs: '',
      os: '',
      officeVersion: '',
      antiVirus: '',
      list: '',
    });
  };

  return (
    <Container>
      <Button
        variant="outlined"
        severity="primary"
        onClick={handleOpen}
        size="small"
      >
        Add user device details
      </Button>
      <Dialog open={open} onClose={handleClose} scroll="paper">
        <DialogTitle id="scroll-dialog-title">Add Device details</DialogTitle>
        <DialogContent dividers={true}>
          <Grid mt={2} item>
            <TextField
              label="Device Type"
              variant="outlined"
              type="text"
              name="deviceType"
              value={deviceType}
              onChange={onChange}
              autoComplete="false"
              fullWidth
              required
            />
          </Grid>
          <Grid mt={2} item>
            <TextField
              label="Device Make & Model"
              variant="outlined"
              type="text"
              name="deviceModel"
              value={deviceModel}
              onChange={onChange}
              autoComplete="false"
              fullWidth
              required
            />
          </Grid>
          <Grid mt={2} item>
            <TextField
              label="Serial Number"
              variant="outlined"
              type="text"
              name="serialNumber"
              value={serialNumber}
              onChange={onChange}
              autoComplete="false"
              fullWidth
              required
            />
          </Grid>
          <Grid mt={2} item>
            <TextField
              label="Device Specs"
              variant="outlined"
              type="text"
              name="deviceSpecs"
              value={deviceSpecs}
              onChange={onChange}
              autoComplete="false"
              fullWidth
              required
            />
          </Grid>
          <Grid mt={2} item>
            <TextField
              label="Operating System"
              variant="outlined"
              type="text"
              name="os"
              value={os}
              onChange={onChange}
              autoComplete="false"
              fullWidth
              required
            />
          </Grid>
          <Grid mt={2} item>
            <TextField
              label="Microsoft Office Version"
              variant="outlined"
              type="text"
              name="officeVersion"
              value={officeVersion}
              onChange={onChange}
              autoComplete="false"
              fullWidth
              required
            />
          </Grid>
          <Grid mt={2} item>
            <TextField
              label="Anti Virus"
              variant="outlined"
              type="text"
              name="antiVirus"
              value={antiVirus}
              onChange={onChange}
              autoComplete="false"
              fullWidth
              required
            />
          </Grid>
          <Grid mt={2} item>
            <TextField
              label="Other software"
              variant="outlined"
              type="text"
              multiline={true}
              name="list"
              value={list}
              onChange={onChange}
              autoComplete="false"
              fullWidth
            />
          </Grid>
          <Stack mt={2} mb={2} direction="row" spacing={2}>
            <div>
              Purchase date:
              <DatePicker
                selected={purchased}
                onChange={(date) => setPurchaseDate(date)}
              />
            </div>
            <div>
              Warranty expiring:
              <DatePicker
                selected={deviceWarranty}
                onChange={(date) => setWarranty(date)}
              />
            </div>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={addData} mt={2} fullWidth>
            Add Device data
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  note: state.alert,
});

export default connect(mapStateToProps, { addDeviceData })(AddDeviceDetails);
