import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Grid, Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';

function HomeClient({ client }) {
  let navigate = useNavigate();
  return (
    <>
      <Grid
        container
        spacing={2}
        id="client-details"
        onClick={() => navigate(`/tech/user-admin/${client.id}`)}
      >
        <Grid item xs={6}>
          <Typography gutterBottom>
            <HomeIcon sx={{ marginRight: '10px' }} /> {client.name}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography gutterBottom>
            <EmailIcon sx={{ marginRight: '10px' }} /> {client.email}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
}

export default HomeClient;
