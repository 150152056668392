import { useEffect } from 'react';
import { connect } from 'react-redux';
import { currentUserClient } from '../../../actions/users/admin';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { tokens } from '../../../theme';
import { getLeads } from '../../../actions/users/sales';
import SalesPipeline from './SalesPipeline';

const SalesDashBoard = ({
  client,
  currentUserClient,
  getLeads,
  pipeline_leads,
}) => {
  const { clientId } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let navigate = useNavigate();
  useEffect(() => {
    currentUserClient(clientId);
    getLeads(clientId);
  }, [clientId, currentUserClient, getLeads]);

  if (client === null || pipeline_leads === null) return <CircularProgress />;

  const navToCreate = () => {
    navigate(`/user/sales/create-lead/${clientId}`);
  };
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid xs={10} item>
          <Typography variant="h4" sx={{ ml: '20px', color: colors.grey[500] }}>
            Sales pipeline
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            onClick={navToCreate}
            sx={{
              borderColor: colors.blueAccent[100],
              color: colors.blueAccent[100],
            }}
          >
            Add lead
          </Button>
        </Grid>
      </Grid>

      <Divider
        variant="middle"
        sx={{ marginBottom: '10px', marginTop: '10px' }}
      />
      <Grid container>
        <Grid xs={8} item>
          <Typography variant="h6" sx={{ ml: '20px', color: colors.grey[500] }}>
            Your sales pipeline
          </Typography>
          {pipeline_leads.length === 0 ? (
            'You have no leads'
          ) : (
            <SalesPipeline sales={pipeline_leads} />
          )}
        </Grid>
        <Grid xs={4} item>
          <Typography variant="h6" sx={{ ml: '20px', color: colors.grey[500] }}>
            Sales overview
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: state.user_admin.client,
  pipeline_leads: state.client_sales.pipeline_leads,
});

export default connect(mapStateToProps, { currentUserClient, getLeads })(
  SalesDashBoard
);
