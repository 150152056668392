import { connect } from 'react-redux';
import { Alert } from '@mui/material';

const Note = ({ alerts }) =>
  alerts !== null &&
  alerts.length > 0 &&
  alerts.map((alert) => (
    <Alert key={alert.id} severity={`${alert.alertType}`}>
      <strong>{alert.msg}</strong>
    </Alert>
  ));

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(Note);
