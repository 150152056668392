import {
  GET_PROJECT,
  GET_PROJECTS,
  CLEAR_PROJECTS,
  NEXT,
  FEATURE,
  MILESTONE,
} from '../../actions/types';

const initState = {
  project: null,
  projects: null,
  loading: true,
  next: null,
  milestones: null,
  feature: null,
};

function projectReducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROJECTS:
      return {
        ...state,
        projects: payload,
        loading: false,
      };
    case GET_PROJECT:
      return {
        ...state,
        project: payload,
        loading: false,
      };
    case CLEAR_PROJECTS:
      return {
        ...state,
        project: null,
        projects: null,
        loading: true,
      };
    case NEXT:
      return {
        ...state,
        next: payload,
        loading: false,
      };
    case MILESTONE:
      return {
        ...state,
        milestones: payload,
        loading: false,
      };
    case FEATURE:
      return {
        ...state,
        feature: payload,
        loading: false,
      };
    default:
      return state;
  }
}
export default projectReducer;
