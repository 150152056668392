import {
  PENDING_TICKETS,
  ADMIN_TICKET,
  ADMIN_TEXTS,
  CLEAR_ADMIN_TICKET,
  CLEAR_UPLOAD,
  CLIENT_TICKETS,
  USER_TICKETS,
  CUSTOM_TICKET_RANGE,
  TIME_ON_TICKETS,
  TIME_ON_CUSTOM_TICKETS,
  STILL_ACTIVE,
  CUSTOM_STILL_ACTIVE,
  TICKETS_THAT_NEED_REVIEW,
} from '../types';
import { firestore } from '../../firebase-config';
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  where,
  addDoc,
  updateDoc,
  deleteDoc,
  getDocs,
} from 'firebase/firestore';
import { setAlert } from '../alert';
import moment from 'moment';
import axios from 'axios';

const backend = process.env.REACT_APP_BACKEND_URL;

//Create ticket for user//
export const createUserTicket =
  ({
    header,
    howbad,
    desc,
    userId,
    username,
    clientId,
    createdAt,
    clientName,
    createdBy,
    email,
  }) =>
  async (dispatch) => {
    const ticket = collection(firestore, 'tickets');
    const data = await getDocs(ticket);

    let tickets = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    const num = tickets.length + 1;
    const ticketNum = `CIT-00${num}`;

    let isComplete = false;
    let notified = false;
    let review = null;
    try {
      await addDoc(ticket, {
        username,
        header,
        howbad,
        desc,
        userId,
        clientId,
        createdAt,
        isComplete,
        clientName,
        createdBy,
        email,
        ticketNum,
        notified,
        review,
      });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const body = JSON.stringify({ email, username, header });

      await axios.post(
        `${backend}/api/appemails/ticket-onbehalf`,
        body,
        config
      );
      dispatch(setAlert('You have successfully created the ticket', 'success'));
    } catch (error) {
      console.error(error.message);
    }
  };

//Fetch pending tickets//
export const fetchTickets = () => async (dispatch) => {
  const ticketsRef = collection(firestore, 'tickets');
  const q = query(
    ticketsRef,
    where('isComplete', '==', false),
    orderBy('createdAt')
  );

  onSnapshot(q, (snapshot) => {
    let tickets = [];
    snapshot.docs.forEach((doc) => {
      tickets.push({ ...doc.data(), id: doc.id });
    });

    const bizTikets = tickets.filter((ticket) => {
      return ticket.isBusiness !== true;
    });
    dispatch({ type: PENDING_TICKETS, payload: bizTikets });
  });
};

//Fetch Tickets for a specific client//
export const fetchClientTickets = (clientId) => async (dispatch) => {
  try {
    const ticketRef = collection(firestore, 'tickets');
    const q = query(
      ticketRef,
      where('clientId', '==', clientId),
      orderBy('createdAt')
    );
    onSnapshot(q, (snapshot) => {
      let tickets = [];
      snapshot.docs.forEach((doc) => {
        tickets.push({ ...doc.data(), id: doc.id });
      });
      dispatch({ type: CLIENT_TICKETS, payload: tickets });
    });
  } catch (error) {
    console.error('Error Fetching Client Tickets: ', error.message);
  }
};

//fetch and listen to ticket texts//
export const fetchTexts = (ticketId) => (dispatch) => {
  const textsRef = collection(firestore, 'texts');

  const q = query(
    textsRef,
    where('ticketId', '==', ticketId),
    orderBy('createdAt')
  );

  const unsub = onSnapshot(q, (snapshot) => {
    let texts = [];
    snapshot.docs.forEach((doc) => {
      texts.push({ ...doc.data(), id: doc.id });
    });
    dispatch({ type: ADMIN_TEXTS, payload: texts });
  });
  return unsub;
};

//Fetch a single ticket//
export const fetchTicket = (ticketId) => async (dispatch) => {
  dispatch({ type: CLEAR_ADMIN_TICKET });
  const ticketRef = doc(firestore, 'tickets', ticketId);

  const ticket = await getDoc(ticketRef);
  const adminTicket = ticket.data();
  dispatch({ type: ADMIN_TICKET, payload: adminTicket });
};

//Send text//
export const ticketMsg =
  ({ text, authorId, authorName, ticketId, createdAt, deviceToken }) =>
  async (dispatch) => {
    const message = collection(firestore, 'texts');

    try {
      await addDoc(message, {
        text,
        authorId,
        authorName,
        ticketId,
        createdAt,
      });

      const noteData = {
        notification: {
          title: 'Call IT Services',
          body: `${text}`,
        },
        data: {
          click_action: 'FLUTTER_NOTIFICATION_CLICK',
          id: `${ticketId}`,
          status: 'done',
        },
        to: `${deviceToken}`,
      };

      const body = JSON.stringify(noteData);

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `key=${process.env.REACT_APP_AUTH_KEY}`,
        },
      };

      await axios.post('https://fcm.googleapis.com/fcm/send', body, config);

      dispatch({ type: CLEAR_UPLOAD });
    } catch (error) {
      console.error(error.message);
    }
  };

//Claim Ticket//
export const claimTicket =
  ({ ticketId, techId, techName, email, deviceToken, username }) =>
  async (dispatch) => {
    const ticketRef = doc(firestore, 'tickets', ticketId);

    const newFields = { assignedTo: { id: techId, name: techName } };
    await updateDoc(ticketRef, newFields);

    dispatch(ticketClaimed({ email, username }));

    const noteData = {
      notification: {
        title: 'Call IT Services',
        body: 'A technician has been assigned to your ticket',
      },
      data: {
        click_action: 'FLUTTER_NOTIFICATION_CLICK',
        id: `${ticketId}`,
        status: 'done',
      },
      to: `${deviceToken}`,
    };

    const body = JSON.stringify(noteData);

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `key=${process.env.REACT_APP_AUTH_KEY}`,
      },
    };

    await axios.post('https://fcm.googleapis.com/fcm/send', body, config);
    dispatch(setAlert('You have successfully claimed the ticket', 'success'));
    dispatch(fetchTicket(ticketId));
    dispatch(fetchTexts(ticketId));
  };

//Fetch user tickets//
export const fetchUserTickets = (userId) => async (dispatch) => {
  const ticketsRef = collection(firestore, 'tickets');

  try {
    const q = query(
      ticketsRef,
      where('userId', '==', userId),
      orderBy('createdAt')
    );

    onSnapshot(q, (snapshot) => {
      let tickets = [];
      snapshot.docs.forEach((doc) => {
        tickets.push({ ...doc.data(), id: doc.id });
      });

      dispatch({ type: USER_TICKETS, payload: tickets });
    });
  } catch (error) {
    console.error(error.message);
  }
};

//Close a ticket//
export const closeTicket =
  ({ ticketId, deviceToken, email, ticketNum, username }) =>
  async (dispatch) => {
    try {
      const ticketRef = doc(firestore, 'tickets', ticketId);
      const newFields = { isComplete: true, completedAt: moment().format() };
      await updateDoc(ticketRef, newFields);

      const noteData = {
        notification: {
          title: 'Call IT Services',
          body: 'Your ticket has been closed successfully',
        },
        data: {
          click_action: 'FLUTTER_NOTIFICATION_CLICK',
          id: `${ticketId}`,
          status: 'done',
        },
        to: `${deviceToken}`,
      };

      const body = JSON.stringify(noteData);

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `key=${process.env.REACT_APP_AUTH_KEY}`,
        },
      };

      await axios.post('https://fcm.googleapis.com/fcm/send', body, config);
      dispatch(closingTicketEmail({ email, username, ticketNum }));
      dispatch(setAlert('You have successfully closed the ticket', 'success'));
      dispatch(fetchTicket(ticketId));
      dispatch(fetchTexts(ticketId));
    } catch (error) {
      console.error(error.message);
    }
  };

//send email and notification that the ticket has been claimed//
export const ticketClaimed =
  ({ email, username }) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const body = JSON.stringify({ email, username });
      await axios.post(`${backend}/api/appemails/ticket-claimed`, body, config);
    } catch (error) {
      console.error('Email Js Error;', error.message);
    }
  };

//Send email and notification to user that the ticket has been resolved//
export const closingTicketEmail =
  ({ email, username, ticketNum }) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const body = JSON.stringify({ email, username, ticketNum });
      await axios.post(`${backend}/api/appemails/ticket-closed`, body, config);
    } catch (error) {
      console.error(error.message);
    }
  };

//Delete a ticket//
export const deleteTicket =
  ({ ticketId, clientId }) =>
  async (dispatch) => {
    try {
      await deleteDoc(doc(firestore, 'tickets', ticketId));
      dispatch(setAlert('Ticket has been successfully deleted', 'success'));
      dispatch(fetchClientTickets(clientId));
    } catch (error) {
      dispatch(setAlert('Something went wrong', 'error'));
    }
  };
//Fetch client ticket report//
export const clientTicketReport = (clientId) => async (dispatch) => {
  dispatch({ type: CLEAR_ADMIN_TICKET });

  const firstday = moment().date(1).format();
  try {
    const ticketsRef = collection(firestore, 'tickets');
    const q = query(
      ticketsRef,
      where('createdAt', '>=', firstday),
      where('clientId', '==', clientId),
      orderBy('createdAt')
    );

    onSnapshot(q, (snapshot) => {
      let tickets = [];
      snapshot.docs.forEach((doc) => {
        tickets.push({ ...doc.data(), id: doc.id });
      });

      const num = tickets.filter((ticket) => {
        return ticket.isComplete === false;
      });
      dispatch({ type: STILL_ACTIVE, payload: num.length });
      dispatch({ type: CLIENT_TICKETS, payload: tickets });
    });
  } catch (error) {
    console.error(error.message);
  }
};

//Time on tickets//
export const timeOnTickets =
  ({ client_tickets }) =>
  async (dispatch) => {
    try {
      const hours = [];
      const minutes = [];
      client_tickets.forEach((ticket) => {
        function diff_minutes(d1, d2) {
          var diff = (d2.getTime() - d1.getTime()) / 1000;
          diff /= 60;

          if (diff < 60) {
            let time = Math.round(diff);
            minutes.push(time);
          } else if (diff > 60) {
            diff /= 60;
            let time = Math.round(diff);
            hours.push(time);
          }
        }

        let d1 = new Date(ticket.createdAt);
        let d2 = new Date(ticket.completedAt);

        diff_minutes(d1, d2);
      });

      const sumh = hours.reduce((index, value) => {
        return index + value;
      }, 0);

      const summ = minutes.reduce((index, value) => {
        return index + value;
      }, 0);

      const time = sumh + ':' + summ;
      dispatch({ type: TIME_ON_TICKETS, payload: time });
    } catch (error) {
      console.error(error.message);
    }
  };

//Fetch custom ticket report//
export const customTicketReport =
  ({ clientId, first, last }) =>
  async (dispatch) => {
    dispatch({ type: CLEAR_ADMIN_TICKET });
    try {
      const ticketRef = collection(firestore, 'tickets');
      const q = query(
        ticketRef,
        where('createdAt', '>=', first),
        where('clientId', '==', clientId),
        orderBy('createdAt')
      );

      onSnapshot(q, (snapshot) => {
        let tickets = [];
        snapshot.docs.forEach((doc) => {
          tickets.push({ ...doc.data(), id: doc.id });
        });

        const active = tickets.filter((ticket) => {
          return ticket.isComplete === false;
        });
        const loot = tickets.filter((ticket) => {
          return ticket.completedAt <= last;
        });

        //Calculate time spent on tickets//
        const hours = [];
        const minutes = [];
        loot.forEach((ticket) => {
          function diff_minutes(d1, d2) {
            var diff = (d2.getTime() - d1.getTime()) / 1000;
            diff /= 60;

            if (diff < 60) {
              let time = Math.round(diff);
              minutes.push(time);
            } else if (diff > 60) {
              diff /= 60;
              let time = Math.round(diff);
              hours.push(time);
            }
          }

          let d1 = new Date(ticket.createdAt);
          let d2 = new Date(ticket.completedAt);

          diff_minutes(d1, d2);
        });

        const sumh = hours.reduce((index, value) => {
          return index + value;
        }, 0);

        const summ = minutes.reduce((index, value) => {
          return index + value;
        }, 0);

        const time = sumh + ':' + summ;
        dispatch({ type: TIME_ON_CUSTOM_TICKETS, payload: time });
        dispatch({ type: CUSTOM_TICKET_RANGE, payload: loot });
        dispatch({ type: CUSTOM_STILL_ACTIVE, payload: active.length });
      });
    } catch (error) {
      console.error(error.message);
    }
  };

//Get tickets that need review//
export const getTicketsThatNeedReview = () => async (dispatch) => {
  try {
    const ticketRef = collection(firestore, 'tickets');
    const q = query(
      ticketRef,
      where('review', '==', null),
      where('isComplete', '==', true),
      orderBy('createdAt')
    );

    onSnapshot(q, (snapshot) => {
      let tickets = [];
      snapshot.docs.forEach((doc) => {
        tickets.push({ ...doc.data(), id: doc.id });
      });
      dispatch({ type: TICKETS_THAT_NEED_REVIEW, payload: tickets });
    });
  } catch (error) {
    console.error(error.message);
  }
};
