import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon,
  Alert,
  Button,
} from '@mui/material';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { fetchUser, userDevice } from '../../../actions/clients/clients';
import { fetchUserTickets } from '../../../actions/techs/ticket';
import { Box, Grid } from '@mui/material';
import Tickets from '../Tickets/Tickets';
import AddDeviceDetails from './AddDeviceDetails';
import EditDeviceDetails from './EditDeviceDetails';
import DecomisionDevice from './DecomisionDevice';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BackSpaceIcon from '@mui/icons-material/HomeWork';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import ToggleAdmin from './ToggleAdmin';
import UpdateUser from './UpdateUser';
import moment from 'moment';
import DeleteUser from './DeleteUser';
import TicketForClient from './TicketForClient';

const User = ({
  fetchUser,
  userClient: { user, client, device },
  tickets,
  fetchUserTickets,
  userDevice,
}) => {
  const [showTickets, setShowTickets] = useState(true);
  const [software, setSoftware] = useState('');
  useEffect(() => {
    if (device) {
      const soft = device.software.toString();
      setSoftware(soft);
    }
  }, [device]);

  const { userId } = useParams();

  useEffect(() => {
    fetchUser(userId);
    fetchUserTickets(userId);
    userDevice(userId);
  }, [fetchUser, fetchUserTickets, userId, userDevice]);

  if (user === null || tickets === null) return <CircularProgress />;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Grid container>
            <Grid item>
              {client ? (
                <Link as={Link} to={`/tech/tech-clients/${client.id}`}>
                  <Button color="primary">
                    <BackSpaceIcon />
                  </Button>
                </Link>
              ) : (
                <Link as={Link} to={`/tech/home-users`}>
                  <Button color="primary">
                    <BackSpaceIcon />
                  </Button>
                </Link>
              )}
            </Grid>
            <Grid item>
              <ToggleAdmin user={user} userId={userId} />
            </Grid>
            <Grid item>
              <UpdateUser user={user} userId={userId} />
            </Grid>
            <Grid item>
              <DeleteUser user={user} userId={userId} />
            </Grid>
          </Grid>

          <Box id="client-info">
            <List>
              <ListItem>
                <ListItemIcon>
                  <BusinessOutlinedIcon />
                </ListItemIcon>
                {client ? (
                  <ListItemText primary={`${client.name}`} />
                ) : (
                  <ListItemText primary={`Home User`} />
                )}
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemIcon>
                  <AccountCircleOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={`${user.name} ${user.surname}`} />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemIcon>
                  <EmailOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={`${user.email}`} />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemIcon>
                  <SmartphoneIcon />
                </ListItemIcon>
                <ListItemText primary={`${user.cellphone}`} />
              </ListItem>
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container>
            <Grid xs={10} item>
              <Alert style={{ marginBottom: '10px' }} severity="warning">
                User Tickets
              </Alert>
            </Grid>
            <Grid xs={1} item>
              <TicketForClient userId={userId} client={client} user={user} />
            </Grid>
            <Grid xs={1} item>
              {showTickets ? (
                <KeyboardArrowUpIcon
                  style={{ marginTop: '10px' }}
                  onClick={() => setShowTickets(false)}
                  color="warning"
                />
              ) : (
                <KeyboardArrowDown
                  style={{ marginTop: '10px' }}
                  onClick={() => setShowTickets(true)}
                  color="warning"
                />
              )}
            </Grid>
          </Grid>
          {tickets.length === 0 ? (
            <Typography>No Tickets </Typography>
          ) : showTickets ? (
            <List>
              {tickets.map((ticket) => (
                <Tickets key={ticket.id} ticket={ticket} />
              ))}
            </List>
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {device ? (
            <>
              <Grid container>
                <Grid item>
                  <EditDeviceDetails device={device} />
                </Grid>
                <Grid item>
                  <DecomisionDevice device={device} />
                </Grid>
              </Grid>

              <List>
                <ListItem>
                  <ListItemText primary={`Device Type: ${device.deviceType}`} />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={`Make and model: ${device.deviceModel}`}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={`Serial number: ${device.serialNumber}`}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={`Specifications: ${device.deviceSpecs}`}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary={`Operating System: ${device.os}`} />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={`Microsoft office version: ${device.officeVersion}`}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary={`Antivirus: ${device.antiVirus}`} />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText primary={`Software: ${software}`} />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={`Purchase date: ${moment(
                      device.purchaseDate
                    ).format('ll')}`}
                  />
                </ListItem>
                <Divider />
                <ListItem>
                  <ListItemText
                    primary={`Warranty expriary: ${moment(
                      device.warranty
                    ).format('ll')}`}
                  />
                </ListItem>
              </List>
            </>
          ) : client ? (
            <AddDeviceDetails
              userId={userId}
              clientId={client.id}
              user={user}
            />
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  userClient: state.client,
  tickets: state.tech_tickets.user_tickets,
});

export default connect(mapStateToProps, {
  fetchUser,
  fetchUserTickets,
  userDevice,
})(User);
